import { useState } from 'react';

import { useAppDispatch, useTypedSelector } from '../../../store/reducers/use-typed-selector';
import { EPermissions, Session } from '../../../types/working-model';
import { setGreenroomLoginError } from '../../../store/actions/authentication';
import { GreenroomLogin } from '../../../connection/create-event';
import WaitingIndicator from '../waiting-indicator/waiting-indicator';
import { OptionalComponent } from '../../../utils/optional-component';
import { useShowGreenroomButton } from './use-show-greenroom-button';
import { userHasRoleOnActiveChannel } from 'utils/utils';

interface Props {
	session: Session;
}

export default function OpenGRButton({ session }: Props): JSX.Element {
	const publishedUrl = useTypedSelector(state => state.CreateEventReducer.publishedUrl);
	const token = useTypedSelector(state => state.AuthReducer.token);
	const user = useTypedSelector(state => state.AuthReducer.user);

	const allowedPermission = userHasRoleOnActiveChannel([
		EPermissions.Owner,
		EPermissions.Admin,
		EPermissions.Analytics,
		EPermissions.Moderation,
		EPermissions.ModeratorRegistrant,
		EPermissions.PasscodeManager,
		EPermissions.Editor,
		EPermissions.GroupAdmin,
		EPermissions.Viewer,
		EPermissions.Builder,
		EPermissions.Producer,
	], user);

	const [loading, setLoading] = useState(false);

	const dispatch = useAppDispatch();

	const shouldHaveGreenroomProducerUrl = useShowGreenroomButton(session);

	const handleClick = async () => {
		if (!token) return;
		try {
			setLoading(true);
			const result = await GreenroomLogin(token);
			if (!result.token) {
				throw new Error('No token returned from greenroom login');
			}

			const greenroomUrl = `${process.env.REACT_APP_GREENROOM_FRONTEND_URL}/login-with-streams-token/${result.token}?page=/create/${session.uuid}`;
			window.open(greenroomUrl, "_blank");
		} catch (e) {
			console.error(e);
			dispatch(setGreenroomLoginError(true));
		}

		setLoading(false);
	};

	return (
		<OptionalComponent display={shouldHaveGreenroomProducerUrl && allowedPermission}>
			<div title={!publishedUrl ? "Project not yet published" : undefined}>
				<button
					onClick={handleClick}
					disabled={loading}
					className='go-to-greenroom lemonade'
				>
					{loading ? <WaitingIndicator /> : 'Greenroom'}
				</button>
			</div>
		</OptionalComponent>
	);
}
