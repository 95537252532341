import { useEffect } from "react";
import { useTypedSelector } from "../../../store/reducers/use-typed-selector";
import { sleep } from "../../../utils/utils";

const useShowRecaptchaBadge = (): void => {

	const enableRecaptcha = useTypedSelector(state => !!state.LiveEventReducer.eventBundle?.registration_settings?.enableRecaptcha);

	useEffect(() => {
		if (enableRecaptcha) {
			// use recursion here to handle dynamic widget/script loading
			const enableRecaptchaFunc = async (tries = 1) => {
				// if we still haven't picked up the widget after 15 seconds, all hope is lost
				if (tries === 30) return;
				const badges = document.querySelectorAll('.grecaptcha-badge');
				for (let i = 0; i < badges.length; i++) {
					const badge = badges[i] as any;
					if (badge?.style) {
						badge.style.visibility = 'visible';
						return;
					}
				}
				await sleep(500);
				enableRecaptchaFunc(tries + 1);
			};
			enableRecaptchaFunc();
		}

		return () => {
			const badges = document.querySelectorAll('.grecaptcha-badge');
			for (let i = 0; i < badges.length; i++) {
				const badge = badges[i] as any;
				if (badge?.style) {
					badge.style.visibility = 'hidden';
				}
			}
		};
	}, [enableRecaptcha]);
};

export default useShowRecaptchaBadge;
