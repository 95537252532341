import { Dispatch } from "redux";
import { setRegistrationOn, updateHomepageHeader, updateHomepageModule } from "../store/actions/admin/create-event";
import { GetDefaultCallToAction } from "../store/utils/create-event";
import { BrandliveEvent, PageLinkType, PageModuleType } from "../types/working-model";


export const registrationToggled = (
	isOn: boolean,
	workingEvent: BrandliveEvent,
	dispatch: Dispatch<any>,
	batch: (cb: () => void) => void,
): any => {
	// update landing page callToAction buttons (header and callout module) when turning registration on/off
	// only if pointing to registration or home page
	const headerLink = workingEvent?.homepage?.header.callToAction.content.page;
	const calloutModule = workingEvent?.homepage?.modules.find(module => module.type === PageModuleType.callout);
	const calloutLink = calloutModule?.content.callToAction.content.page;

	const newCallToAction = GetDefaultCallToAction(isOn);
	const updateHeaderCallToAction =
		headerLink === PageLinkType.homepage || headerLink === PageLinkType.registration ? newCallToAction : null;
	const updateCalloutCallToAction =
		calloutLink === PageLinkType.homepage || calloutLink === PageLinkType.registration ? newCallToAction : null;

	batch(() => {
		if (updateHeaderCallToAction) {
			dispatch(updateHomepageHeader({
				...workingEvent!.homepage!.header,
				callToAction: updateHeaderCallToAction
			}));
		}
		if (calloutModule && updateCalloutCallToAction) {
			dispatch(updateHomepageModule({
				...calloutModule,
				content: {
					...calloutModule.content,
					callToAction: updateCalloutCallToAction
				}
			}));
		}
		dispatch(setRegistrationOn(isOn));
	});

	return;
};