import { useMemo, useRef } from "react";
import { v4 as uuidv4 } from 'uuid';

import { useTypedSelector } from "../../../store/reducers/use-typed-selector";
import { BlProfileValues, IModeratorProfile } from "../../../types/working-model";
import { GetUploadEndpoint, UploadFileToProvider } from '../../../connection/uploads';
import { EIcon } from "../../general-ui/icon/icon";
import FileInput from "../../../utils/file-input";
import { SetItemContentType } from "../../../connection/multicloud/upload";
import { useAvatarIsEnabled } from "../../../utils/avatar-is-enabled";
import { TypographyItem } from "@general-ui/typography-item/typography-item";
import { useTranslate } from "i18n/useTranslationModules";
import classNames from "classnames";

interface AvatarUploadProps {
	setUploading: (isUploading: boolean) => void;
	setImage: (image: string | null) => void;
	image: string | null;
	isDisplayOnly?: boolean;
	moderatorProfile?: IModeratorProfile;
}

const AvatarUpload: React.FC<AvatarUploadProps> = ({ isDisplayOnly, setUploading, image, setImage, moderatorProfile }) => {
	const blProfileUser = useTypedSelector(state => state.LiveEventReducer.blProfileUser);
	const adminUser = useTypedSelector(state => state.AuthReducer.user?.profile);
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const blProfileUserToken = useTypedSelector(state => state.LiveEventReducer.blProfileUserToken);
	const moderatorRegistration = useTypedSelector(state => state.ModeratorReducer.moderatorRegistration);
	const activeEvent = useTypedSelector(state => state.ModeratorReducer.activeEvent);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);

	const event = moderatorProfile ? activeEvent : (eventBundle ?? workingEvent);

	const channel = event?.channel ?? 0;
	const displayAvatar = useAvatarIsEnabled(event) || moderatorProfile; // allow avatar upload by default if social settings are undefined
	const profile = moderatorProfile ?? blProfileUser?.profile?.[channel];
	const token = moderatorProfile ? moderatorRegistration?.token : blProfileUserToken;
	const { t } = useTranslate('session');
	const uploadRef = useRef<HTMLInputElement | null>(null);

	const upload = (e: any) => {
		if (isDisplayOnly) return null;
		e.preventDefault();
		uploadRef.current?.click();
	};

	const handleSelect = async ({ target }: React.ChangeEvent<HTMLInputElement>) => {
		if (isDisplayOnly || !token) return null;
		if (target.files && target.files[0]) {
			setUploading(true);
			try {
				const file = target.files[0];

				const nameParts = file.name.split(".");

				const suffix = nameParts[nameParts.length - 1];

				const endpoint = await GetUploadEndpoint(token, `${uuidv4()}.${suffix}`, false, true);

				const finalUrl = await UploadFileToProvider(endpoint, file, undefined, `${uuidv4()}.${suffix}`);
				SetItemContentType(token, finalUrl, file.type).catch(console.error);
				setImage(finalUrl);
			} catch (e) {
				console.error(e);
			} finally {
				setUploading(false);
			}
		}
	};

	const initials = useMemo(() => {
		if (workingEvent && adminUser) {
			return getAvatarInitials(adminUser.first_name || '', adminUser.last_name || '', '');
		}

		if (moderatorProfile) {
			return getAvatarInitials(profile.first_name, profile.last_name, '');
		}

		return getAvatarInitials(profile?.[BlProfileValues.firstName], profile?.[BlProfileValues.lastName], profile?.[BlProfileValues.email]);
	}, [moderatorProfile, profile, workingEvent, adminUser]);

	return (
		<div className="registration-avatar" style={image && displayAvatar ? { backgroundImage: `url(${image})` } : {}}>
			{(!displayAvatar || !image) && <span className="registration-avatar-name">{initials}</span>}
			<FileInput onChange={handleSelect} ref={uploadRef} type="file" style={{ display: 'none' }} accept={['image/*']} />

			{displayAvatar &&
				<button role="button" className={classNames("avatar-upload secondary", { 'has-image': image })} onClick={upload} disabled={isDisplayOnly}>
					<EIcon name="camera" size="large" aria-hidden="true" />
					<TypographyItem className="evt-typography-caption-l1" tagName="span">
						{image ? t("Replace") : t("Add")}
					</TypographyItem>
				</button>
			}
		</div>
	);
};

export default AvatarUpload;

export function getAvatarInitials(firstName: string, lastName: string, email?: string): string {
	let str = '';
	if (firstName && lastName) {
		if (firstName?.[0]) {
			str += firstName[0];
		}

		if (lastName?.[0]) {
			str += lastName[0];
		}
	} else if (email) {
		str += email[0];
	}

	return str;
}