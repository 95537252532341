import classNames from "classnames";
import React from "react";

import { useTranslate } from "../../../i18n/useTranslationModules";
import { Field, FieldLabel, FieldProps } from "../field/field";
import { TypographyItem } from "../typography-item/typography-item";
import "../../../scss/live-event/base/general-ui/select-native.scss";

export interface SelectOption {
	label: string;
	value: any;
}
export type TSelectViewType = "default" | "expanded";
export interface SelectNativeProps extends FieldProps<string> {
	options?: SelectOption[];
	className?: string;
	onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
	onBlur?: (e: React.FocusEvent<HTMLSelectElement>) => void;
	value?: string | number;
	viewType?: TSelectViewType;
	template?: string;
	persistent?: boolean;
	disabled?: boolean;
}

// eslint-disable-next-line react/display-name
const SelectNative: React.FC<SelectNativeProps> = React.forwardRef<
	HTMLSelectElement,
	SelectNativeProps
>(
	(props, ref): JSX.Element => {
		const {
			options,
			label,
			defaultValue = null,
			onChange,
			className,
			name,
			onBlur,
			required,
			value,
			viewType = "default",
			template,
			placeholder,
			disabled = false,
		} = props;
		const { t } = useTranslate("homepage");

		const renderExpandedOption = (option: SelectOption): JSX.Element => {
			return (
				<button
					className={classNames("clear no-style inherit-dimensions evt-modal-filters-list-item", {
						selected: option.value === value,
					})}
					disabled={disabled}
					onClick={(e) => {
						onChange &&
							onChange({
								...e,
								name,
								target: { name: name || "", value: option.value },
							} as any);
					}}
					key={option.value}
				>
					{ // eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-expect-error}
						t(option.label, { nsSeparator: false }) ?? option.label
					}
				</button>
			);
		};

		return (
			<Field {...props}>
				{viewType === "default" && (
					<>
						<FieldLabel
							label={label}
							required={required}
						/>
						<select
							ref={ref}
							name={name || ""}
							onBlur={onBlur}
							onChange={onChange}
							className={classNames("evt-select", className, template)}
							defaultValue={defaultValue || undefined}
							value={value || undefined}
							aria-label={label}
							disabled={disabled}
						>
							<option value='' disabled selected={!defaultValue} hidden>{placeholder || t('homepage:registration.selectOption')}</option>
							{options?.map((option: SelectOption, index: number) => (
								<option key={option.value + index} value={option.value}>
									{
										t(option.label, option.label)
									}
								</option>
							))}
						</select>
					</>
				)}

				{viewType === "expanded" && (
					<div className="evt-modal-filters-inner">
						<TypographyItem className="evt-modal-filters-title stable">
							{label}
						</TypographyItem>
						<div className="evt-modal-filters-list">
							{options?.map((option) => renderExpandedOption(option))}
						</div>
					</div>
				)}
			</Field>
		);
	}
);
export default SelectNative;

// TODO will update in future
// export default function SelectNative({
// 	options,
// 	label,
// 	defaultSelected = null,
// 	onChange,
// 	size = 'normal',
// }: Props): JSX.Element {
// 	return (
// 		<div className={classNames('field-group select')}>
// 			<label>{label}</label>
// 			<select
// 				onChange={onChange}
// 				className={size}
// 				defaultValue={defaultSelected}
// 			>
// 				{options.map((option: SelectOption) => (
// 					<option key={option.value} value={option.value}>
// 						{option.label}
// 					</option>
// 				))}
// 			</select>
// 		</div>
// 	);
// }
