import { useEffect, useMemo, useRef, useState } from 'react';

import { batch } from 'react-redux';

import { CreateNewColorPack, SaveColor } from '../../../connection/colors';
import { UploadFile } from '../../../connection/uploads';
import { useIsNewNavigation } from '../../../hooks/navigation.hooks';
import {
	addNewColorPack,
	saveColorPack,
	setDesignColors,
	updateNavbarBlurLevel
} from '../../../store/actions/admin/create-event';
import { useAppDispatch, useTypedSelector } from '../../../store/reducers/use-typed-selector';
import { ColorOptions, ENavbarTypes } from '../../../types/working-model';
import { parseColor } from '../../../utils/document-head';
import ColorEditorModal from '../../admin/create/settings/design/colors/color-editor-modal';
import LargeButton from '../button/large-button';
import Icon, { COLORS, ICONS } from '../icon';
import ImageEditor from '../image-editor/image-editor';
import ModalComponent from '../modal/modal';
import WaitingIndicator from '../waiting-indicator/waiting-indicator';
import ColorEditor from '../../admin/create/settings/design/colors/color-editor';
import { showAlert } from '../alert/alert-service';
import FileInput from '../../../utils/file-input';
import { OptionalComponent } from '../../../utils/optional-component';
import useGetTopLevelColorVariables from 'utils/use-get-top-level-color-variables';

interface IThemePreviewProps {
	color?: [string, number]; //color hex, opacity
	imgSrc?: string;
	isCurrent?: boolean;
	onClick: any;
}

const ThemePreview: React.FC<IThemePreviewProps> = (props) => {
	const {
		color,
		imgSrc,
		isCurrent,
		onClick: handleClick
	} = props;

	return (
		<button
			style={{
				backgroundColor: color ? parseColor(color) : "",
				backgroundImage: imgSrc ? `url(${imgSrc})` : "",
				width: '40px',
				height: '40px',
				borderRadius: '50%'
			}}
			className={`round color-option ${isCurrent ? 'selected' : ''}`}
			onClick={handleClick}
		></button>);
};

export enum EContentColorOverrideNames {
	start_time = 'Start Time',
	title = 'Title',
	description = 'Description',
	custom_heading = 'Custom Heading',
	modules = 'Modules',
	background = 'Background',
	navItems = 'Navigation',
	blLogo = 'Brandlive Logo'
}

export enum EModuleStyleOverrides {
	title = 'title',
	description = 'description',
	custom_heading = 'custom_heading',
	primary_button = 'primary_button',
	secondary_butto = 'secondary_button',
	dropdowns = 'dropdowns'
}


interface IEditSectionColorsModalProps {
	open: boolean;
	title: EContentColorOverrideNames;
	backgroundImage?: string;
	isEventPages?: boolean;
	onFinish: (colorKey: string) => void;
	onClose: () => void;
	allowBackgroundImage?: boolean;
}

const EditSectionColorsModal: React.FC<IEditSectionColorsModalProps> = (props) => {
	const {
		open,
		title,
		backgroundImage,
		isEventPages,
		onFinish: handleFinish,
		onClose: handleClose,
		allowBackgroundImage = true,
	} = props;

	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const { token, user } = useTypedSelector(state => state.AuthReducer);
	const dispatch = useAppDispatch();

	const colorPalette = useGetTopLevelColorVariables(workingEvent?.settings.design.colors.colors);

	const [uploading, setUploading] = useState(false);
	const [topLevelColors, setTopLevelColorsColors] = useState(colorPalette);
	const [displayCustomColor, setDisplayCustomColor] = useState(false);
	const [displayAddImage, setDisplayAddImage] = useState(false);
	const [editing, setEditing] = useState<boolean>(false); // used to disable save while user is actively editing the image
	const [imageFile, setImageFile] = useState<string | undefined>(backgroundImage);
	const [resizedImage, setResizedImage] = useState<File | null>();
	const [colorChange, setColorChange] = useState('');
	const [customColor, setCustomColor] = useState<[string, number] | null>(null);
	const [openEditColorModal, setOpenEditColorModal] = useState<boolean>(false);

	const defaultBlurLevel = workingEvent?.settings?.event_navbar?.blurLevel;
	const [blurLevel, setBlurLevel] = useState(defaultBlurLevel || 0);

	const isNewNavigation = useIsNewNavigation();

	const navbarType = workingEvent?.settings?.event_navbar?.navbarType;
	const isTopNavbar = navbarType === ENavbarTypes.Horizontal;

	const activeColor = workingEvent?.settings?.event_navbar?.styling_overrides?.background?.color.split('-')[0];
	const color = colorPalette?.[activeColor || ''];

	const imageRef = useRef<HTMLInputElement | null>(null);

	useEffect(() => {
		if (colorPalette) {
			setTopLevelColorsColors(colorPalette);
		}
	}, [colorPalette]);

	useEffect(() => {
		setImageFile(backgroundImage);
	}, [backgroundImage]);

	const customColors = useMemo(() => {
		return Object.entries(topLevelColors ?? {}).filter((color: any) => color[0].includes('customColor'));
	}, [topLevelColors]);

	async function addCustomColor() {
		if (!workingEvent?.settings?.design?.colors || !topLevelColors || !token) return;

		const allCustomColorByNumber = customColors.map((color: any) => Number(color[0].replace(/\D/g, '')));

		const lastCustomColorNumber = allCustomColorByNumber.reduce((a: number, c: number) => c > a ? c : a, 0);

		const workingColor = { ...workingEvent.settings.design.colors };
		if (customColor) {
			workingColor.colors = { ...workingColor.colors, [`customColor${lastCustomColorNumber + 1}`]: customColor } as ColorOptions;
		}

		if (!Number(workingEvent?.settings.design.colors.channel)) {
			const newColor = await CreateNewColorPack(token, workingColor);
			batch(() => {
				dispatch(addNewColorPack(newColor));
				dispatch(setDesignColors(newColor));
			});
		} else {
			await SaveColor(token, workingColor);
			batch(() => {

				dispatch(setDesignColors(workingColor));
				dispatch(saveColorPack(workingColor));
			});
		}

		if (isNewNavigation && isEventPages) {
			if (isTopNavbar) {
				dispatch(updateNavbarBlurLevel(blurLevel));

				return;
			}

			dispatch(updateNavbarBlurLevel(0));
		}
	}

	const palletteColors = useMemo(() => {
		return Object.entries(topLevelColors ?? {}).filter((color: any) => !color[0].includes('customColor'));
	}, [topLevelColors]);

	const backgroundImgElement = useMemo(() => {
		if (!imageFile) return <></>;

		return (
			<ThemePreview
				key={imageFile}
				imgSrc={imageFile}
				onClick={() => setColorChange(imageFile as string)}
				isCurrent={colorChange === imageFile}
			/>
		);
	}, [imageFile, setColorChange, colorChange]);

	const contentStylingOptions = palletteColors.map(color => {
		if (color[0] && color[1] && Array.isArray(color[1])) {
			return <ThemePreview
				key={color[1][0] + color[0]}
				color={color[1]}
				onClick={() => setColorChange(color[0] as string)}
				isCurrent={colorChange === color[0]}
			/>;
		}
	});

	const customColorElements = customColors.map(color => {
		if (color[0] && color[1] && Array.isArray(color[1])) {
			return <ThemePreview
				key={color[1][0]}
				color={color[1]}
				onClick={() => setColorChange(color[0] as string)}
				isCurrent={colorChange === color[0]}
			/>;

		}
	});

	async function handleAddImage(f: File | FileList) {
		if (!user || !token) return;
		try {
			if (f instanceof File) {
				setUploading(true);
				setResizedImage(f);
				const uploadedImage = await UploadFile(user, token, f);
				setImageFile(uploadedImage);
				setUploading(false);
			}
		} catch (e: any) {
			console.error(e);
			setEditing(false);
			setUploading(false);
			setResizedImage(null);
			if (e?.message) {
				showAlert({
					message: e.message,
					duration: 7000,
					type: "error"
				});
			}
		}
	}

	function handleRestoreDefault() {
		setColorChange("");
	}

	const handleAddNewColor = () => {
		if (isNewNavigation && isTopNavbar && isEventPages) {
			setOpenEditColorModal(true);
			return;
		}

		setDisplayCustomColor(!displayCustomColor);
	};

	const workingStyleOptionMap = {
		background: (
			<div className="style-options">
				{
					displayAddImage ?
						imageFile ?
							<ImageEditor
								originalImage={imageFile}
								onFinished={setResizedImage}
								setEditing={setEditing}
							/>
							:
							<div className="upload-background-image">
								<LargeButton
									title={"Upload Image"}
									subtitle={"Use PNG or JPG files up to 10 Mb size"}
									allowedFileTypes={['image/png', 'image/jpeg', 'image/gif']}
									onFile={handleAddImage}
									style={{ marginTop: 40, marginBottom: 40, paddingTop: 36, paddingBottom: 36 }}
								/>
							</div>
						:
						<>
							<p>Background</p>
							<div className="options">
								{contentStylingOptions}
								{customColorElements}
								<button
									className="small-button round"
									onClick={handleAddNewColor}
								>
									<Icon name={ICONS.ADD} size={16} color={COLORS.WHITE} />
								</button>
								{
									// for modules that shouldnt have background image
									allowBackgroundImage && (
										<>
											<p>or</p>
											{backgroundImgElement}
											<button
												className="small-button round"
												onClick={() => {
													setDisplayCustomColor(false);
													setDisplayAddImage(true);
												}}
											>
												<Icon name={ICONS.IMAGE} size={20} color={COLORS.WHITE} />
											</button>
										</>
									)
								}
							</div>
						</>
				}
			</div>),
		content: (
			<div className="style-options">
				<p>{title}</p>
				<div className="options">
					{contentStylingOptions}
					{customColorElements}
					<button onClick={() => setDisplayCustomColor(true)} className="small-button round">
						<Icon name={ICONS.ADD} size={16} color={COLORS.WHITE} />
					</button>
				</div>
			</div>
		),
	};

	const handleCloseModal = (): void => {
		setOpenEditColorModal(false);
		setCustomColor(null);
	};

	return (
		<>
			<ModalComponent
				cancellable={true}
				closeable={false}
				open={open}
				onRequestClose={handleClose}
				title={displayAddImage ? "Upload background photo" : "Customize Section Colors"}
				size={displayAddImage ? undefined : "large"}
				footer={(
					<>
						{
							displayAddImage && imageFile ? (
								<>
									<button
										className="no-style replace-logo"
										onClick={() => {
											if (!uploading) { imageRef.current?.click(); }
										}}
									>
										<Icon
											name={ICONS.UPLOAD}
											color={COLORS.CYAN}
											size={12}
										/>
										Replace Photo
									</button>
									<FileInput
										type="file"
										accept={["image/jpeg", "image/png", "image/gif"]}
										onChange={(e) => handleAddImage((e.target.files?.[0] as File))}
										style={{ display: 'none' }}
										ref={imageRef}
									/>
								</>
							) : (
								<button
									className="no-style restore-default text-only-primary"
									onClick={handleRestoreDefault}
								>
									Restore default
								</button>
							)
						}
						<button
							style={{ marginRight: 16 }}
							onClick={() => {
								if (displayAddImage) {
									setDisplayAddImage(false);
									return;
								}
								handleClose();
							}}
						>
							Cancel
						</button>
						<button
							disabled={uploading || editing}
							className="lemonade"
							onClick={async () => {
								try {
									if (displayAddImage && resizedImage) {
										await handleAddImage(resizedImage);
										setDisplayAddImage(false);
										setEditing(false);
									} else {
										const classOverride = title === EContentColorOverrideNames.background
											? colorChange
											: colorChange + "-color";
										//Checks to see if the string going back is a backgound URL
										// If so just, send that back, if not send back the ful class override name
										handleFinish(colorChange.includes(".") ? colorChange : classOverride);
										handleClose();
									}
								} catch (e) {
									console.error(e);
									setEditing(false);
									setUploading(false);
									setImageFile(undefined);
								}
							}}
						>
							{
								uploading ?
									<WaitingIndicator />
									:
									'Done'
							}
						</button>
					</>
				)}
			>
				<div className="style-modal-content">
					{title === EContentColorOverrideNames.background ? workingStyleOptionMap.background : workingStyleOptionMap.content}

					<OptionalComponent display={(!isEventPages || isNewNavigation && !isTopNavbar || !isNewNavigation) && displayCustomColor}>
						<div className="custom-color-editor">
							<ColorEditor
								color={customColor ?? ['', 1]}
								title={"Custom Color"}
								onChange={(s: string, n: number) => setCustomColor([s, n])}
							/>
							<button
								className="small-button round"
								onClick={() => {
									addCustomColor();
									setDisplayCustomColor(false);
								}}
							>
								<Icon name={ICONS.ADD} size={16} color={COLORS.WHITE} />
							</button>
						</div>
					</OptionalComponent>
				</div>
			</ModalComponent>

			<OptionalComponent display={isNewNavigation && isTopNavbar}>
				<ColorEditorModal
					color={Array.isArray(color) ? color : ['', 1]}
					open={openEditColorModal}
					handleClose={handleCloseModal}
					setCustomColor={setCustomColor}
					addCustomColor={addCustomColor}
					blurLevel={blurLevel}
					setBlurLevel={setBlurLevel}
				/>
			</OptionalComponent>
		</>
	);
};

export default EditSectionColorsModal;
