import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import classNames from 'classnames';

import { reRegister } from '../../../../store/actions/event/event-actions';
import { EIcon } from '../../../general-ui/icon/icon';
import { TypographyItem } from '../../../general-ui/typography-item/typography-item';
import TextInput, { Validation } from '../../../general-ui/text-input/text';
import { isValidEmail } from '../../../../utils/utils';
import { useTypedSelector } from '../../../../store/reducers/use-typed-selector';
import { useTranslate } from '../../../../i18n/useTranslationModules';
import { ParamsProps } from '../../live-event';
import { Button } from '../../../general-ui/button/button';
import { handleRecaptcha } from '../../../../utils/execute-recaptcha';
import { OptionalComponent } from '../../../../utils/optional-component';
import RecaptchaV2 from '../../../general-ui/recaptcha-v2/recaptcha-v2';
import { useIsRegistrationV2 } from 'hooks/registration-hooks';


export function SignIn({ template, onPrevStepClick }: { template: string; onPrevStepClick: () => void; }): JSX.Element {
	const { t } = useTranslate('homepage');
	const registering = useTypedSelector(state => state.LiveEventReducer.registering);
	const eventUuid = useTypedSelector(state => state.LiveEventReducer.eventBundle?.uuid);
	const loginRecaptchaActionRequired = useTypedSelector(state => state.LiveEventReducer.loginRecaptchaActionRequired);
	const recaptchaEnabled = useTypedSelector(state => !!state.LiveEventReducer.eventBundle?.registration_settings?.enableRecaptcha);
	const shouldRedirectToAlreadyRegisteredEmail = useTypedSelector(state => state.LiveEventReducer.shouldRedirectToAlreadyRegisteredEmail);
	const isRegistrationV2 = useIsRegistrationV2();

	const dispatch = useDispatch();
	const [email, setEmail] = useState('');
	const [valid, setValid] = useState(Validation.normal);
	const [widgetId, setWidgetId] = useState<number | null>(null);

	const { language } = useParams() as ParamsProps;

	const setValue = useCallback((value: string) => {
		setEmail(value);
		setValid(isValidEmail(value) ? Validation.ok : Validation.error);
	}, []);

	const getWidgetId = useCallback((widgetId) => {
		setWidgetId(widgetId);
	}, []);

	const handleSubmit = useCallback(async (e: React.FormEvent) => {
		e.preventDefault();

		if (isValidEmail(email) && eventUuid) {

			try {
				const ACTION = 'login';
				// recaptcha (runs v3, if v3 fails, runs v2)
				const recaptchaResponse = recaptchaEnabled && await handleRecaptcha({
					action: ACTION,
					useV2: loginRecaptchaActionRequired,
					widgetId,
				});

				const headers = recaptchaResponse?.headers ?? {};

				if (eventUuid) {
					dispatch(reRegister(
						{
							eventUuid,
							email,
							lang: language,
							timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
							action: ACTION, // for recaptcha v3
							hostname: window.location.hostname, // for recaptcha
						},
						headers,
					));
				}
			} catch (e) {
				console.error(e);
			}
		}
	}, [
		email,
		eventUuid,
		dispatch,
		language,
		widgetId,
		loginRecaptchaActionRequired,
		recaptchaEnabled,
	]);

	useEffect(() => {
		if (shouldRedirectToAlreadyRegisteredEmail) {
			setValue(shouldRedirectToAlreadyRegisteredEmail);
		}
	}, [shouldRedirectToAlreadyRegisteredEmail]);

	return (
		<div className="registration-slider">
			{/* this component is dynamically rendered so we can hard code "active" class to make sure it's visible when rendered */}
			<div className={classNames({ "registration-panel fixed active": !isRegistrationV2, "registration-panel-v2 fixed active": isRegistrationV2 })}>
				<form className="registration-form" onSubmit={handleSubmit}>
					<div className="registration-form-inner verification" onSubmit={handleSubmit} style={{ overflow: 'hidden' }}>
						<TypographyItem className={classNames("evt-heading-2 stable registration-form-title", template)} tagName="h2">
							{t('homepage:registration.verificationHeader')}
						</TypographyItem>
						<TypographyItem tagName="p" className={classNames(template)}>
							{t("homepage:registration.signinBody")}
						</TypographyItem>
						<br />
						<div className={classNames('evt-field-wrapper', template)} id={`registration-field-3`}>
							<TextInput
								id="reregister-email-field"
								placeholder={t("homepage:registration.registration_fields.3")}
								label={t("homepage:registration.registration_fields.3")}
								required={true}
								onChange={e => setValue(e.target.value)}
								email={true}
								valid={valid}
								value={email}
							/>
							{!valid && <p className="registration-error" role="alert">{t("homepage:registration.Please enter a valid email address")}</p>}
						</div>
					</div>
					<div className="registration-actions secondary">
						<Button
							isRound={true}
							classButton={classNames('registration-button secondary')}
							template={template}
							onClick={onPrevStepClick}
							typeBtn="button"
						>
							<EIcon name="keyboard-arrow-left" />
						</Button>

						<div>
							<OptionalComponent display={loginRecaptchaActionRequired}>
								<RecaptchaV2
									// we need the widget id to pass to grecaptcha's `getResponse` method to ensure we're using the correct widget
									// just in case we have multiple recaptcha widget's on the screen at any time
									getWidgetId={getWidgetId}
								/>
							</OptionalComponent>
							<Button
								classButton={classNames('registration-button primary')}
								template={template}
								typeBtn="submit"
								isDisabled={!email || registering}
							>
								{t("homepage:Submit")}
							</Button>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
}
