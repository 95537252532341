import classNames from 'classnames';
import { useCallback, useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import AppContext, { AppContexts } from 'components/app-context';
import { useGetSession } from 'hooks/session.hooks';
import Icon, { ICONS } from '@general-ui/icon';

import { setEditingNavbar } from 'store/actions/admin/create-event';
import { setEventNavOpen } from 'store/actions/event/event-actions';
import { useTypedSelector } from 'store/reducers/use-typed-selector';
import { ENavbarTypes, LanguagesAbbr } from 'types/working-model';
import { OptionalComponent } from 'utils/optional-component';
import { useEventNameParam } from 'utils/use-event-name-param';
import ErrorBoundary from 'utils/error-boundary';
import { useLanguageParam } from '../utils';
import { useScreenMediaQuery } from 'utils/use-screen-media-query';
import AnnouncementsV2 from '../announcements-v2/announcements-v2';
import { useGetNavIconName } from '../marketing-page/navigation-v2/hooks/navigation-icon.hooks';
import EventLogo from '../marketing-page/navigation/event-logo';
import ProfileDetailsDropdown from '../marketing-page/navigation/profile-details-dropdown';
import { ThemeContext } from '../theme-context';
import NavbarItems from './navbar-items';
import NavbarProfileCard from './navbar-profile-card';
import { useNavbarStyles } from './navbar.hooks';

import './navbar.scss';
import './navbar-legacy-mods.scss';

type NavbarProps = {
	children: JSX.Element | JSX.Element[];
	displayMenu?: boolean;
	canHide?: boolean;
	onSession?: boolean;
	forceHidden?: boolean;
};
const Navbar = ({ children, displayMenu, canHide, onSession, forceHidden }: NavbarProps) => {
	const [theme] = useContext(ThemeContext);
	const appContext = useContext(AppContext);
	const eventBundle = useTypedSelector(event => event.LiveEventReducer.eventBundle);
	const workingEvent = useTypedSelector(event => event.CreateEventReducer.workingEvent);
	const event = eventBundle || workingEvent;
	const profile = useTypedSelector(state => state.LiveEventReducer.blProfileUser);
	const navOpen = useTypedSelector(state => state.LiveEventReducer.navOpen);
	const editorSize = useTypedSelector(state => state.CreateEventReducer.editorSize);
	const dispatch = useDispatch();
	const language = useLanguageParam() as LanguagesAbbr;
	const eventName = useEventNameParam();
	const session = useGetSession();
	const isLessThan1024 = useScreenMediaQuery().isLessThan1024;
	const isEditor = appContext === AppContexts.admin;
	const navbarStyles = useNavbarStyles(event);
	const showMobileMenu = isEditor ? editorSize !== 'desktop' : isLessThan1024;
	const isSticky = event?.settings?.event_navbar?.isSticky;
	const blurLevel = event?.settings?.event_navbar?.blurLevel;
	const navbarType = event?.settings?.event_navbar?.navbarType;
	const navIconName = useGetNavIconName({ navbarType });
	const isTopNavbar = navbarType === ENavbarTypes.Horizontal;

	const headerStyle = {
		...navbarStyles.backgroundImg,
		'--blur-level': blurLevel || 0,
	} as unknown as React.CSSProperties;

	const userProfile: Record<number, string> | undefined = useMemo(() => {
		if (profile?.profile) {
			return Object.values(profile.profile)[0];
		}
	}, [profile]);

	const editNav = useCallback(() => {
		if (isEditor) {
			dispatch(setEditingNavbar(true));
		}
	}, [isEditor, dispatch]);

	const languages = session?.languages || event?.homepage?.languages || [];

	return (
		<>
			{event && (
				<>
					<OptionalComponent display={canHide && navOpen}>
						<div className={classNames("navbar__overlay", { top: isTopNavbar })} onClick={() => dispatch(setEventNavOpen(false))} />
					</OptionalComponent>
					<nav
						className={classNames(
							"navbar",
							event?.template.name,
							{
								navbar__admin: isEditor,
								navbar__top: isTopNavbar,
								navbar__left: !isTopNavbar,
								navbar__sticky: !isTopNavbar || isSticky,
								navbar__open: navOpen,
								navbar__hideable: canHide,
								navbar__dark: theme === 'dark',
								navbar__session: onSession,
								navbar__forcehidden: forceHidden
							}
						)}
						style={headerStyle}>
						<div className="navbar__legacy-menu">
							<button className={classNames('navbar__legacy-menu-button no-style no-padding no-margin')} onClick={() => dispatch(setEventNavOpen(!navOpen))}>
								<Icon name={!navOpen ? ICONS.MENU_NO_SHADOW : navIconName} color={''} size={20} />
							</button>
						</div>

						<div className="navbar__content">
							<div className="navbar__upper">
								<div className={classNames("navbar__actions", { show: displayMenu })}>
									<button className={classNames('navbar__navigation-fixed-button no-style no-padding no-margin')} onClick={() => dispatch(setEventNavOpen(!navOpen))}>
										<Icon name={!navOpen ? ICONS.MENU_NO_SHADOW : navIconName} color={''} size={20} />
									</button>
								</div>
								<div className="navbar__logo">
									<ErrorBoundary uniqueLabel='Navbar -> Event logo'>
										<EventLogo logo={event?.homepage?.header.logo ?? undefined} />
									</ErrorBoundary>
								</div>
							</div>

							<OptionalComponent display={!showMobileMenu}>
								<div className={"navbar__menu-middle"}>
									<div className="navbar__menu-middle-close">
										<button className="no-style" onClick={() => dispatch(setEventNavOpen(false))}>
											<Icon name={ICONS.CLOSE} color={''} size={20} />
										</button>
									</div>
									<ErrorBoundary uniqueLabel='Navbar -> Navbar Items'>
										<NavbarItems
											event={event}
											language={language}
											styles={navbarStyles}
											eventName={eventName}
										/>
									</ErrorBoundary>
								</div>
								<div className="navbar__profile">
									{
										// not using <OptionalComponent> because it does not assert the type of its children's props
										!!event && (
											<ErrorBoundary uniqueLabel='Navbar -> Profile details dropdown'>
												<ProfileDetailsDropdown
													profile={profile}
													channel={event.channel}
													eventBundle={event}
													template={event.template.name}
													isEditor={isEditor}
													cssPrefix="navbar__"
												/>
											</ErrorBoundary>
										)}
								</div>
							</OptionalComponent>

							<OptionalComponent display={isEditor}>
								<div className="navbar__edit" onClick={editNav}>
									<button className="no-style round">
										<Icon name={ICONS.EDIT} color={''} size={14} />
									</button>
								</div>
							</OptionalComponent>
						</div>

						<ErrorBoundary uniqueLabel='Navbar -> Announcements'>
							<AnnouncementsV2 />
						</ErrorBoundary>

						<OptionalComponent display={showMobileMenu}>
							<div className="navbar__mobile">
								<div className="navbar__mobile-header">
									<button className={classNames('navbar__mobile-header-button no-style no-padding no-margin')} onClick={() => dispatch(setEventNavOpen(!navOpen))}>
										<Icon name={ICONS.CLOSE} color={''} size={20} />
									</button>
									<div className="navbar__mobile-header-logo">
										<EventLogo logo={event?.homepage?.header.logo ?? undefined} />
									</div>
								</div>
								<ErrorBoundary uniqueLabel='Navbar -> Navbar profile card'>
									<NavbarProfileCard
										userProfile={userProfile}
										eventBundle={event}
									/>
								</ErrorBoundary>
								<div className="navbar__mobile-header-menu">
									<ErrorBoundary uniqueLabel='Navbar -> Navbar items mobile'>
										<NavbarItems
											event={event}
											language={language}
											languages={languages as LanguagesAbbr[]}
											styles={navbarStyles}
											eventName={eventName}
											forceIcon={true}
											fullWidth={true}
										/>
									</ErrorBoundary>
								</div>
							</div>
						</OptionalComponent>
					</nav>
				</>
			)}
			{children}
		</>
	);
};

export default Navbar;
