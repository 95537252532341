import { useMemo } from 'react';
import './waiting-indicator.scss';

const width = 30;
const height = 15;
const centerX = width / 2;
const centerY = height / 2;
const radius = 2.5;
const difX = 9;

export default function WaitingIndicator({
	fill = '#B2B2BF',
	fillSpace = false,
	minHeight,
	transparentFill = false
}: { fill?: string, fillSpace?: boolean, minHeight?: number | string, transparentFill?: boolean }): JSX.Element {
	const svg = useMemo(() => {
		return (
			<svg className="waiting-indicator" width={width} height={height}>
				<circle
					className="circle-1"
					cx={centerX - difX}
					cy={centerY}
					r={radius}
					fill={fill}
				/>
				<circle
					className="circle-2"
					cx={centerX}
					cy={centerY}
					r={radius}
					fill={fill}
				/>
				<circle
					className="circle-3"
					cx={centerX + difX}
					cy={centerY}
					r={radius}
					fill={fill}
				/>
			</svg>
		);
	}, [fill]);

	return fillSpace ? (
		<div className="waiting-indicator-fill-space" style={{ minHeight, backgroundColor: transparentFill ? 'transparent' : undefined }}>
			{svg}
		</div>
	) : svg;
}
