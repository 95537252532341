import images from '../../../../images';

import './module-empty-state.scss';

export default function ModuleEmptyState(): JSX.Element {
	return (
		<div className="empty-module">
			<img className="empty-module-icon" src={images.TellyLogo} alt="" />
			<p className="empty-module-message evt-typography-h6">
				Sections without content will not be visible to the audience
			</p>
		</div>
	);
}