export enum ErrorsTypes {
	QuestionTitle,
	NotSavedQuestion,
	QuestionOptions,
	QuestionOptionText,
	SurveyTitle,
	SurveyWithoutQuestions,
	QuizCorrectOption,
	QAndATitle,
	FeedbackTitle
}


export enum EngagementErrorsTexts {
	QuestionTitle = "Question is required.",
	NotSavedQuestion = "Question is not saved.",
	QuestionOptions = "Please add options.",
	QuestionOptionText = "Please add option text.",
	SurveyTitle = "Title is required.",
	SurveyWithoutQuestions = "Please add question.",
	QuizCorrectOption = "Please select correct option.",
	QAndATitle = "Question title is required.",
	FeedbackTitle = "Feedback title is required."
}