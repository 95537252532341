import { generateMetricsFilterParams } from "../components/admin/analytics/utils/utils";
import { PieChartData, ScorecardData, LineChartData } from "../store/reducers/admin/analytics";
import { AnalyticsEvent } from "../types/working-model";
import { GetMetrics as Get, GetJson } from "./helpers";

const withAdditionalParams = (filterParam: string, additionalParams: Record<string, string>) => {
	if (Object.keys(additionalParams).length) {
		const _additionalParams = new URLSearchParams();
		Object.entries(additionalParams).forEach(([key, val]) => {
			_additionalParams.set(key, val);
		});
		if (_additionalParams.toString()) {
			const prefix = filterParam ? '&' : '?';
			filterParam += prefix + _additionalParams.toString();
		}
	}
	return filterParam;
};

const filterParams = (
	start: number | undefined,
	end: number | undefined,
	audienceList: string[] | undefined,
	language?: string,
	additionalParams = {},
) => {
	const _start = start ? new Date(start) : null;
	const _end = end ? new Date(end) : null;
	const filterParam = generateMetricsFilterParams({ start: _start, end: _end, audienceList, language });
	// stringify additionalParams values
	const stringifiedAdditionalParams = Object.entries(additionalParams).reduce((accum, curr) => {
		return {
			...accum,
			[curr[0]]: typeof curr[1] === 'string' ? curr[1] : JSON.stringify(curr[1])
		};
	}, {});
	return withAdditionalParams(filterParam, stringifiedAdditionalParams);
};

export function GetChannelEvents(
	channel: number,
	token: string,
	start?: number,
	end?: number,
	audienceList?: string[],
	offset?: number,
	search?: string,
	sortBy?: string
): Promise<AnalyticsEvent[]> {
	const optionalParams: Record<string, string | number> = {};

	if (search) {
		optionalParams.search = search;
	}

	if (sortBy) {
		optionalParams.sortBy = sortBy;
	}

	if (offset) {
		optionalParams.offset = offset;
		optionalParams.limit = 10;
	}

	return Get(`/v3/admin/metrics/channels/${channel}/events${filterParams(start, end, audienceList, undefined, optionalParams)}`, token);
}

export function GetChannelSessionsTotal(channel: number, token: string) {
	return GetJson<{ total: number }>({ path: `/v3/admin/metrics/channels/${channel}/sessions/total`, token }).then((res) => res.total);
}

export function GetChannelDeviceTypes(channel: number, token: string, start?: number, end?: number, audienceList?: string[]): Promise<any> {
	return Get(`/v3/admin/metrics/channels/${channel}/deviceTypes${filterParams(start, end, audienceList)}`, token);
}

export function GetChannelBrowsers(channel: number, token: string, start?: number, end?: number, audienceList?: string[]): Promise<any> {
	return Get(`/v3/admin/metrics/channels/${channel}/browsers${filterParams(start, end, audienceList)}`, token);
}

export function GetChannelLanguages(channel: number, token: string, start?: number, end?: number, audienceList?: string[]): Promise<Array<PieChartData>> {
	return Get(`/v3/admin/metrics/channels/${channel}/languages${filterParams(start, end, audienceList)}`, token);
}

export function GetChannelAudience(channel: number, token: string, start?: number, end?: number, audienceList?: string[]): Promise<any> {
	return Get(`/v3/admin/metrics/channels/${channel}/audience${filterParams(start, end, audienceList)}`, token);
}

export function GetChannelRegistrations(channel: number, token: string, start?: number, end?: number, audienceList?: string[]): Promise<any> {
	return Get(`/v3/admin/metrics/channels/${channel}/registrations${filterParams(start, end, audienceList)}`, token);
}

export function GetUniqueChannelRegistrations(channel: number, token: string, start?: number, end?: number, audienceList?: string[]): Promise<any> {
	return Get(`/v3/admin/metrics/channels/${channel}/unique-registrations${filterParams(start, end, audienceList)}`, token);
}

export function GetChannelDurations(channel: number, token: string, start?: number, end?: number, audienceList?: string[]): Promise<any> {
	return Get(`/v3/admin/metrics/channels/${channel}/averageDuration${filterParams(start, end, audienceList)}`, token);
}

export function GetChannelViews(channel: number, token: string, start?: number, end?: number, audienceList?: string[]): Promise<any> {
	return Get(`/v3/admin/metrics/channels/${channel}/views${filterParams(start, end, audienceList)}`, token);
}

export function GetChannelViewsPerEvent(channel: number, token: string, start?: number, end?: number, audienceList?: string[]): Promise<any> {
	return Get(`/v3/admin/metrics/channels/${channel}/views-per-event${filterParams(start, end, audienceList)}`, token);
}

export function GetChannelWatchtime(channel: number, token: string, start?: number, end?: number, audienceList?: string[]): Promise<any> {
	return Get(`/v3/admin/metrics/channels/${channel}/watchtime${filterParams(start, end, audienceList, undefined)}`, token);
}

export function GetChannelRetentionAverage(channel: number, token: string, start?: number, end?: number, audienceList?: string[]): Promise<any> {
	return Get(`/v3/admin/metrics/channels/${channel}/retention/average${filterParams(start, end, audienceList)}`, token);
}

export function GetChannelRetentionBuckets(channel: number, token: string, start?: number, end?: number, audienceList?: string[]): Promise<any> {
	return Get(`/v3/admin/metrics/channels/${channel}/retention/buckets${filterParams(start, end, audienceList)}`, token);
}

export function GetChannelRegions(channel: number, token: string, start?: number, end?: number, audienceList?: string[]): Promise<any> {
	return Get(`/v3/admin/metrics/channels/${channel}/regions${filterParams(start, end, audienceList)}`, token);
}

export function GetChannelUniqueAttendance(channel: number, token: string, start?: number, end?: number, audienceList?: string[]): Promise<Record<string, number>> {
	return Get(`/v3/admin/metrics/channels/${channel}/unique-attendance${filterParams(start, end, audienceList)}`, token);
}

export function GetChannelAverageUserWatchtime(channelID: number, token: string, start?: number, end?: number, audienceList?: string[]): Promise<ScorecardData> {
	return Get(`/v3/admin/metrics/channels/${channelID}/averageUserWatchtime${filterParams(start, end, audienceList)}`, token);
}

export function GetEventDeviceTypes(uuid: string, token: string, start?: number, end?: number, audienceList?: string[]): Promise<any> {
	return Get(`/v3/admin/metrics/events/${uuid}/deviceTypes${filterParams(start, end, audienceList)}`, token);
}
export function GetEventRegions(uuid: string, token: string, start?: number, end?: number, audienceList?: string[]): Promise<any> {
	return Get(`/v3/admin/metrics/events/${uuid}/regions${filterParams(start, end, audienceList)}`, token);
}
export function GetEventLanguages(uuid: string, token: string, start?: number, end?: number, audienceList?: string[]): Promise<any> {
	return Get(`/v3/admin/metrics/events/${uuid}/languages${filterParams(start, end, audienceList)}`, token);
}
export function GetEventViews(uuid: string, token: string, start?: number, end?: number, audienceList?: string[]): Promise<any> {
	return Get(`/v3/admin/metrics/events/${uuid}/views${filterParams(start, end, audienceList)}`, token);
}
export function GetLiveAndOnDemandEventViews(uuid: string, token: string, start?: number, end?: number, audienceList?: string[]): Promise<ScorecardData> {
	return Get(`/v3/admin/metrics/events/${uuid}/total-page-views${filterParams(start, end, audienceList)}`, token);
}
export function GetEventDuration(uuid: string, token: string, start?: number, end?: number, audienceList?: string[]): Promise<any> {
	return Get(`/v3/admin/metrics/events/${uuid}/averageDuration${filterParams(start, end, audienceList)}`, token);
}
export function GetEventRetentionAverage(uuid: string, token: string, start?: number, end?: number, audienceList?: string[]): Promise<any> {
	return Get(`/v3/admin/metrics/events/${uuid}/retention/average${filterParams(start, end, audienceList)}`, token);
}
export function GetEventRetentionBuckets(uuid: string, token: string, start?: number, end?: number, audienceList?: string[]): Promise<any> {
	return Get(`/v3/admin/metrics/events/${uuid}/retention/buckets${filterParams(start, end, audienceList)}`, token);
}
export function GetEventRegistrations(uuid: string, token: string, start?: number, end?: number, audienceList?: string[]): Promise<any> {
	return Get(`/v3/admin/metrics/events/${uuid}/registrations${filterParams(start, end, audienceList)}`, token);
}
export function GetEventEmails(uuid: string, token: string, start?: number, end?: number, audienceList?: string[]): Promise<any> {
	return Get(`/v3/admin/metrics/events/${uuid}/emails${filterParams(start, end, audienceList)}`, token);
}
export function GetEventWatchtime(uuid: string, token: string, start?: number, end?: number, audienceList?: string[]): Promise<any> {
	return Get(`/v3/admin/metrics/events/${uuid}/watchtime${filterParams(start, end, audienceList)}`, token);
}
export function GetEventBrowsers(uuid: string, token: string, start?: number, end?: number, audienceList?: string[]): Promise<any> {
	return Get(`/v3/admin/metrics/events/${uuid}/browsers${filterParams(start, end, audienceList)}`, token);
}
export function GetEventSessions(uuid: string, token: string, start?: number, end?: number, audienceList?: string[]): Promise<any> {
	return Get(`/v3/admin/metrics/events/${uuid}/sessions${filterParams(start, end, audienceList)}`, token);
}
export function GetEventSessionsPageViews(uuid: string, token: string, start?: number, end?: number, audienceList?: string[]): Promise<any> {
	return Get(`/v3/admin/metrics/events/${uuid}/page-views-per-session${filterParams(start, end, audienceList)}`, token);
}

export function GetEventAverageSessionAttendance(uuid: string, token: string, start?: number, end?: number, audienceList?: string[]): Promise<ScorecardData> {
	return Get(`/v3/admin/metrics/events/${uuid}/sessionAttendance${filterParams(start, end, audienceList)}`, token);
}

export function GetChannelEventMapData(channel: number, token: string, start?: number, end?: number, audienceList?: string[]): Promise<any> {
	return Get(`/v3/admin/metrics/channels/${channel}/countryMap${filterParams(start, end, audienceList)}`, token);
}

export function GetEventMapData(uuid: string, token: string, start?: number, end?: number, audienceList?: string[]): Promise<any> {
	return Get(`/v3/admin/metrics/events/${uuid}/countryMap${filterParams(start, end, audienceList)}`, token);
}

export function GetEventUniqueAttendance(uuid: string, token: string, start?: number, end?: number, audienceList?: string[]): Promise<any> {
	return Get(`/v3/admin/metrics/events/${uuid}/unique-attendance${filterParams(start, end, audienceList)}`, token);
}

export function GetEventAttendanceRate(uuid: string, token: string): Promise<any> {
	return Get(`/v3/admin/metrics/events/${uuid}/attendance-rate`, token);
}

export function GetEventSessionsListUniqueAttendees(eventUUID: string, token: string, start?: number, end?: number, audienceList?: string[]): Promise<ScorecardData> {
	return Get(`/v3/admin/metrics/events/${eventUUID}/sessions/unique-attendance${filterParams(start, end, audienceList)}`, token);
}

export function GetEventSessionsListAvgRetention(eventUUID: string, token: string, start?: number, end?: number, audienceList?: string[]): Promise<ScorecardData> {
	return Get(`/v3/admin/metrics/events/${eventUUID}/sessions/avg-retention${filterParams(start, end, audienceList)}`, token);
}

export function GetEventAverageUserWatchtime(eventUUID: string, token: string, start?: number, end?: number, audienceList?: string[]): Promise<ScorecardData> {
	return Get(`/v3/admin/metrics/events/${eventUUID}/averageUserWatchtime${filterParams(start, end, audienceList)}`, token);
}

export function GetFirstSessionStartTime(eventUUID: string, token: string): Promise<any> {
	return Get(`/v3/admin/metrics/events/${eventUUID}/first-session-start-time`, token);
}

export function GetPaginatedSessionAttendees(
	sessionUuid: string,
	token: string,
	channel: number,
	start?: number,
	end?: number,
	audienceList?: string[],
	language?: string,
	pageNumber = 1,
	textSearch?: string,
	sortBy = 'attendee',
	sortOrder = 0
): Promise<ScorecardData> {
	return Get(`/v3/admin/metrics/sessions/${sessionUuid}/attendees${filterParams(
		start,
		end,
		audienceList,
		language,
		{
			page: pageNumber,
			sortBy,
			sortOrder,
			...(textSearch ? { textSearch } : {}),
			channel
		}
	)}`, token);
}

export function GetSessionDeviceTypes(uuid: string, token: string, start?: number, end?: number, audienceList?: string[], language?: string): Promise<any> {
	return Get(`/v3/admin/metrics/sessions/${uuid}/deviceTypes${filterParams(start, end, audienceList, language)}`, token);
}

export function GetSessionUserSurvey(uuid: string, token: string, start?: number, end?: number, audienceList?: string[], language?: string): Promise<PieChartData[]> {
	return Get(`/v3/admin/metrics/sessions/${uuid}/userSurveys${filterParams(start, end, audienceList, language)}`, token);
}

export function GetSessionRegions(uuid: string, token: string, start?: number, end?: number, audienceList?: string[]): Promise<any> {
	return Get(`/v3/admin/metrics/sessions/${uuid}/regions${filterParams(start, end, audienceList)}`, token);
}
export function GetSessionLanguages(uuid: string, token: string, start?: number, end?: number, audienceList?: string[], language?: string): Promise<any> {
	return Get(`/v3/admin/metrics/sessions/${uuid}/languages${filterParams(start, end, audienceList, language)}`, token);
}
export function GetSessionViews(uuid: string, token: string, start?: number, end?: number, audienceList?: string[], language?: string): Promise<any> {
	return Get(`/v3/admin/metrics/sessions/${uuid}/views${filterParams(start, end, audienceList, language)}`, token);
}
export function GetSessionDuration(uuid: string, token: string, start?: number, end?: number, audienceList?: string[]): Promise<any> {
	return Get(`/v3/admin/metrics/sessions/${uuid}/averageDuration${filterParams(start, end, audienceList)}`, token);
}
export function GetSessionRetentionAverage(uuid: string, token: string, start?: number, end?: number, audienceList?: string[]): Promise<any> {
	return Get(`/v3/admin/metrics/sessions/${uuid}/retention/average${filterParams(start, end, audienceList)}`, token);
}
export function GetSessionRegistrations(uuid: string, token: string, start?: number, end?: number, audienceList?: string[]): Promise<any> {
	return Get(`/v3/admin/metrics/sessions/${uuid}/registrations${filterParams(start, end, audienceList)}`, token);
}
export function GetSessionEmails(uuid: string, token: string, start?: number, end?: number, audienceList?: string[]): Promise<any> {
	return Get(`/v3/admin/metrics/sessions/${uuid}/emails${filterParams(start, end, audienceList)}`, token);
}
export function GetSessionWatchtime(uuid: string, token: string, start?: number, end?: number, audienceList?: string[], language?: string): Promise<any> {
	return Get(`/v3/admin/metrics/sessions/${uuid}/watchtime${filterParams(start, end, audienceList, language)}`, token);
}
export function GetAverageSessionWatchtimes(uuid: string, token: string, start?: number, end?: number, audienceList?: string[], language?: string): Promise<any> {
	return Get(`/v3/admin/metrics/sessions/${uuid}/average-watchtimes${filterParams(start, end, audienceList, language)}`, token);
}
export function GetSessionBrowsers(uuid: string, token: string, start?: number, end?: number, audienceList?: string[], language?: string): Promise<any> {
	return Get(`/v3/admin/metrics/sessions/${uuid}/browsers${filterParams(start, end, audienceList, language)}`, token);
}
export function GetSessionTimeline(uuid: string, token: string, start?: number, end?: number, audienceList?: string[]): Promise<any> {
	return Get(`/v3/admin/metrics/sessions/${uuid}/timeline${filterParams(start, end, audienceList)}`, token);
}
export function GetSessionMapData(uuid: string, token: string, start?: number, end?: number, audienceList?: string[], language?: string): Promise<any> {
	return Get(`/v3/admin/metrics/sessions/${uuid}/countryMap${filterParams(start, end, audienceList, language)}`, token);
}

export function GetHighestConcurrent(uuid: string, token: string, start?: number, end?: number, audienceList?: string[]): Promise<any> {
	return Get(`/v3/admin/metrics/sessions/${uuid}/max-concurrent${filterParams(start, end, audienceList)}`, token);
}

export function GetSessionPageViews(uuid: string, token: string, start?: number, end?: number, audienceList?: string[], language?: string): Promise<Record<string, number>> {
	return Get(`/v3/admin/metrics/sessions/${uuid}/page-views${filterParams(start, end, audienceList, language)}`, token);
}

export function GetSessionUniqueAttendance(session: string, token: string, start?: number, end?: number, audienceList?: string[], language?: string): Promise<Record<string, number>> {
	return Get(`/v3/admin/metrics/sessions/${session}/unique-attendance${filterParams(start, end, audienceList, language)}`, token);
}

export function GetHighestConcurrentLang(uuid: string, lang: string, token: string, start?: number, end?: number, audienceList?: string[]): Promise<any> {
	return Get(`/v3/admin/metrics/sessions/${uuid}/max-concurrent/${lang}${filterParams(start, end, audienceList)}`, token);
}

export function GetSession90SecondVideoViewers(uuid: string, token: string, start: number | undefined, end: number | undefined, audienceLists: string[] | undefined, language: string | undefined): Promise<ScorecardData> {
	return Get(`/v3/admin/metrics/sessions/${uuid}/90-sec-viewers${filterParams(start, end, audienceLists, language)}`, token);
}

export function GetSessionRetentionTimeline(uuid: string, token: string, start?: number, end?: number, audienceList?: string[], language?: string): Promise<LineChartData> {
	return Get(`/v3/admin/metrics/sessions/${uuid}/retention${filterParams(start, end, audienceList, language)}`, token);
}

export function GetSessionReactions(sessionUUID: string, token: string, start?: number, end?: number, audienceList?: string[], language?: string): Promise<PieChartData[]> {
	return Get(`/v3/admin/metrics/sessions/${sessionUUID}/reactions${filterParams(start, end, audienceList, language)}`, token);
}

export function GetSessionEngagement(sessionUUID: string, token: string, start?: number, end?: number, audienceList?: string[], language?: string): Promise<LineChartData> {
	return Get(`/v3/admin/metrics/sessions/${sessionUUID}/engagement${filterParams(start, end, audienceList, language)}`, token);
}

export function GetActualSessionStartTime(sessionUUID: string, token: string): Promise<any> {
	return Get(`/v3/admin/metrics/sessions/${sessionUUID}/actual-session-start-time`, token);
}

export function GetChannelMinDate(channel: number, token: string): Promise<{ timestamp: string }> {
	return GetJson<{ timestamp: string }>({ path: `/v3/admin/metrics/channels/${channel}/min-date`, token });
}