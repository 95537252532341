import { useInView } from "react-intersection-observer";
import ScrollOverlay from "components/live-event/session/above-the-fold/scroll-overlay";

import './fade-container.scss';

interface IProps {
	children: JSX.Element;
	isEditor?: boolean;
}

const ScrollViewWithFade: React.FC<IProps> = ({ children, isEditor }) => {

	// const ScrollViewWithFade = (props: IProps): JSX.Element => {
	const { ref: inViewRefTop, inView: hasScrolledToTop } = useInView({ threshold: 0 });
	const { ref: inViewRefBottom, inView: hasScrolledToBottom } = useInView({ threshold: 0 });


	return (
		<>
			<div className={"fade-container"}>
				<ScrollOverlay
					display={!hasScrolledToTop}
					className="panel-scroll-top-overlay"
					key={"top-overlay"}
					isEditor={isEditor}
				/>

				<div className={"fade-scroller"}>
					<div className="top-element" ref={inViewRefTop} key={"top-element"}></div>

					{children}

					<div className="bottom-element" ref={inViewRefBottom} key={"bottom-element"}></div>
				</div>

				<ScrollOverlay
					display={!hasScrolledToBottom}
					className="panel-scroll-bottom-overlay"
					key={"bottom-overlay"}
					isEditor={isEditor}
				/>
			</div>

		</>
	);
};

export default ScrollViewWithFade;