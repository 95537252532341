import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { PaymentIntentResult, StripeCardNumberElementOptions } from "@stripe/stripe-js";
import classNames from "classnames";
import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";

import { useTranslate } from '../../../../i18n/useTranslationModules';
import { createStripeIntent, setPaidSessions, setRegistrationStep } from "../../../../store/actions/event/event-actions";
import { EventsState } from "../../../../store/types";
import { TemplateNames } from "../../../../types/template-layouts";
import { EPreviewTypes, GlobalPrice, RegistrationStepType, TicketingSet } from "../../../../types/working-model";
import { parseColor } from "../../../../utils/document-head";
import { showAlert } from "../../../general-ui/alert/alert-service";
import CustomCheckbox from "../../../general-ui/checkbox/custom-checkbox";
import { Field, FieldLabel } from "../../../general-ui/field/field";
import { ICONS } from "../../../general-ui/icon";
import TextInput, { Validation } from "../../../general-ui/text-input/text";
import { ParamsProps } from "../../live-event";
import { ContentProps } from "../registration";
import { RegistrationFooter } from "../registration-footer";
import { Button } from "../../../general-ui/button/button";
import { getDisplayPrice, getTemplateClassName } from "../../../../utils/utils";
import useGetTopLevelColorVariables from "utils/use-get-top-level-color-variables";

export function TicketingContent({ footerProps, template, ticketing, onSaveData, channel }: ContentProps): JSX.Element {
	const {
		LiveEventReducer: {
			blProfileUser,
			eventBundle,
			registrationStep,
			registrationId,
			stripeIntentError,
			stripeIntent,
			numberOfTicketsPurchased,
			paymentRequired

		},
		CreateEventReducer: {
			workingEvent,
			previewMode,
		}
	} = useSelector((event: EventsState) => event);

	const stripe = useStripe();
	const stripeElements = useElements();

	const event = eventBundle ?? workingEvent;
	const registrationSteps = event?.registration_steps;

	const [fieldValues, setFieldValues] = useState<any | null>({});
	const [errors, setErrors] = useState<any | null>({});
	const [cardFields, setCardFields] = useState<any | null>({});
	const [selectedTicket, setSelectedTicket] = useState<TicketingSet>();
	const [ticketError, setTicketError] = useState<boolean>(false);
	const [choosingTicket, setChoosingTicket] = useState<boolean>(true);
	const [selectedCurrency, setSelectedCurrency] = useState<string>();
	const [submittingCharge, setSubmittingCharge] = useState<boolean>(false);

	const elements = useElements();
	const { t } = useTranslate('homepage');

	const { language }: ParamsProps = useParams();

	const dispatch = useDispatch();

	const colors: { [key: string]: string } = {};

	const eventColors = useGetTopLevelColorVariables(event?.settings?.design?.colors?.colors);

	if (eventColors) {
		Object.keys(eventColors).forEach(color => {
			const _color = eventColors[color];
			if (Array.isArray(_color)) {
				colors[color] = parseColor(_color);
			}
		});
	}

	const activeSteps = useMemo(
		() => registrationSteps?.filter((step) => step.isOn) || [],
		[registrationSteps]
	);

	useEffect(() => {
		setFieldValues((values: any) => {
			values.ticket = blProfileUser?.profile?.[channel].ticket;
			return { ...values };
		});
	}, [blProfileUser, channel]);

	useEffect(() => {
		if (!stripeIntentError) {
			return;
		}

		showAlert({
			message: stripeIntentError,
			type: 'error',
			duration: 20000,
		});
	}, [stripeIntentError]);

	// Should only be called if they already have ticket, VIP or already purchased
	useEffect(() => {
		const ticketingStep = activeSteps.findIndex(step => step.type === RegistrationStepType.ticketing);

		// payment isn't required, they've registered, just submit an empty form, they're done
		if (!paymentRequired && registrationId && registrationStep === ticketingStep) {
			onSaveData({});
		}
	}, [activeSteps, dispatch, paymentRequired, registrationId, registrationStep, onSaveData]);

	const currencyMap = useMemo(() => {
		if (!ticketing) return;

		const map: { [key: string]: string[]; } = {};

		const addToMap = (currency: string, ticketUUID: string): void => {
			if (!currency || !ticketUUID) return;
			map[currency] ? map[currency].push(ticketUUID) : map[currency] = [ticketUUID];
		};

		ticketing.forEach((ticket: TicketingSet) => {
			if (ticket.prices) {
				ticket.prices.forEach((price: GlobalPrice) => addToMap(price.currency.toUpperCase(), ticket.uuid));
			} else if (ticket.currency) {
				addToMap(ticket.currency.toUpperCase(), ticket.uuid);
			}
		});

		// select user's preferred currency or default to first on list
		const currencyList = Object.keys(map);
		const userPreferred = currencyList.find(currency => currency === blProfileUser?.currency);
		setSelectedCurrency(userPreferred ?? currencyList[0]);

		return map;
	}, [blProfileUser, ticketing]);

	const selectCurrency = (e: Event, value: string) => {
		e.preventDefault();
		setSelectedCurrency(value);
		// when currency changes also clear selected ticket (if any)
		setSelectedTicket(undefined);
	};

	const renderedCurrencies = useMemo((): JSX.Element => {
		if (!currencyMap || Object.keys(currencyMap).length < 2) return (<></>);

		return (<>
			{Object.keys(currencyMap).map((currency: string) => (
				<Button
					key={currency}
					template={getTemplateClassName(template)}
					classButton={`currency-btn primary ${currency !== selectedCurrency ? 'negative' : ''}`}
					onClick={(e) => selectCurrency(e, currency)}
				>
					{currency}
				</Button>
			))}
		</>);
	}, [currencyMap, selectedCurrency, template]);

	const renderedTickets = useMemo(() => {
		const renderTicket = (ticket: TicketingSet) => {
			const numberPurchased = numberOfTicketsPurchased?.[ticket.uuid];

			if (numberPurchased && numberPurchased >= ticket.quantity) {
				return (
					<div className="ticket-sold-out">
						<p>{t('homepage:registration.soldOut')}</p>
					</div>
				);
			}

			return (
				<div className="ticket-checkbox">
					<CustomCheckbox
						name={`ticket-${ticket.uuid}`}
						size={26}
						onChange={() => setSelectedTicket(ticket)}
						checked={ticket.uuid == selectedTicket?.uuid}
						selectedIconName={ICONS.SQUARE_CHECKBOX_SELECTED}
					/>
				</div>
			);
		};

		return ticketing?.map((ticket: TicketingSet) => {
			const globalPrice: GlobalPrice | undefined = ticket.prices?.find(price => price.currency.toUpperCase() === selectedCurrency);
			// older tickets will not have global price so we fall back to deprecated price if it's currency matches user selected
			const price: number | undefined = ticket.prices ? globalPrice?.price : (ticket.currency?.toUpperCase() === selectedCurrency ? ticket.price : undefined);

			const displayPrice: string | undefined = getDisplayPrice(price, selectedCurrency, globalPrice?.multiplier);
			if (!displayPrice) return (<></>);

			return (
				<div key={`ticket-${ticket.uuid}`} className="ticket">
					<div className="ticket-info">
						<p className="ticket-name">{ticket.name_translations?.[language] || ticket.name}</p>
						<p className="ticket-description">{ticket.description_translations?.[language] || ticket.description}</p>
						<p className="ticket-price">{displayPrice}</p>
					</div>
					{renderTicket(ticket)}
				</div>
			);
		});
	}, [language, numberOfTicketsPurchased, selectedCurrency, selectedTicket?.uuid, t, ticketing]);

	const selectedTicketPrice: GlobalPrice | undefined = useMemo(() => {
		if (!selectedTicket || !selectedCurrency) return;

		if (selectedTicket.prices) {
			return selectedTicket.prices?.find((globalPrice: GlobalPrice) => globalPrice.currency === selectedCurrency);
		}

		if (selectedTicket.price && selectedTicket.currency) {
			// fallback for backward compatibility
			return { price: selectedTicket.price, currency: selectedTicket.currency, multiplier: 100 };
		}
	}, [selectedCurrency, selectedTicket]);

	if (!elements || !ticketing || !event) {
		return <div></div>;
	}

	const handleTicketChoice = async (e: React.FormEvent) => {
		e.preventDefault();

		if (!selectedTicket) {
			setTicketError(true);
			return;
		} else {
			setTicketError(false);
		}

		const path = window.location.href.split("/");
		path.pop();
		const source = path.join("/");

		const options = {
			channel_id: event.channel,
			event_uuid: event.uuid,
			lang: language,
			profile: blProfileUser,
			registration_uuid: registrationId,
			source: source,
			timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
		};

		if (!selectedTicketPrice) return;
		dispatch(createStripeIntent(options, { ticket: selectedTicket.uuid, ticketPrice: selectedTicketPrice }));

		setChoosingTicket(false);
	};

	const creditCardSubmitHandler = async (e: React.FormEvent) => {
		e.preventDefault();

		if (submittingCharge) return;

		setSubmittingCharge(true);

		if (previewMode === EPreviewTypes.Registration) {
			return dispatch(setRegistrationStep(registrationStep + 1));
		}

		// all fields must be completed before submitting
		const _errors: any = {};
		if (fieldValues?.email?.toLowerCase() !== blProfileUser?.email) { _errors.email = true; }
		if (!fieldValues.card_name) _errors.card_name = true;
		if (!cardFields.cardNumber) _errors.cardNumber = true;
		if (!cardFields.cardExpiry) _errors.cardExpiry = true;
		if (!cardFields.cardCvc) _errors.cardCvc = true;
		setErrors(_errors);
		if (Object.keys(_errors).length !== 0) {
			setSubmittingCharge(false);
			return false;
		}

		await charge();
	};

	const charge = async () => {

		if (!stripeElements || !blProfileUser) {
			return;
		}

		try {
			const card = stripeElements?.getElement?.(CardNumberElement);

			if (!card) return;

			const res: PaymentIntentResult | undefined = await stripe?.confirmCardPayment(stripeIntent.clientSecret, {
				payment_method: {
					billing_details: {
						email: blProfileUser.email,
						name: stripeIntent.card_name,
					},
					card,
				},
				receipt_email: blProfileUser.email,
			});

			if (!res) {
				showAlert({
					message: 'Unable to communicate with credit card processor. Please try again.',
					type: 'error',
					duration: 20000,
				});
			} else if (res.error) {
				showAlert({
					message: res.error.message,
					type: 'error',
					duration: 20000,
				});
			} else if (res.paymentIntent.status === 'succeeded') {
				dispatch(setPaidSessions(selectedTicket?.session_uuids || []));
				onSaveData(fieldValues);
			}
		} catch (e) {
			console.error('chargeError', e);
			showAlert({
				message: 'Unable to communicate with credit card processor. Please try again.',
				type: 'error',
				duration: 20000,
			});
		}

		setSubmittingCharge(false);
	};

	const setValue = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFieldValues((values: any) => {
			values[e.target.name] = e.target.value;
			return { ...values };
		});

		setErrors((errors: any) => {
			const _errors = { ...errors };

			if (!fieldValues[e.target.name] || fieldValues[e.target.name].length === 0) {
				_errors[e.target.name] = true;
			} else {
				delete _errors[e.target.name];
			}

			return _errors;
		});
	};

	const elementChangeHandler = (e: any) => {
		setErrors((errors: any) => {
			const _errors = { ...errors };

			if (typeof e.error !== 'undefined') {
				_errors[e.elementType] = true;
			} else {
				delete _errors[e.elementType];
			}

			return _errors;
		});

		setCardFields((cardFields: any) => {
			const _cardFields = { ...cardFields };

			if (e.complete) {
				_cardFields[e.elementType] = true;
			} else {
				delete _cardFields[e.elementType];
			}

			return _cardFields;
		});
	};

	const options: StripeCardNumberElementOptions = {
		style: {
			base: {
				color: template === TemplateNames.O2 ? colors.containerColor : colors.bodyTextColor,
				fontSize: "15px"
			},
			invalid: {
				color: template === TemplateNames.O2 ? colors.containerColor : colors.bodyTextColor,
			}
		}
	};


	if (choosingTicket) {
		return (
			<form name="ticket-selector" className="registration-form" onSubmit={handleTicketChoice}>
				<h2 className="evt-heading-2 stable registration-form-title">{t('homepage:registration.selectTicket')}</h2>
				{ticketing && ticketing.length > 0 && (
					<div className={classNames('evt-field-wrapper', template)} id={`registration-field-ticketing`}>
						<div className="currencies">{renderedCurrencies}</div>
						<div className="ticketing">{renderedTickets}</div>
						{ticketError && (
							<p className="registration-error" role="alert">{t("homepage:registration.ticketRequired")}</p>
						)}
					</div>
				)}
				<RegistrationFooter
					{...footerProps}
					template={getTemplateClassName(template)}
					isNextDisabled={footerProps.isNextDisabled || !selectedTicket}
					isSkippable={false}
				/>
			</form>
		);
	}

	return (
		<form name="credit-card" className="registration-form" onSubmit={creditCardSubmitHandler}>
			<div className="ticketing">
				<h2 className="evt-heading-2 stable registration-form-title">{t('homepage:registration.Checkout', 'Checkout')}</h2>
				<h4>
					{selectedTicket?.name}&nbsp;
					{getDisplayPrice(selectedTicketPrice?.price ?? selectedTicket?.price, selectedCurrency, selectedTicketPrice?.multiplier)}
				</h4>
				<div className="credit-card">
					<div className={classNames('evt-field-wrapper', getTemplateClassName(template))}>
						<TextInput label={t('homepage:registration.registration_fields.3')} name="email" onChange={setValue} required valid={errors.email ? Validation.error : (fieldValues.email ? Validation.ok : Validation.normal)} />
						{errors.email && (<p className="registration-error" role="alert">{t("registration.mismatchedEmail")}</p>)}
					</div>
					<div className={classNames('evt-field-wrapper', getTemplateClassName(template))}>
						<TextInput label={t('homepage:registration.nameOnCard')} id="card_name" name="card_name" onChange={setValue} required valid={errors.card_name ? Validation.error : (fieldValues.card_name ? Validation.ok : Validation.normal)} />
						{errors.card_name && (<p className="registration-error" role="alert">{t("registration.Field is required")}</p>)}
					</div>
					<StripeInput label={t('homepage:registration.cardNumber')} template={getTemplateClassName(template)} valid={errors.cardNumber ? Validation.error : (cardFields.cardNumber ? Validation.ok : Validation.normal)}>
						<CardNumberElement onChange={elementChangeHandler} options={options} />
					</StripeInput>
					{errors.cardNumber && (<p className="registration-error" role="alert">{t("registration.cardNumberError")}</p>)}
					<div className="small-cols">
						<StripeInput label={t('homepage:registration.expirationDate')} template={getTemplateClassName(template)} valid={errors.cardExpiry ? Validation.error : (cardFields.cardExpiry ? Validation.ok : Validation.normal)}>
							<CardExpiryElement onChange={elementChangeHandler} options={options} />
						</StripeInput>
						<StripeInput label="CVC" template={getTemplateClassName(template)} valid={errors.cardCvc ? Validation.error : (cardFields.cardCvc ? Validation.ok : Validation.normal)}>
							<CardCvcElement onChange={elementChangeHandler} options={options} />
						</StripeInput>
					</div>
					{errors.cardExpiry && (<p className="registration-error" role="alert">{t("registration.expirationDateError")}</p>)}
					{errors.cardCvc && (<p className="registration-error" role="alert">{t("registration.codeError")}</p>)}
				</div>
				<RegistrationFooter
					{...footerProps}
					onPrevStepClick={() => setChoosingTicket(true)}
					template={getTemplateClassName(template)}
					waiting={submittingCharge}
					isNextDisabled={footerProps.isNextDisabled || !!stripeIntentError || !selectedTicket}
					isSkippable={false}
				/>
			</div>
		</form>
	);
}

const StripeInput = (props: any) => {
	const { label, template, children, valid = Validation.normal } = props;
	return (
		<div className={classNames('evt-field-wrapper', template)}>
			<Field>
				<div className={classNames('field-group', 'text', valid)}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<FieldLabel label={label} required />
					</div>
					<div className={classNames('stripe-wrapper', template, valid)}>
						{children}
					</div>
				</div>
			</Field>
		</div>
	);
};
