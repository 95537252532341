import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { CreateQuestionPrompt, LanguagesAbbr, QuestionPrompt, SessionPanelLayoutsTypes } from "../../../../../../types/working-model";
import ToggleOption from "../../../../../general-ui/switch/toggle-option";
import TextInput, { Validation } from "../../../../../general-ui/text-input/text";
import { ErrorsTypes } from "../engage/types/engage";
import {
	clearErrorsHandler,
	setErrorHandler,
	validateQAndA,
} from "../engage/utils/engage-validate.utils";
import PanelBlock from "../../../homepage/navigation/panel/panel-block";
import { GetDefaultQuestionPrompt } from "../../../../../../store/utils/create-event";
import { updateTranslateKey } from "../../../../../../utils/utils";
import { useTypedSelector } from "../../../../../../store/reducers/use-typed-selector";
import { useParams } from "react-router-dom";
import { CreateQuestionModule, UpdateQuestionModule } from "../../../../../../connection/questions";
import { showAlert } from "../../../../../general-ui/alert/alert-service";
import { useDispatch } from "react-redux";
import { updatePageModuleAndSave } from "../../../../../../store/actions/admin/create-event/session";
import { useGetAdminUrl } from "../../../../../../utils/admin-routing-utils";
import { SessionPanelMap } from "../panel/session-panel-route-map";
import { useFinishNavigate, usePageModule } from "../panel/hooks/panel.hooks";

export default function CreateQuestion(): JSX.Element {
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);
	const token = useTypedSelector(state => state.AuthReducer.token);
	const adminPath = useGetAdminUrl();
	const [question, setQuestion] = React.useState<CreateQuestionPrompt | QuestionPrompt>(GetDefaultQuestionPrompt());
	const [validationErrors, setValidationErrors] = useState<{ [key: string]: string } | null>(null);
	const [saving, setSaving] = useState(false);
	const { language, page_module } = useParams<{ language: LanguagesAbbr, page_module: string }>();
	const { likes, replies, anonymous_replies, private_responses } = question.settings;
	const dispatch = useDispatch();
	const setError = setErrorHandler(setValidationErrors);
	const clearErrors = clearErrorsHandler(setValidationErrors);
	const ePageModule = usePageModule();
	const finish = useFinishNavigate();

	useEffect(() => {
		if (ePageModule?.modules?.[0] && 'question_prompt' in ePageModule.modules[0]) {
			setQuestion(ePageModule.modules[0]);
		}
	}, [ePageModule]);

	const validateQAndAHandler = useCallback(() => {
		return validateQAndA({ question, setError });
	}, [question, setError]);

	const setTitle = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
		if (!workingSession) return;
		clearErrors(ErrorsTypes.QAndATitle);
		setQuestion({
			...question,
			title: updateTranslateKey({
				translateString: question.title,
				input: value,
				baseLanguage: workingSession.default_language,
				language: language
			})
		});
	};

	const setOptions = (key: keyof CreateQuestionPrompt['settings'], value: boolean) => {
		setQuestion({ ...question, settings: { ...question.settings, [key]: !value } });
	};

	const handleCancel = () => {
		finish(adminPath({ path: SessionPanelMap[SessionPanelLayoutsTypes.Engage] }));
	};

	const onSave = async () => {
		const errors = validateQAndAHandler();
		if (errors[ErrorsTypes.QAndATitle]) return;

		if (!token || !workingSession) {
			return;
		}

		try {
			setSaving(true);

			// we are editing this because it already has an ID
			if ('question_prompt' in question && question.question_prompt) {
				if (!ePageModule) return;

				const updated = await UpdateQuestionModule({
					token,
					questionPrompt: { ...question, channel: workingSession.channel },
					baseLanguage: workingSession.default_language,
					languages: workingSession.languages
				});

				const mod = { ...ePageModule, modules: [updated] };

				dispatch(updatePageModuleAndSave(mod));
				// if it does not have an ID then this is a new question to create
			} else {
				const {
					pageModule,
					questionPrompt
				} = await CreateQuestionModule({
					token,
					questionPrompt: { ...question, channel: workingSession.channel },
					pageModule: Number(page_module),
					baseLanguage: workingSession.default_language,
					languages: workingSession.languages
				});

				const mod = { ...pageModule, modules: [questionPrompt] };

				dispatch(updatePageModuleAndSave(mod));
			}

			finish(adminPath({ path: SessionPanelMap[SessionPanelLayoutsTypes.Engage] }));
		} catch (e) {
			console.error(e);
			showAlert({
				message: "Unable to save question",
				description: "We ran into an issue saving that question, please try again.",
				type: "error"
			});
		} finally {
			setSaving(false);
		}
	};

	return (
		<>
			<TextInput
				id="question-title"
				className="padding-24"
				label={'Title*'}
				placeholder={'Type question title'}
				valid={validationErrors?.[ErrorsTypes.QAndATitle] ? Validation.error : Validation.normal}
				onChange={setTitle}
				maxLength={1024}
				value={question.title[language] as string ?? question.title.base}
			/>
			{validationErrors?.[ErrorsTypes.QAndATitle] && (
				<span className="session-panel-field-error admin-typography-label-2 padding-24">
					{validationErrors?.[ErrorsTypes.QAndATitle]}
				</span>
			)}

			<PanelBlock className="margin-24" title="Engagement Options">
				<ToggleOption
					id="likes"
					title="Likes"
					tooltip="Allow attendees to like other responses"
					switchName="likes"
					toggleValue={likes}
					onSwitchToggle={() => setOptions('likes', likes)}
				/>
				<ToggleOption
					id="replies"
					title="Replies"
					tooltip="Allow attendees to reply to other responses"
					switchName="replies"
					toggleValue={replies}
					onSwitchToggle={() => setOptions('replies', replies)}
				/>
				<ToggleOption
					id="anonymous-replies"
					title="Anonymous"
					tooltip="Allow attendees to submit their responses anonymously"
					switchName="anonymousReplies"
					toggleValue={anonymous_replies}
					onSwitchToggle={() => setOptions('anonymous_replies', anonymous_replies)}
				/>
				<ToggleOption
					id="private-responses"
					title="Enable private responses"
					tooltip="Enabling will only display questions to the submitting user"
					switchName="privateResponses"
					toggleValue={!!private_responses}
					onSwitchToggle={() => setOptions('private_responses', !!private_responses)}
				/>
			</PanelBlock>

			<div className="session-panel-actions margin-0">
				<button onClick={handleCancel}>Cancel</button>
				<button className="lemonade" disabled={saving} onClick={onSave}>Save</button>
			</div>
		</>
	);
}