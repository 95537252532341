import classNames from "classnames";
import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";

import { useTranslate } from "../../../../i18n/useTranslationModules";
import { useAppDispatch, useTypedSelector } from "../../../../store/reducers/use-typed-selector";
import { EventsState } from "../../../../store/types";
import { QuestionType, RegistrationStepType, RegistrationTypes, RegFieldsEnum, SingleSignOnTypes, ISSOIntegration, ContentEditors, SSOIntegrationProviderType, ChannelFeaturesEnum } from "../../../../types/working-model";
import { arrayToOptions, getQueryParams, getRandomValue, isValidEmail, getTemplateClassName } from "../../../../utils/utils";
import { Button } from "../../../general-ui/button/button";
import CustomCheckbox from "../../../general-ui/checkbox/custom-checkbox";
import RenderEditorDescription from '../../../general-ui/editable-text/render-editor-description';
import SelectNative from "../../../general-ui/select/select-native";
import TextInput, { Validation } from "../../../general-ui/text-input/text";
import Textarea from "../../../general-ui/textarea/textarea";
import { TypographyItem } from "../../../general-ui/typography-item/typography-item";
import { ContentProps, IRegistrationFormField, SubmissionValues } from "../registration";
import { RegistrationFooter } from "../registration-footer";
import images from '../../../../images';
import { showAlert } from "../../../general-ui/alert/alert-service";
import MultiSelect from "../../../general-ui/select/multi-select";
import { OptionalComponent } from "../../../../utils/optional-component";
import { setStorageItem } from "../../../../utils/local-storage";
import WaitingIndicator from "../../../general-ui/waiting-indicator/waiting-indicator";
import { hasAnyConfiguredCustomSSO, isCustomSignOnType, isUsingCustomSSO } from "../../../../utils/sso-utils";
import { GetHv, parseHvPath, HvHostMap } from "../../../../connection/helpers";
import { validate as validateUuid } from 'uuid';
import { OptInCheckBoxes } from "./opt-in-checkboxes";
import { getRegFieldLabel } from "./utils";
import { clearRegistrationError } from "../../../../store/actions/event/event-actions";
import { useIsRegistrationV2 } from "hooks/registration-hooks";
import useIsStepSkippable from "./use-is-step-skippable";


// taken from https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
const RegFieldAutoComplete: Record<number, string> = {
	1: 'given-name',
	2: 'family-name',
	3: 'email',
	5: 'organization-title',
	6: 'organization',
	21: 'postal-code',
	38: 'tel'
};

const getGoogleSigninValues = () => {
	interface GoogleAuthUser {
		firstName: string;
		lastName: string;
		image: string;
		email: string;
		googleId: string;
		googleToken: string;
	}

	const params = new URLSearchParams(window.location.search);
	let defaultValues: Record<string | number, string> = {};
	for (const item of Array.from(params.entries())) {
		const [key, value] = item;

		//iterating over these rather than just checking the one key so we can expand on this later if we need to.
		if (key === "googleToken") {
			//handle google sign in success
			const user: GoogleAuthUser = {
				firstName: params.get('firstName') as string,
				lastName: params.get('lastName') as string,
				email: params.get('email') as string,
				image: params.get('image') as string,
				googleToken: value,
				googleId: params.get('googleId') as string
			};

			//delete their previous passcode sub
			delete defaultValues['10'];

			defaultValues = {
				...defaultValues,
				[RegFieldsEnum.first_name]: user.firstName ?? "",
				[RegFieldsEnum.last_name]: user.lastName ?? "",
				[RegFieldsEnum.email]: user.email,
				'avatar': user.image,
				'googleToken': user.googleToken
			};

			break;
		}

		if (key === "error") {
			defaultValues = {
				...defaultValues,
				error: params.get("details") ?? "popup"
			};
		}
	}

	//user has signed in with google, immedately remove the search params so that
	//we don't have issues when going to the profile page
	if (params.get('googleToken')) {
		window.history.replaceState({}, document.title, window.location.pathname);
	}

	return defaultValues;
};

const getCustomSignInCode = () => {

	const urlParams = getQueryParams(location.search);
	const authCode = urlParams?.code;

	// remove search params
	if (authCode && !window.location.pathname.includes('/sso-redirect')) {
		window.history.replaceState({}, document.title, window.location.pathname);
	}

	if (authCode) return authCode;
};


//we only want to calculate this once on load. Hooks will fire this repeatedly and overwrite the values after they've been cleared
//this will make it so that it's set once on load only.
const signinValues = getGoogleSigninValues();
const customSignInCode = getCustomSignInCode();

// new base language select field map should include the base language value of any item in a select field if it exists
export const getSubmissionWithBaseLanguageSelects = (
	values: SubmissionValues,
	selectBaseLanguageFieldMap: Map<string, string>,
	fields: IRegistrationFormField[]
) => {
	const _values = { ...values };
	for (const field of fields) {
		const value = _values[field.field_id];
		if (field.type === 'select' && typeof value === 'string') {
			_values[`select_${field.field_id}`] = selectBaseLanguageFieldMap.get(value);
		}
	}

	return _values;
};

export function GeneralContent({
	fields,
	template,
	onSaveData,
	footerProps,
	defaultValues,
	profile,
	channel,
	ticketing,
	handleSignIn,
	eventBundle,
	currentStep,
	language,
	renderRecaptcha,
	selectBaseLanguageFieldMap,
	gdprStatus,
	setIsMarketingOptInChecked,
	isMarketingOptInChecked
}: ContentProps): JSX.Element {
	const customSSOs = useTypedSelector(state => state.IntegrationsReducer.customSSOs);
	const registrationError = useTypedSelector(state => state.LiveEventReducer.registrationError);
	const ssoSettings = eventBundle?.registration_settings?.singleSignOn;
	const cookiesEnabled = !!eventBundle?.settings.gdpr?.enable_cookie_banner;
	const cookieOptIn = !!eventBundle?.channel_features?.includes(ChannelFeaturesEnum.cookie_opt_in);
	const externalRegistrationEnabled = !!eventBundle?.registration_settings?.externalRegistration?.isOn;
	const isRegistrationV2 = useIsRegistrationV2();
	const dispatch = useAppDispatch();

	//ensure that the stored passcode is blanked out
	const [fieldValues, setFieldValues] = useState<SubmissionValues>({
		...defaultValues,
		...signinValues,
		[RegFieldsEnum.passcode]: undefined
	});

	const [invalidFields, setInvalidFields] = useState<number[]>([]);
	const profileValues = profile?.profile?.[channel];
	const [soldOut, setSoldOut] = useState<boolean>(false);
	const isRegClosed = !!eventBundle?.registration_settings?.isClosed;
	const { t } = useTranslate(['registrations', 'homepage']);

	const [autoRegistering, setAutoRegistering] = useState(false);
	const [fetchedSSO, setFetchedSSO] = useState(null);

	const ssoErrors = fieldValues.error;

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const signedInWithGoogle = useMemo(() => !!signinValues.googleToken, []);
	const signedInWithCustomLogin = useMemo(() => !!customSignInCode, []);

	const {
		LiveEventReducer: {
			numberOfTicketsPurchased,
		}
	} = useSelector((event: EventsState) => event);

	const requiredFieldIds = useMemo(() => (fields
		.filter(field => field.required)
		.map(field => field.field_id)
	), [fields]);

	const isSkippable = useIsStepSkippable(
		currentStep?.type,
		eventBundle?.registration_steps,
		requiredFieldIds,
	);

	useEffect(() => {
		if (ssoErrors && !isUsingCustomSSO(eventBundle ?? null)) {
			const _ssoErrors = Array.isArray(ssoErrors) ? ssoErrors.join(', ') : String(ssoErrors);
			showAlert({
				message: t("homepage:registration.Google Sign in Error"),
				description: ssoErrors === "popup" ? t("homepage:registration.closed_popup") : _ssoErrors,
				duration: 7000,
				type: "error"
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ssoErrors]);

	// fieldValues needs to be updated with the defaultValues once the general step is passed
	useEffect(() => setFieldValues((prev) => ({ ...prev, ...defaultValues })), [defaultValues]);

	useEffect(() => {
		if (!ticketing || !numberOfTicketsPurchased || Object.keys(numberOfTicketsPurchased).length === 0) {
			return;
		}

		// If ALL tickets have quantity <= the tickets purchased for that ticket, then the event is sold out
		const _soldOut = ticketing.map(ticket => {
			return ticket.quantity <= (numberOfTicketsPurchased[ticket.uuid] || 0);
		}).reduce((prev: boolean, current: boolean) => prev && current, true);

		setSoldOut(_soldOut);
	}, [numberOfTicketsPurchased, ticketing]);

	const setSelectValue = (field: number) => {
		return ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
			//pull field by id
			const _field = fields.find(_field => _field.field_id === field);

			//check if entry is valid (if _field doesn't exist, don't break registration, let it through)
			const isValid = _field ? validateField(_field, value) : true;

			//hold ids of invalid fields
			setInvalidFields((fields) => {
				if (isValid) {
					return fields.filter(_field => _field !== field);
				} else {
					return Array.from(new Set([...fields, field]).values());
				}
			});

			//set values for submission
			setFieldValues((values) => {
				values[field] = value;
				return { ...values };
			});
		};
	};

	const setValue = (field: number) => {
		return ({ target: { value } }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			//pull field by id
			const _field = fields.find(_field => _field.field_id === field);

			//check if entry is valid (if _field doesn't exist, don't break registration, let it through)
			const isValid = _field ? validateField(_field, value) : true;

			//hold ids of invalid fields
			setInvalidFields((fields) => {
				if (isValid) {
					return fields.filter(_field => _field !== field);
				} else {
					return Array.from(new Set([...fields, field]).values());
				}
			});

			//set values for submission
			setFieldValues((values) => {
				values[field] = value;
				return { ...values };
			});
		};
	};

	//checkbox returns different event type than text/select fields, needs different handler
	const setCheckboxValue = (field: number) => {
		return (isChecked: boolean) => {
			//pull field by id
			const _field = fields.find(_field => _field.field_id === field);

			//if checkbox is required, user must check it to pass
			setInvalidFields((fields) => {
				if (_field?.required && !isChecked) {
					return Array.from(new Set([...fields, field]).values());
				} else {
					return fields.filter(_field => _field !== field);
				}
			});

			//set values for submission
			setFieldValues((values) => {
				values[field] = isChecked;
				return { ...values };
			});
		};
	};

	// clear error message on unmount so we don't show it when navigating away and back to the page
	useEffect(() => {
		return () => {
			dispatch(clearRegistrationError());
		};
	}, [dispatch]);

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();

		dispatch(clearRegistrationError()); // clear error on submit so we get some sort of user feedback if an error occurs again

		const values = fieldValues;

		// prevent submit and show errors for missing required info, event sold out, and no ticket selected
		const missingRequired = requiredFieldIds.filter(id => {
			const val = values?.[id];
			if (val && Array.isArray(val)) {
				return !val.length;
			} else {
				return !val;
			}
		});

		setInvalidFields([...invalidFields, ...missingRequired]);
		if (soldOut || missingRequired.length) {
			return false;
		}

		onSaveData(getSubmissionWithBaseLanguageSelects(values, selectBaseLanguageFieldMap, fields));
	};

	const renderHeader = () => {
		if (soldOut) {
			return t('homepage:registration.soldOutHeader');
		}

		const customTitle = currentStep?.title?.[language] || currentStep?.title?.base;

		if (currentStep?.type === RegistrationStepType.general) {
			return customTitle || t('homepage:registration.Grab your spot', t('homepage:registration.Register for the event'));
		}
		if (currentStep?.type === RegistrationStepType.profile) {
			return customTitle || t("homepage:registration.Tell us about yourself");
		}
	};

	const canReRegister = useMemo(() => {
		return fields.some((field) => field.field_id === RegFieldsEnum.email);
	}, [fields]);

	const renderInvalidField = (field: IRegistrationFormField) => {
		if (soldOut) return; // no need to show error messages if form is sold out
		if (invalidFields.includes(field.field_id)) {
			return (
				field.type === QuestionType.email ? (
					<p className="registration-error" role="alert">{t("homepage:registration.Please enter a valid email address")}</p>
				) : (
					<p className="registration-error" role="alert">{t("homepage:registration.Field is required")}</p>
				)
			);
		}
		return <></>;
	};

	const handleMultiSelectChange = (value: any, _meta: any, field: IRegistrationFormField) => {
		//hold ids of invalid fields
		const isValid = !field?.required || (field?.required && value?.length);
		setInvalidFields((fields: any) => {
			if (isValid) {
				return fields.filter((_field: any) => _field !== field.field_id);
			} else {
				return Array.from(new Set([...fields, field.field_id]).values());
			}
		});

		//set values for submission
		setFieldValues((prev) => {
			return {
				...prev,
				[field.field_id]: value?.map?.((val: any) => val.value) ?? [],
			};
		});
	};

	const renderField = (field: IRegistrationFormField) => {
		if (!eventBundle || !field) return null;

		switch (field.type) {
			//these are all text fields
			case QuestionType.email:
			case QuestionType.numeric:
			case QuestionType.text: {
				const hidePasscode = field.field_id === RegFieldsEnum.passcode &&
					eventBundle?.registration_settings?.type !== RegistrationTypes.gated &&
					!eventBundle?.settings.pageGating?.isOn;

				if (hidePasscode) {
					return null;
				}

				const [translatedLabel, translatedPlaceHolder] = getRegFieldLabel({
					t,
					eventBundle,
					field,
					language,
					eventRegistrationTranslations: eventBundle?.registration_settings?.registration_questions_translations,
				});

				return (
					<TextInput
						label={translatedLabel}
						placeholder={translatedPlaceHolder}
						required={field.required}
						defaultValue={fieldValues[field.field_id] as string ?? profileValues?.[field.field_id]}
						onChange={setValue(field.field_id)}
						email={field.type === QuestionType.email}
						numeric={field.type === QuestionType.numeric}
						name={field.field_id.toString()}
						valid={invalidFields.includes(field.field_id) ? Validation.error : (fieldValues[field.field_id] ? Validation.ok : Validation.normal)}
						disabled={soldOut}
						autoComplete={RegFieldAutoComplete[field.field_id]}
					/>
				);
			}
			case QuestionType.checkbox: {
				return (
					<CustomCheckbox
						key={field.field_id}
						label={field.label}
						onChange={setCheckboxValue(field.field_id)}
						defaultValue={fieldValues[field.field_id] ?? profileValues?.[field.field_id]}
						checked={!!fieldValues[field.field_id]}
						name={field.field_id.toString()}
						disabled={soldOut}
						useMarkdown
					/>
				);
			}
			case QuestionType.select: {
				return (
					<SelectNative
						options={field.options}
						label={field.label}
						required={field.required}
						defaultValue={fieldValues[field.field_id] ?? profileValues?.[field.field_id]}
						onChange={setSelectValue(field.field_id)}
						name={field.field_id.toString()}
						placeholder={field.placeholder}
						disabled={soldOut}
					/>
				);
			}
			case QuestionType.multiselect: {
				return (
					<MultiSelect
						defaultValue={arrayToOptions(fieldValues[field.field_id] ?? profileValues?.[field.field_id] ?? [])}
						options={field.options ?? []}
						onChange={handleMultiSelectChange}
						template={isRegistrationV2 ? '' : getTemplateClassName(template)}
						label={field.label}
						field={field}
						placeholder={field.placeholder}
						disabled={soldOut}
					/>
				);
			}
			case QuestionType.textarea: {
				return (
					<Textarea
						label={field.label}
						placeholder={field.placeholder}
						required={field.required}
						defaultValue={fieldValues[field.field_id] ?? profileValues?.[field.field_id]}
						onChange={setValue(field.field_id)}
						name={field.field_id.toString()}
						valid={invalidFields.includes(field.field_id) ? Validation.error : (fieldValues[field.field_id] ? Validation.ok : Validation.normal)}
						disabled={soldOut}
					/>
				);
			}
			case QuestionType.customtext: {
				return (
					<TypographyItem className={classNames("evt-body-text-1 banner-description", {
						'is-quill': eventBundle?.settings?.content_editor === ContentEditors.quill
					})}>
						<RenderEditorDescription
							description={field.label || ''}
							useNew={eventBundle?.settings?.content_editor === ContentEditors.quill}
						/>
					</TypographyItem>
				);
			}
			default: {
				return null;
			}
		}
	};

	const handleSigninWithGoogle = () => {
		if (isRegClosed) return;
		window.history.pushState({}, document.title, window.location.pathname); // clear params before sending to Google
		const params = new URLSearchParams(window.location.search);
		params.set('googleAuthRedirect', encodeURIComponent(window.location.href));

		if (window.location.host.includes('localhost')) {
			window.location.href = "http://localhost:3000/google-auth?" + params.toString();
		} else {
			window.location.href = `https://google-auth.${(process.env.REACT_APP_BASE_URL as string).replace('https://', '')}?${params.toString()}`;
		}
	};

	useEffect(() => {
		if (signedInWithGoogle && !autoRegistering && currentStep?.type === RegistrationStepType.general && currentStep?.isOn) {
			setAutoRegistering(true);
			const values = fieldValues;

			// prevent submit and show errors for missing required info, event sold out, and no ticket selected
			// also ignore basic profile fields first and last name if they're not defined from the google profile
			const missingRequired = requiredFieldIds.filter(id => id > 2).filter(id => {
				const val = values?.[id];
				if (val && Array.isArray(val)) {
					return !val.length;
				} else {
					return !val;
				}
			});
			setInvalidFields(invalidFields => [...invalidFields, ...missingRequired]);
			if (!missingRequired.length) {
				onSaveData(values);
			}
		}
		if (signedInWithCustomLogin && !autoRegistering && currentStep?.type === RegistrationStepType.general && currentStep?.isOn && !registrationError) {
			setAutoRegistering(true);
			console.log('customSSO autoRegistering');
			onSaveData(fieldValues, customSignInCode);
		}
		// if autoRegistering is in dependency array then we get an infinite loop
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fieldValues, requiredFieldIds, signedInWithGoogle, soldOut, onSaveData, currentStep, signedInWithCustomLogin, registrationError]);

	// if there is an error auto registering, then set it to false and display the error
	useEffect(() => {
		if (autoRegistering && registrationError && isUsingCustomSSO(eventBundle ?? null)) {
			setAutoRegistering(false);

			showAlert({
				message: t("homepage:registration.SSO Sign in Error"),
				description: t("homepage:registration.Please try again"),
				duration: 7000,
				type: "error"
			});
		}
		// if autoRegistering is in dependency array then we get an infinite loop
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [registrationError]);

	const renderSSOOption = (signOn: string, index: number) => {
		if (signOn === SingleSignOnTypes.Google) {
			return (
				<Button
					key={signOn + index}
					typeBtn="button"
					classButton={classNames("evt-button full-width primary negative google-signin", getTemplateClassName(template))}
					onClick={handleSigninWithGoogle}
					isDisabled={autoRegistering || soldOut || isRegClosed}
				>
					{autoRegistering ? <WaitingIndicator /> : (
						<>
							<img src={images.Google} />
							<OptionalComponent display={isRegClosed}>
								<>{t("homepage:registration.Registration is closed")}</>
							</OptionalComponent>
							<OptionalComponent display={!isRegClosed}>
								<>{t("homepage:registration.Sign in with Google")}</>
							</OptionalComponent>
						</>
					)}
				</Button>
			);
		} else {
			return null;
		}
	};

	// we use the same reg step component for general and profile steps. Need to include the !profile step case here to make sure
	// sso doesn't appear on that step as well
	const displaySSO = ssoSettings?.isOn && ssoSettings?.singleSignOns?.length && currentStep?.type === RegistrationStepType.general;
	const useCustomLogin = isUsingCustomSSO(eventBundle ?? null);
	const ssoArray = eventBundle?.registration_settings?.singleSignOn?.singleSignOns;
	// in preview mode the customSSOs will be in redux, in a live event we get the sso from the channel settings in the event bundle
	const customSSO: ISSOIntegration | undefined = ssoArray && (customSSOs ?
		customSSOs?.find((sso: ISSOIntegration) => sso.uuid === ssoArray[0]) :
		fetchedSSO ?? eventBundle?.channel_settings?.sso?.[ssoArray[0]]);

	// Fetch latest version of SSO in case the event hasn't been published since being updated
	useEffect(() => {
		const getSSO = async (uuid: string) => {
			try {
				const response = await GetHv(HvHostMap.authorization, `/auth/getSSO/${uuid}`);

				setFetchedSSO(response);
			} catch (e) {
				console.log(`Failed to fetch SSO ${uuid}. Error: `, e);
			}
		};

		if (useCustomLogin && ssoArray?.length) {
			const uuid = ssoArray[0]; // Limited to one sso per event;

			if (validateUuid(uuid)) {
				getSSO(uuid);
			}
		}

	}, [ssoArray, useCustomLogin]);

	const publicOauthIntegration = eventBundle?.channel_settings?.oauth2Integration;
	const ssoButtonText = (customSSO ? customSSO?.login_text : publicOauthIntegration?.login_text) ?? "";
	const ssoButtonImage = (customSSO ? customSSO?.icon : publicOauthIntegration?.icon) ?? "";


	const redirectToOAuth2SignIn = async () => {
		const state = getRandomValue();
		setStorageItem(`sso_state`, state, 1);

		const params = new URLSearchParams({ redirect_uri: `${location.origin}/sso-redirect`, state });

		window.location.href = await parseHvPath(HvHostMap.registration, `/e3-oauth-sign-in/${eventBundle?.channel}?${params}`);
	};

	const handleExternalRegLink = () => {
		if (!eventBundle?.registration_settings?.externalRegistration?.url) return;
		window.open(eventBundle.registration_settings?.externalRegistration?.url, '_target');
	};

	return (
		<form
			className="registration-form"
			onSubmit={handleSubmit}
		>
			<fieldset disabled={!cookieOptIn && cookiesEnabled && !gdprStatus} className="clear-fieldset">
				<div className="registration-form-inner">
					<TypographyItem className={classNames("evt-heading-2 stable registration-form-title", currentStep?.styling_override?.color)} tagName="h2">
						{renderHeader()}
					</TypographyItem>
					{useCustomLogin && displaySSO ? (
						<div>
							<div className="button-icon-wrapper">

								<Button
									typeBtn="button"
									classButton={classNames("evt-button full-width primary negative google-signin", getTemplateClassName(template))}
									onClick={() => {
										// set an expiration so if the user for some reason does not go through the flow and they
										// end up on a different event at a later time, they don't get redirected to the wrong event
										// 0.17 is about 10 minutes converted to hours
										if (eventBundle?.uuid && useCustomLogin) {
											setStorageItem(`sso_event_url`, window.location.href, 1);

											if (customSSO) {
												window.location.href = getSSOSignOnUrl(customSSO);
											} else if (ssoArray?.some(isCustomSignOnType) && publicOauthIntegration) {
												redirectToOAuth2SignIn();
											}
										}
									}}
									isDisabled={autoRegistering || soldOut || isRegClosed}
								>
									{autoRegistering ? <WaitingIndicator /> : (
										<>
											<OptionalComponent display={!!ssoButtonImage}>
												<img src={ssoButtonImage} />
											</OptionalComponent>

											<OptionalComponent display={!!isRegClosed}>
												<>{t("homepage:registration.Registration is closed")}</>
											</OptionalComponent>

											<OptionalComponent display={!isRegClosed}>
												<>{t("homepage:registration.SignInWith")} {ssoButtonText}</>
											</OptionalComponent>
										</>
									)}
								</Button>
							</div>
						</div>
					) : (
						<>
							{externalRegistrationEnabled && !!eventBundle.registration_settings?.externalRegistration?.url && (
								<button className="registration-button primary external-registration" onClick={handleExternalRegLink}>{t("homepage:registration.Register")}</button>
							)}
							{canReRegister && (
								<button onClick={handleSignIn} role="button" type="button" className="no-style sign-in already-signed-in">{t("homepage:registration.AlreadyRegistered")}</button>
							)}

							{soldOut && (<p className="sold-out">{t('homepage:registration.soldOutSubHeader')}</p>)}

							{!externalRegistrationEnabled && (
								<div className={classNames(soldOut ? 'sold-out-container' : '')}>
									{displaySSO && ssoSettings?.displayTop ? (
										<div className='sso-container'>
											<div className="button-icon-wrapper">
												{ssoSettings?.singleSignOns?.map(renderSSOOption)}
											</div>
											<div className="sso-sign-in-text">- {t('homepage:registration.or register with email')} -</div>
										</div>
									) : null}
									{fields.map((field: IRegistrationFormField, index) => {
										return (
											<div key={index + field.label + field.field_id} className={classNames('evt-field-wrapper')} id={`registration-field-${field.field_id}`}>
												{renderField(field)}
												{renderInvalidField(field)}
											</div>
										);
									})}

									{setIsMarketingOptInChecked && typeof isMarketingOptInChecked !== 'undefined' && (
										<OptInCheckBoxes
											setIsMarketingOptInChecked={setIsMarketingOptInChecked}
											template={''}
											soldOut={soldOut}
											isMarketingOptInChecked={isMarketingOptInChecked}
										/>
									)}

									{displaySSO && !ssoSettings?.displayTop ? (
										<div className='sso-container'>
											<div className="sso-sign-in-text">- {t('homepage:registration.or sign in with')} -</div>
											<div className="button-icon-wrapper">
												{ssoSettings?.singleSignOns?.map(renderSSOOption)}
											</div>
										</div>
									) : null}
								</div>)}
						</>
					)}
				</div>

				{!externalRegistrationEnabled && (
					<RegistrationFooter
						{...footerProps}
						isNextDisabled={soldOut}
						isSkippable={isSkippable}
						waiting={footerProps.waiting || invalidFields.length > 0 || soldOut}
						template={getTemplateClassName(template)}
						renderRecaptcha={renderRecaptcha}
						useCustomLogin={hasAnyConfiguredCustomSSO(eventBundle ?? null)}
					/>)}
			</fieldset>
		</form>
	);

}

function validateField(field: IRegistrationFormField, value: string): boolean {
	if (field.field_id === RegFieldsEnum.email) {
		// if email is not required, allow empty field only, else run validation as normal
		if (!field.required && value === "") {
			return true;
		}
		return isValidEmail(value);
	}

	if (field.required) {
		return !!value;
	} else {
		return true;
	}
}

export function getSSOSignOnUrl(ssoConfig: ISSOIntegration): string {
	const client_id = ssoConfig.user_pool_client_id;
	const params = new URLSearchParams({
		redirect_uri: `${location.origin}/sso-redirect`,
		client_id: client_id,
		identity_provider: ssoConfig.idp_name,
		response_type: 'code',
		//scope: ['email', 'openid', 'profile']
	});

	let baseUrl = process.env.REACT_APP_COGNITO_URL;

	if (ssoConfig.integration_provider === SSOIntegrationProviderType.JACKSON) {
		const state = getRandomValue();
		setStorageItem(`sso_state`, state, 1);

		params.set('state', state);

		baseUrl = `${process.env.REACT_APP_SSO_HOST}/sso-redirect`;
	}



	return `${baseUrl}?${params}`;
}
