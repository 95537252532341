import classNames from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { isObject } from "underscore";
import { CreatePageModule } from "../../../../../../../../connection/page-modules";
import { useContentSearch, SearchResults } from "../../../../../../../../connection/sessions-panel/search";
import { ESort } from "../../../../../../../../connection/sessions-panel/types";
import { addSessionPageModule, updateGroupModules, updatePageModuleAndSave } from "../../../../../../../../store/actions/admin/create-event/session";
import { useTypedSelector } from "../../../../../../../../store/reducers/use-typed-selector";
import { PageModule, PageModuleType, Product, SessionPanelLayoutsTypes, Templates } from "../../../../../../../../types/working-model";
import { useGetAdminUrl } from "../../../../../../../../utils/admin-routing-utils";
import { getSessionPanelRouteState } from "../../../../../../../../utils/path-utils";
import { mergeUniqueById } from "../../../../../../../../utils/utils";
import { showAlert } from "../../../../../../../general-ui/alert/alert-service";
import StaggerChildren from "../../../../../../../general-ui/animated/stagger-children";
import SmallSelect from "../../../../../../../general-ui/select/small-select";
import TextInput from "../../../../../../../general-ui/text-input/text";
import WaitingIndicator from "../../../../../../../general-ui/waiting-indicator/waiting-indicator";
import { handleCompleteSelection } from "../../../../../homepage/editor/products/products-utils";
import ProductCard from "../../components/product-card";
import SessionPanelAddFooter from "../../components/session-panel-add-footer";
import { customProductsItems, extrasProductsItems } from "../../empty-state-panel/constants/empty-panel";
import { useFinishNavigate, usePageModule, usePageModuleGroup } from "../../hooks/panel.hooks";
import { SessionPanelMap } from "../../session-panel-route-map";
import { appendNewPageModulesToGroups } from "../../utils/module-group.utils";
import { PageModuleGroup } from "../../utils/prototypes/page-module-group-modules";

const sortOptions = [
	{ label: 'Newest', value: ESort.dateDesc },
	{ label: 'Oldest', value: ESort.date },
	{ label: 'Name A-Z', value: ESort.name },
	{ label: 'Name Z-A', value: ESort.nameDesc },
];

type Props = {
	withinTab?: boolean;
}
const ProductsLibrary: React.FC<Props> = ({ withinTab }) => {
	const token = useTypedSelector(state => state.AuthReducer.token);
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const [awaitingInitialLoad, setAwaitingInitialLoad] = useState(true);
	const [products, setProducts] = useState<Product[]>([]);
	const [total, setTotal] = useState(0);
	const [sortOrder, setSortOrder] = useState<ESort | undefined>(ESort.dateDesc);
	const searchTermRef = useRef<string>();
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [selectedProducts, setSelectedProducts] = useState<number[]>([]);
	const [hasScrolledToBottom, setHasScrolledToBottom] = useState(true);
	const [saving, setSaving] = useState(false);
	const containerRef = useRef<HTMLDivElement | null>(null);
	const scrollRef = useRef<HTMLDivElement | null>(null);
	const loadMoreTimeout = useRef<NodeJS.Timeout | null>(null);
	const { customPath } = useParams<{ customPath?: string }>();
	const { isExtrasCustom } = getSessionPanelRouteState(location.pathname);
	const extrasItems = isExtrasCustom ? customProductsItems : extrasProductsItems;
	const pageModuleGroup = usePageModuleGroup();
	const pageModule = usePageModule();
	const dispatch = useDispatch();

	const adminPath = useGetAdminUrl();
	const eventId = workingEvent?.event;
	const channelId = workingSession?.channel;
	const finish = useFinishNavigate();
	const history = useHistory<{ productToReplace?: Product }>();

	const handleError = useCallback((e: string) => {
		console.error(e);
		showAlert({
			message: e,
			type: "error",
		});
	}, []);

	const {
		load,
		search,
		loading,
		clear,
		setSort,
		next
	} = useContentSearch<Product>('products', handleError);

	const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		searchTermRef.current = e.target.value;
	}, []);

	// append new items to end of list
	const handleMoreResults = useCallback((res: SearchResults<Product> | undefined) => {
		if (isObject(res) && res.results) {
			setTotal(res.total ?? 0);
			setProducts(mergeUniqueById<Product>('product', res.results ?? []));
		}
	}, []);

	// replace list with new items
	const handleNewResults = useCallback((res: SearchResults<Product> | undefined) => {
		if (isObject(res)) {
			if (scrollRef.current) {
				scrollRef.current.scrollTop = 0;
			}

			setAwaitingInitialLoad(false);
			setTotal(res.total ?? 0);
			setProducts(res.results ?? []);
		}
	}, []);

	const handleSearch = useCallback(() => {
		// not doing typeahead search, user has to press enter or blur field
		// so we aren't updating the state every keystroke, just holding the value in a ref
		setSearchTerm(searchTermRef.current ?? '');
	}, []);

	useEffect(() => {
		if (searchTerm && searchTerm.length >= 3) {
			// apply new search term and replace existing results with new
			search(searchTerm).then(handleNewResults);
		} else {
			// clear search term and replace existing results with new
			clear(true).then(handleNewResults);
		}
	}, [clear, handleNewResults, search, searchTerm]);

	const handleSort = useCallback((value: string) => {
		if (value) {
			setSortOrder(value as ESort);
		} else {
			setSortOrder(undefined);
		}
	}, []);

	const handleSelectProduct = (product: number, on: boolean) => {
		if (history.location.state?.productToReplace) {
			if (on) {
				setSelectedProducts([product]);
			} else {
				setSelectedProducts([]);
			}
		} else {
			if (on) {
				setSelectedProducts(products => [...products, product]);
			} else {
				setSelectedProducts(products => products.filter(p => p !== product));
			}
		}
	};

	useEffect(() => {
		const moreAvailable = total > products.length;

		if (hasScrolledToBottom && moreAvailable && !loading) {
			// debounce send in case user scrolls like crazy or we get multiple triggers
			if (loadMoreTimeout.current) {
				clearTimeout(loadMoreTimeout.current);
			}

			loadMoreTimeout.current = setTimeout(() => {
				loadMoreTimeout.current = null;
				next().then(handleMoreResults);
			}, 200);
		}
	}, [hasScrolledToBottom, total, products.length, handleMoreResults, next, loading]);

	useEffect(() => {
		// apply new value and replace list with new results
		setSort(sortOrder).then(handleNewResults);
	}, [handleNewResults, setSort, sortOrder]);

	useEffect(() => {
		setAwaitingInitialLoad(true);
		// initialize list
		load().then(handleNewResults);
	}, [handleNewResults, load]);

	const handleDone = async () => {
		if (!token || !workingEvent || !workingSession || !workingSession.module_grouping || !pageModuleGroup) return;
		let page_module: PageModule;
		let appendToGroup = false;

		if (!pageModule?.id) {
			// no page module exists to contain these products - create a new one and append it ot the module group
			page_module = await CreatePageModule(token, {
				type: PageModuleType.products,
				eventName: workingEvent.name,
				template: Templates.Limelight,
				languages: workingSession.languages,
				baseLanguage: workingSession.default_language
			});

			dispatch(addSessionPageModule(page_module));
			appendToGroup = true;
		} else {
			page_module = pageModule;

			// this is a custom tab, and the user has navigated to the products tab, so we need to convert this
			// empty custom tab to a products tab
			if (page_module.type === PageModuleType.feed) {
				page_module.type = PageModuleType.products;
				dispatch(updatePageModuleAndSave(page_module));
			}
		}

		let newProducts: Product[] = [];

		if (history.location.state?.productToReplace?.product) {
			const productToReplace = history.location.state.productToReplace;
			newProducts = [
				...products.filter(prod => selectedProducts.includes(prod.product as number)),
				...(page_module.modules?.filter(p => p.product !== productToReplace.product) ?? []),
			];
		} else {
			newProducts = [
				...products.filter(prod => selectedProducts.includes(prod.product as number)),
				...(page_module.modules ?? []),
			];
		}

		await handleCompleteSelection({
			token,
			channelId,
			// this is additive - we are not removing existing products, just adding new ones
			products: newProducts,
			eventId,
			page_module,
			dispatch,
			setSaving,
			isSession: true
		});

		if (appendToGroup) {
			const newModules = (workingSession.module_grouping as PageModuleGroup[])
				.map(appendNewPageModulesToGroups(pageModuleGroup.uuid, [page_module.id as number]));

			dispatch(updateGroupModules(newModules));
		}

		const path = (() => {
			if (customPath) {
				return adminPath({ path: SessionPanelMap[SessionPanelLayoutsTypes.CustomProducts], customPath, page_module: page_module.id });
			}

			if (page_module.content.custom_tab) {
				return adminPath({ path: SessionPanelMap[SessionPanelLayoutsTypes.ExtraCustom], page_module: page_module.id });
			} else {
				return adminPath({ path: SessionPanelMap[SessionPanelLayoutsTypes.ExtraProducts], page_module: page_module.id });
			}
		})();

		finish(path);

	};

	return (
		<div className={classNames("session-panel products-list", { 'has-selections': selectedProducts.length > 0 })} ref={containerRef}>
			<div className="session-panel-header-options">
				<TextInput
					defaultValue={''}
					onChange={handleSearchChange}
					onBlur={handleSearch}
					onEnterKey={handleSearch}
					placeholder="Search..."
					className="small"
				/>
				<SmallSelect
					options={sortOptions}
					selected={sortOrder || ''}
					onChange={handleSort}
				/>
			</div>

			{!withinTab && <label className="session-panel-section-label">Library</label>}

			{products.length ? (
				<StaggerChildren
					onScrolledToBottom={() => setHasScrolledToBottom(true)}
					onScrolledUpFromBottom={() => setHasScrolledToBottom(false)}
					ref={scrollRef}
					footer={loading ? <WaitingIndicator fillSpace={true} minHeight={36} transparentFill={true} /> : undefined}
					className={classNames("session-panel-content")}>
					{products.map((product: Product) => (
						<ProductCard
							key={product.product}
							product={product}
							selected={selectedProducts.includes(product.product as number)}
							handleSelectProduct={handleSelectProduct}
						/>
					))}
				</StaggerChildren>
			) : (
				<>
					{(loading) ? (
						<div className="session-panel-no-results padding-24">
							<section><WaitingIndicator /></section>
						</div>
					) : (
						<div className="session-panel-no-results padding-24">
							<section>No results</section>
							<SessionPanelAddFooter
								scrollRef={scrollRef}
								items={extrasItems}
								small
							/>
						</div>
					)}
				</>
			)}

			<div className="session-panel-footer">
				<button onClick={() => setSelectedProducts([])}>Clear Selections</button>
				<button
					className="lemonade"
					onClick={handleDone}
					disabled={saving}
				>{saving ? <WaitingIndicator /> : 'Add to page'}</button>
			</div>
		</div>
	);
};

export default ProductsLibrary;