import React, { createRef, useCallback, useEffect, useMemo, useState, lazy, Suspense, useRef } from "react";
import { matchPath, Redirect, useHistory, useParams } from "react-router";
import classNames from "classnames";

import { useIsSessionPage } from "../../../hooks/path.hooks";
import { EditorSizes, TemplateNames } from "../../../types/template-layouts";
import {
	ActionSource,
	BlProfile,
	BrandliveEvent,
	LanguagesAbbr,
	PageModuleGroupModules,
	PageModuleType,
	RegistrationStepType,
	Session,
	SessionTabsLayoutTypes,
	SessionTypesEnum,
	TranslateString,
	IAnnouncement,
	EPreviewTypes,
	EBroadcastTypes,
	ModuleMap,
	SqsSocketTypes,
	ENavbarTypes,
	ModuleGroupingTypes,
	PageModule
} from '../../../types/working-model';
import { ParamsProps } from '../live-event';
import {
	clearErrorBoundaryComponents,
	getUserSession,
	setUserSession,
	getSessionUserSurveyRating,
	setSessionUserSurveyRating,
	resetUserSession,
	toggleGoogleMeetEmbedView,
	setShowSessionUserFeedbackSurveyModal
} from "../../../store/actions/event/event-actions";
import { getStorageItem } from "../../../utils/local-storage";
import ModuleEmptyState from './session-modules/module-empty-state';
import { getMockBlProfile, getStoredReferrer, getTemplateClassName, isMobile, replaceSpaceWithDash, shouldDisplayHomepage } from "../../../utils/utils";
import { eventPath, getDefaultLanguage, getSessionStylingOverrides } from "../utils";
import { useScreenMediaQuery } from "../../../utils/use-screen-media-query";
import SessionDocumentHead from "./session-document-head";
import useTranslationModules from "../../../i18n/useTranslationModules";
import { hasEventAccess, hasSessionAccess } from "../../../utils/registration-utils";
import { useSocket } from '../../../connection/socket';
import { showAnnouncement } from "../../general-ui/alert/alert-service";
import { useAppDispatch, useTypedSelector } from "../../../store/reducers/use-typed-selector";
import { getCurrentPageSourceInfo, useTracking } from "../../../utils/tracking";
import SessionStream from './session-stream/session-stream';
import SessionStreamV2 from './session-stream/session-stream-v2';
import SessionDescription from './session-modules/description/description';
import MainEventBannerBreakout from '../modules/main-event-banner-breakout/main-event-banner-breakout';
import ErrorBoundary, { TEST_ERROR_BOUNDARIES } from "../../../utils/error-boundary";
import useRouteMap, { DebugDefaultRouteMap, DefaultRouteMap } from "../hooks/use-route-map";
import { LanguageDropdown } from "../../general-ui/language-dropdown/language-dropdown";
import { OptionalComponent } from "../../../utils/optional-component";
import useChangeLanguage from "../../../utils/use-change-language";
import useTrackEndSessionTimes from "../../../utils/tracking/use-track-end-session-times";
import FeedbackSurveyAlert from '../../general-ui/user-survey-modal/user-survey-alert';
import socketManager from "../../../connection/socket-main-thread/socket-manager";
import useTrackPageView from "../../../utils/tracking/use-track-page-view";
import {
	useIsSingleSessionWithoutHome,
	useIsBelowTheFold,
	useIsAboveTheFold,
	useIsNewModuleGrouping,
	joinGoogleMeetRoom,
	useDarkMode,
	useThemeUpdate,
} from "../../../hooks/session.hooks";
import { useIsNewNavigation } from "../../../hooks/navigation.hooks";
import { useSessionDetailsV2, useVideoV2 } from "../../../hooks/channel.hooks";
import { MeetAppBuilder, MeetApp, StartCallResult } from "../../../types/meet";
import { useBelowTheFoldScroll } from "../../../hooks/below-the-fold-scroll";
import GroupedNavButtonsSession from "./grouped-nav-buttons-session";
import { Redirects } from "../../../utils/redirects";
import useHasScrolledPast from "../../../utils/use-has-scrolled-past";
import useOrientation from "../../../utils/use-orientation";
import { modalState } from "../../general-ui/modal/modal-state";
import { useIsSingleSessionPage } from "../../../hooks/path.hooks";
import { useSurveyPollQuizResults } from "./hooks/session-hooks";
import { setGoogleMeetFsSettings } from "store/actions/event/firesides-actions";
import * as Signals from '../../../utils/event-emitter';
import getModuleSectionTitle from "./get-module-section-title";

const Documents = lazy(() => import("@session-editor/documents/documents"));
const Products = lazy(() => import("@session-editor/products/products"));
const SimilarSessions = lazy(() => import("@session-editor/similar-sessions/similar-sessions"));
const Surveys = lazy(() => import('@session-editor/surveys/surveys'));
const AdminSpeakers = lazy(() => import("@session-editor/speakers/speakers"));
const QuestionPrompts = lazy(() => import('@session-editor/surveys/questions'));
const QuestionPrompt = lazy(() => import('./session-modules/questions/questions-v2'));
const SessionDetailsModalContainer = lazy(() => import('./session-modules/session-details-modal/session-details-modal-container'));
const SessionBreakoutRooms = lazy(() => import("./session-modules/breakout-rooms/breakout-rooms"));
const SessionPreviewModal = lazy(() => import("@general-ui/session-preview/session-preview"));
const ProfileDetailsDropdown = lazy(() => import('../marketing-page/navigation/profile-details-dropdown'));
const FiresideHostControls = lazy(() => import("./fireside/session-host"));
const SessionDocuments = lazy(() => import("./session-modules/documents/documents"));
const Sticky = lazy(() => import("@general-ui/sticky/sticky"));
const SessionSpeakers = lazy(() => import("./session-modules/speakers/speakers"));
const SessionSimilarSessions = lazy(() => import("./session-modules/similar-sessions/similar-sessions"));
const SessionProducts = lazy(() => import("./session-modules/products/products"));
const SessionSurvey = lazy(() => import("./session-modules/survey/survey"));
const SessionQuestions = lazy(() => import("./session-modules/questions/questions"));
const LiveChat = lazy(() => import("./live-chat/live-chat"));
const BlankSection = lazy(() => import("../modules/blank-section/blank-section"));
const Footer = lazy(() => import("../marketing-page/footer/footer"));
const UserSurveyModal = lazy(() => import("@general-ui/user-survey-modal/user-survey-modal"));
const AddToCalendarModal = React.lazy(() => import('../modules/agenda/add-to-calendar-modal/add-to-calendar-modal'));

import '../../../scss/live-event/base/session/session.scss';
import './above-the-fold/session-main-below-the-fold.scss';
import './session-v2-modal.scss';

const VideoGutter: React.FC = () => <div className="video-gutter"></div>;

export enum ETabLayoutClassNames {
	noTabs = 'no-tabs',
	vertical = 'vertical',
	horizontal = 'horizontal'
}

interface RenderSessionModulesProps {
	session: Session;
	template: string;
	moduleGroup?: PageModuleGroupModules;
	preview?: boolean;
	isBelowTheFold?: boolean;
	moduleGroupsRef?: React.RefObject<HTMLParagraphElement>;
	submitGoogleToken?: (token: string | null, url: string) => Promise<StartCallResult>;
	googleMeetJoined?: boolean;
	isEditor?: boolean;
	customPath?: string;
}

const shouldHideModule = (
	module: PageModule | undefined,
	moduleGroup: PageModuleGroupModules,
	isModuleGroupingV2: boolean,
	isNoTabsLayout?: boolean,
) => {
	// if the module is toggled off, should hide
	if (!module?.is_on) {
		return true;
	}

	// in moduleGroupingV2, if current page module is speakers in the overview group, hide
	// being used in the overview modal, shouldn't display on session page
	const speakersInOverview =
		isModuleGroupingV2 &&
		(module.type === PageModuleType.speakers) &&
		(moduleGroup.type === ModuleGroupingTypes.Overview);

	if (speakersInOverview) return true;

	// if the layout is "no tabs" we still need to hide the module if it belongs to a module group that is toggled off
	// if:
	// the layout is "no tabs",
	// and the module group (e.g. Overview/Engage/Extras) is toggled off,
	// and the module is in that group,
	// then hide
	if (
		isNoTabsLayout
		&& !moduleGroup.is_on
		&& (module?.id && moduleGroup.modules.includes(module.id))
	) {
		return true;
	}

	// in V1, the questions module contained no content modules, so rendering should not depend on those modules being present
	// but in V2, module must contain a Questions module to render
	// if the module is ON and the module type is questions and the type is V1, should NOT hide
	if (module.type === PageModuleType.questions && !isModuleGroupingV2) {
		return false;
	}

	// if the group is OFF, hide, otherwise if the module has a defined modules array but it is empty, hide
	return !moduleGroup.is_on || (module.type !== PageModuleType.similar_sessions && module.modules && module.modules.length === 0);
};

const isEmptyStateV2 = (
	moduleGroup: PageModuleGroupModules | undefined,
	isModuleGroupingV2: boolean,
	session: Session | undefined,
	isEditor?: boolean,
) => {
	if (!isModuleGroupingV2 || !isEditor || !moduleGroup) return false;
	let isEmpty = true;

	for (const moduleId of moduleGroup.modules) {
		const module = session?.modules.find(module => module.id === moduleId);

		if (module && !moduleRequiresContentInV2[module.type]) {
			isEmpty = false;
		}

		if (module?.modules?.length) {
			isEmpty = false;
		}
	}

	return isEmpty;
};

export const moduleRequiresContentInV2: { [key in PageModuleType]?: boolean } = {
	[PageModuleType.products]: true,
	[PageModuleType.documents]: true,
	[PageModuleType.similar_sessions]: true,
	[PageModuleType.speakers]: true,
	[PageModuleType.blank]: false,
	[PageModuleType.feed]: true,
	[PageModuleType.survey]: true,
	[PageModuleType.questions]: true,
	[PageModuleType.quizzes]: true
};

export const RenderSessionModules: React.FC<RenderSessionModulesProps> = ({
	session,
	template,
	moduleGroup,
	preview,
	moduleGroupsRef,
	submitGoogleToken,
	googleMeetJoined,
	isEditor
}) => {
	const { language } = useParams<ParamsProps>();

	const isModuleGroupingV2 = useIsNewModuleGrouping();
	const isSessionPage = useIsSessionPage();
	const sessionDetailsV2FeatureFlagOn = useSessionDetailsV2();

	const modules = useMemo(() => {
		const tabLayoutClassname = replaceSpaceWithDash(session.layout?.tabs?.layout.toLowerCase() || ETabLayoutClassNames.horizontal);
		const moduleGroupName = moduleGroup?.name as TranslateString | undefined;

		// Breakout Rooms is separate because it's not a normal module group that has child modules inside
		if (moduleGroup?.type === "breakout" && moduleGroup.is_on) {
			// Don't display the header when only a single session that is already joined.
			// When a Meet is joined, it is removed from the list of available Meets.
			const hideBreakoutRoomTitle = (session.breakout_rooms?.length === 1 && googleMeetJoined) || !isModuleGroupingV2;
			return <Suspense fallback="">
				<ErrorBoundary uniqueLabel="Session breakout rooms">
					<div>
						<p
							id={`breakout-container`}
							className={classNames("module-title", { 'hide-title': hideBreakoutRoomTitle })}
							ref={moduleGroupsRef}
						>
							{moduleGroupName?.[language] || moduleGroupName?.base || ""}
						</p>
						<SessionBreakoutRooms key={module.id} session={session} template={template} submitGoogleToken={submitGoogleToken} />
					</div>
				</ErrorBoundary>
			</Suspense>;
		}

		if (preview && moduleGroup?.modules.length === 0) return <div className="editor-wrapper"><ModuleEmptyState /></div>;
		// was falling back on itself as a translate string, which would show [object Object] or undefined in the UI if the base was undefined for some reason
		// since there's no title to show, fall back to an empty string

		const title = getModuleSectionTitle(session, language, moduleGroup);

		// Logic to determine if module grouping in session details v2 has any modules with content
		// handling outside of loop below to avoid multiple renderings of empty state in multi module groupings
		if (!moduleGroup?.modules?.length && isEditor) {
			// keeping this conditional separate from isEmptyStateV2 conditional to have clear separation of concerns for future unit testing
			return <ModuleEmptyState />;
		}

		if (isEmptyStateV2(moduleGroup, isModuleGroupingV2, session, isEditor)) {
			return <ModuleEmptyState />;
		}

		return (
			<Suspense fallback="">
				<>
					<div
						ref={moduleGroupsRef}
						className="module-group-scroll-anchor"
						id={moduleGroup?.uuid}
					/>
					<OptionalComponent display={!!title.length}>
						{/* TODO Put this CSS in the correct file once Theme epic is merged */}
						<h5 style={{ fontSize: '16px', marginBottom: '10px' }}>{title}</h5>
					</OptionalComponent>
					{moduleGroup?.modules.map<React.FC<unknown> | JSX.Element>(moduleId => {
						const module = session?.modules.find(module => module.id === moduleId);

						const stub = <React.Fragment key={moduleId}></React.Fragment>;

						if (!isModuleGroupingV2 && !module) return stub;

						// if no-tabs is selected and the module grouping is toggled off, then do not display the module if it
						// belongs to that group
						const isNoTabsLayout = !sessionDetailsV2FeatureFlagOn && session.layout?.tabs?.layout === SessionTabsLayoutTypes.NoTabs;

						const hideModule = shouldHideModule(module, moduleGroup, isModuleGroupingV2, isNoTabsLayout);

						// if the module is OFF, hide
						if (hideModule) return stub;

						const moduleQuestions = session?.modules.find(module => module?.type === PageModuleType.questions);

						const isSurvey = module?.type === PageModuleType.survey;
						const isQuestionsModule = module?.type === PageModuleType.questions;

						const surveySubmoduleQuestions = isSurvey && moduleQuestions?.is_on ? moduleQuestions : undefined;

						const isModuleWithoutContent = module && module.is_on && moduleRequiresContentInV2[module.type] && !module.content_modules?.length;
						const showEmptyState = isModuleGroupingV2 && preview && !isQuestionsModule && isModuleWithoutContent;

						let moduleToRender = stub;

						switch (module?.type) {
							case (PageModuleType.description): {
								if (hideModule) return stub;
								moduleToRender = <SessionDescription key={module.id} module={module} template={template} session={session} isEditor={preview} />;
								break;
							}
							case (PageModuleType.speakers): {
								if (preview && isSessionPage) {
									return moduleToRender = <AdminSpeakers key={moduleId} page_module={module} template={template} tabLayout={tabLayoutClassname as ETabLayoutClassNames} />;
								}
								moduleToRender = <SessionSpeakers key={moduleId} module={module} template={template} tabLayout={tabLayoutClassname as ETabLayoutClassNames} />;
								break;
							}
							case (PageModuleType.similar_sessions): {
								if (preview && isSessionPage) {
									return moduleToRender = <SimilarSessions isEditor={isEditor} key={moduleId} page_module={module} template={template} />;
								}

								moduleToRender = <SessionSimilarSessions key={moduleId} module={module} template={template} />;
								break;
							}
							case (PageModuleType.products): {
								if (preview && isSessionPage) {
									return moduleToRender = (
										<Products
											page_module={module}
											template={template}
											tabLayout={tabLayoutClassname as ETabLayoutClassNames}
											key={moduleId}
										/>
									);
								}

								moduleToRender = (
									<SessionProducts
										key={moduleId}
										module={module}
										template={template}
										tabLayout={tabLayoutClassname as ETabLayoutClassNames}
									/>
								);
								break;
							}
							case (PageModuleType.quizzes): {
								// IF we got here - we goofed - this is a dead type, all quizzes are surveys
								return stub;
							}
							case (PageModuleType.survey): {
								if (preview && isSessionPage) {
									return moduleToRender = (
										<Surveys
											page_module={module}
											template={template}
											isLandingPage={false}
											surveySubmoduleQuestions={surveySubmoduleQuestions}
											key={moduleId}
										/>
									);
								}

								moduleToRender = (
									<SessionSurvey
										key={moduleId}
										module={module.is_on ? module : undefined}
										template={template}
										sessionUuid={session.uuid}
										preview={preview}
										surveySubmoduleQuestions={surveySubmoduleQuestions}
										session={session}
									/>
								);
								break;
							}
							case (PageModuleType.documents): {
								if (preview && isSessionPage) {
									return moduleToRender = <Documents key={moduleId} page_module={module} template={template} />;
								}

								moduleToRender = (
									<SessionDocuments
										key={moduleId}
										module={module}
										template={template}
										preview={preview}
										session={session}
									/>
								);
								break;
							}
							case (PageModuleType.questions): {
								// if this is module grouping V2, questions use the questionPrompts modules
								if (hideModule || (isModuleGroupingV2 && !module.modules?.length)) {
									moduleToRender = stub;
								} else if (isModuleGroupingV2 && preview) {
									moduleToRender = <QuestionPrompts key={moduleId} page_module={module} questionPromptModule={module} template={template} />;
								} else if (isModuleGroupingV2) {
									moduleToRender = <QuestionPrompt sessionUuid={session.uuid} key={moduleId} module={module} template={template} session={session} />;
								} else {
									moduleToRender = <SessionQuestions key={moduleId} module={module} template={template} session={session.session} session_uuid={session.uuid} />;
								}
								break;
							}
							case (PageModuleType.embed_widget):
							case (PageModuleType.blank): {
								if (showEmptyState) return <ModuleEmptyState key={moduleId} />;

								moduleToRender = <BlankSection key={moduleId} module={module} template={template} />;
								break;
							}
						}

						return (
							<ErrorBoundary uniqueLabel={`${module && ModuleMap[module.type]} ${module?.id?.toString()}`} key={moduleId}>
								{moduleToRender}
							</ErrorBoundary>
						);
					})}
				</>
			</Suspense>);

	}, [
		session,
		moduleGroup,
		isSessionPage,
		moduleGroupsRef,
		template,
		submitGoogleToken,
		preview,
		isModuleGroupingV2,
		language,
		googleMeetJoined,
		sessionDetailsV2FeatureFlagOn,
		isEditor
	]);

	return <>{modules}</>;
};


interface BelowTheFoldContainerProps {
	backgroundColor: string,
	backgroundImg: React.CSSProperties;
	belowTheFoldContainerRef: React.RefObject<HTMLDivElement>;
	moduleGroupsRefs: React.RefObject<HTMLParagraphElement>[];
	groupedNavButtons: JSX.Element;
	isScrolledPastTheFold: boolean;
	isSessionDetailsBelowTheFoldV2: boolean;
	layout: SessionTabsLayoutTypes;
	renderAllModulesBelowTheFold: () => JSX.Element;
	session: Session | undefined;
	selectedTabUuid: string | undefined;
	autoScroll: boolean | undefined;
	isTopNavbar: boolean;
}
const BelowTheFoldContainer: React.FC<BelowTheFoldContainerProps> = ({
	belowTheFoldContainerRef,
	groupedNavButtons,
	isScrolledPastTheFold,
	isSessionDetailsBelowTheFoldV2,
	layout,
	renderAllModulesBelowTheFold,
	session,
	isTopNavbar
}) => {
	const isModuleGroupingV2 = useIsNewModuleGrouping();

	useEffect(() => {
		setTimeout(() => {
			document.documentElement.style.setProperty('--scrollbarWidth', (window.innerWidth - document.documentElement.clientWidth) + "px");
		});
	}, []);

	const isBreakout = session?.session_type === SessionTypesEnum.breakoutRooms;

	return <>
		<div className={classNames('below-the-fold-with-gutter', { "horizontal-nav": !!isTopNavbar })}>
			<div className={classNames("page-container-below-the-fold-tabs-container read-only-exception",
				{
					"scrolled-past-the-fold-active": isScrolledPastTheFold,
					"breakout": isBreakout,
				})}
				ref={belowTheFoldContainerRef}
			>
				<ul className="page-container-below-the-fold-tabs tab-navigation">
					{groupedNavButtons}
				</ul>
			</div>
			<OptionalComponent
				// maintains scroll height of the page content container
				// because the tabs are going to jump to fixed positioning
				display={isScrolledPastTheFold}
			>
				<div className="scrolled-past-placeholder"></div>
			</OptionalComponent>

			<div
				className={classNames("page-container-below-the-fold")}
			>
				{!isModuleGroupingV2 && session?.enable_feedback_survey &&
					<FeedbackSurveyAlert rating={0} show layout={layout} />}
				<div
					className="page-container-body"
				>
					{renderAllModulesBelowTheFold()}
				</div>
			</div>
			{isSessionDetailsBelowTheFoldV2 ? <VideoGutter /> : <></>}
		</div>
	</>;
};

const shouldBlockSessionPageView = false; // pulled out into a named variable to give context about what this boolean is controlling 
const ninetySeconds = 1000 * 60 * 1.5;
const fiveMinutes = 1000 * 60 * 5;

interface SessionViewProps {
	singleSession?: boolean;
	previewBundle?: BrandliveEvent;
	previewSession?: Session;
	previewLanguage?: LanguagesAbbr;
	interacted?: boolean;
	debug?: boolean;
}

const SessionView: React.FC<SessionViewProps> = ({ singleSession, previewBundle, previewSession, previewLanguage, debug }) => {
	// redux hooks
	const loadingEventBundle = useTypedSelector(state => state.LiveEventReducer.loadingEventBundle);
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const playerType = useTypedSelector(state => state.LiveEventReducer.playerType);
	const gettingUserSession = useTypedSelector(state => state.LiveEventReducer.gettingUserSession);
	const blProfileUserToken = useTypedSelector(state => state.LiveEventReducer.blProfileUserToken);
	const playbackUrls = useTypedSelector(state => state.LiveEventReducer.playbackUrls);
	const secondaryVideos = useTypedSelector(state => state.LiveEventReducer.secondaryVideos);
	const getUserSessionError = useTypedSelector(state => state.LiveEventReducer.getUserSessionError);
	const blProfileUser = useTypedSelector(state => state.LiveEventReducer.blProfileUser);
	const registrationId = useTypedSelector(state => state.LiveEventReducer.registrationId);
	const validSessions = useTypedSelector(state => state.LiveEventReducer.validSessions);
	const finishedRegistering = useTypedSelector(state => state.LiveEventReducer.finishedRegistering);
	const userVerified = useTypedSelector(state => state.LiveEventReducer.userVerified);
	const firesidesHostSessions = useTypedSelector(state => state.LiveEventReducer.firesidesHostSessions);
	const editorSize = useTypedSelector(state => state.CreateEventReducer.editorSize);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const validPasscodeLists = useTypedSelector((state) => state?.LiveEventReducer?.validPasscodeLists);
	const loadingValidSessions = useTypedSelector(event => event.LiveEventReducer.loadingValidSessions);
	const sessionUserSurveyRatings = useTypedSelector(state => state.LiveEventReducer.sessionFeedbackSurveyRatings);
	const paidSessions = useTypedSelector(state => state.LiveEventReducer.paidSessions);
	const embedMeetJoined = useTypedSelector(state => state.LiveEventReducer.embeddedMeetJoined);
	const registrationBypass = useTypedSelector(state => !!state.LiveEventReducer.registration_bypass);
	const previewMode = useTypedSelector(state => state.CreateEventReducer.previewMode);
	const adminUser = useTypedSelector(state => state.AuthReducer.user);
	const registeredLanguage = useTypedSelector(state => state.LiveEventReducer?.registeredLanguage);
	const showSessionUserFeedbackSurveyModal = useTypedSelector(state => state.LiveEventReducer.showSessionUserFeedbackSurveyModal);
	const firesideSessionSettings = useTypedSelector(state => state.FiresidesReducer.firesideSessionSettings);
	const isModerator = useTypedSelector(state => state.ModeratorReducer?.moderatorRegistration?.isModerator);

	const inMeetWaitingRoom = useTypedSelector(state => state.FiresidesReducer.inMeetWaitingRoom);

	// state hooks
	const [selectedTabUuid, setSelectedTabUuid] = useState<string>();
	const [customUrlEnabled, setCustomUrlEnabled] = useState<boolean>(false);
	const [customUrl, setCustomUrl] = useState<string>('');
	const [sessionStarted, setSessionStarted] = useState(false);
	const [showFeedbackSurveyAlert, setShowFeedbackSurveyAlert] = useState(true);
	const [surveyId, setSurveyId] = useState<number | null>(null);
	const [showFeedbackSurveyModal, setShowFeedbackSurveyModal] = useState(false);
	const [isScrolledPastTheFold, setIsScrolledPastTheFold] = useState(false);
	const [moduleGroupsRefs, setModuleGroupsRefs] = useState<React.RefObject<HTMLParagraphElement>[]>([]);
	const [autoScroll, setAutoScroll] = useState(false);
	const [blinkPlayer, setBlinkPlayer] = useState(true);
	const [meetAppBuilt, setMeetAppBuilt] = useState<{ app: MeetApp, builder: MeetAppBuilder }>();
	const [openAddToCalendar, setOpenAddToCalendar] = useState(false);

	// ref hooks
	const googleMeetBreakoutsRef = useRef<HTMLDivElement | null>(null);
	const sessionHeaderRef = useRef<HTMLDivElement | null>(null);
	const scrollContainer = useRef<HTMLDivElement | null>(null);
	const belowTheFoldContainer = useRef<HTMLDivElement | null>(null);
	const profileRefDesktop = useRef(null);

	// hooks with return values
	const isModuleGroupingV2 = useIsNewModuleGrouping();
	const videoV2 = useVideoV2();
	const history = useHistory();
	const dispatch = useAppDispatch();
	const RouteMap = useRouteMap({ debug });

	const { sessionUuid: sessionParamUuid, uuid, eventName, language }: ParamsProps = useParams();
	const handleLanguageChange = useChangeLanguage(eventBundle);
	const hasScrolledPastMeet = useHasScrolledPast({ scrollContainer: googleMeetBreakoutsRef });
	const deviceOrientation = useOrientation();
	const isSingleSessionPage = useIsSingleSessionPage();
	const showNewNav = useIsNewNavigation();
	const isSingleSessionWithoutHome = useIsSingleSessionWithoutHome();
	const {
		isLessThan640,
		isLessThan768,
		isLessThan1024
	} = useScreenMediaQuery();

	const StreamComponent = videoV2 ? SessionStreamV2 : SessionStream;
	const baseLanguage = eventBundle ? getDefaultLanguage(eventBundle) : '';
	const isPreview = !!(previewBundle || previewSession);
	const event = isPreview ? previewBundle : eventBundle;
	const sessionUuid = singleSession ? event?.sessions?.[0]?.uuid : sessionParamUuid;

	// get session from event bundle
	const session = useMemo(() => {
		let session: Session | undefined;

		if (singleSession) {
			session = event?.sessions?.[0];
		} else if (previewSession) {
			session = previewSession;
		} else {
			session = event?.sessions?.find(session => session.uuid === sessionUuid);
		}

		if (session) {
			modalState.setSession(session);
		}

		return session;
	}, [singleSession, event?.sessions, sessionUuid, previewSession]) as Session | undefined;

	useEffect(() => {
		// Load from bundle before API call
		if (session?.uuid && session.uuid != firesideSessionSettings?.uuid && session.fireside_session_settings) {
			dispatch(setGoogleMeetFsSettings(session.fireside_session_settings));
		}

	}, [session, dispatch, firesideSessionSettings?.uuid]);

	// hooks that depend on the session
	const sessionDetailsV2FeatureFlagOn = useSessionDetailsV2();
	const isSessionDetailsBelowTheFoldV2 = useIsBelowTheFold(session);
	const isSessionDetailsAboveTheFoldV2 = useIsAboveTheFold(session);
	const { Track } = useTracking({ source_type: ActionSource.Session, source_id: session?.session });

	// using socket for announcements and user surveys from admin or moderator
	const socket = useSocket(`session-${session?.uuid}-${language}`);
	const sessionCustomUrlSocket = socketManager.get(`event-${event?.uuid}`);
	const isRegistered = eventBundle?.registration_on && blProfileUserToken;
	const registeredSocket = useSocket(isRegistered ? `registered-${eventBundle?.uuid}-${language}` : '');

	// primitive values
	const isV2 = isSessionDetailsAboveTheFoldV2 || isSessionDetailsBelowTheFoldV2;
	const hideFooterATFDesktop = isSessionDetailsAboveTheFoldV2 && !isLessThan1024;
	const displayFooter = event?.settings?.display_session_footer !== false && !hideFooterATFDesktop;
	const displaySessionPageLanguageToggle = (session?.languages ?? []).length > 1 && (event?.settings?.display_session_language_dropdown ?? false);
	const single_stream = session?.streaming_options?.single_stream ?? false; // defaulting to false for backwards compatibility
	const { backgroundColor, backgroundImg } = getSessionStylingOverrides(session?.layout.styling_overrides);
	const notValidTranslation = !session?.languages.includes(language);
	const isFireside = session?.session_type === SessionTypesEnum.fireside;
	const isFiresideTablet = isFireside && isLessThan1024;
	const channel = eventBundle?.channel;
	const blProfile = blProfileUser?.bl_profile;
	const template = eventBundle?.template.name || previewBundle?.template.name || TemplateNames.Classic;
	const sessionId = session?.session;
	const eventId = eventBundle?.event;
	const eventUuid = eventBundle?.uuid;
	const registrationOn = eventBundle?.registration_on;
	const hasNavigation = shouldDisplayHomepage(event ?? null);

	// if single session event and no langing page and on session page and not mobile, make z-index 0
	const isSingleSession = eventBundle?.sessions?.length === 1;
	const match = matchPath(history.location.pathname, {
		path: [
			DebugDefaultRouteMap.Landing,
			DefaultRouteMap.Landing,
		],
		exact: true
	});

	const setChatUnderVideo = match && isSingleSession && !hasNavigation && !isMobile && !isLessThan640;
	const previewNavigation = previewBundle?.homepage;
	const isBreakout = session?.session_type === SessionTypesEnum.breakoutRooms;
	const isIFrameBroadcast = session?.session_type === SessionTypesEnum.broadcast && session?.broadcast_type === EBroadcastTypes.embed;
	const mobileChat = !isBreakout
		&& (editorSize === EditorSizes.mobile
			|| isLessThan640
			|| isFiresideTablet
			|| (isFireside && editorSize && editorSize !== EditorSizes.desktop)
			|| (isIFrameBroadcast && isLessThan1024)
			|| (isIFrameBroadcast && editorSize && editorSize !== EditorSizes.desktop));

	const tabletChat = /* attendeeInPersonModeEnabled
		&&  */!isBreakout
		&& (editorSize === EditorSizes.tablet
			|| isLessThan1024
			|| isFiresideTablet
			|| (isFireside && editorSize && editorSize !== EditorSizes.desktop)
			|| (isIFrameBroadcast && isLessThan1024)
			|| (isIFrameBroadcast && editorSize && editorSize !== EditorSizes.desktop));

	// display the profile bubble if there is no homepage but
	// registration is on or the session has more than 1 language
	const noHomepageShowProfileBubble = !hasNavigation && (event?.registration_on || (event?.sessions[0]?.languages?.length ?? 0) > 1);
	const navbarType = eventBundle?.settings?.event_navbar?.navbarType;
	const isTopNavbar = navbarType === ENavbarTypes.Horizontal;
	const isLeftNavbar = navbarType === ENavbarTypes.Vertical;

	// isMobile or isLessThan1024 (because "tablet" is considered less than 1024 in our chat layout)
	const [chatClosed, setChatClosed] = useState(isMobile || isLessThan640 || isFiresideTablet);

	useTrackEndSessionTimes({ interval: ninetySeconds, runOneInterval: true });
	useTrackEndSessionTimes({ interval: fiveMinutes, beginAfter: ninetySeconds, sessionId });
	useTrackPageView(shouldBlockSessionPageView, language);
	useTranslationModules({ language: notValidTranslation ? session?.default_language : language });
	useBelowTheFoldScroll(
		autoScroll,
		isPreview,
		isScrolledPastTheFold,
		moduleGroupsRefs,
		scrollContainer,
		selectedTabUuid,
		session?.module_grouping,
		setIsScrolledPastTheFold,
		setSelectedTabUuid,
		null
	);
	useSurveyPollQuizResults(session);
	useThemeUpdate(session);

	if (!session && !singleSession) {
		history.replace(`/${eventPath(eventName, uuid)}`);
	}

	//
	// memoized values
	//
	const triggerMeetPiP = useMemo(() => (
		!isLessThan1024
		&& !!embedMeetJoined
		&& hasScrolledPastMeet
	), [isLessThan1024, embedMeetJoined, hasScrolledPastMeet]);

	const ticketingStep = useMemo(() =>
		eventBundle?.registration_steps?.find(step => step.type === RegistrationStepType.ticketing && step.isOn),
		[eventBundle]);

	const isDesktop = useMemo(() =>
		!isLessThan1024 || (!!workingEvent && editorSize === EditorSizes.desktop),
		[isLessThan1024, editorSize, workingEvent]);

	// When in Google Meet, with an active Meet, on mobile, in landscape,
	//  hide various items.  This variable is inteded for any state
	//  that wants to hide everything but the primary video content
	//  when in landscape mode on a mobile device.
	const isFullScreenMode = useMemo(() =>
		isLessThan1024 // only on mobile for now
		&& deviceOrientation === 'landscape'
		&& !!embedMeetJoined
		, [isLessThan1024, deviceOrientation, embedMeetJoined]);

	const isDarkMode = useMemo(() => useDarkMode(session), [session]);

	const tabGroupMap = useMemo(() => {
		return session?.module_grouping?.reduce((acc, curr) => {
			acc[curr.uuid] = curr;
			return acc;
		}, {} as { [key: string]: PageModuleGroupModules | undefined; }); // if we don't set this as potentially undefined, TS will assume any string will return a PageModuleGroupModules when it might not
	}, [session]);

	const hasTicket = useMemo(() =>
		hasSessionAccess(event, session, validSessions, (registrationBypass && !loadingValidSessions), paidSessions)
		, [event, loadingValidSessions, paidSessions, registrationBypass, session, validSessions]);

	const [layout, layoutClassName]: [SessionTabsLayoutTypes, string] = useMemo(() => {
		const _layout = session?.layout?.tabs?.layout || previewSession?.layout?.tabs?.layout || SessionTabsLayoutTypes.Horizontal;

		// Removes spaces for use in className: 'no-tabs','horizontal','vertical'
		const layoutClassName = _layout?.toLowerCase().replace(/ /g, "-");

		if (_layout === SessionTabsLayoutTypes.Vertical && isLessThan768) {
			return [SessionTabsLayoutTypes.Horizontal, layoutClassName];
		}

		return [_layout, layoutClassName];
	}, [isLessThan768, previewSession?.layout?.tabs?.layout, session?.layout?.tabs?.layout]);

	//
	// memoized functions
	//
	const setFeedbackRating = useCallback((rating: number) => sessionUuid && dispatch(setSessionUserSurveyRating(sessionUuid, rating)), [dispatch, sessionUuid]);

	const submitGoogleToken = useCallback(async (token: string | null, url: string): Promise<StartCallResult> => {
		if (googleMeetBreakoutsRef.current) {
			// used to be: return  joinGoogleMeetRoom(googleMeetBreakoutsRef.current, url, token)
			const [joinResult] = await joinGoogleMeetRoom(googleMeetBreakoutsRef.current, url, token, meetAppBuilt, setMeetAppBuilt, setSelectedTabUuid, session, isDarkMode);
			return joinResult;
		}
		return Promise.resolve(StartCallResult.UNKNOWN_START_CALL_RESULT);
	}, [isDarkMode, meetAppBuilt, session]);

	const renderModulesByGroup = useCallback(() => {
		if (!selectedTabUuid || !session || !tabGroupMap) return <></>;
		const templateClassName = getTemplateClassName(template);
		return <RenderSessionModules key={tabGroupMap[selectedTabUuid]?.uuid} moduleGroup={tabGroupMap[selectedTabUuid]} session={session} template={templateClassName} preview={previewSession ? true : false} submitGoogleToken={submitGoogleToken} />;
	}, [previewSession, selectedTabUuid, session, submitGoogleToken, tabGroupMap, template]);

	const renderAllModules = useCallback(() => {
		if (!session || !tabGroupMap) return <></>;
		const templateClassName = getTemplateClassName(template);
		return (
			<>
				{session?.module_grouping?.map((group, i) => <RenderSessionModules key={group.uuid + i} moduleGroup={group} session={session} template={templateClassName} preview={previewSession ? true : false} submitGoogleToken={submitGoogleToken} />)}
			</>
		);
	}, [previewSession, session, submitGoogleToken, tabGroupMap, template]);

	const renderAllModulesBelowTheFold = useCallback(() => {
		if (!session || !tabGroupMap) return <></>;
		const templateClassName = getTemplateClassName(template);
		return (
			<>
				{session?.module_grouping?.filter(group => group.is_on)?.map((group, i) => <RenderSessionModules
					key={group.uuid + i}
					isBelowTheFold={sessionDetailsV2FeatureFlagOn}
					moduleGroup={group}
					session={session}
					template={templateClassName}
					preview={previewSession ? true : false}
					submitGoogleToken={submitGoogleToken}
					moduleGroupsRef={moduleGroupsRefs[i]}
					googleMeetJoined={!!embedMeetJoined}
				/>)}
			</>
		);
	}, [embedMeetJoined, moduleGroupsRefs, previewSession, session, sessionDetailsV2FeatureFlagOn, submitGoogleToken, tabGroupMap, template]);

	const feedbackSurvey = useCallback(() => {
		if (!isModuleGroupingV2 && session?.enable_feedback_survey && sessionStarted) {
			return (
				<FeedbackSurveyAlert
					onClick={rating => {
						setFeedbackRating(rating);
						setShowFeedbackSurveyModal(true);
					}}
					rating={sessionUserSurveyRatings[session.uuid]}
					show={showFeedbackSurveyAlert}
					setShow={setShowFeedbackSurveyAlert}
					layout={layout}
				/>
			);
		}
	}, [isModuleGroupingV2, layout, session?.enable_feedback_survey, session?.uuid, sessionStarted, sessionUserSurveyRatings, setFeedbackRating, showFeedbackSurveyAlert]);

	const handleChatClose = useCallback((chatClosed: boolean) => {
		setChatClosed(chatClosed);
	}, []);

	const renderMeetContainer = useCallback(() => (
		<OptionalComponent display={!!session?.breakout_session?.use_google_meet}>
			{/* Placeholder expands when Meet is in PiP to ensure screen layout/size doesn't change */}
			<div id="meet-room-placeholder"
				className={classNames(
					{ 'dark-mode': isDarkMode },
					{ 'meet-room-pip': triggerMeetPiP }
				)}
			/>
			<div
				className={classNames(
					// Warning: Leave this meet-room-container class!  
					// If there isn't a persistent class, adding a class property dymanically
					// will cause the div to re-render and lose the iframe.
					'meet-room-container',
					{ 'dark-mode': isDarkMode },
					{ 'full-screen': isFullScreenMode },
					{ 'meet-room-pip': triggerMeetPiP }
				)}
				style={{ display: (embedMeetJoined ? 'block' : 'none') }}
				id="meet-room"
				ref={googleMeetBreakoutsRef}
			>
				<div className={classNames("meet-header-gradient", { 'dark-mode': isDarkMode })}></div>
			</div>
		</OptionalComponent>
	), [embedMeetJoined, isDarkMode, isFullScreenMode, session?.breakout_session?.use_google_meet, triggerMeetPiP]);


	//
	// functions
	//
	// the tab-navigation component is the session navigation panel,
	// not to be confused with a tab-header component.  
	const renderContent = () => {
		let _layout = layout;

		const isNewLayout = isSessionDetailsBelowTheFoldV2 || isSessionDetailsAboveTheFoldV2;

		const revertLayoutToDefaultCheck = !sessionDetailsV2FeatureFlagOn && isNewLayout;

		if (revertLayoutToDefaultCheck) {
			_layout = SessionTabsLayoutTypes.NoTabs;
		}

		switch (_layout) {
			case SessionTabsLayoutTypes.Vertical:
				return (
					<>
						<ul className="tab-navigation">

							<GroupedNavButtonsSession
								moduleGroupsRefs={moduleGroupsRefs}
								singleSession={singleSession}
								previewBundle={previewBundle}
								previewSession={previewSession}
								selectedTabUuid={selectedTabUuid}
								setSelectedTabUuid={setSelectedTabUuid}
								autoScroll={autoScroll}
								setAutoScroll={setAutoScroll}
							/>
						</ul>
						<div className="page-container-body">
							{feedbackSurvey()}
							{renderModulesByGroup()}
						</div>
					</>
				);
			case SessionTabsLayoutTypes.NoTabs:
				return (
					<div className="page-container-body">
						{feedbackSurvey()}
						{renderAllModules()}
					</div>
				);
			case SessionTabsLayoutTypes.BelowTheFold:
				return (
					<BelowTheFoldContainer
						backgroundColor={backgroundColor}
						backgroundImg={backgroundImg}
						belowTheFoldContainerRef={belowTheFoldContainer}
						moduleGroupsRefs={moduleGroupsRefs}
						groupedNavButtons={
							<GroupedNavButtonsSession
								moduleGroupsRefs={moduleGroupsRefs}
								singleSession={singleSession}
								previewBundle={previewBundle}
								previewSession={previewSession}
								selectedTabUuid={selectedTabUuid}
								setSelectedTabUuid={setSelectedTabUuid}
								autoScroll={autoScroll}
								setAutoScroll={setAutoScroll}
								googleMeetJoined={!!embedMeetJoined}
							/>
						}
						isScrolledPastTheFold={isScrolledPastTheFold}
						isSessionDetailsBelowTheFoldV2={isSessionDetailsBelowTheFoldV2}
						layout={layout}
						renderAllModulesBelowTheFold={renderAllModulesBelowTheFold}
						session={session}
						selectedTabUuid={selectedTabUuid}
						autoScroll={autoScroll}
						isTopNavbar={isTopNavbar}
					/>
				);

			case SessionTabsLayoutTypes.AboveTheFold:
				if (isDesktop) return <></>;
				return (
					<BelowTheFoldContainer
						backgroundColor={backgroundColor}
						backgroundImg={backgroundImg}
						belowTheFoldContainerRef={belowTheFoldContainer}
						moduleGroupsRefs={moduleGroupsRefs}
						groupedNavButtons={
							<GroupedNavButtonsSession
								moduleGroupsRefs={moduleGroupsRefs}
								singleSession={singleSession}
								previewBundle={previewBundle}
								previewSession={previewSession}
								selectedTabUuid={selectedTabUuid}
								setSelectedTabUuid={setSelectedTabUuid}
								autoScroll={autoScroll}
								setAutoScroll={setAutoScroll}
							/>
						}
						isScrolledPastTheFold={isScrolledPastTheFold}
						isSessionDetailsBelowTheFoldV2={isSessionDetailsBelowTheFoldV2}
						layout={layout}
						renderAllModulesBelowTheFold={renderAllModulesBelowTheFold}
						session={session}
						selectedTabUuid={selectedTabUuid}
						autoScroll={autoScroll}
						isTopNavbar={isTopNavbar}
					/>
				);

			default: // Horizontal
				return (
					<>
						<Suspense fallback="">
							<Sticky
								top={session && session.languages?.length > 1 ? '70px' : '0px'}
								renderStickyElements={() => (
									<ul className="tab-navigation">
										<GroupedNavButtonsSession
											moduleGroupsRefs={moduleGroupsRefs}
											singleSession={singleSession}
											previewBundle={previewBundle}
											previewSession={previewSession}
											selectedTabUuid={selectedTabUuid}
											setSelectedTabUuid={setSelectedTabUuid}
											autoScroll={autoScroll}
											setAutoScroll={setAutoScroll}
										/>
									</ul>
								)}
							/>
						</Suspense>
						{feedbackSurvey()}
						<div className="page-container-body">
							{renderModulesByGroup()}
						</div>
					</>
				);
		}
	};

	//
	// effects
	//
	useEffect(() => {
		setBlinkPlayer(true);
		setTimeout(() => {
			setBlinkPlayer(false);
		});
	}, [sessionUuid]);

	useEffect(() => {
		return () => {
			modalState.setSession(null);
		};
	}, []);

	useEffect(() => {
		if (!sessionStarted) {
			const pastStartTime = session?.timestamp
				? session.timestamp - Date.now() < 0
				: true; // null timestamp for on demand, so it's always past the start time

			if (pastStartTime) {
				setSessionStarted(true);
			}
		}
	}, [eventBundle, session, sessionStarted]);

	// This isn't setting the value, it's setting a REFERECNCE
	// The value of the html element ends up getting added during
	// the refercen bind in RenderSessionModules
	useEffect(() => {
		const _moduleGroupsRefs = session?.module_grouping?.filter(group => group.is_on)?.map(() => createRef<HTMLParagraphElement>()) || [];
		setModuleGroupsRefs(_moduleGroupsRefs);
	}, [session?.module_grouping]);

	// When we autoscroll, we don't want the tabs to auto switch when starting in a different section than the selected section
	useEffect(() => {
		setTimeout(() => {
			if (autoScroll) {
				setAutoScroll(false);
			}
		}, 1000);
	}, [autoScroll]);

	useEffect(() => {
		if (showSessionUserFeedbackSurveyModal && sessionUuid && blProfileUser?.uuid && !sessionUserSurveyRatings[sessionUuid] && blProfileUserToken) {
			dispatch(getSessionUserSurveyRating(sessionUuid, blProfileUser.uuid, blProfileUserToken));
		}
		// do not add sessionUserSurveyRatings or we'll get an infinite loop
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sessionUuid, blProfileUser, blProfileUserToken, dispatch, showSessionUserFeedbackSurveyModal]);

	useEffect(() => {
		//check to make sure language is in translated list, if not, push them to the default language
		if (notValidTranslation && session && !isPreview) {
			if (singleSession) {
				history.replace(`/${eventPath(eventName, uuid)}/${session.default_language}`);
			} else {
				history.replace(`/${eventPath(eventName, uuid)}/${session.default_language}/session/${sessionUuid}`);
			}
		}
	}, [history, eventName, uuid, sessionUuid, session, notValidTranslation, isPreview, singleSession]);

	useEffect(() => {
		// show announcement as alert
		const handleAnnouncement = ({ data }: { data: Partial<IAnnouncement>; }) => {
			// misc is needed for tracking, and hooks cannot be called in the Alert component
			if (eventBundle) {
				const { source_id, source_type } = getCurrentPageSourceInfo(location.pathname, eventBundle, blProfile || 0);

				showAnnouncement({ ...data, language, baseLanguage, source_id, source_type });
			}
		};
		// show user survey modal
		const handleUserSurvey = ({ data }: { data: number; }) => {
			setSurveyId(data);
		};

		const handleSessionCustomUrl = ({ data }: { data: { backup_url_enabled: boolean, backup_url: string } }) => {
			setCustomUrlEnabled(data.backup_url_enabled);
			setCustomUrl(data.backup_url);
		};

		// add listeners and remove when done
		socket.addListener('announcement', handleAnnouncement);

		if (isRegistered) {
			registeredSocket.addListener('announcement', handleAnnouncement);
		}

		socket.addListener(SqsSocketTypes.SURVEY, handleUserSurvey);
		sessionCustomUrlSocket.addListener('session-backup-url-update', handleSessionCustomUrl);
		return () => {
			socket.removeListener('announcement', handleAnnouncement);
			socket.removeListener(SqsSocketTypes.SURVEY, handleUserSurvey);
			registeredSocket?.removeListener?.('announcement', handleAnnouncement);
			sessionCustomUrlSocket.removeListener('session-backup-url-update', handleSessionCustomUrl);
		};
	}, [socket, registeredSocket, sessionCustomUrlSocket, isRegistered, blProfile, eventBundle, language, baseLanguage]);

	useEffect(() => {
		if (TEST_ERROR_BOUNDARIES) {
			return () => {
				dispatch(clearErrorBoundaryComponents());
			};
		}
	}, [dispatch]);

	useEffect(() => {
		Signals.on('open-add-to-calendar-modal', () => setOpenAddToCalendar(true));
		return () => {
			Signals.off('open-add-to-calendar-modal');
		};
	}, []);

	useEffect(() => {
		setCustomUrlEnabled(session?.custom_url_enabled || session?.backup_url_enabled || false);
		setCustomUrl(session?.custom_url || session?.backup_url || '');
	}, [session?.backup_url_enabled, session?.backup_url, session?.custom_url_enabled, session?.custom_url]);

	useEffect(() => {
		if (
			customUrlEnabled &&
			customUrl &&
			((eventBundle?.sessions.length && eventBundle?.sessions.length > 1 && matchPath(location.pathname, { path: RouteMap.Session })) ||
				(eventBundle?.sessions.length && eventBundle?.sessions.length === 1 && isSingleSessionPage))
		) {
			window.location.href = customUrl;
		}
	}, [customUrlEnabled, customUrl, RouteMap.Session]);

	// make sure we close the user feedback modal if component unmounts
	useEffect(() => {
		return () => {
			dispatch(setShowSessionUserFeedbackSurveyModal(null));
		};
	}, [dispatch]);

	useEffect(() => {
		// getUserSessionError prevents this from turning into an infinite loop.
		// if the dispatch(getUSerSession...) lambda call fails, then getUserSessionError will be the result string message
		if (!gettingUserSession && sessionId && !getUserSessionError && !isPreview && eventId && channel && eventUuid) {
			const storedSession = getStorageItem(`ses.${sessionId}`);
			if (storedSession) {
				dispatch(setUserSession(storedSession));
			} else {
				if (!loadingValidSessions || !registrationOn) { // we need to wait to make sure we have passcode lists
					dispatch(getUserSession(
						sessionId,
						eventId,
						channel,
						blProfileUserToken,
						registrationId ?? undefined,
						registeredLanguage ?? undefined,
						getStoredReferrer(eventUuid) ?? undefined,
						Array.from(new Set(validPasscodeLists ?? [])),
					));
				}
			}
		}
		return () => {
			// remove user session from redux so if we go to a new session we dont have a stale user_session_uuid 
			dispatch(resetUserSession());
		};
	}, [
		gettingUserSession,
		sessionId,
		dispatch,
		blProfileUserToken,
		getUserSessionError,
		registrationId,
		isPreview,
		channel,
		eventId,
		eventUuid,
		registrationOn,
		registeredLanguage,
		validPasscodeLists,
		loadingValidSessions,
	]);

	useEffect(() => {
		if (googleMeetBreakoutsRef.current) {
			const observerConfig: MutationObserverInit = { attributes: true, childList: true, subtree: true, attributeOldValue: true };
			const observerCallback = (mutationList: MutationRecord[]) => {
				for (const mutation of mutationList) {
					if (mutation.type === "attributes") {
						// attributes modified
						if (mutation.oldValue === "") {
							// user is leaving call
							dispatch(toggleGoogleMeetEmbedView(null));
						}
					}
				}
			};
			const observer = new MutationObserver(observerCallback);
			observer.observe(googleMeetBreakoutsRef.current, observerConfig);

			return () => {
				// clean up observer
				observer.disconnect();

				// Kludge to force disconnect Google Meet call
				// if user is currently in a google meet room and hasn't left manually but navigated away from the session page
				// navigating back to the session page will lock them out of joining other rooms
				// we need to force a refresh to clear the google meet sdk out of the dom and refresh the users credentials
				window.location.reload();
			};
		}
	}, [dispatch]);


	/*

		Begin return statements
		-----------------------

		No hooks below this point!

	*/

	// if backup url, do not display the session page at all otherwise it temp flashes the session page
	if (
		!workingEvent && // Ignore this in the editor
		((session?.backup_url && session?.backup_url_enabled) || (session?.custom_url && session?.custom_url_enabled))
	) {
		return null;
	}

	//no redirect if this is the preview
	if (eventBundle?.registration_on && !registrationId && !isPreview) {
		return <Redirect to={Redirects.toRegistration()} />;
	}

	if (loadingEventBundle || !session) {
		return (
			<div className={classNames('session', getTemplateClassName(template))}>
				<div className={"session-main-content"}>
					<div
						className={classNames(
							"modules-container",
							layoutClassName,
							{
								[backgroundColor]: !isModuleGroupingV2
							}
						)}
						style={isModuleGroupingV2 ? undefined : backgroundImg}
					>
					</div>
				</div>
			</div>
		);
	}

	//do not redirect if this is the preview
	//user has invalid validation OR has not yet registered and registration is on, so redirect to registration page

	if (!hasEventAccess(eventBundle, validSessions, finishedRegistering, userVerified, firesidesHostSessions, registrationBypass, loadingValidSessions) && !isPreview) {
		return (
			<Redirect
				to={{
					pathname: Redirects.toRegistration(),
					state: eventBundle ? { referrer: Redirects.postRegistrationReferrer(eventBundle) } : undefined
				}}
			/>
		);
	}

	if (eventBundle?.registration_on && ticketingStep && !hasTicket && !isPreview) {
		return (
			<Redirect
				to={{
					pathname: Redirects.toRegistration(),
					state: eventBundle ? { referrer: Redirects.postRegistrationReferrer(eventBundle) } : undefined
				}}
			/>
		);
	}

	if (previewSession && previewBundle) {
		// display the profile bubble if there is no homepage but
		// registration is on or the session has more than 1 language and its desktop preview
		const noHomepageShowProfileBubble = !previewNavigation && (previewBundle?.registration_on || (previewBundle?.sessions[0]?.languages?.length ?? 0) > 1) && editorSize === EditorSizes.desktop;

		return (
			<div
				className={classNames('session', getTemplateClassName(template), {
					'breakout-session': session.session_type === SessionTypesEnum.breakoutRooms,
					'dark-mode': session?.session_chat_dark_mode_enabled,
					'pre-live': playerType === 'none',
					'chat-disabled': !session.session_chat_enabled,
				})}
			>
				{!isV2 && noHomepageShowProfileBubble && adminUser && adminUser?.active_channel && previewBundle && (
					<aside className="profile-details-actions single-session">
						<Suspense fallback="">
							<ProfileDetailsDropdown
								profile={getMockBlProfile(adminUser)}
								channel={adminUser?.active_channel}
								eventBundle={previewBundle}
								template={getTemplateClassName(template)}
								isEditor={!!workingEvent}
							/>
						</Suspense>
					</aside>
				)}
				<div
					className={classNames("session-main-content", {
						"single-session": previewBundle ? !previewNavigation : !hasNavigation,
						'fireside-session': isFireside,
						'chat_enabled': session.session_chat_enabled,
						'new-navigation': showNewNav && isDesktop
					})}
				>
					<SessionDocumentHead session={session} />
					{session?.session_type === SessionTypesEnum.breakoutRooms ? (
						<div>
							<MainEventBannerBreakout
								isEditor={false}
								session={session}
								template={getTemplateClassName(template)}
								googleMeetBreakoutsRef={googleMeetBreakoutsRef}
							/>
						</div>
					) : (
						<>
							{!blinkPlayer && (
								<StreamComponent
									languageProp={language}
									session={session}
									eventBundle={previewBundle}
									playback={playbackUrls[session.uuid]}
									editorSize={editorSize}
									sessionHeaderRef={sessionHeaderRef}
									googleMeetBreakoutsRef={googleMeetBreakoutsRef}
								/>
							)}

						</>
					)}
					{mobileChat && session.session_chat_enabled && (
						<Suspense fallback="">
							<LiveChat
								session={session}
								loading={!!loadingEventBundle}
								language={language}
								registrationOn={!!previewBundle?.registration_on}
								blProfileUser={blProfileUser as BlProfile}
								template={getTemplateClassName(previewBundle.template.name)}
								forceOverlay={false}
								mobileOnly={true}
								isEditor={true}
								eventBundle={previewBundle}
								chatClosed={chatClosed}
								onChatClose={handleChatClose}
							/>
						</Suspense>
					)}
					<OptionalComponent display={!videoV2}>
						<Suspense fallback="">
							<FiresideHostControls session={session} template={getTemplateClassName(template)} admin />
						</Suspense>
					</OptionalComponent>
					<div
						className={classNames(
							"modules-container",
							layoutClassName,
							{
								["display-language-dropdown"]: displaySessionPageLanguageToggle,
								[backgroundColor]: !isModuleGroupingV2
							}
						)}
						style={isModuleGroupingV2 ? undefined : backgroundImg}
					>
						<OptionalComponent display={displaySessionPageLanguageToggle && !isLessThan640 && !isSessionDetailsAboveTheFoldV2}>
							<div className="language-dropdown-container">
								<LanguageDropdown languages={session.languages as LanguagesAbbr[]} currentLanguageCode={language} useISOLangCodes onLanguageChange={handleLanguageChange} isNoScroll={false} animateDropdown />
							</div>
						</OptionalComponent>
						{renderContent()}
					</div>
					{(displayFooter && event?.homepage?.footer) ? (
						<Suspense fallback="">
							<Footer
								footer={event.homepage.footer}
								template={getTemplateClassName(template)}
							/>
						</Suspense>
					) : null}
				</div>
			</div>
		);
	}

	const showUserSurveyModal = () => {
		return (
			(showFeedbackSurveyModal && !!sessionUserSurveyRatings[session.uuid])
			|| !!surveyId
			|| (showSessionUserFeedbackSurveyModal === session.uuid)
		);
	};

	return eventBundle ? (
		<>
			<div className={classNames('session', getTemplateClassName(template), {
				'breakout-session': session.session_type === SessionTypesEnum.breakoutRooms,
				'fireside-session': session.session_type === SessionTypesEnum.fireside,
				'meet-fireside': session.session_type === SessionTypesEnum.fireside && firesideSessionSettings?.settings?.use_google_meet,
				'in-meet-waiting-room': inMeetWaitingRoom,
				'new-top-navbar': isDesktop && (isTopNavbar && showNewNav || isSingleSessionWithoutHome && showNewNav),
				'video-v2': videoV2,
				'dark-mode': session?.session_chat_dark_mode_enabled,
				'pre-live': playerType === 'none',
				'chat-open': !chatClosed,
				'chat-disabled': !session.session_chat_enabled,
				'version-1': !isModuleGroupingV2,
				'above-the-fold': isSessionDetailsAboveTheFoldV2,
				"left-nav": isLeftNavbar,
			})}>
				<Track>
					{!isV2 && noHomepageShowProfileBubble && channel && event && (
						<aside
							ref={profileRefDesktop}
							className={classNames('profile-details-actions', 'single-session', { 'set-chat-under-video': setChatUnderVideo })}
						>
							<Suspense fallback="">
								<ProfileDetailsDropdown
									profile={(workingEvent && adminUser) ? getMockBlProfile(adminUser) : blProfileUser}
									channel={channel}
									eventBundle={event}
									template={getTemplateClassName(template)}
									isEditor={!!workingEvent}
								/>
							</Suspense>
						</aside>
					)}
					<div
						className={classNames("session-main-content", {
							'single-session': !eventBundle.homepage,
							'fireside-session': isFireside,
							'chat_enabled': session.session_chat_enabled,
							'new-navigation': showNewNav && isDesktop
						})}
					>
						<SessionDocumentHead session={session} />

						{session?.session_type === SessionTypesEnum.breakoutRooms
							&& (
								// ALWAYS display this when in breakout room mode.  The header is hidden if it is not needed internally
								// However, the Google Meet location is housed here.  It needs to be done in a consistant place to
								// prevent the component from being lost during a react re-render.
								//
								// scrollContainer is how we know we're below the fold and need
								// to float the tabs
								<div className={classNames(
									'session-header-and-stream',
									'breakouts-header',
									{
										'chat-open': !chatClosed,
										'new-navigation': isDesktop && showNewNav && isSingleSession && !session?.breakout_session?.use_google_meet,
										'single-session-nav': isSingleSessionWithoutHome,
										'meet-room-is-pip': triggerMeetPiP
									})}
									ref={scrollContainer}>

									<MainEventBannerBreakout
										isEditor={false}
										session={session}
										template={getTemplateClassName(template)}
										googleMeetBreakoutsRef={googleMeetBreakoutsRef}
										renderGoogleMeet={renderMeetContainer}
										googleMeetJoined={embedMeetJoined}
										fullScreenMode={isFullScreenMode}
									/>
								</div>
							)}
						{session?.session_type !== SessionTypesEnum.breakoutRooms
							&& (
								<div className={classNames(
									'session-header-and-stream',
									{
										'chat-open': !chatClosed,
										'new-navigation': isDesktop && showNewNav && isSingleSession,
										'single-session-nav': isSingleSessionWithoutHome,
									})}
									ref={scrollContainer}>

									{!blinkPlayer && (
										<ErrorBoundary uniqueLabel="Session stream">
											<StreamComponent
												languageProp={language}
												session={session}
												eventBundle={eventBundle}
												playback={playbackUrls?.[`${session.uuid}-${single_stream ? session.default_language : language}`]}
												secondaryVideos={secondaryVideos}
												sessionHeaderRef={sessionHeaderRef}
												googleMeetBreakoutsRef={googleMeetBreakoutsRef}
											/>
										</ErrorBoundary>
									)}
								</div>
							)}

						{mobileChat && session.session_chat_enabled && (
							<Suspense fallback="">
								<ErrorBoundary uniqueLabel="Mobile live chat">
									<LiveChat
										session={session}
										loading={!!loadingEventBundle}
										language={language}
										registrationOn={!!eventBundle?.registration_on}
										blProfileUser={blProfileUser as BlProfile}
										template={getTemplateClassName(eventBundle.template.name)}
										forceOverlay={false}
										mobileOnly={true}
										chatClosed={chatClosed}
										onChatClose={handleChatClose}
										eventBundle={eventBundle}
										videoV2={videoV2}
									/>
								</ErrorBoundary>
							</Suspense>
						)}

						<OptionalComponent display={!videoV2}>
							<Suspense fallback="">
								<FiresideHostControls session={session} template={getTemplateClassName(template)} />
							</Suspense>
						</OptionalComponent>

						{!isFullScreenMode
							&& <div
								className={classNames(
									"modules-container",
									layoutClassName,
									{
										["display-language-dropdown"]: displaySessionPageLanguageToggle,
										[backgroundColor]: !isModuleGroupingV2
									}
								)}
								style={isModuleGroupingV2 ? undefined : backgroundImg}
							>
								<OptionalComponent display={displaySessionPageLanguageToggle && !isLessThan640 && !isSessionDetailsAboveTheFoldV2}>
									<div className="language-dropdown-container">
										<LanguageDropdown languages={session.languages as LanguagesAbbr[]} currentLanguageCode={language} useISOLangCodes onLanguageChange={handleLanguageChange} isNoScroll={false} animateDropdown />
									</div>
								</OptionalComponent>

								{renderContent()}
							</div>}

						{(!isFullScreenMode && displayFooter && event?.homepage?.footer) ? (
							<Suspense fallback="">
								<Footer
									footer={event.homepage.footer}
									template={getTemplateClassName(template)}
								/>
							</Suspense>
						) : null}
						<Suspense fallback="">
							{showUserSurveyModal() && (
								<UserSurveyModal
									setFeedbackRating={setFeedbackRating}
									setShowFeedbackSurveyAlert={setShowFeedbackSurveyAlert}
									onClose={() => {
										setSurveyId(null);
										setShowFeedbackSurveyModal(false);
										dispatch(setShowSessionUserFeedbackSurveyModal(null));
									}}
									template={getTemplateClassName(template)}
									feedbackRating={sessionUserSurveyRatings[session.uuid] || 0}
									surveyID={surveyId || null}
									language={language}
									sessionUuid={sessionUuid}
								/>
							)}
						</Suspense>
					</div>
					{(session && template) && (
						<Suspense fallback="">
							<SessionDetailsModalContainer
								session={session}
								template={template}
							/>
						</Suspense>
					)}
					<OptionalComponent display={!isModerator}>
						<Suspense fallback="">
							<AddToCalendarModal
								session={session}
								eventName={eventBundle?.name || ''}
								open={openAddToCalendar}
								close={() => setOpenAddToCalendar(false)}
								language={language}
								isSingleSessionNoHomepage={eventBundle?.sessions?.length === 1 && !eventBundle?.homepage}
							/>
						</Suspense>
					</OptionalComponent>
				</Track>
			</div>
		</>
	) : previewBundle ? (
		<div
			className={classNames('session', getTemplateClassName(template), {
				'breakout-session': session.session_type === SessionTypesEnum.breakoutRooms,
				'fireside-session': session.session_type === SessionTypesEnum.fireside,
				'dark-mode': session?.session_chat_dark_mode_enabled,
				'pre-live': playerType === 'none'
			})}
		>
			<div
				className={classNames("session-main-content", {
					'single-session': !previewBundle?.homepage,
					'fireside-session': isFireside,
					'chat_enabled': session.session_chat_enabled,
					'new-navigation': showNewNav && isDesktop
				})}
			>
				{session.session_type === SessionTypesEnum.breakoutRooms ? (
					<div style={{ maxHeight: '460px' }}>
						<MainEventBannerBreakout
							session={session}
							template={getTemplateClassName(template)}
							googleMeetBreakoutsRef={googleMeetBreakoutsRef}
						/>
					</div>
				) : (
					<>
						<StreamComponent
							languageProp={language}
							session={session}
							editorSize={editorSize}
							eventBundle={previewBundle}
							playback={playbackUrls[`${session.uuid}-${single_stream ? session.default_language : language}`]}
							secondaryVideos={secondaryVideos}
							isEditor
							sessionHeaderRef={sessionHeaderRef}
							googleMeetBreakoutsRef={googleMeetBreakoutsRef}
						/>
						{mobileChat && session.session_chat_enabled && (
							<Suspense fallback="">
								<LiveChat
									session={session}
									loading={!!loadingEventBundle}
									language={language}
									registrationOn={!!previewBundle?.registration_on}
									blProfileUser={blProfileUser as BlProfile}
									template={getTemplateClassName(previewBundle.template.name)}
									forceOverlay={false}
									mobileOnly={true}
									isEditor={true}
									eventBundle={previewBundle}
									chatClosed={chatClosed}
									onChatClose={handleChatClose}
								/>
							</Suspense>
						)}
						<OptionalComponent display={!videoV2}>
							<Suspense fallback="">
								<FiresideHostControls session={session} template={getTemplateClassName(template)} admin />
							</Suspense>
						</OptionalComponent>
					</>
				)}
				<div
					className={classNames(
						"modules-container",
						layoutClassName,
						{
							["display-language-dropdown"]: displaySessionPageLanguageToggle,
							[backgroundColor]: !isModuleGroupingV2
						}
					)}
					style={isModuleGroupingV2 ? undefined : backgroundImg}
				>
					<OptionalComponent display={displaySessionPageLanguageToggle && !isLessThan640 && !isSessionDetailsAboveTheFoldV2}>
						<div className="language-dropdown-container">
							<LanguageDropdown languages={session.languages as LanguagesAbbr[]} currentLanguageCode={language} useISOLangCodes onLanguageChange={handleLanguageChange} isNoScroll={false} animateDropdown />
						</div>
					</OptionalComponent>
					{renderContent()}
				</div>
				{
					(displayFooter && event?.homepage?.footer) ? (
						<Suspense fallback="">
							<Footer
								footer={event.homepage.footer}
								template={getTemplateClassName(template)}
							/>
						</Suspense>
					) : null
				}
			</div >
			<Suspense fallback="">
				<SessionPreviewModal open={previewMode === EPreviewTypes.Session} singleSession={workingEvent?.sessions.length === 1} />
			</Suspense>

			{(session && template) && (
				<Suspense fallback="">
					<SessionDetailsModalContainer
						session={session}
						template={template}
					/>
				</Suspense>
			)}
		</div >
	) : null;
};

export default SessionView;
