import { useEffect, useMemo } from 'react';
import { generatePath, useHistory, useParams } from 'react-router';
import { Dictionary, IHomepageMainNavItems } from "../../../types/working-model";

interface RouteMapProps {
	eventMainNavItems?: IHomepageMainNavItems[];
	debug?: boolean;
}

// PLEASE NOTE - when adding new routes, please add new variables
// to the CommonParams and getParams definitions in utils/redirects.ts
export const DebugDefaultRouteMap = {
	CustomPage: "/event/:uuid/:language/pages/:customPath",
	Session: "/event/:uuid/:language/session/:sessionUuid",
	Fireside: "/event/:uuid/:language/session/:sessionUuid/fireside",
	FiresideNoHomepage: "/event/:uuid/:language/fireside",
	Breakout: "/event/:uuid/:language/session/:sessionUuid/breakout/:breakoutUuid",
	Registration: "/event/:uuid/:language/registration",
	PrivacyPolicy: "/event/:uuid/:language/privacy-policy",
	TermsConditions: "/event/:uuid/:language/terms-conditions",
	Home: `/event/:uuid/:language/home`,
	Sessions: `/event/:uuid/:language/sessions`,
	People: `/event/:uuid/:language/people`,
	Directory: '/event/:uuid/:language/directory',
	Profile: "/event/:uuid/:language/profile",
	Watchlist: "/event/:uuid/:language/watchlist",
	AttendeeProfile: "/event/:uuid/:language/profile/:attendeeId",
	Landing: `/event/:uuid/:language`,
	LandingRedirect: "/event/:uuid",
	Leaderboard: `/event/:uuid/:language/leaderboard`,

	// there's no route map that uses these, please figure out why they exist and remove them, it's a dead-end
	SingleSession: "/event/:uuid/:language",
	AdminSingleSession: "/admin/events/create/:id/event/:language?",
};

export const DefaultRouteMap = {
	CustomPage: "/:eventName/:language/pages/:customPath",
	Session: "/:eventName/:language/session/:sessionUuid",
	Breakout: "/:eventName/:language/session/:sessionUuid/breakout/:breakoutUuid",
	Fireside: "/:eventName/:language/session/:sessionUuid/fireside",
	FiresideNoHomepage: "/:eventName/:language/fireside",
	Registration: "/:eventName/:language/registration/:sessionUuid?",
	PrivacyPolicy: "/:eventName/:language/privacy-policy",
	TermsConditions: "/:eventName/:language/terms-conditions",
	Home: `/:eventName/:language/home`,
	Sessions: `/:eventName/:language/sessions`,
	People: `/:eventName/:language/people`,
	Directory: `/:eventName/:language/directory`,
	Profile: "/:eventName/:language/profile",
	Watchlist: "/:eventName/:language/watchlist",
	AttendeeProfile: "/:eventName/:language/profile/:attendeeId",
	Landing: `/:eventName/:language`,
	LandingRedirect: "/:eventName",
	Leaderboard: `/:eventName/:language/leaderboard`
};

class Linker {
	debug = false;
	params: Record<string, string> = {};

	getLink = (path: keyof typeof DefaultRouteMap) => {
		if (this.debug) {
			return generatePath(DebugDefaultRouteMap[path], this.params);
		} else {
			return generatePath(DefaultRouteMap[path], this.params);
		}
	};

	setDebug = (debug: boolean) => {
		this.debug = debug;
	};

	setParams = (params: Record<string, string>) => {
		this.params = params;
	};
}

export const linker = new Linker();

export default function useRouteMap({ eventMainNavItems, debug }: RouteMapProps): Dictionary {
	const history = useHistory();
	const params = useParams();

	useEffect(() => {
		linker.setDebug(debug || false);
		linker.setParams(params);
	}, [history, debug, params]);

	return useMemo(() => {
		let routeMap: Dictionary = {};

		// TODO: Post MVP when we do custom tab names decide if we want to use custom_name or
		// name for the endpoint. if custom_name, update marketing-nav to use that as well.
		const customRouteMap = eventMainNavItems?.reduce((a: { [key: string]: string }, c: IHomepageMainNavItems) => {
			a[c.name] = `${debug ? '/event/:uuid' : '/:eventName'}/:language/${c.name.toLowerCase()}`;
			return a;
		}, {});

		let defaultRouteMap: Dictionary = {};

		if (!debug) {
			defaultRouteMap = DefaultRouteMap;
		} else {
			defaultRouteMap = DebugDefaultRouteMap;
		}

		if (customRouteMap) {
			if (!debug) {
				customRouteMap.Profile = "/:eventName/:language/profile";
				customRouteMap.Watchlist = "/:eventName/:language/watchlist";
				customRouteMap.AttendeeProfile = "/:eventName/:language/profile/:attendeeId";
				customRouteMap.Directory = "/:eventName/:language/directory";
			} else {
				customRouteMap.Profile = "/event/:uuid/:language/profile";
				customRouteMap.Watchlist = "/event/:uuid/:language/watchlist";
				customRouteMap.AttendeeProfile = "/event/:uuid/:language/profile/:attendeeId";
				customRouteMap.Directory = "/event/:uuid/:language/directory";
			}

			routeMap = customRouteMap;
		} else {
			routeMap = defaultRouteMap;
		}

		return routeMap;
	}, [debug, eventMainNavItems]);
}