import { showAlert } from "@general-ui/alert/alert-service";
import { OptionalComponent } from "@general-ui/animated/optional-component";
import classNames from "classnames";
import { EndLiveMeetFireside, GoLiveMeetFireside } from "connection/firesides";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTypedSelector } from "store/reducers/use-typed-selector";
import { convertSecondsToHMS } from "utils/utils";
import { VideoStateContext } from "./session-stream-provider";
import './fireside-host-banner.scss';
import { useEventCanUseMeetFiresides } from "hooks/channel.hooks";
import { useTranslate } from "i18n/useTranslationModules";
import { UseFiresideMeetData } from "../hooks/fireside-meet";
import icon, { ICONS } from "@general-ui/icon";
import NavigationToggle from "components/live-event/marketing-page/navigation-v2/navigation-toggle";
import { ButtonIcon, ControlButton } from "./video/controls";
import { useIsSingleSessionWithoutHome } from "hooks/session.hooks";
import { useHistory } from "react-router";
import { linker } from "components/live-event/hooks/use-route-map";

type Props = {
	fsMeetData: UseFiresideMeetData;
}

const FiresideHostBanner: React.FC<Props> = ({
	fsMeetData
}) => {
	const {
		state: {
			session
		}
	} = useContext(VideoStateContext);

	const {
		hostToken,
		meetUrl,
		firesideSettings,
		isFiresideLive: isLive,
		firesideLiveSince: liveSince
	} = fsMeetData;

	const blProfileToken = useTypedSelector(state => state.LiveEventReducer.blProfileUserToken);
	const meetEntered = useTypedSelector(state => state.FiresidesReducer.meetEntered);
	const [awaitingStart, setAwaitingStart] = useState(false);
	const sessionUuid = session?.uuid;
	const meetName = firesideSettings?.settings?.google_meet?.name;
	const [sec, setSec] = useState(0);
	const mounted = useRef(true);
	const timeInterval = useRef<NodeJS.Timeout | null>(null);
	const canActivate = useEventCanUseMeetFiresides();
	const singleSession = useIsSingleSessionWithoutHome();
	const history = useHistory();
	const { t } = useTranslate("session");

	const toggleLive = useCallback(async () => {
		if (!sessionUuid || !blProfileToken || !hostToken || !meetUrl || !meetName) {
			return;
		}

		try {
			setAwaitingStart(true);
			if (isLive) {
				await EndLiveMeetFireside(sessionUuid, blProfileToken, {
					hostToken,
					meetingUrl: meetUrl,
					meetName
				});
			} else {
				await GoLiveMeetFireside(sessionUuid, blProfileToken, {
					hostToken,
					meetingUrl: meetUrl,
					meetName
				});
			}
		} catch (e) {
			console.error(e);
			showAlert({
				message: "Failed to toggle live status",
				description: "We were unable to toggle the live status of the session. Please try again.",
				type: "error"
			});
		} finally {
			setAwaitingStart(false);
		}
	}, [isLive, sessionUuid, blProfileToken, hostToken, meetUrl, meetName]);

	useEffect(() => {
		if (!canActivate) return;

		// start time not guaranteed to update every second, so we need to update the current time every second to keep it rendering
		timeInterval.current = setInterval(() => {
			setSec(Date.now());
		}, 1000);

		return () => {
			mounted.current = false;
			if (timeInterval.current) {
				clearInterval(timeInterval.current);
			}
		};
	}, [canActivate]);

	const canDisplay = canActivate && !!hostToken && !!meetUrl && meetEntered;

	useEffect(() => {
		if (canDisplay) {
			document.body.style.setProperty("--fireside-host-banner-height", "60px");
		} else {
			document.body.style.setProperty("--fireside-host-banner-height", "0px");
		}
	}, [canDisplay]);

	const handleBack = () => {
		history.push(linker.getLink('Home'));
	};

	return canActivate ? (
		<div className={classNames("fireside-host-banner-wrapper", { visible: canDisplay })}>
			<div className="fireside-host-banner">
				{/* Marketing Nav Button Opener */}
				<NavigationToggle
					forceOn={true}
					hideToggle={false}
					isDesktop={true} // Forces to not show on NON desktop views
				/>

				{/* Back Button */}
				<OptionalComponent display={!singleSession}>
					<ControlButton
						onClick={handleBack}
						tooltip={t('Go back', 'Go back')}
						tooltipClass='under'
					>
						{ButtonIcon(ICONS.ARROW_LEFT_SHADOW)}
					</ControlButton>
				</OptionalComponent>
				<button
					className={classNames("live-button x-small", { live: isLive })}
					onClick={toggleLive}
					disabled={awaitingStart}
				>
					{isLive ? t("End Stream", "End Stream") : t("Stream", "Stream")}
				</button>

				<OptionalComponent display={isLive}>
					<div className="live-indicator">
						<span className="live-dot" />
						<span>LIVE {(!!liveSince && sec > liveSince) && <span id="current-second">{convertSecondsToHMS((sec - liveSince) / 1000, true)}</span>}</span>
					</div>
				</OptionalComponent>

			</div>
		</div>
	) : null;
};

export default FiresideHostBanner;