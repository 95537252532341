enum LinkEntrySystem {
	brandlive = 'LINKED_SYSTEM_BRANDLIVE',
	RSVP = 'LINKED_SYSTEM_RSVP'
}

export enum RSVPLinkSyncStatus {
	approved = 'APPROVED',
	requested = 'REQUESTED',
}

export interface LinkEntry {
	system: LinkEntrySystem;
	event: string; // "events/{uuid}"
	eventAdminUrl?: string;
	eventGuestUrl?: string;
}
export interface RSVPLinkSync {
	id: number;
	event_uuid: string;
	status: string;
	link_group: string;
	rsvp_link_entry: LinkEntry;
	created_at: number;
	updated_at?: number;
	approved_by?: number;
}