import Quill from 'quill';

const Parchment = Quill.import('parchment');

interface IRegisterQuillStyles {
	additionalColors?: string[];
}

export interface IRegisterQuillStylesLists {
	FONT_SIZE_ARRAY: { label: string; value: string; }[];
	LINE_HEIGHT_ARRAY: { label: string; value: string; }[];
	TEXT_INDENT_ARRAY: { label: string; value: string; }[];
	COLORS_ARRAY: string[];
	BG_COLORS_ARRAY: string[];
}

// BEGIN CUSTOM STYLE REGISTRATIONS
const registerStyle = (styleTag: string, scope = Parchment.Scope.ANY) => {
	const lowerCaseTag = styleTag.toLowerCase();
	const Style = new Parchment.Attributor.Style(lowerCaseTag.replace('-', ''), lowerCaseTag, {
		scope,
	});
	const parchStyle = Parchment.register(Style);
	Quill.register(parchStyle, true);
};

export const QUILL_COLORS = [
	"",
	"rgb(0, 0, 0)",
	"rgb(230, 0, 0)",
	"rgb(255, 153, 0)",
	"rgb(255, 255, 0)",
	"rgb(0, 138, 0)",
	"rgb(0, 102, 204)",
	"rgb(153, 51, 255)",
	"rgb(255, 255, 255)",
	"rgb(250, 204, 204)",
	"rgb(255, 235, 204)",
	"rgb(255, 255, 204)",
	"rgb(204, 232, 204)",
	"rgb(204, 224, 245)",
	"rgb(235, 214, 255)",
	"rgb(187, 187, 187)",
	"rgb(240, 102, 102)",
	"rgb(255, 194, 102)",
	"rgb(255, 255, 102)",
	"rgb(102, 185, 102)",
	"rgb(102, 163, 224)",
	"rgb(194, 133, 255)",
	"rgb(136, 136, 136)",
	"rgb(161, 0, 0)",
	"rgb(178, 107, 0)",
	"rgb(178, 178, 0)",
	"rgb(0, 97, 0)",
	"rgb(0, 71, 178)",
	"rgb(107, 36, 178)",
	"rgb(68, 68, 68)",
	"rgb(92, 0, 0)",
	"rgb(102, 61, 0)",
	"rgb(102, 102, 0)",
	"rgb(0, 55, 0)",
	"rgb(0, 41, 102)",
	"rgb(61, 20, 102)",
];

const registerQuillStyles = (props?: IRegisterQuillStyles): IRegisterQuillStylesLists => {
	const additionalColors = props?.additionalColors || [];

	// font-size
	const fontSizeStyle = Quill.import('attributors/style/size');
	const FONT_SIZE_ARRAY = [
		{ label: 'Extra small', value: '12px' },
		{ label: 'Small', value: '14px' },
		{ label: 'Medium', value: '20px' },
		{ label: 'Large', value: '24px' },
		{ label: 'Extra large', value: '36px' },
		{ label: 'Huge', value: '48px' },
	];

	const ignoreValues: Record<number, number> = { 12: 12, 14: 14, 20: 20, 24: 24, 36: 36, 48: 48 };
	Array.from({ length: 100 }).forEach((_, idx) => {
		if (!ignoreValues[idx + 1] && idx + 1 > 9) {
			FONT_SIZE_ARRAY.push({
				label: idx + 1 + 'px',
				value: idx + 1 + 'px',
			});
		}
	});
	fontSizeStyle.whitelist = FONT_SIZE_ARRAY.map(item => item.value);
	Quill.register(fontSizeStyle, true);

	// line-height
	const LINE_HEIGHT_ARRAY: { label: string; value: string; }[] = [];
	for (let i = 1.0; i < 2.1; i += 0.1) {
		// Very important that whole numbers do not have a decimal and that everything else is fixed to 1 decimal
		// if a whole number, strip the decimal
		const valFixed = parseFloat(i.toFixed(1));
		const val = valFixed % 1 === 0 ? parseInt(valFixed.toString()).toString() : valFixed.toFixed(1);
		LINE_HEIGHT_ARRAY.push({ label: val, value: val });
	}
	const whitelist = LINE_HEIGHT_ARRAY.map(item => item.value.toString());
	const LineHeightStyle = new Parchment.Attributor.Style('lineheight', 'line-height', {
		scope: Parchment.Scope.BLOCK,
		whitelist,
	});
	const LineHeightClass = new Parchment.Attributor.Class('lineheight', 'ql-line-height', { whitelist });
	const lineHeightParchStyle = Parchment.register(LineHeightStyle);
	Parchment.register(LineHeightClass);
	Quill.register(lineHeightParchStyle, true);

	// text-align
	// register text align options so we use style tags instead of quill's built in classes
	const whitelistTextAlign = ['left', 'center', 'right', 'justify', 'start'];
	const TextAlignStyle = new Parchment.Attributor.Style('align', 'text-align', {
		whitelist: whitelistTextAlign,
	});
	const TextAlignClass = new Parchment.Attributor.Class('align', 'ql-text-align', { whitelist });
	const textAlignParchStyle = Parchment.register(TextAlignStyle);
	Parchment.register(TextAlignClass);
	Quill.register(textAlignParchStyle, true);

	// color (with custom color picker)
	// register text align options so we use style tags instead of quill's built in classes
	// only add rgb values, not hex
	let COLORS_ARRAY = [...QUILL_COLORS];
	COLORS_ARRAY.push(...additionalColors);
	COLORS_ARRAY = Array.from(new Set(COLORS_ARRAY));
	const ColorStyle = new Parchment.Attributor.Style('color', 'color', {
		scope: Parchment.Scope.INLINE,
	});
	const ColorClass = new Parchment.Attributor.Class('color', 'ql-color');
	const colorParchStyle = Parchment.register(ColorStyle);
	Parchment.register(ColorClass);
	Quill.register(colorParchStyle, true);

	let BG_COLORS_ARRAY = [...QUILL_COLORS];
	BG_COLORS_ARRAY.push(...additionalColors);
	BG_COLORS_ARRAY = Array.from(new Set(BG_COLORS_ARRAY));
	const BgColorStyle = new Parchment.Attributor.Style('background', 'background', {
		scope: Parchment.Scope.INLINE,
	});
	const BgColorClass = new Parchment.Attributor.Class('background', 'ql-background');
	const bgColorParchStyle = Parchment.register(BgColorStyle);
	Parchment.register(BgColorClass);
	Quill.register(bgColorParchStyle, true);

	// create options for text-indent
	// quill makes it difficult to add simple increment/decrement buttons, so we create a dropdown list for text indent value options
	const TEXT_INDENT_ARRAY: { label: string; value: string; }[] = [
		{ label: 'xsmall', value: '2%' },
		{ label: 'small', value: '4%' },
		{ label: 'med', value: '8%' },
		{ label: 'large', value: '16%' },
		{ label: 'xlarge', value: '32%' },
		{ label: 'xxlarge', value: '64%' },
	];
	// create a whitelisted array specifically for quill to use internally
	const whitelistTextIndent = TEXT_INDENT_ARRAY.map(item => item.value.toString());
	const TextIndentStyle = new Parchment.Attributor.Style('textindent', 'text-indent', {
		scope: Parchment.Scope.BLOCK,
		whitelistTextIndent,
	});
	const TextIndentClass = new Parchment.Attributor.Class('textindent', 'ql-text-indent', { whitelist: whitelistTextIndent });
	// register the styles with quill
	const textIndentParchStyle = Parchment.register(TextIndentStyle);
	Parchment.register(TextIndentClass);
	Quill.register(textIndentParchStyle, true);

	// register styles does not add the options to the toolbar, it just prevents quill from stripping inline styles on load
	registerStyle('margin');
	registerStyle('margin-left');
	registerStyle('margin-right');

	registerStyle('padding');
	registerStyle('padding-left');
	registerStyle('padding-right');

	// allow font styling (this does not add it to the toolbar, it just prevents quill from stripping inline styles)
	registerStyle('font-family');
	registerStyle('text-transform');

	registerStyle('text-decoration');

	// sanitize links to ensure they start with an appropriate protocol
	const Link = Quill.import('formats/link');
	Link.PROTOCOL_WHITELIST = ['http', 'https', 'mailto', 'tel', 'radar', 'rdar', 'smb', 'sms', 'data'];

	Link.sanitize = (url: string) => {
		// Not whitelisted URL based on protocol so, let's return `blank`
		if (!url || url === 'about:blank') return url;

		// check if the url has a whitelisted protocol
		const hasWhitelistedProtocol = Link.PROTOCOL_WHITELIST.some((protocol: string) => {
			return url.startsWith(protocol);
		});

		if (hasWhitelistedProtocol) return url;

		// if not, then append only 'https' to not to be a relative URL
		return `https://${url}`;
	};

	class EditorLink extends Link {
		static create(value: string) {
			const node = Link.create(value);
			value = Link.sanitize(value);
			node.setAttribute('href', value);
			const target = node.getAttribute('target');
			if (!target) {
				node.setAttribute('target', '_blank');
			}

			if (!target || target === '_blank') {
				// get toolbar icon and set it's value to active
				const quillWrapper = document?.querySelector(".quill-wrap.active");
				if (quillWrapper) {
					const targetEl = quillWrapper.querySelector('.ql-target');
					const linkEl = quillWrapper.querySelector('.ql-link');
					setTimeout(() => { // setTimeout hack
						targetEl?.classList.add('ql-active');
						linkEl?.classList.add('ql-active');
					}, 0);
				}
			}
			return node;
		}

		format(name: string, value?: string | boolean) {
			super.format(name, value);

			// Temporarily keeping this around because it's a potential way to clear target when clearing link - but it's buggy.
			// if link and no value, then force click remove link and target
			// const toolbarEl = document?.querySelector?.('.ql-toolbar.active');
			// if (name === 'link' && !value && toolbarEl) {
			// const removeEl = document?.querySelector?.('a.ql-remove');
			// if (removeEl) {
			// 	try {
			// 		assertIsAnchorElement(removeEl);
			// 		removeEl.click();
			// const targetEl = document?.querySelector?.('button.ql-target.ql-active');
			// if (targetEl) {
			// 	assertIsButtonElement(targetEl);
			// 	setTimeout(() => {
			// 		targetEl.click();
			// 	}, 0);
			// }
			// 	} catch (e) {
			// 		console.error(e);
			// 	}
			// 	return;
			// }
			// }

			if (name !== this.statics.blotName || !value) {
				return;
			}
			const target = this.domNode.getAttribute('target');
			if (!target) {
				this.domNode.setAttribute('target', '_blank');
			}
		}
	}

	Quill.register(EditorLink, true);

	const TargetAttr = new Parchment.Attributor.Attribute('target', 'target', {
		scope: Parchment.Scope.INLINE_ATTRIBUTE
	});
	const targetAttr = Parchment.register(TargetAttr);
	Quill.register(targetAttr, true);


	// END CUSTOM STYLE REGISTRATIONS

	return {
		FONT_SIZE_ARRAY,
		LINE_HEIGHT_ARRAY,
		COLORS_ARRAY,
		BG_COLORS_ARRAY,
		TEXT_INDENT_ARRAY,
	};
};

export default registerQuillStyles;
