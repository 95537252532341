import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { debounce } from 'underscore';

import { DeleteSpeaker, InsertSpeaker, SaveSpeaker, SearchSpeakers } from '../../../../connection/speakers';
import { UploadFile } from '../../../../connection/uploads';
import { CreateSpeaker, LanguagesAbbr, ModuleStatus, RelatedSpeakerSessions, SocialLink, SocialPlatforms, Speaker, SpeakerAttribute, TranslateString } from '../../../../types/working-model';
import Icon, { ICONS, COLORS } from '../../../general-ui/icon';
import ImageEditor from '../../../general-ui/image-editor/image-editor';
import SessionSelectInput from '../../../general-ui/select/session-select';
import TextInput, { Validation } from '../../../general-ui/text-input/text';
import Textarea from '../../../general-ui/textarea/textarea';
import ModalComponent from '../../../general-ui/modal/modal';
import WaitingIndicator from '../../../general-ui/waiting-indicator/waiting-indicator';
import Switch from "../../../general-ui/switch/switch";
// import { EventsState } from '../../../../store/types';
import { updateTranslateKey } from '../../../../utils/utils';
import SmallSelect from '../../../general-ui/select/small-select';
import AddSpeaker from '../../create/homepage/editor/speakers/add-speaker';
import { createSpeaker, getSpeakers, updateSpeaker } from '../../../../store/actions/admin/speakers';
import { GetDefaultSpeaker, GetDefaultTranslateString } from '../../../../store/utils/create-event';
import SelectInput from '../../../general-ui/select/select';
import { EIcon } from '../../../general-ui/icon/icon';
import { TIconName } from '../../../general-ui/icon/icon.types';
import { Tooltip } from '../../../general-ui/tooltip/tooltip';
import { showAlert } from '../../../general-ui/alert/alert-service';
import { socialOptions } from '../../../../utils/speaker-utils';
import FileInput from '../../../../utils/file-input';
import { useTypedSelector } from '../../../../store/reducers/use-typed-selector';

const iconNameList: TIconName[] = ['company', 'email-filled', 'send-filled', 'info-circle'];
export const speakerAttributeIconOptions = iconNameList.map(name => ({
	value: name,
	label: <EIcon name={name} />
}));

export const initialRequiredSpeakerFields: { [key: string]: Validation } = {
	firstName: Validation.normal,
	lastName: Validation.normal,
	speakerImage: Validation.normal,
	jobTitle: Validation.normal,
};

interface Props {
	open: boolean;
	close: () => void;
	onComplete: (workingSpeaker: Speaker, sessionIds: number[]) => void;
	editingSpeaker: Speaker | CreateSpeaker | null;
	language: LanguagesAbbr;
	baseLanguage: LanguagesAbbr;
	replacing?: Speaker | null | undefined;
	onSelectionComplete?: (s: Speaker[]) => void | undefined;
	initialSelected?: Speaker[] | undefined;
	initialRelatedSessions?: RelatedSpeakerSessions[];
	inModal?: boolean
}

const CreateSpeakerModal: React.FC<Props> = (props) => {
	const {
		open,
		close,
		onComplete,
		editingSpeaker,
		replacing,
		initialSelected,
		onSelectionComplete,
		initialRelatedSessions,
		inModal = true,
		language,
		baseLanguage
	} = props;

	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const token = useTypedSelector(state => state.AuthReducer.token);
	const user = useTypedSelector(state => state.AuthReducer.user);
	const speakers = useTypedSelector(state => state.SpeakersReducer.speakers);
	// const loadingSpeakers = useTypedSelector(state => state.SpeakersReducer.loadingSpeakers);

	const dispatch = useDispatch();

	useEffect(() => {
		if (!user || !token) return;
		if (open) {
			dispatch(getSpeakers(user.active_channel, token));
		}
	}, [dispatch, user, token, open]);

	const [editingImage, setEditingImage] = useState(false);
	const [originalImage, setOriginalImage] = useState<string | undefined>(editingSpeaker?.original_image || editingSpeaker?.image);
	const [workingImage, setWorkingImage] = useState<File | null>(null);
	const [editing, setEditing] = useState<boolean>(false); // used to disable save while user is actively editing the image
	const [uploading, setUploading] = useState(false);
	const [speakerToReplace, setSpeakerToReplace] = useState<Speaker | null | undefined>(null);
	const imageInput = useRef<HTMLInputElement | null>(null);
	const replaceImageInput = useRef<HTMLInputElement | null>(null);
	const [workingSpeaker, setWorkingSpeaker] = useState<CreateSpeaker | Speaker | null>(editingSpeaker);
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [searchAllSpeakers, setSearchAllSpeakers] = useState('');
	const [searchedSpeakers, setSearchedSpeakers] = useState<Speaker[]>([]);
	const [updatedModules, setUpdatedModules] = useState<Speaker[] | undefined>(initialSelected);
	const [requiredFields, setRequiredFields] = useState(initialRequiredSpeakerFields);
	const [workingRelatedSessions, setWorkingRelatedSessions] = useState(initialRelatedSessions?.find(r => r.speaker === (workingSpeaker as Speaker)?.speaker)?.sessions || []);
	const [saving, setSaving] = useState(false);
	const [openDeleteSpeaker, setOpenDeleteSpeaker] = useState(false);
	const [deleteSpeaker, setDeleteSpeaker] = useState<Speaker | null>(null);

	const dropdownRef = useRef<HTMLDivElement>(null);

	useEffect(() => setSpeakerToReplace(replacing), [replacing]);

	useEffect(() => setUpdatedModules(initialSelected), [initialSelected]);

	useEffect(() => {
		setWorkingSpeaker(editingSpeaker);
		setOriginalImage(editingSpeaker?.original_image);
	}, [editingSpeaker]);

	useEffect(() => setWorkingRelatedSessions(initialRelatedSessions?.find((r: any) => r.speaker === (editingSpeaker as Speaker)?.speaker)?.sessions || []), [initialRelatedSessions, editingSpeaker]);

	useEffect(() => {
		if (open) {
			window.addEventListener('click', handleDropdownClose);
		}
		return () => window.removeEventListener('click', handleDropdownClose);
	}, [open]);

	const handleDropdownClose = (e: any) => {
		const path = e.path || e.composedPath();
		for (const item of path) {
			if (dropdownRef.current === item) return;
		}
		setDropdownOpen(false);
	};

	const hasFinishedRequiredFields = useCallback(() => {
		const _requiredFields = { ...requiredFields };
		if (!workingSpeaker?.first_name) _requiredFields.firstName = Validation.error;
		if (!workingSpeaker?.last_name) _requiredFields.lastName = Validation.error;
		if (!workingSpeaker?.image) _requiredFields.speakerImage = Validation.error;
		if (!(workingSpeaker?.job_title?.[language] ?? workingSpeaker?.job_title?.base)) _requiredFields.jobTitle = Validation.error;

		setRequiredFields(_requiredFields);

		for (const field in _requiredFields) if (_requiredFields[field] === Validation.error) return false;
		return true;
	}, [requiredFields, workingSpeaker, language]);

	const finishedAddingSpeaker = useCallback(async () => {
		if (!workingSpeaker) return;
		if (!hasFinishedRequiredFields()) return;
		setSaving(true);

		const _workingSpeaker: Speaker | CreateSpeaker = { ...workingSpeaker };

		//if there is only one link and its left blank turn off link_social
		if (_workingSpeaker.link_social
			&& !_workingSpeaker.social_links?.[0]?.url
			&& _workingSpeaker.social_links?.length === 1
		) {
			_workingSpeaker.link_social = false;
		}

		// if no misc_translations then add it with "View more" 
		if (!_workingSpeaker.misc_translations?.view_more) {
			_workingSpeaker.misc_translations = {
				..._workingSpeaker?.misc_translations,
				view_more: updateTranslateKey({
					translateString: _workingSpeaker?.misc_translations?.view_more || GetDefaultTranslateString(),
					input: (
						_workingSpeaker?.misc_translations?.view_more?.[language]
						|| _workingSpeaker?.misc_translations?.view_more?.base
						|| "View more"
					) as string,
					baseLanguage,
					language,
				}),
			};
		}

		if ('speaker' in _workingSpeaker) {
			try {
				if (!token) throw "Missing token";
				await SaveSpeaker(token, _workingSpeaker as Speaker, workingEvent?.homepage?.languages, baseLanguage);
				onComplete(_workingSpeaker, workingRelatedSessions);
				close();
			} catch (e) {
				console.error(e);
				setSaving(false);
			}
			// using a callback here because otherwise one function will override the other in global state 
		} else {
			try {
				if (!token) throw "Missing token";
				const newSpeaker = await InsertSpeaker(token, _workingSpeaker, workingEvent?.homepage?.languages, baseLanguage);
				onComplete(newSpeaker, workingRelatedSessions);
				// update Redux with new speaker so it can be added to a page_module
				dispatch(createSpeaker(_workingSpeaker as Speaker));
				setWorkingSpeaker(null);
			} catch (e) {
				console.error(e);
				setSaving(false);
			}
		}
		setSaving(false);
	}, [baseLanguage, close, dispatch, hasFinishedRequiredFields, language, onComplete, token, workingEvent?.homepage?.languages, workingRelatedSessions, workingSpeaker]);


	// handling speaker image, in order of operations...
	const openImageModal = useCallback(() => {
		setEditingImage(true);
	}, []);

	const openImage = useCallback(() => {
		imageInput.current?.click();
	}, []);

	const uploadImage = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		if (file && user && token) {
			setUploading(true);
			const uploaded = await UploadFile(user, token, file);
			setOriginalImage(uploaded);
			setWorkingImage(file);
			setUploading(false);
			setEditingImage(true);
		}
	}, [token, user]);

	const handleEditing = useCallback((inProcess: boolean) => {
		setEditing(inProcess);
	}, []);

	const pickNewPhoto = useCallback(() => {
		replaceImageInput.current?.click();
	}, []);

	const cancelEditingImage = useCallback(() => {
		setWorkingImage(null);
		setEditingImage(false);
	}, []);

	const doneEditingImage = useCallback(async () => {
		setUploading(true);
		if (!user || !token || !workingSpeaker || !workingImage) return;

		const uploaded = await UploadFile(user, token, workingImage);
		setRequiredFields({ ...requiredFields, speakerImage: uploaded ? Validation.normal : Validation.error });

		const _workingSpeaker = {
			...workingSpeaker,
			image: uploaded,
			original_image: originalImage ?? ''
		};
		setWorkingSpeaker(_workingSpeaker);

		setUploading(false);
		setEditingImage(false);
	}, [user, token, workingSpeaker, workingImage, requiredFields, originalImage]);

	const requireImage = useCallback(() => {
		setRequiredFields({ ...requiredFields, speakerImage: workingSpeaker?.image ? Validation.normal : Validation.error });
	}, [requiredFields, workingSpeaker?.image]);



	function setFirstName({ target: { value } }: React.ChangeEvent<HTMLInputElement>) {
		setWorkingSpeaker(workingSpeaker => {
			if (!workingSpeaker) { return null; }

			return {
				...workingSpeaker,
				first_name: value
			};
		});
		setRequiredFields({ ...requiredFields, firstName: value ? Validation.normal : Validation.error });
	}

	function setLastName({ target: { value } }: React.ChangeEvent<HTMLInputElement>) {
		setWorkingSpeaker(workingSpeaker => {
			if (!workingSpeaker) return null;

			return {
				...workingSpeaker,
				last_name: value
			};
		});
		setRequiredFields({ ...requiredFields, lastName: value ? Validation.normal : Validation.error });
	}
	function setDescription({ target: { value } }: React.ChangeEvent<HTMLTextAreaElement>) {
		setWorkingSpeaker(workingSpeaker => {
			if (!workingSpeaker) { return null; }
			return {
				...workingSpeaker,
				description: updateTranslateKey({
					translateString: workingSpeaker.description,
					input: value,
					baseLanguage,
					language
				})
			};
		});
	}

	function setJobTitle({ target: { value } }: React.ChangeEvent<HTMLInputElement>) {
		setWorkingSpeaker(workingSpeaker => {
			if (!workingSpeaker) { return null; }
			return {
				...workingSpeaker,
				job_title: updateTranslateKey({
					translateString: workingSpeaker.job_title ?? { base: "", changed: "" },
					input: value,
					baseLanguage,
					language
				})
			};
		});
		setRequiredFields({ ...requiredFields, jobTitle: value ? Validation.normal : Validation.error });
	}

	function setRelatedSessions(sessionIds: number[]) {
		setWorkingRelatedSessions(sessionIds);
	}

	const resetRequiredFields = useCallback(() => {
		for (const field in requiredFields) requiredFields[field] = Validation.normal;
	}, [requiredFields]);

	function toggleLinkSocial() {
		setWorkingSpeaker(workingSpeaker => {
			if (!workingSpeaker) { return null; }

			return {
				...workingSpeaker,
				link_social: !workingSpeaker.link_social
			};
		});
	}

	function setSocialLinkUrl(index: number) {
		return (e: React.ChangeEvent<HTMLInputElement>) => {
			const value = e.target.value;
			setWorkingSpeaker(workingSpeaker => {
				if (!workingSpeaker) { return null; }

				return {
					...workingSpeaker,
					social_links: workingSpeaker.social_links?.map((link: SocialLink, i: number) => {
						if (i === index) {
							link.url = value;
						}
						return link;
					})
				};
			});
		};
	}

	function setSocialLinkPlatform(index: number) {
		return (platform: SocialPlatforms) => {
			setWorkingSpeaker(workingSpeaker => {
				if (!workingSpeaker) { return null; }

				return {
					...workingSpeaker,
					social_links: workingSpeaker.social_links?.map((link: SocialLink, i: number) => {
						if (i === index) {
							link.platform = platform;
						}
						return link;
					})
				};
			});
		};
	}

	function setSpeakerAttribute(index: number, key: 'icon' | 'attribute' | 'value') {
		return (option: any) => {
			const isIcon = key === 'icon';
			const value = isIcon ? option : option.target.value;
			setWorkingSpeaker(workingSpeaker => {
				if (!workingSpeaker) return null;
				return {
					...workingSpeaker,
					attributes: workingSpeaker?.attributes?.map((attribute: SpeakerAttribute, i: number) => {
						if (i === index) {
							attribute[key] = isIcon ? value : updateTranslateKey({
								translateString: (attribute[key] as TranslateString) ?? { base: "", changed: "" },
								input: value,
								baseLanguage,
								language
							});
						}
						return attribute;
					})
				};
			});
		};
	}
	function addSocialMediaLink() {
		if (workingSpeaker?.social_links && workingSpeaker.social_links.length > 5) { return; }

		setWorkingSpeaker(workingSpeaker => {
			if (!workingSpeaker) { return null; }

			if (workingSpeaker.social_links) {
				return {
					...workingSpeaker,
					social_links: [...workingSpeaker.social_links, { platform: '', url: '' }]
				};
			} else {
				return {
					...workingSpeaker,
					social_links: [{ platform: '', url: '' }]
				};
			}
		});
	}

	function addSpeakerAttribute() {
		const defaultTranslateString = { base: '', changed: '' };
		setWorkingSpeaker(workingSpeaker => {
			if (!workingSpeaker) return null;

			if (workingSpeaker?.attributes) {
				return {
					...workingSpeaker,
					attributes: [...(workingSpeaker?.attributes ?? []), { icon: 'info-circle', attribute: defaultTranslateString, value: defaultTranslateString }]
				};
			} else {
				return {
					...workingSpeaker,
					attributes: [{ icon: 'info-circle', attribute: defaultTranslateString, value: defaultTranslateString }]
				};
			}
		});
	}

	function removeSocialLink(index: number) {
		return () => {
			setWorkingSpeaker(workingSpeaker => {
				if (!workingSpeaker) { return null; }

				if (workingSpeaker.social_links) {
					return {
						...workingSpeaker,
						social_links: workingSpeaker.social_links.filter((link: any, i: number) => i !== index)
					};
				} else {
					return workingSpeaker;
				}
			});
		};
	}

	function removeAttribute(index: number) {
		return () => {
			setWorkingSpeaker(workingSpeaker => {
				if (!workingSpeaker) return null;

				if (workingSpeaker?.attributes) {
					return {
						...workingSpeaker,
						attributes: workingSpeaker?.attributes.filter((a, i) => i !== index)
					};
				} else {
					return workingSpeaker;
				}
			});
		};
	}

	function handleSelectSpeaker(selected: Speaker) {
		if (!workingSpeaker) { return; }
		if (editingSpeaker && 'speaker' in editingSpeaker) {
			const createNewSpeakerFromOld: Speaker = {
				...selected,
				// we don't want to override the selected speaker, we want to override
				// the editing speaker, so we need to use the editing speakers speaker id
				speaker: editingSpeaker.speaker,
				description: updateTranslateKey({
					translateString: selected.description,
					input: selected.description.base,
					baseLanguage,
					language
				}),
				first_name: selected.first_name,
				image: selected.image,
				job_title: updateTranslateKey({
					translateString: selected.job_title ?? { base: "", changed: "" },
					input: selected.job_title?.base || '',
					baseLanguage,
					language
				}),
				last_name: selected.last_name,
				link_social: selected.link_social,
				original_image: selected.original_image,
				social_links: selected.social_links,
			};
			setWorkingSpeaker(createNewSpeakerFromOld);
		} else {
			const createNewSpeakerFromOld: CreateSpeaker = {
				description: updateTranslateKey({
					translateString: selected.description,
					input: selected.description.base,
					baseLanguage,
					language
				}),
				first_name: selected.first_name,
				image: selected.image,
				job_title: updateTranslateKey({
					translateString: selected.job_title ?? { base: "", changed: "" },
					input: selected.job_title?.base || '',
					baseLanguage,
					language
				}),
				last_name: selected.last_name,
				link_social: selected.link_social,
				original_image: selected.original_image,
				social_links: selected.social_links,
				attributes_enabled: selected.attributes_enabled
			};
			setWorkingSpeaker(createNewSpeakerFromOld);
		}
	}

	useEffect(() => {
		if (editingSpeaker !== workingSpeaker) {
			setWorkingSpeaker(editingSpeaker);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editingSpeaker]);

	const debounced = useRef(debounce((value: string, cb: (value: string) => void) => {
		cb(value);
	}, 300)).current;

	const handleSpeakerSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFirstName(event);
		// debounce search for names
		debounced(event.target.value, async value => {
			if (workingEvent?.channel && token) {
				const results = await SearchSpeakers(workingEvent.channel, token, value);
				setSearchedSpeakers(results);
				setDropdownOpen(true);
			}
		});
	};

	const handleNameFocus = useCallback(() => {
		if (searchedSpeakers.length) {
			setDropdownOpen(true);
		}
	}, [searchedSpeakers.length]);

	const handleCloseDropdown = useCallback(() => setDropdownOpen(false), []);

	const handleRequestClose = useCallback(() => {
		close();
		setDropdownOpen(false);
		setSearchedSpeakers([]);
		// reset the working speaker to empty values so when it's reopened it doesn't maintain
		// the old values
		setWorkingRelatedSessions([]);
		// prevent flashing state
		setTimeout(() => setWorkingSpeaker(null), 400);
		setSearchAllSpeakers('');
		setUpdatedModules(initialSelected);
		resetRequiredFields();
	}, [close, initialSelected, resetRequiredFields]);

	const toggleDisplayedSpeakers = useCallback((speaker: Speaker) => {
		const _displayedSpeakers = updatedModules ? updatedModules.slice() : initialSelected?.slice() || [];

		if (replacing) {
			if (_displayedSpeakers.find((s: Speaker) => s.speaker === speaker.speaker) || replacing.speaker === speaker.speaker) return;

			const lastReplacedSpeaker = speakerToReplace || replacing;
			const startIndex = _displayedSpeakers.findIndex((s: Speaker) => Number(s.speaker) === Number(lastReplacedSpeaker.speaker));
			_displayedSpeakers.splice(startIndex, 1, speaker);

			setSpeakerToReplace(speaker);

			setUpdatedModules(_displayedSpeakers);
			return;
		}
		const foundSpeakerIndex = _displayedSpeakers.findIndex((s: Speaker) => Number(s.module_id) === Number(speaker.module_id));

		// speaker does exist on page module
		if (foundSpeakerIndex >= 0) {
			_displayedSpeakers?.splice(foundSpeakerIndex, 1);
		} else {
			// speaker doesnt exist on page_module
			_displayedSpeakers?.push(speaker);
		}

		// update homepage with new displayedSpeakers
		setUpdatedModules(_displayedSpeakers);
	}, [initialSelected, replacing, speakerToReplace, updatedModules]);

	const setUpBlankSpeaker = useCallback(() => {
		setWorkingSpeaker(GetDefaultSpeaker());
	}, []);

	const visibleSpeakers = useMemo(() => {
		if (speakers.length) {
			const _term = searchAllSpeakers.replace(/\s/g, '').trim().toLowerCase();
			return speakers.filter((speaker: Speaker) => {
				// 0 is deleted
				return speaker.status === ModuleStatus.active && (speaker.first_name + speaker.last_name).replace(/\s/g, '').toLowerCase().trim().includes(_term);
			});
		} else {
			return [];
		}
	}, [speakers, searchAllSpeakers]);

	const modalTitle = useMemo(() => {
		// order matters here because of the conditional rendering
		if (editingImage) return 'Edit Photo';
		if (editingSpeaker) return 'Edit Speaker';
		if (replacing) return 'Replace Speaker';
		return 'Add Speaker';

	}, [editingImage, replacing, editingSpeaker]);

	const handleDelete = useCallback((speaker: Speaker) => {
		setOpenDeleteSpeaker(true);
		setDeleteSpeaker(speaker);
	}, []);

	const memoizedVisibleSpeakers = useMemo(() => {
		return visibleSpeakers.map((speaker: Speaker, idx: number) => {
			//filter for saftey checking null values
			const arr = updatedModules ? updatedModules : initialSelected;
			return <AddSpeaker
				key={idx}
				speaker={speaker}
				replacing={speakerToReplace}
				handleChange={toggleDisplayedSpeakers}
				isSelected={arr?.map((s: Speaker) => Number(s.module_id)).includes(Number(speaker.module_id)) ?? false}
				handleDelete={handleDelete}
			/>;
		});
	}, [handleDelete, initialSelected, speakerToReplace, toggleDisplayedSpeakers, updatedModules, visibleSpeakers]);

	const body = editingImage && originalImage ? (
		<ImageEditor
			originalImage={originalImage}
			onFinished={(file: File) => setWorkingImage(file)}
			setEditing={handleEditing}
		/>
	) : (
		workingSpeaker && workingEvent
			? (
				<div className="working-speaker">
					<div className="speaker-image-and-name">
						{!workingSpeaker.image ? (
							<button className={`image-upload no-style ${requiredFields.speakerImage === Validation.error ? 'speaker-image-error' : ''}`} onBlur={requireImage} onClick={openImage}>
								<label><Icon name={ICONS.ADD} size={14} color={COLORS.WHITE} /> Upload Photo*</label>
								<span>Use PNG or JPG files of up to 10MB in size</span>
							</button>
						) : (
							<div className="speaker-image" style={{ backgroundImage: `url(${workingSpeaker.image})` }}>
								<button className="round" onClick={openImageModal}><Icon name={ICONS.EDIT} size={13} color={COLORS.WHITE} /></button>
							</div>
						)}
						<FileInput type="file" style={{ display: 'none' }} accept={["image/jpg", "image/jpeg", "image/png"]} onChange={uploadImage} ref={imageInput} />
						<div className="speaker-name">
							<div className="speaker-name-input-wrapper">
								<TextInput
									placeholder={"Enter First Name"}
									label={"First Name*"}
									defaultValue={workingSpeaker.first_name}
									onChange={handleSpeakerSearch}
									onFocus={handleNameFocus}
									onBlur={() => {
										setRequiredFields({ ...requiredFields, firstName: workingSpeaker.first_name ? Validation.normal : Validation.error });
									}}
									id="first_name"
									onEscape={handleCloseDropdown}
									onTab={handleCloseDropdown}
									shouldUpdateValue={true}
									updateValue={workingSpeaker.first_name}
									valid={requiredFields.firstName}
								/>
								{dropdownOpen ? (
									<div className="speaker-name-dropdown" ref={dropdownRef}>
										{searchedSpeakers
											?.filter((speaker: Speaker) => speaker.status === ModuleStatus.active)
											?.map((speaker: Speaker) => (
												<div key={speaker.speaker}>
													<button
														className="no-style speaker-name-dropdown-button"
														onFocus={() => setDropdownOpen(true)}
														onClick={() => {
															handleSelectSpeaker(speaker);
															setDropdownOpen(false);
														}}
													>
														{speaker.image ? (
															<img className="speaker-dropdown-avatar" src={speaker.image} alt={`${speaker.first_name[0]}`} />
														) : <div className="speaker-dropdown-avatar">{speaker.first_name[0]}</div>}
														{speaker.first_name} {speaker.last_name}
													</button>
												</div>
											))}
									</div>
								) : null}
							</div>
							<TextInput
								placeholder={"Enter Last Name"}
								label={"Last Name*"}
								onChange={setLastName}
								defaultValue={workingSpeaker.last_name}
								onBlur={() => {
									setRequiredFields({ ...requiredFields, lastName: workingSpeaker?.last_name ? Validation.normal : Validation.error });
								}}
								id="last_name"
								shouldUpdateValue={true}
								updateValue={workingSpeaker.last_name}
								valid={requiredFields.lastName}
							/>
						</div>

					</div>
					<TextInput
						placeholder={"Enter Job Title"}
						label={"Job Title*"}
						onBlur={() => {
							setRequiredFields({ ...requiredFields, jobTitle: (workingSpeaker?.job_title?.[language] || workingSpeaker?.job_title?.base) ? Validation.normal : Validation.error });
						}}
						onChange={setJobTitle}
						defaultValue={workingSpeaker.job_title?.[language] as string || workingSpeaker.job_title?.base}
						id="job_title"
						shouldUpdateValue={true}
						valid={requiredFields.jobTitle}
					/>
					<Textarea
						label={"Description"}
						placeholder={"About speaker"}
						onChange={setDescription}
						defaultValue={workingSpeaker.description[language] as string || workingSpeaker.description.base}
						shouldUpdateValue={true}
					/>
					<SessionSelectInput
						sessions={workingEvent.sessions}
						label={"Related Sessions"}
						placeholder={"Selected Sessions"}
						onChange={setRelatedSessions}
						preSelectedSessions={workingRelatedSessions}
						ignoreInlineStyling
					/>
					<label className="social-media-label">Speaker Attributes</label>
					{
						workingSpeaker?.attributes && (
							<>
								{workingSpeaker?.attributes?.map((attribute: SpeakerAttribute, index: number) => (
									<div className="speaker-attributes-list" key={index}>
										<SelectInput
											noMaxHeight
											mainIcon={<Icon name={ICONS.KEYBOARD_ARROW_DOWN} size={14} color={COLORS.WHITE} />}
											iconIfOpen={<Icon name={ICONS.KEYBOARD_ARROW_UP} size={14} color={COLORS.WHITE} />}
											options={speakerAttributeIconOptions}
											selected={attribute.icon || 'info-circle'}
											onChange={setSpeakerAttribute(index, 'icon')}
											ignoreInlineStyling
										/>
										<TextInput
											placeholder="Name"
											defaultValue={attribute.attribute.base}
											onChange={setSpeakerAttribute(index, 'attribute')}
											id="attribute-name"
											shouldUpdateValue={true}
											updateValue={attribute.attribute.base}
										/>
										<TextInput
											placeholder="Value"
											defaultValue={attribute.value.base}
											onChange={setSpeakerAttribute(index, 'value')}
											id="value"
											shouldUpdateValue={true}
											updateValue={attribute.value.base}
										/>
										<button className="clear" onClick={removeAttribute(index)}><Icon name={ICONS.CLOSE} size={20} color={COLORS.WHITE} /></button>
									</div>
								))}
							</>
						)
					}
					<button className="small add-attribute" onClick={addSpeakerAttribute}><Icon name={ICONS.ADD} size={12} color={COLORS.WHITE} /> Add</button>
					<div className="link-social">
						<Switch
							value={'link-social'}
							on={workingSpeaker.link_social}
							onClick={toggleLinkSocial}
						/>
						<p>Link social media</p>
					</div>
					{workingSpeaker?.link_social && (
						<>
							<label className="social-media-label">Social Media</label>
							{workingSpeaker?.social_links?.map((link: SocialLink, index: number) => (
								<div className="social-media-list" key={index}>
									<SmallSelect
										options={socialOptions}
										selected={link.platform}
										placeholder="Select social"
										onChange={setSocialLinkPlatform(index)}
									/>
									<TextInput
										placeholder={"URL"}
										defaultValue={link.url}
										onChange={setSocialLinkUrl(index)}
										id="url"
										shouldUpdateValue={true}
										updateValue={link.url}
									/>
									<button className="clear" onClick={removeSocialLink(index)}><Icon name={ICONS.CLOSE} size={20} color={COLORS.WHITE} /></button>
								</div>
							))}
							<button className="small" onClick={addSocialMediaLink}><Icon name={ICONS.ADD} size={12} color={COLORS.WHITE} /> Add</button>
						</>
					)}
				</div>
			)
			: (
				<div className="select-speakers">
					<div className="top-controls">
						<TextInput
							onChange={(e) => setSearchAllSpeakers(e.target.value)}
							placeholder="Search Speakers..."
							icon={ICONS.SEARCH}
							size={"small"}
						/>
						or
						<button className="small" onClick={setUpBlankSpeaker} disabled={language !== baseLanguage}>
							Create Speaker
						</button>
						{language !== baseLanguage && (
							<div className="speaker-tooltip">
								<Tooltip tooltip="Speakers may only be created in the base language">
									<Icon name={ICONS.PRIMARY_TOOLTIP} color={COLORS.CYAN} size={12} />
								</Tooltip>
							</div>
						)}
					</div>
					<div className="select-speakers-list">
						{
							visibleSpeakers &&
							memoizedVisibleSpeakers
						}
					</div>
				</div>
			)
	);

	const closeModal = useCallback(() => {
		close();
		// prevent flashing state
		setTimeout(() => setWorkingSpeaker(null), 400);
		setSpeakerToReplace(replacing);
		resetRequiredFields();
		setDropdownOpen(false);
		setSearchedSpeakers([]);
		// reset the working speaker to empty values so when it's reopened it doesn't maintain
		// the old values
		setWorkingRelatedSessions([]);
		setSearchAllSpeakers('');
		setUpdatedModules(initialSelected);
		resetRequiredFields();
	}, [close, initialSelected, replacing, resetRequiredFields]);

	const closeDeleteModal = useCallback(() => {
		setOpenDeleteSpeaker(false);
		// delay resetting this state as to not display flashing state
		setTimeout(() => setDeleteSpeaker(null), 250);
	}, []);

	const handleDeleteSpeaker = useCallback(async () => {
		setSaving(true);
		try {
			if (!deleteSpeaker || !token) return;

			const _workingSpeaker = { ...deleteSpeaker };
			_workingSpeaker.status = ModuleStatus.deleted;

			if (_workingSpeaker?.speaker && user?.active_channel) {
				const response = await DeleteSpeaker(token, user?.active_channel, _workingSpeaker.speaker);

				if (response?.cannotDelete) {
					showAlert({
						message: "Failed to delete",
						description: "This speaker must be removed from all events before being deleted",
						duration: 4000,
						type: 'error'
					});
				} else {
					dispatch(updateSpeaker(_workingSpeaker));
					setOpenDeleteSpeaker(false);
				}
			}

		} catch (e) {
			console.error(e);
		} finally {
			setSaving(false);
		}

	}, [deleteSpeaker, dispatch, token, user?.active_channel]);

	if (!inModal) return body;
	return (
		<>
			<ModalComponent
				closeable={false}
				cancellable={false}
				open={open}
				onRequestClose={closeModal}
				title={modalTitle}
				footer={editingImage ? (
					<div className="image-modal-footer">
						<div>
							{workingSpeaker?.original_image && (
								<>
									<button className="no-style left-button" onClick={pickNewPhoto}><Icon name={ICONS.UPLOAD} size={12} color={COLORS.CYAN} />Replace Photo</button>
									<FileInput accept={["image/png", "image/jpg", "image/jpeg", "image/gif"]} type="file" style={{ display: 'none' }} ref={replaceImageInput} onChange={uploadImage} />
								</>
							)}
						</div>
						<div className="image-edit-right">
							<button onClick={cancelEditingImage} style={{ marginRight: 16 }}>Cancel</button>
							<button onClick={doneEditingImage} className="lemonade" disabled={!workingImage || editing || uploading}>{uploading ? <WaitingIndicator /> : "Save & Continue"}</button>
						</div>
					</div>
				) : (
					<>
						<button onClick={handleRequestClose} style={{ marginRight: 16 }}>Cancel</button>
						{
							workingSpeaker ?
								<button onClick={finishedAddingSpeaker} className="lemonade">{editingSpeaker ? (
									(saving ? <WaitingIndicator /> : 'Save')
								) : 'Done'}</button>
								:
								<button
									onClick={() => {
										onSelectionComplete?.(updatedModules ?? []);
										close();
									}}
									className="lemonade"
								>Done</button>
						}
					</>
				)}
			>
				{body}
			</ModalComponent >
			<ModalComponent
				closeable={false}
				cancellable={false}
				open={openDeleteSpeaker}
				onRequestClose={closeDeleteModal}
				title="Delete Speaker"
				footer={
					<>
						<button onClick={closeDeleteModal}>Cancel</button>
						<button className="lemonade" disabled={saving} onClick={handleDeleteSpeaker}>{saving ? <WaitingIndicator /> : "Delete"}</button>
					</>
				}
			>
				{
					deleteSpeaker && (
						<p>Are you sure you want to delete speaker: {deleteSpeaker?.first_name} {deleteSpeaker?.last_name}</p>
					)
				}
			</ModalComponent>
		</>
	);
};

export default CreateSpeakerModal;
