import React, { useMemo } from "react";
import { useParams } from "react-router";
import ReactSelect from "react-select";

import { SurveyQuestion, SurveyQuestionType, SurveyType } from "../../../../../../../types/working-model";
import { OptionalComponent } from "../../../../../../../utils/optional-component";
import Icon, { ICONS } from "../../../../../../general-ui/icon";
import { reactSelectStyles } from "../../../../../../general-ui/react-select/react-select-styles";
import TextInput, { Validation } from "../../../../../../general-ui/text-input/text";
import { ParamsProps } from "../../../../../../live-event/live-event";
import { SELECT_OPTIONS } from "../constants/engage";
import { useAddOption, useSetQuestionTitle } from "../hooks/engage.hooks";
import { ErrorsTypes } from "../types/engage";
import { setQuestionTypeHandler } from "../utils/engage-create.utils";

interface CreateEngageQuestionProps {
	survey_type: SurveyType;
	workingQuestion: SurveyQuestion | null;
	validationErrors: { [p: string]: string } | null;
	clearErrors: (key: (ErrorsTypes | null)) => void;
	setWorkingQuestion: React.Dispatch<React.SetStateAction<SurveyQuestion | null>>;
	setTitleHasFocus: (hasFocus: boolean) => void;
}

export default function CreateEngageQuestion({
	survey_type,
	workingQuestion,
	validationErrors,
	clearErrors,
	setWorkingQuestion,
	setTitleHasFocus
}: CreateEngageQuestionProps): JSX.Element {
	const { language } = useParams<ParamsProps>();

	const typeOptions = useMemo(() => {
		return survey_type !== SurveyType.survey ? SELECT_OPTIONS.filter(option => option.value !== String(SurveyQuestionType.text)) : SELECT_OPTIONS;
	}, [survey_type]);

	const setWorkingQuestionTitle = useSetQuestionTitle({ clearErrors, setWorkingQuestion });
	const addOption = useAddOption({ setWorkingQuestion, clearErrors });
	const setQuestionType = setQuestionTypeHandler({
		survey_type,
		workingQuestion,
		setWorkingQuestion,
		addOption,
	});

	return (
		<div className="e-engage-card-field-list">
			<div>
				<TextInput
					label={"Question Title"}
					placeholder={"Type your question"}
					value={workingQuestion?.title?.[language] as string ?? workingQuestion?.title.base}
					valid={validationErrors?.[ErrorsTypes.QuestionTitle] ? Validation.error : Validation.normal}
					onChange={setWorkingQuestionTitle}
					autoFocus={true}
					onFocus={() => setTitleHasFocus(true)}
					onBlur={() => setTitleHasFocus(false)}
				/>
				{validationErrors?.[ErrorsTypes.QuestionTitle] && (
					<span className="session-panel-field-error admin-typography-label-2">
						{validationErrors?.[ErrorsTypes.QuestionTitle]}
					</span>
				)}
			</div>

			<OptionalComponent display={survey_type !== SurveyType.quiz}>
				<ReactSelect
					classNamePrefix="session-panel-select"
					className="session-panel-select"
					tabSelectsValue={false}
					styles={reactSelectStyles({
						control: { borderRadius: "100px" },
					})}
					options={typeOptions}
					defaultValue={
						workingQuestion?.question_type
							? typeOptions.find(option => String(option.value) === String(workingQuestion?.question_type))
							: { label: "Single choice", value: String(SurveyQuestionType.radio) }
					}
					menuPortalTarget={document.body}
					components={{
						DropdownIndicator: () => {
							return <Icon name={ICONS.KEYBOARD_ARROW_DOWN} size={12} color="" />;
						},
					}}
					isSearchable={false}
					onChange={value => setQuestionType(Number(value?.value))}
				/>
			</OptionalComponent>

		</div>
	);
}