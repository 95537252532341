import { useCallback, useEffect } from 'react';

import { getQueryParams } from "../../utils/utils";
import { getStorageItem, getStorageObject, removeStorageItem, setStorageItem } from '../../utils/local-storage';
import WaitingIndicator from '../general-ui/waiting-indicator/waiting-indicator';
import Alerts from '../general-ui/alert/alert';
import { CognitoAdminAuth, SelectChannel } from '../../connection/auth';
import { useAppDispatch } from '../../store/reducers/use-typed-selector';
import { setChangingChannels, setUser } from '../../store/actions/authentication';
import { setSelectedEventGroup } from '../../store/actions/admin/events';
import { jwtDecode } from '../../utils/utils';
import { checkUserIsPresenterOnly } from '../admin/authentication/auth.hooks';
import { BLAdmin } from '../../types/working-model';

const SSORedirect: React.FC = () => {
	const queryParams = getQueryParams(location.search);
	const authCode = queryParams?.code;
	const state = queryParams?.state;

	const dispatch = useAppDispatch();

	const handleEventSso = useCallback((ssoEventUrlString: string) => {
		let code = authCode;

		if (state && state !== getStorageItem('sso_state')) { // Validate State
			console.warn('State is not correct');
			code = null;
		}

		removeStorageItem('sso_event_url'); // immediately remove so going to different events does not cause problems
		removeStorageItem(`sso_state`);
		let ssoEventUrl;

		try {
			ssoEventUrl = new URL(ssoEventUrlString);
		} catch (e) {
			console.error(`Invalid sso_event_url: ${ssoEventUrl}`);
			code = null; // Don't try to forward to an invalid URL
		}

		// If no code, stay so the error can be captured while testing 
		if (code && ssoEventUrl instanceof URL) {
			ssoEventUrl.searchParams.append('code', code);
			window.location.href = ssoEventUrl.href;
		}
	}, [authCode, state]);

	const handleAdminSso = useCallback((ssoAdminAuth: { oauth2?: true, client_id?: string, channel: number }) => {
		const code = authCode;
		const {
			channel,
			oauth2,
			client_id
		} = ssoAdminAuth;

		const payload = (() => {
			if (oauth2) {
				return {
					channel,
					auth_code: code,
					oauth2
				};
			} else if (client_id) {
				return {
					user_pool_client_id: client_id,
					auth_code: code,
					channel
				};
			}
		})();

		if (code && channel && payload) {
			CognitoAdminAuth(payload)
				.then(async (result) => {
					if (result.token) {
						const user = jwtDecode(result.token) as BLAdmin;
						const userIsPresenterOnly = checkUserIsPresenterOnly(user.channels, channel);

						// When presenter flow is enabled, we need to redirect to presenter handler
						const oauthFlow = getStorageItem('auth_oauth_flow');
						if (oauthFlow || userIsPresenterOnly) {
							setStorageItem('auth_oauth_sso_token', result.token, .05);
							setStorageItem('forgot_flow_auth_oauth_sso_token', result.token);
							window.location.pathname = '/authentication/presenter/redirect';
							return;
						}

						setStorageItem('currentUser', result.token, 1);

						dispatch(setChangingChannels(true));
						const updatedToken = await SelectChannel(result.token, channel);
						dispatch(setUser(updatedToken));
						dispatch(setSelectedEventGroup(''));
						dispatch(setChangingChannels(false));
					} else {
						setStorageItem('sso_admin_auth_error', result.reason ?? "unknown error", 0.1);
					}

					window.location.pathname = '/admin';
				})
				.catch(e => {
					console.error(e);
					window.location.pathname = '/admin';
				})
				.finally(() => {
					removeStorageItem('sso_admin_channel');
					removeStorageItem('sso_state');
					removeStorageItem('auth_oauth_flow');
				});
		}
	}, [authCode, dispatch]);

	useEffect(() => {
		const ssoEventUrl = getStorageItem('sso_event_url');
		const ssoAdminAuth = getStorageObject('sso_admin_auth');

		if (ssoEventUrl) {
			handleEventSso(ssoEventUrl);
		}

		if (ssoAdminAuth) {
			handleAdminSso(ssoAdminAuth);
		}
	}, [handleEventSso, handleAdminSso]);

	return (
		<div className='center-content-view-port'>
			<WaitingIndicator />
			<Alerts />
		</div>
	);
};

export default SSORedirect;
