import { v4 as uuid } from 'uuid';
import {
	CallToAction,
	SortableCallToAction,
	CallToActionType,
	Color,
	CreateColorPack,
	CreateDocument,
	CreateEventRequest,
	CreateFontPack,
	CreateProduct,
	CreateQuiz,
	CreateSpeaker,
	CreateSponsor,
	CreateSurvey,
	CreateVideo,
	CustomHeading,
	IHomepageMainNavItems,
	ModuleMap,
	NavMainItems,
	NavProfileItems,
	PageLinkType,
	PageModule,
	PageModuleType,
	PASSCODE_LIST_TYPE,
	QuestionType,
	Registration,
	RegistrationQuestion,
	RegistrationStep,
	RegistrationStepType,
	SlideshowContent,
	Template,
	ThumbnailStatus,
	TranslateString,
	TicketingSet,
	LanguagesAbbr,
	Session,
	EventNavbar,
	RegFieldsEnum,
	IInsertScheduledEmailRequest,
	EmailTypeEnum,
	IScheduledEmailSettings,
	SurveyType,
	ENavbarTypes,
	RegistrationTypes,
	Templates,
	CreateQuestionPrompt,
	FiresideSessionSettings,
} from "../../types/working-model";
import { WorkingPasscodeList } from '../../components/admin/create/registration/passcode-list';
import { updateTranslateKey } from '../../utils/utils';
import { TemplateClassNames } from '../../types/template-layouts';
import defaultImages from '../../components/admin/create/session/editor/moduleControls/layout-images.json';
import { staticTranslator } from '../../i18n/config';

export const GetEmptyEvent = (channel: number, template: Template): CreateEventRequest => ({
	channel,
	template: template.template,
	name: "",
	sessions: [],
	homepage: false,
	registration_on: false,
	event_type: undefined,
	defaultLanguage: 'en',
	event_image: null,
	is_test_event: false
});

export const GetDefaultRegistrationSettings = (): Registration => ({
	type: RegistrationTypes.none,
	enableSocial: false,
	enableRecaptcha: false,
	enableOverviewContent: true,
	enableSpeakers: true,
	singleSignOn: {
		isOn: false,
		displayTop: false,
		singleSignOns: []
	},
	sessionReminders: true,
	registrationConfirmation: true,
	sendEmailConfirmation: true,
	registration_confirmation_email: {},
	postEventFeedbackSurvey: false,
	useBlockedDomainEmails: false,
	blockedDomainEmails: [],
	requirements: {}
});

export const GetDefaultPasscodeList = (): WorkingPasscodeList => ({
	type: PASSCODE_LIST_TYPE.EMAILS_ONLY,
	name: '',
	max_uses: 0,
	master_passcode: '',
	url: ''
});

export const GetDefaultRegistrationSteps = (): RegistrationStep[] => {
	return ([
		{
			type: RegistrationStepType.general,
			isOn: true,
			questions: GetDefaultRegistrationQuestions()
		},
		{
			type: RegistrationStepType.profile,
			isOn: false,
			questions: GetDefaultProfileQuestions()
		},
		{
			type: RegistrationStepType.avatar,
			isOn: false,
		},
	]);
};

export const GetDefaultTicketingSet = async (defaultLanguage: LanguagesAbbr, sessions: Session[]): Promise<TicketingSet> => {
	await staticTranslator.loadLanguage(defaultLanguage);
	const baseValue = staticTranslator.getValue(defaultLanguage, 'homepage', 'Ticket Name');
	const sessionUUIDs = sessions?.map(session => session?.uuid) || [];
	const sessionIDs = sessions?.map(session => session?.session) || [];
	return {
		name: 'Ticket Name',
		prices: [{ price: 1, currency: 'USD', multiplier: 100 }],
		quantity: 10000,
		uuid: uuid(),
		sessions: sessionIDs,
		session_uuids: sessionUUIDs, // Originally this was an empty array
		name_translations: updateTranslateKey({
			translateString: GetDefaultTranslateString(),
			input: baseValue,
			baseLanguage: defaultLanguage,
			language: defaultLanguage,
		}),
		description_translations: updateTranslateKey({
			translateString: GetDefaultTranslateString(),
			input: '',
			baseLanguage: defaultLanguage,
			language: defaultLanguage,
		}),
	};
};

export const GetPaidTicketingRegistrationStep = (): RegistrationStep => ({
	type: RegistrationStepType.ticketing,
	isOn: false,
	ticketing: []
});

export const GetDefaultRegistrationQuestions = (): RegistrationQuestion[] => ([
	{
		registration_question: RegFieldsEnum.first_name,
		channel: 0,
		name: 'First Name',
		type: QuestionType.text,
		options: null,
		global: true,
		persistent: true,
		default_value: null
	},
	{
		registration_question: RegFieldsEnum.last_name,
		channel: 0,
		name: 'Last Name',
		type: QuestionType.text,
		options: null,
		global: true,
		persistent: true,
		default_value: null
	},
	{
		registration_question: RegFieldsEnum.email,
		channel: 0,
		name: 'Email Address',
		type: QuestionType.text,
		options: null,
		global: true,
		persistent: true,
		default_value: null
	},
	// Commented out until we know if we are including enable messaging in registration
	// GetDefaultEnableMessagingQuestion()
]);

export const GetDefaultProfileQuestions = (): RegistrationQuestion[] => ([
	{
		registration_question: RegFieldsEnum.company_name,
		channel: 0,
		name: 'Company Name',
		type: QuestionType.text,
		options: null,
		global: true,
		persistent: true,
		default_value: null
	},
	{
		registration_question: RegFieldsEnum.bio,
		channel: 0,
		name: 'Bio',
		type: QuestionType.text,
		options: null,
		global: true,
		persistent: false,
		default_value: null
	},
	{
		registration_question: RegFieldsEnum.fun_fact,
		channel: 0,
		name: 'Fun Fact',
		type: QuestionType.text,
		options: null,
		global: true,
		persistent: false,
		default_value: null
	},
]);

// Commented out until we know if we are including enable messaging in registration
// export function GetDefaultEnableMessagingQuestion(): RegistrationQuestion {
// 	return {
// 		registration_question: RegFieldsEnum.enable_messaging,
// 		channel: 0,
// 		name: 'Enable Direct Messaging',
// 		type: QuestionType.checkbox,
// 		options: null,
// 		global: true,
// 		persistent: true,
// 		default_value: 'checked'
// 	};
// }

export const GetEmptyFontPack = (): CreateFontPack => ({
	title: '',
	description: 'Custom font',
	heading_url: '',
	heading_name: '',
	body_url: '',
	body_name: ''
});

export const GetWorkingColorPack = (color: Color | CreateColorPack): CreateColorPack => ({
	title: `${color.title} Copy`,
	colors: { ...color.colors }
});

export const GetDefaultColorPack = (): CreateColorPack => ({
	title: "Default Gray",
	colors: {
		backgroundColor: ["#949494", 100], 	//hex, opacity
		bodyTextColor: ["#D3D3D3", 100],
		containerColor: ["#808080", 100],
		accentColor: ["#BEBEBE", 100],
		headingTextColor: ["#EBECF0", 100],
		secondaryAccentColor: ["#BEBEBE", 100],
		secondaryBackgroundColor: ["#949494", 100]
	}
});


export const GetDefaultSpeaker = (): CreateSpeaker => ({
	first_name: "",
	last_name: "",
	description: {
		base: "",
		changed: ""
	},
	image: "",
	original_image: "",
	link_social: true,
	social_links: [
		{
			platform: "",
			url: ""
		}
	],
	attributes_enabled: true,
	attributes: [],
	job_title: {
		base: "",
		changed: ""
	},
});

export const getDefaultAgendaImage = (template: string | number, index: number): string => {
	//We want three rotating images as defaults, we need to turn any index number into either 1, 2, or 3. Modulo magic to the rescue! Contact Scott Harlan for an explination if you want to.
	const convertedIndex = String((index % 3) + 1);
	function getEndpointKey() {
		if (typeof template === 'number') {
			switch (template) {
				case Templates.O2:
					return `O2-Agenda-default_image_${convertedIndex}`;
				case Templates['Studio 54']:
					return `Studio_54-Agenda-default_image_${convertedIndex}`;
				case Templates.Apollo:
				default:
					return `Apollo-Agenda-default_image_${convertedIndex}`;
			}
		} else {
			switch (template) {
				case TemplateClassNames.O2:
					return `O2-Agenda-default_image_${convertedIndex}`;
				case TemplateClassNames['Studio 54']:
					return `Studio_54-Agenda-default_image_${convertedIndex}`;
				case TemplateClassNames.Apollo:
				default:
					return `Apollo-Agenda-default_image_${convertedIndex}`;
			}
		}
	}
	// redeclaring the type here so we can use the template literals above
	const _defaultImages: { [key: string]: string } = defaultImages;
	const endpoint = _defaultImages[getEndpointKey()];
	return `${process.env.REACT_APP_ASSET_SERVER}/template-previews/${endpoint}`;
};

export const GetDefaultProduct = (): CreateProduct => ({
	tab_title: { base: "", changed: "" },
	product_title: { base: "", changed: "" },
	images: [],
	categories: [],
	description: { base: "", changed: "" },
	price: { base: "", changed: "" },
	buy_now_link: "",
	sale_price: { base: "", changed: "" },
	button_text: { base: "", changed: "" },
	attributes: [],
	use_attributes: true,
	cta_new_tab: true,
});

export const GetDefaultQuestionPrompt = (): CreateQuestionPrompt => ({
	title: { base: "", changed: "" },
	settings: {
		likes: true,
		replies: true,
		anonymous_replies: true
	}
});

export const GetDefaultVideo = (): CreateVideo => ({
	type: "uploaded",
	name: "",
	source: "",
	use_thumbnail: false,
	image: "",
	original_image: ""
});

export const GetDefaultSponsor = (): CreateSponsor => ({
	name: "",
	description: { base: "", changed: "" },
	image: "",
	enable_cta: false,
	call_to_action: {
		type: CallToActionType.url,
		button_name: { base: "Learn More", changed: "" },
		content: {}
	}
});

export const GetDefaultPageModule = (type: PageModuleType, content: any = {}): PageModule => ({
	type,
	content,
	is_on: true,
	content_modules: [],
	modules: [],
	is_custom: true,
	is_edited: false,
});

export const GetDefaultSessionModule = (
	type: PageModuleType,
	content: any = {}
): PageModule => ({
	type,
	content,
	is_on: true,
	content_modules: [],
	modules: [],
	is_custom: true,
	is_edited: false,
});

export const GetDefaultQuiz = (): CreateQuiz => ({
	title: { base: "", changed: "" },
	success_threshold: 1,
	success_message: { base: "Quiz Passed!", changed: "" },
	failure_message: { base: "Quiz Failed.", changed: "" },
	allow_retake: false,
	questions: []
});

export const GetDefaultSurvey = (): CreateSurvey => ({
	name: { base: "Surveys", changed: "" },
	show_results: true,
	description: { base: "this is the description!", changed: "" },
	completion_message: { base: "Thank you!", changed: "" },
	allow_retake: false,
	questions: [],
	survey_type: SurveyType.survey,
});

export const GetDefaultDocument = (): CreateDocument => ({
	original_name: "",
	location: "",
	thumbnail: null,
	thumbnail_status: ThumbnailStatus.generating,
	filesize: 0,
	display_name: { base: "", changed: "" },
	generated_thumbnail: null
});

export const GetDefaultSlideshow = (): SlideshowContent => ({
	images: [],
	duration: -1,
	advance_by_moderator: false
});

export const GetDefaultTranslateString = (text?: string): TranslateString => ({
	base: text ?? "",
	changed: "",
});

export const GetDefaultQuestion = (): CustomHeading => ({
	custom_heading: GetDefaultTranslateString(),
});


//This should all be taken care of in the API, but leaving it in as a fallback for now
export const GetDefaultMainNavItems = (defaultLanguage: LanguagesAbbr): IHomepageMainNavItems[] => (
	// TODO: on hold for MVP
	// {
	// 	uuid: uuid(),
	// 	name: NavMainItems.People,
	// 	custom_name: updateTranslateKey(
	// 		GetDefaultTranslateString(),
	// 		NavMainItems.People,
	// 	),
	// 	is_on: true,
	// },
	[
		{
			uuid: uuid(),
			name: NavMainItems.Home,
			custom_name: updateTranslateKey({
				translateString: GetDefaultTranslateString(),
				input: staticTranslator.getValue(defaultLanguage, 'homepage', NavMainItems.Home),
				baseLanguage: defaultLanguage,
				language: defaultLanguage
			}),
			is_on: true,
		},
		{
			uuid: uuid(),
			name: NavMainItems.Session,
			custom_name: updateTranslateKey({
				translateString: GetDefaultTranslateString(),
				input: staticTranslator.getValue(defaultLanguage, 'homepage', NavMainItems.Session),
				baseLanguage: defaultLanguage,
				language: defaultLanguage
			}),
			is_on: true,
		},
	]);

export const GetMultipleSessionsMainNavItems = (defaultLanguage: LanguagesAbbr, homeUuid?: string, sessionUuid?: string, leaderboardNavIcon?: IHomepageMainNavItems): IHomepageMainNavItems[] => {
	const navItems = [
		{
			uuid: homeUuid?.length ? homeUuid : uuid(),
			name: NavMainItems.Home,
			custom_name: updateTranslateKey({
				translateString: GetDefaultTranslateString(),
				input: staticTranslator.getValue(defaultLanguage, 'homepage', NavMainItems.Home),
				baseLanguage: defaultLanguage,
				language: defaultLanguage
			}),
			is_on: true,
		},
		// do not change param is added so that "Agenda" is shown by default when a user adds a session to a single session event
		{
			uuid: sessionUuid?.length ? sessionUuid : uuid(),
			name: NavMainItems.Sessions,
			custom_name: updateTranslateKey({
				translateString: GetDefaultTranslateString(),
				input: staticTranslator.getValue(defaultLanguage, 'homepage', NavMainItems.Sessions),
				baseLanguage: defaultLanguage,
				language: defaultLanguage,
				doNotChange: true
			}),
			is_on: true,
		},
	];
	if (leaderboardNavIcon) {
		navItems.push(leaderboardNavIcon);
	}
	return navItems;
};

export const GetDefaultHeaderNavItems = (): CallToAction[] => {
	// default nav items are About, Agenda, and Speakers
	const defaultModules = [PageModuleType.about, PageModuleType.agenda, PageModuleType.speakers];

	const defaultHeaderCallToActionList: CallToAction[] = defaultModules?.map((_moduleType: PageModuleType) => ({
		type: CallToActionType.section,
		button_name: {
			base: ModuleMap[_moduleType],
			changed: ''
		},
		content: {
			isOn: true,
			section: _moduleType,
			page: PageLinkType.landing,
			url: ''
		}
	}));

	return defaultHeaderCallToActionList;
};

export const GetDefaultFooterNavItems = (): SortableCallToAction[] => ([
	// default nav items are "Terms and Conditions", and "Privacy Policy"
	{
		id: uuid(),
		type: CallToActionType.page,
		button_name: {
			base: NavProfileItems.TermsAndConditions,
			changed: ''
		},
		content: {
			isOn: true,
			page: PageLinkType.termsAndConditions,
		}
	},
	{
		id: uuid(),
		type: CallToActionType.page,
		button_name: {
			base: NavProfileItems.PrivacyPolicy,
			changed: ''
		},
		content: {
			isOn: true,
			page: PageLinkType.privacyPolicy,
		}
	},
]);

export function addIdToCallToAction(cta: CallToAction): SortableCallToAction {
	return { ...cta, id: uuid() };
}

export function GetDefaultCallToAction(registrationOn: boolean): CallToAction {
	const buttonName = registrationOn ? 'Registration' : 'Join Event';
	const pageLink = registrationOn ? PageLinkType.registration : PageLinkType.homepage;
	return {
		type: CallToActionType.page,
		button_name: { base: buttonName, changed: '' },
		content: {
			isOn: true,
			page: pageLink,
		}
	};
}

export function getDefaultGrabYourSpot(): CallToAction {
	const buttonName = 'Grab Your Spot';
	return {
		type: CallToActionType.registrationForm,
		button_name: { base: buttonName, changed: '' },
		content: {
			isOn: true,
		}
	};
}

export function GetDefaultStylingOverrides(): Session['layout']['styling_overrides'] {
	return {
		background: {
			image: {
				src: '',
				is_on: false
			},
			color: ''
		},
		content: {}
	};
}

export function GetDefaultEventNavbar(): EventNavbar {
	return {
		navbarItems: [],
		styling_overrides: {
			background: {
				color: '',
				image: {
					is_on: false,
					src: '',
				}
			},
			content: {}
		},
		navbarType: ENavbarTypes.Vertical,
		isTransparent: false,
		isSticky: false,
		blurLevel: 0,
	};
}

export const defaultEmailSettings: IScheduledEmailSettings = { showAddCalendar: true, showStartTime: true };
export const defaultEmailTimezone = 'America/Los_Angeles';
export const createEmptyEmail = (channel: number, event_uuid: string, email_type = EmailTypeEnum.event_invite, language: LanguagesAbbr): IInsertScheduledEmailRequest => {
	// Because we don't let editing within 10 min of a schedule, we need it to be at least 10 min from now, so we just went with 20 min.
	// But, we also need it to be an increment of 5.
	const fiveMinInterval = 1000 * 60 * 5;
	const roundedDate = new Date(Math.ceil((Date.now() + 1000 * 60 * 20) / fiveMinInterval) * fiveMinInterval);
	return {
		channel,
		event_uuid,
		email_type,
		sent: false,
		audiences: [],
		delivery_date: roundedDate.getTime(),
		language: language,
		failed_reason: '',
		customizations: {},
		timezone: defaultEmailTimezone,
		settings: defaultEmailSettings,
	};
};

export const getDefaultFiresideSessionSettings = (): Partial<FiresideSessionSettings> => ({
	settings: {
		use_google_meet: false,
	}
});