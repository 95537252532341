import { Action, AsyncAction } from '../../../types/actions';
import { AddAdminToGroup, AddAdminToGroups, AddNewAdmin, CreateChannelRedirect, DeactivateChannelApiKey, DeleteChannelRedirect, GetAdmins, GetChannelApiKeys, GetChannelRedirects, GetProjectAlertEmails, RemoveAdminFromAllGroups, RemoveAdminFromGroup, UnlockEmail, UpdateChannelRedirect, UpdateUserChannelSettings, UpdateProjectAlertEmails, RemoveUserFromChannel } from '../../../connection/auth';
import { BLAdmin } from '../../../types/working-model';
import { ChannelRedirect } from '../../../components/admin/settings/channel-redirects';

export const GET_ADMINS = "GET_ADMINS";
export function getAdmins(token: string, channel: number): AsyncAction {
	return {
		type: GET_ADMINS,
		promise: GetAdmins(token, channel, false)
	};
}

export const GET_ADMINS_AND_LOCKED_EMAILS = "GET_ADMINS_AND_LOCKED_EMAILS";
export function getAdminsAndLockedEmails(token: string, channel: number): AsyncAction {
	return {
		type: GET_ADMINS_AND_LOCKED_EMAILS,
		promise: GetAdmins(token, channel, true)
	};
}

export const UNLOCK_EMAIL = "UNLOCK_EMAIL";
export function unlockEmail(token: string, email: string): AsyncAction {
	return {
		type: UNLOCK_EMAIL,
		promise: UnlockEmail(token, email)
	};
}

export const SET_WORKING_PERMISSIONS = "SET_WORKING_PERMISSIONS";
export function setWorkingPermissions(permissions: string[]): Action {
	return {
		type: SET_WORKING_PERMISSIONS,
		payload: permissions
	};
}

export const UPDATE_USER_CHANNEL_SETTINGS = "UPDATE_USER_CHANNEL_SETTINGS";
export function updateUserChannelSettings(token: string, profile: BLAdmin, channel: number): AsyncAction {
	const ssoChannels = profile.channels_using_sso ? profile.channels_using_sso : [];
	const isSSO = ssoChannels.includes(channel);
	return {
		type: UPDATE_USER_CHANNEL_SETTINGS,
		promise: UpdateUserChannelSettings(token, profile.id, channel, profile.channels[channel], isSSO)
	};
}

export const ADD_ADMIN = "ADD_ADMIN";
export function addAdmin(token: string, email: string, permissions: string[], isSSO: boolean): AsyncAction {
	return {
		type: ADD_ADMIN,
		promise: AddNewAdmin(token, email, permissions, isSSO)
	};
}

export const REMOVE_ADMIN = "REMOVE_ADMIN";
export function removeAdmin(token: string, profile: BLAdmin, channel: number): AsyncAction {
	return {
		type: REMOVE_ADMIN,
		promise: RemoveUserFromChannel(token, profile.id, channel)
	};
}

export const GET_CHANNEL_REDIRECTS = "GET_CHANNEL_REDIRECTS";
export function getChannelRedirects(token: string, channel: number): AsyncAction {
	return {
		type: GET_CHANNEL_REDIRECTS,
		promise: GetChannelRedirects(token, channel)
	};
}

export const CREATE_CHANNEL_REDIRECT = "CREATE_CHANNEL_REDIRECT";
export function createChannelRedirect(token: string, redirect: Omit<ChannelRedirect, 'id'>): AsyncAction {
	return {
		type: CREATE_CHANNEL_REDIRECT,
		promise: CreateChannelRedirect(token, redirect)
	};
}

export const UPDATE_CHANNEL_REDIRECT = "UPDATE_CHANNEL_REDIRECT";
export function updateChannelRedirect(token: string, redirect: ChannelRedirect): AsyncAction {
	return {
		type: UPDATE_CHANNEL_REDIRECT,
		promise: UpdateChannelRedirect(token, redirect)
	};
}

export const DELETE_CHANNEL_REDIRECT = "DELETE_CHANNEL_REDIRECT";
export function deleteChannelRedirect(token: string, redirect: ChannelRedirect): AsyncAction {
	return {
		type: DELETE_CHANNEL_REDIRECT,
		promise: DeleteChannelRedirect(token, redirect)
	};
}

export const ADD_ADMIN_TO_GROUP = "ADD_ADMIN_TO_GROUP";
export function addAdminToGroup(token: string, admin_id: number, group_uuid: string, channel: number): AsyncAction {
	return {
		type: ADD_ADMIN_TO_GROUP,
		promise: AddAdminToGroup(token, admin_id, group_uuid, channel)
	};
}

export const ADD_ADMIN_TO_GROUPS = "ADD_ADMIN_TO_GROUPS";
export function addAdminToGroups(token: string, admin_id: number, group_uuids: string[], channel: number): AsyncAction {
	return {
		type: ADD_ADMIN_TO_GROUPS,
		promise: AddAdminToGroups(token, admin_id, group_uuids, channel)
	};
}

export const REMOVE_ADMIN_FROM_GROUP = "REMOVE_ADMIN_FROM_GROUP";
export function removeAdminFromGroup(token: string, admin_id: number, group_uuid: string, channel: number): AsyncAction {
	return {
		type: REMOVE_ADMIN_FROM_GROUP,
		promise: RemoveAdminFromGroup(token, admin_id, group_uuid, channel)
	};
}

export const REMOVE_ADMIN_FROM_ALL_GROUPS = "REMOVE_ADMIN_FROM_ALL_GROUPS";
export function removeAdminFromAllGroups(token: string, admin_id: number, channel: number): AsyncAction {
	return {
		type: REMOVE_ADMIN_FROM_ALL_GROUPS,
		promise: RemoveAdminFromAllGroups(token, admin_id, channel)
	};
}

export const CLEAR_BL_ADMIN_ERROR = "CLEAR_BL_ADMIN_ERROR";
export function clearBlAdminError(): Action {
	return {
		type: CLEAR_BL_ADMIN_ERROR,
		payload: null
	};
}

export const GET_CHANNEL_API_KEYS = "GET_CHANNEL_API_KEYS";
export function getChannelApiKeys(token: string, channel: number): AsyncAction {
	return {
		type: GET_CHANNEL_API_KEYS,
		promise: GetChannelApiKeys(token, channel)
	};
}

export const DEACTIVATE_CHANNEL_API_KEY = "DEACTIVATE_CHANNEL_API_KEY";
export function deactivateChannelApiKey(token: string, channel: number, key: string): AsyncAction {
	return {
		type: DEACTIVATE_CHANNEL_API_KEY,
		promise: DeactivateChannelApiKey(token, channel, key)
	};
}

export const GET_PROJECT_ALERT_EMAILS = "GET_PROJECT_ALERT_EMAILS";
export function getProjectAlertEmails(token: string, channel: number): AsyncAction {
	return {
		type: GET_PROJECT_ALERT_EMAILS,
		promise: GetProjectAlertEmails(token, channel)
	};
}

export const UPDATE_PROJECT_ALERT_EMAILS = "UPDATE_PROJECT_ALERT_EMAILS";
export function updateProjectAlertEmails(token: string, channel: number, emails: string[]): AsyncAction {
	return {
		type: UPDATE_PROJECT_ALERT_EMAILS,
		promise: UpdateProjectAlertEmails(token, channel, emails)
	};
}
