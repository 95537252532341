import classNames from 'classnames';
import '../../../scss/live-event/base/general-ui/avatar.scss';

export function getTextColorForBackground(backgroundColor: string) {
	// Split the RGBA color values

	const colorValues = backgroundColor.match(/\d+/g);
	if (!colorValues) {
		return 'unknown';
	}

	// Extract the RGBA values
	const [r, g, b] = colorValues;

	// Calculate relative luminance using the formula
	const relativeLuminance = 0.2126 * (Number(r) / 255) + 0.7152 * (Number(g) / 255) + 0.0722 * (Number(b) / 255);

	// Decide whether to use light or dark text based on relative luminance
	if (relativeLuminance > 0.5) {
		return false; // Use dark text on a light background
	} else {
		return true; // Use light text on a dark background
	}
}

export const pickColor = (char:string, colors:string[]) => {
	if (char) {
		return colors[char.charCodeAt(0) % colors.length];
	} else {
		return colors[0];
	}
};

interface IAvatarProps {
	imageUrl?: string;
	initials?: string;
	className?: string;
	style?: React.CSSProperties;
	onClick?(): void;
	tabIndex?: number;
	canViewProfile?: boolean;
	isModuleGroupingV2?: boolean;
}

const Avatar: React.FC<IAvatarProps> = ({
	imageUrl,
	initials = '',
	className = '',
	style = {},
	onClick = () => ({}),
	tabIndex,
	canViewProfile = false,
	isModuleGroupingV2 = false,
}) => {



	// --accentColor
	// --customColor0
	// --customColor1
	// --customColor2
	// --customColor3
	// --customColor4
	// --customColor5
	// --bodyTextColor
	// --containerColor
	// --backgroundColor
	// --headingTextColor
	// --secondaryAccentColor
	// --secondaryBackgroundColor

	const displayInitials = initials ? initials.replace(/ /g, '') : '';

	if (isModuleGroupingV2){
	const container = document.querySelector('.live-event-container');
	const getCSSProp = (element:Element, propName:string) => getComputedStyle(element)?.getPropertyValue(propName);

	// DEFAULT colors coming from https://github.com/brandlive1941/events-app/blob/efa56bc0ed631f3456317bc38be16b3a880e721c/src/components/general-components/avatar.js#L5
		if (container && !imageUrl) {
			const colorAccent = getCSSProp(container, '--accentColor') || "#026A93";
			const color0 = getCSSProp(container, '--customColor0') || "#EF731A";
			const color1 = getCSSProp(container, '--customColor1') || "#28374E";
			const color2 = getCSSProp(container, '--customColor2') || "#576377";
			const color3 = getCSSProp(container, '--customColor3') || "#00A4E4";
			const color4 = getCSSProp(container, '--customColor4') || "#8B939F";
			const color5 = getCSSProp(container, '--customColor5') || "#62B45C";
			const bodyTextColor = getCSSProp(container, '--bodyTextColor') || "#42B590";
			const containerColor = getCSSProp(container, '--containerColor') || "#0084B8";
			const backgroundColor = getCSSProp(container, '--backgroundColor') || "#D3112C";
			const headingTextColor = getCSSProp(container, '--headingTextColor') || "#3c5a99";
			const secondaryAccentColor = getCSSProp(container, '--secondaryAccentColor') || "#1da1f2";
			const secondaryBackgroundColor = getCSSProp(container, '--secondaryBackgroundColor') || "#f02261";

			const colors = [
				colorAccent,
				color0,
				color1,
				color2,
				color3,
				color4,
				color5,
				bodyTextColor,
				containerColor,
				backgroundColor,
				headingTextColor,
				secondaryAccentColor,
				secondaryBackgroundColor
			];

			const color = pickColor(initials[0], colors);
			const isDarkBackground = getTextColorForBackground(color);
			style.color = isDarkBackground ? "white" : "black";
			style.backgroundColor = color;
		}
	}

	return (
		<div
			className={classNames('chat-avatar', className, { clickable: canViewProfile })}
			style={{ backgroundImage: imageUrl ? `url("${imageUrl}")` : 'none', ...style }}
			onClick={canViewProfile ? onClick : undefined}
			tabIndex={tabIndex}
			onKeyPress={e => {
				if (e.key === 'Enter' && canViewProfile) {
					onClick();
				}
			}}
		>
			{!imageUrl && displayInitials}
		</div>
	);
};

export default Avatar;
