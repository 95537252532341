import { Color, CreateColorPack } from "../types/working-model";
import { Delete, Get, Post, Put } from "./helpers";

export function GetColors(token: string, channel: number): Promise<Color[]> {
	return Get(`/v3/admin/channel/colors/${channel}`, token);
}

export function CreateNewColorPack(
	token: string,
	colors: CreateColorPack
): Promise<Color> {
	return Post(`/v3/admin/channel/colors`, token, colors);
}

export function SaveColor(token: string, colors: Color): Promise<Color> {
	return Put(`/v3/admin/channel/colors`, token, colors, undefined, undefined, true);
}

export interface DeleteColorsReturn {
	cannotDelete: number[];
	deletedColors: number[];
}
export function DeleteColors(colorPackIDs: number[], token: string, channel: number): Promise<DeleteColorsReturn> {
	return Delete('/v3/admin/channel/colors', token, { colorPackIDs, channel }, true);
}