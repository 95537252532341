import { useMemo, useState } from 'react';
import { batch } from 'react-redux';

import { useAppDispatch, useTypedSelector } from '../../../../../../../store/reducers/use-typed-selector';

import { FeatureFlagsEnum, SessionTypesEnum } from 'types/working-model';

import Switch from '../../../../../../general-ui/switch/switch';
import ModalComponent from '@general-ui/modal/modal';
import { registrationToggled } from 'utils/create-event';
import { useIsRSVPLinkApproved } from 'hooks/rsvp.hooks';

export default function RegistrationTurnOnSwitch() {
	const leaderboardFeatureFlag = useTypedSelector(state => !!state.FeatureFlagsReducer.featureFlags[FeatureFlagsEnum.leaderboard]);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);

	const [registrationModalOpen, setRegistrationModalOpen] = useState(false);

	const dispatch = useAppDispatch();

	const registrationOn = workingEvent?.registration_on || false;
	const directoryOn = workingEvent?.social_settings?.directory?.isOn;
	const messagingIsOn = workingEvent?.social_settings?.messaging;
	const myListIsOn = workingEvent?.settings?.personalizedAttendeeList;
	const leaderboardEnabled = !!workingEvent?.settings?.leaderboard_settings?.enabled && leaderboardFeatureFlag; // enabled in settings and flag on
	const rsvpLinkSyncApproved = useIsRSVPLinkApproved();

	const containsBreakoutSession = useMemo(() => {
		return workingEvent?.sessions.find(session => session.session_type === SessionTypesEnum.breakoutRooms) ? true : false;
	}, [workingEvent]);

	const containsFiresideSession = useMemo(() => {
		return workingEvent?.sessions.find(session => session.session_type === SessionTypesEnum.fireside) ? true : false;
	}, [workingEvent]);

	const containsBreakoutOrFiresideSession = containsBreakoutSession || containsFiresideSession;

	const handleRegistrationToggle = () => {
		if (!workingEvent) {
			return;
		}

		if ((
			containsBreakoutOrFiresideSession ||
			directoryOn ||
			messagingIsOn ||
			myListIsOn ||
			leaderboardEnabled
		) && registrationOn) {
			// registration required message visible, user clicked OK
			setRegistrationModalOpen(false);
			return;
		}

		registrationToggled(!registrationOn, workingEvent, dispatch, batch);
		setRegistrationModalOpen(false);
	};


	const registrationToggleWarning = () => {
		const warning = [];

		// Turning on the registration
		if (!registrationOn) {
			warning.push(<p>Turning on registration will require your visitors to complete a form before coming to the page. A registration page is recommended if you are gating your event or looking to capture more information about attendees.</p>);
			return warning;
		}

		// Turning off the registration and there are still features that need to be disabled
		if (containsBreakoutOrFiresideSession || directoryOn || messagingIsOn || myListIsOn || leaderboardEnabled) {
			warning.push(<p className="registration-warning-heading">Before you can turn off registration, you must disable the following features:</p>);
		}

		// Turning off the registration
		else {
			warning.push(<p>If you turn the registration off, it will become public and everyone will see the event sessions.</p>);
			return warning;
		}

		// Features that need to be disabled
		if (containsBreakoutOrFiresideSession) {
			warning.push(<div className="registration-warning"><p>Breakouts or Fireside sessions: </p><p>Pages ➜ Watch ➜ [Fireside/Breakout session] ➜ Three Dots ➜ Delete Session </p></div>);
		}
		if (directoryOn) {
			warning.push(<div className="registration-warning"><p>Directory: </p><p>Social ➜ Directory ➜ Enable Directory</p></div>);
		}
		if (messagingIsOn) {
			warning.push(<div className="registration-warning"><p>Messaging: </p><p>Social ➜ Messaging ➜ Direct Messaging</p></div>);
		}
		if (myListIsOn) {
			warning.push(<div className="registration-warning"><p>Watchlist Agenda Builder: </p><p>Pages ➜ Settings ➜ Watchlist Agenda Builder</p></div>);
		}
		if (leaderboardEnabled) {
			warning.push(<div className="registration-warning"><p>Leaderboard: </p><p>Social ➜ Leaderboard ➜ Leaderboard Page Toggle</p></div>);
		}

		// Returning the array of warnings
		return warning;
	};
	return (
		<>
			<div className="card-container marketing-email-opt-in">
				<div className="toggle-card recaptcha-card">
					<label>Toggle Registration</label>
					<div>
						<Switch
							on={registrationOn}
							onClick={() => setRegistrationModalOpen(true)}
							value="Registration"
							disabled={registrationOn && rsvpLinkSyncApproved}
						/>
					</div>
				</div>
			</div>

			<ModalComponent
				size='small'
				closeable={false}
				cancellable={false}
				open={registrationModalOpen}
				onRequestClose={() => setRegistrationModalOpen(false)}
				title={registrationOn ? (containsBreakoutOrFiresideSession ? "Registration Required" : "Open the Event to Everyone?") : "Turn on Registration"}
				footer={(
					<>
						{(!containsBreakoutOrFiresideSession && !directoryOn && !messagingIsOn && !myListIsOn) &&
							<button onClick={() => setRegistrationModalOpen(false)}>Cancel</button>
						}
						<button onClick={handleRegistrationToggle} className="lemonade">
							{registrationOn
								? ((containsBreakoutOrFiresideSession || directoryOn || messagingIsOn || myListIsOn || leaderboardEnabled) ? "OK" : "Open Event")
								: "Enable Registration"
							}
						</button>
					</>
				)}
			>
				<div className='registration-toggle-warning'>{registrationToggleWarning()}</div>
			</ModalComponent>

		</>
	);
}
