import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useHistory, useParams } from "react-router";
import classNames from "classnames";
import { Link } from "react-router-dom";

import { CallToAction, LanguagesAbbr, PageModuleType } from "../../../../../../types/working-model";
import Icon, { COLORS, ICONS } from "../../../../../general-ui/icon";
import Modal from "../../../../../general-ui/modal/modal";
import { blurEditor, loadWorkingEvent, updateHomepageHeader } from "../../../../../../store/actions/admin/create-event";
import Controls, { InitialControlsDisplay } from "../../../session/editor/moduleControls/controls";
import NavItem, { checkLinkedSectionIsOn, filterActiveModules } from '../../../../../general-ui/nav-item/nav-item';
import { LanguageDropdown } from "../../../../../general-ui/language-dropdown/language-dropdown";
import { HeaderAllTemplates } from "../../../../../../types/template-layouts";
import { GetDefaultCallToAction } from "../../../../../../store/utils/create-event";
import CallToActionForm from "../../../../../general-ui/call-to-action/call-to-action";
import { Button } from "../../../../../general-ui/button/button";
import { getHeaderStylingOverrides } from "../../../../../live-event/utils";
import NewDropdown from "../../../../../general-ui/dropdown/new-dropdown";
import { EIcon } from "../../../../../general-ui/icon/icon";
import { getHomepageDefaultLanguage } from "../../../../../../utils/get-language";
import { addChecklistItem } from "../../../../../../store/actions/authentication";
import { ChecklistTitles } from "../../../../../../store/reducers/admin/assistant";
import { ALERT_TYPES, showAlert } from "../../../../../general-ui/alert/alert-service";
import { ParamsProps } from "../../../../../live-event/live-event";
import EditSectionColorsModal, {
	EContentColorOverrideNames
} from "../../../../../general-ui/edit-section-colors/edit-section-colors-modal";
import {
	EUploadOptions,
	ImageUploader,
	ImageUploadOptions
} from "../../../../../general-ui/image-uploader/image-uploader";
import { useTranslate } from "../../../../../../i18n/useTranslationModules";
import useTranslationsV2 from "../../../shared/use-translations-v2";
import { useAppDispatch, useTypedSelector } from "../../../../../../store/reducers/use-typed-selector";

import '../../../../../../scss/live-event/base/marketing-page/header.scss';
import './header.scss';

interface IEditorHeaderProps {
	handleScroll: (moduleId?: number, p?: PageModuleType) => void;
	template: string;
	language: LanguagesAbbr;
	baseLanguage: LanguagesAbbr;
}

export function switchingLanguageAlert(): void {
	showAlert({
		message: "Fetching translations",
		description: "Please wait a moment while we get new translations",
		duration: 4000,
		type: ALERT_TYPES.POSITIVE
	});
}

const EditorHeader = React.forwardRef<HTMLDivElement, IEditorHeaderProps>(({
	handleScroll,
	template,
	language,
	baseLanguage
}, ref) => {

	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const editorSize = useTypedSelector(state => state.CreateEventReducer.editorSize);
	const token = useTypedSelector(state => state.AuthReducer.token);
	const user = useTypedSelector(state => state.AuthReducer.user);

	const dispatch = useAppDispatch();

	const header = workingEvent?.homepage?.header;
	const isEmailConfirmationEnabled = !!workingEvent?.registration_settings?.sendEmailConfirmation;

	const [logo, setLogo] = useState<string | null | undefined>(header?.logo);
	const [openEditLogoModal, setOpenEditLogoModal] = useState<ImageUploadOptions>(null);
	const [openEditNavItemColor, setOpenEditNavItemColor] = useState(false);
	const [callToActionOpen, setCallToActionOpen] = useState(false);
	const [workingCallToAction, setWorkingCallToAction] = useState<CallToAction>(header?.callToAction || GetDefaultCallToAction(workingEvent?.registration_on || false));
	const [activePageModules, setActivePageModules] = useState(filterActiveModules(workingEvent?.homepage?.modules));
	const [selectedLanguage, setSelectedLanguage] = useState(language ?? getHomepageDefaultLanguage());

	const { id } = useParams() as ParamsProps;
	const {
		backgroundColor,
		backgroundImg,
		mainTextOverrides
	} = getHeaderStylingOverrides(workingEvent?.homepage?.header.styling_overrides);

	const layout = header?.layout || HeaderAllTemplates.left;

	const useV2 = useTranslationsV2();
	const { t } = useTranslate(['homepage', 'landing']);

	const registration_on = workingEvent?.registration_on;
	const navItems = header?.navItems;

	// get default language from first session and set that to the url language
	const history = useHistory();
	const languages = Array.from(new Set(workingEvent?.homepage?.languages as LanguagesAbbr[]));
	// Homepage doesnt have a default language set, use first sessions default language
	const firstDefaultLanguage = workingEvent?.sessions[0].default_language;
	// if default language changes, update the page
	useEffect(() => history.replace(language), [firstDefaultLanguage, history, language]);

	useEffect(() => setSelectedLanguage(language), [language]);

	useEffect(() => {
		setActivePageModules(filterActiveModules(workingEvent?.homepage?.modules));
	}, [workingEvent?.homepage?.modules]);

	const handleLanguageChange = (langAbbr: LanguagesAbbr) => {
		if (!token) return;
		if (!useV2) {
			switchingLanguageAlert();
			dispatch(blurEditor(true));
			dispatch(loadWorkingEvent(id, token));
			setSelectedLanguage(langAbbr);
		}
		history.replace(langAbbr);
	};

	const saveLogo = useCallback((image: { original: string, edited: string; }) => {
		if (!token) return;
		// TODO: refactor to keep original image for future editing
		if (header) {
			dispatch(updateHomepageHeader({
				...header,
				logo: image.edited
			}));
			// ASSISTANT CHECKLIST ITEM: complete users checklist item for uploading logo in header
			if (user && !user.onboarded.includes(ChecklistTitles.uploadLogo)) {
				dispatch(addChecklistItem(token, user.id, user.onboarded, ChecklistTitles.uploadLogo));
			}
		}
		setLogo(image.edited);
	}, [dispatch, header, token, user]);

	function editButton() {
		setCallToActionOpen(true);
	}

	function cancelEditButton() {
		setCallToActionOpen(false);
	}

	function setCallToActionButton() {
		if (!workingEvent?.homepage) { return; }

		if (header && workingCallToAction) {
			dispatch(
				updateHomepageHeader({
					...header,
					callToAction: workingCallToAction
				})
			);
		}
		setCallToActionOpen(false);
	}

	function setNavItemColorOverrides(colorKey: string) {
		if (!workingEvent?.homepage || !workingEvent?.homepage?.header) return;
		const workingStylingOverrides = { ...workingEvent?.homepage.header?.styling_overrides };

		// since we are not updating the background, color key is sent back with -color. If color key is only -color,
		// then we are restoring to the default color.
		const isRestoringDefault = colorKey === "-color";

		// when implementing the callToAction overrides, make sure to include the navItems in the default
		if (!workingStylingOverrides?.content) {
			workingStylingOverrides.content = {
				navItems: { color: isRestoringDefault ? "" : colorKey },
				callToAction: { backgroundColor: '', borderColor: '', text: { color: '' } }
			};
		} else {
			workingStylingOverrides.content.navItems = { color: isRestoringDefault ? "" : colorKey };
		}

		if (workingStylingOverrides && colorKey) {
			dispatch(
				updateHomepageHeader({
					...workingEvent.homepage.header,
					styling_overrides: workingStylingOverrides
				})
			);
		}
	}

	const activeNavItems = useMemo(() => {
		return navItems?.filter(navItem => {
			return checkLinkedSectionIsOn(navItem, activePageModules);
		});
	}, [activePageModules, navItems]);

	const renderLinks = () => {
		// get the overrideColor to reuse for the hover class
		const overrideColor = mainTextOverrides?.split('-')[0];

		return activeNavItems?.map((navItem, i) => {
			const withV3Translation = navItem.button_name?.[language] || t(`landing:header.${navItem.button_name.base}`, navItem.button_name?.base || '');
			const label = navItem.button_name.base === 'Sessions'
				? t('homepage:Watch', withV3Translation as string)
				: withV3Translation as string;
			return (
				<NavItem
					key={navItem.button_name.base + i}
					navItem={navItem}
					handleScroll={handleScroll}
					isEditor={true}
					className={classNames("navigation-link", mainTextOverrides, { [`${overrideColor}-hover`]: mainTextOverrides })}
					labelOverride={label}
				/>);
		});
	};

	const hasSignInBtn = !!header?.sign_in_button && isEmailConfirmationEnabled;
	const renderSigInButton = () => {
		if (!hasSignInBtn) return null;
		const overrideColor = mainTextOverrides?.split('-')[0];
		return (
			<div
				className="sign-in-btn"
				style={{
					...(editorSize === "desktop" ? {
						position: [HeaderAllTemplates.right, HeaderAllTemplates.left].includes(layout) ? 'static' : 'initial',
						right: '30px',
						margin: '0',
					} : {})
				}}
			>
				<Link
					to={location.pathname} // because this is "admin" we don't actually want to navigate anywhere	
					className={classNames("no-style", "navigation-link", mainTextOverrides, { [`${overrideColor}-hover`]: mainTextOverrides })}
				>{t("Sign In", "")}</Link>
			</div>
		);
	};

	const displayHeaderControls: InitialControlsDisplay = {
		editLayout: true,
		editColors: true,
	};

	const stickyHeader = header?.is_sticky;

	return header ? (
		<>
			<header
				ref={ref}
				className={classNames("editor-wrapper header marketing-page-header header-with-language header-fixed-mobile-only", `header-${layout}`, template, backgroundColor, { sticky: stickyHeader })}
			>
				{/* Controls obscure hamburger icon in mobile view, removing them only in this editor size view */}
				{editorSize !== "mobile" && (
					<Controls
						display={displayHeaderControls}
						module={header}
						baseLanguage={baseLanguage}
						language={language}
					/>
				)}

				<div className={classNames("header-container")} style={backgroundImg}>
					<div className="header-col-left">
						{logo ? (
							<img
								className="header-logo"
								alt="Logo"
								onClick={() => setOpenEditLogoModal(EUploadOptions.upload)}
								src={logo}
							/>
						) : (
							<h3 className="logo-header"
								onClick={() => setOpenEditLogoModal(EUploadOptions.upload)}
							>
								{t("homepage:Your Logo")}
							</h3>
						)}
						<div className='edit-button-container'>
							{logo ? (
								<button onClick={() => setOpenEditLogoModal(EUploadOptions.edit)} className="round edit-button">
									<Icon name={ICONS.IMAGE_CROP} color={COLORS.WHITE} size={14} />
								</button>
							) : null}
							<button className="round replace-image" onClick={() => setOpenEditLogoModal(EUploadOptions.upload)}>
								<Icon name={ICONS.IMAGE} color={COLORS.GRAY} size={16} />
							</button>
						</div>
					</div>
					<nav
						className="header-navigation"
						style={{
							...(layout === HeaderAllTemplates.left && hasSignInBtn ? {
								position: 'relative',
							} : {})
						}}
					>
						{editorSize === "desktop" && (
							<div className="nav-item-container">
								{renderLinks()}
								<button onClick={() => setOpenEditNavItemColor(true)} className="round edit-button">
									<Icon name={ICONS.EDIT} color={COLORS.WHITE} size={12} />
								</button>
							</div>
						)}

						<div
							style={{
								display: 'flex',
								margin: layout === HeaderAllTemplates.left ? '0 0 0 auto' : '0',
							}}
						>
							{
								languages.length > 1 &&
								<LanguageDropdown
									template={template}
									languages={languages}
									currentLanguageCode={selectedLanguage}
									onLanguageChange={handleLanguageChange}
									className={hasSignInBtn ? 'adjust-for-sign-in-btn' : ''}
								/>
							}
						</div>

						{editorSize !== "desktop" && (
							<NewDropdown
								className="menu-tablet"
								popupClassName="menu-tablet-popup"
								showArrow={false}
								renderTitle={({ isOpen }) =>
									isOpen ? (
										<EIcon name="close" size="large" />
									) : (
										<EIcon name="menu" size="large" />
									)
								}
							>
								<div className="tablet-links">{renderLinks()}</div>
								{registration_on && (
									<Button
										classButton="primary header-call-to-action"
										template={template}
										onClick={() => null}
									>
										{t('landing:header.callToAction', workingCallToAction?.button_name?.[language] as string || workingCallToAction?.button_name?.base)}
									</Button>


								)}
							</NewDropdown>
						)}
					</nav>

					<div className='header-col-right'>
						<>
							{
								(header?.callToAction) && editorSize === "desktop" &&
								<>
									<Button
										classButton="header-call-to-action primary"
										template={template}
									>
										{t('landing:header.callToAction', header.callToAction.button_name[language] as string || header.callToAction.button_name.base)}
									</Button>

									<div className="call-to-action-options">
										<button onClick={editButton} className="round edit-button"><Icon name={ICONS.EDIT} size={12} color={COLORS.WHITE} /></button>
									</div>
								</>
							}
							{renderSigInButton()}
						</>

					</div>
				</div>

				<ImageUploader
					originalImage={header?.logo ?? undefined}
					onSave={saveLogo}
					onClose={() => setOpenEditLogoModal(null)}
					fileTitle={"Logo"}
					open={openEditLogoModal}
				/>
			</header>
			<Modal
				cancellable={true}
				closeable={false}
				open={callToActionOpen}
				onRequestClose={cancelEditButton}
				title={"Edit button"}
				footer={(
					<>
						<button onClick={cancelEditButton} style={{ marginRight: 16 }}>Cancel</button>
						<button onClick={setCallToActionButton} className="lemonade">Done</button>
					</>
				)}
			>
				<div className="call-to-action-settings">
					<CallToActionForm
						workingCTA={workingCallToAction}
						onWorkingCTAChange={setWorkingCallToAction}
						language={language}
						baseLanguage={baseLanguage}
					/>
				</div>
			</Modal>
			<EditSectionColorsModal
				open={openEditNavItemColor}
				onClose={() => setOpenEditNavItemColor(false)}
				onFinish={setNavItemColorOverrides}
				title={EContentColorOverrideNames.navItems}
			/>

		</>
	) : null;
});

EditorHeader.displayName = 'EditorHeader';

export default EditorHeader;
