import { handle } from 'redux-pack';
import { Action } from '../../../types/actions';
import { BrandliveEvent, EmailSubscriptions, IScheduledEmailWithEventName, PasscodeListMemberType, SentScheduledEmailsWithEventName } from '../../../types/working-model';
import {
	GET_PASSCODE_LIST_MEMBERS,
	GET_MORE_MEMBERS,
	SEARCH_MEMBERS,
	ADD_SELECTED_MEMBER,
	REMOVE_SELECTED_MEMBER,
	SEARCH_MEMBER_COUNT,
	REMOVE_ALL_SELECTED_MEMBERS,
	GET_AUDIENCE_INVITE_STATUS,
	REMOVE_ALL_MEMBERS,
} from '../../actions/admin/passcode-list-members';

export interface PasscodeListMembersState {
	members: PasscodeListMemberType[];
	selectedMembers: PasscodeListMemberType[];
	member_count: number;
	loadingMembers: boolean;
	audienceInvitesStatuses: Record<string, SentScheduledEmailsWithEventName[]> | null;
	fetchingInvites: boolean;
	eventsWithoutInvites: BrandliveEvent[];
	unsubscribedUsers: Record<string, EmailSubscriptions>;
	pendingInvites: IScheduledEmailWithEventName[];
}

const initialState: PasscodeListMembersState = {
	audienceInvitesStatuses: null,
	eventsWithoutInvites: [],
	fetchingInvites: false,
	loadingMembers: false,
	member_count: 0,
	members: [],
	pendingInvites: [],
	selectedMembers: [],
	unsubscribedUsers: {},
};

export default function PasscodeListMembersReducer(
	state: PasscodeListMembersState = initialState,
	action: Action
): PasscodeListMembersState {
	switch (action.type) {
		case GET_PASSCODE_LIST_MEMBERS: {
			return handle(state, action, {
				start: state => ({ ...state, loadingMembers: true }),
				success: (state) => ({ ...state, members: action.payload }),
				finish: state => ({ ...state, loadingMembers: false }),
			});
		}
		case GET_MORE_MEMBERS: {
			return handle(state, action, {
				start: state => ({ ...state, loadingMembers: true }),
				success: (state) => ({
					...state,
					members: [...state.members.concat(action.payload)],
				}),
				finish: state => ({ ...state, loadingMembers: false }),
			});
		}
		case SEARCH_MEMBERS: {
			return handle(state, action, {
				start: state => ({ ...state, loadingMembers: true }),
				success: (state) => ({ ...state, members: action.payload }),
				finish: state => ({ ...state, loadingMembers: false }),
			});
		}
		case SEARCH_MEMBER_COUNT: {
			return handle(state, action, {
				success: (state) => ({
					...state,
					member_count: Number(action.payload[0]?.member_count),
				}),
			});
		}
		case ADD_SELECTED_MEMBER: {
			return {
				...state,
				selectedMembers: [...state.selectedMembers, action.payload],
			};
		}

		case REMOVE_SELECTED_MEMBER: {
			return {
				...state,
				selectedMembers: state.selectedMembers.filter(
					(member) =>
						member.passcode_list_member !== action.payload.passcode_list_member
				),
			};
		}

		case REMOVE_ALL_SELECTED_MEMBERS: {
			return {
				...state,
				selectedMembers: [],
			};
		}

		case REMOVE_ALL_MEMBERS: {
			return {
				...state,
				selectedMembers: [],
				member_count: 0,
				members: [],
				loadingMembers: false,
			};
		}

		case GET_AUDIENCE_INVITE_STATUS: {
			return handle(state, action, {
				start: state => ({ ...state, fetchingInvites: true }),
				success: (state) => {
					const pendingInvites = action.payload?.pendingInvites || [];
					const invites = action.payload.invites || [];
					const eventsWithoutInvites = action.payload.eventsWithoutInvites || [];
					const unsubscribedUsers = action.payload.unsubscribedUsers || [];

					const unsubscribedMap = unsubscribedUsers.reduce((accum: Record<string, EmailSubscriptions>, curr: EmailSubscriptions) => {
						if (!curr.recipient) return accum;
						return {
							...accum,
							[curr.recipient]: curr
						};
					}, {});

					const groupByEmail: Record<string, SentScheduledEmailsWithEventName[]> = {};
					invites?.forEach?.((invite: SentScheduledEmailsWithEventName) => {
						if (groupByEmail[invite.email]) {
							groupByEmail[invite.email].push(invite);
						} else {
							groupByEmail[invite.email] = [invite];
						}
					});
					return {
						...state,
						audienceInvitesStatuses: groupByEmail,
						fetchingInvites: false,
						eventsWithoutInvites,
						unsubscribedUsers: unsubscribedMap,
						pendingInvites,
					};
				},
				finish: state => ({ ...state, fetchingInvites: false }),
				failure: state => ({ ...state, fetchingInvites: false }),
			});
		}

		default:
			return state;
	}
}
