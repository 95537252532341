import { useEffect, useMemo, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { EventsState } from '../../store/types';
import { NavMainItems } from '../../types/working-model';
import { getVariablesAndOverrides } from '../../utils/document-head';

interface Props {
	eventBundle: EventsState['LiveEventReducer']['eventBundle'];
	favIcon?: string;
}
export default function LiveEventDocumentHead({ eventBundle }: Props): JSX.Element {
	/**
	 *
	 *
	 * What's with this hot mess you ask?
	 * Well, I'll tell you.
	 *
	 * Google fonts using the @import rule send in a stylesheet, not a .ttf file or something
	 * Custom uploaded fonts use the @font-face rule
	 *
	 * We want to use Google fonts for the licensing benefits, but we also want to let them upload their own fonts
	 * So we have to use a two-hander here. If it's a google font, make an import statement. Otherwise,
	 * make a font-face rule. Easy peasy.
	 *
	 * 
	 * Notable changes - Custom CSS MUST be placed at the bottom of the <body> tag, because with code splitting,
	 * custom css is loaded before the stock CSS files are loaded, meaning they're being overridden. 
	 * Left in this component for consistency, but these could me moved out of this component into a new one.
	 * It was left here as part of a hotfix, but this could be refactored since it's no longer related to the 
	 * document head.
	 */

	const customCSS = useRef<HTMLStyleElement | null>(null);

	useEffect(() => {
		//if there is custom css
		if (eventBundle?.custom_css) {
			//if there is no custom css element, create one and append it to the bottom of the body
			if (!customCSS.current) {
				customCSS.current = document.createElement('style');
				document.body.appendChild(customCSS.current);
			}

			//manually fill the custom css tag from the bottom of the <body> tag
			customCSS.current.innerHTML =
				(eventBundle.custom_css_compiled)
					? eventBundle.custom_css_compiled
					: eventBundle.custom_css;
		}
	}, [
		eventBundle?.custom_css,
		eventBundle?.custom_css_compiled
	]);

	const { colorVariables, classOverrides } = getVariablesAndOverrides(eventBundle);

	const leaderboardStyles = eventBundle?.settings?.leaderboard_settings?.styles;
	const leaderboardNavIconEnabled = eventBundle?.homepage?.event_main_nav_items?.find(navItem => {
		if (navItem.name === NavMainItems.Leaderboard && navItem.is_on) {
			return true;
		}
		return false;
	});
	const leaderboardEnabled = eventBundle?.settings?.leaderboard_settings?.enabled && leaderboardNavIconEnabled;

	const recaptchaEnabled = !!eventBundle?.registration_settings?.enableRecaptcha;

	const recaptchaScript = useMemo(() => {
		if (recaptchaEnabled) {
			return (
				<script
					src={`https://www.recaptcha.net/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_CLIENT_SITE_KEY}`}
					async
					defer
				></script>
			);
		}
		return;
	}, [recaptchaEnabled]);

	return (
		<Helmet>
			{recaptchaScript}
			<script type="text/javascript" src="https://www.gstatic.com/meetjs/meet.js" />
			{eventBundle?.settings?.design?.font?.heading_url ? (
				<style>
					{`
					${eventBundle?.settings.design.font.heading_url.includes('fonts.googleapis.com') ||
							eventBundle?.settings.design.font.heading_url.includes('assets.brandlive.com')
							? `	
							@import url(${eventBundle?.settings.design.font.heading_url});`
							: `
							@font-face {
								font-family: "${eventBundle?.settings.design.font.heading_name}";
								src: url(${eventBundle?.settings.design.font.heading_url});
							}
						`
						}
						
					${eventBundle?.settings.design.font.body_url.includes('fonts.googleapis.com') ||
							eventBundle?.settings.design.font.body_url.includes('assets.brandlive.com')
							? `
							@import url(${eventBundle?.settings.design.font.body_url});`
							: `
							@font-face {
								font-family: "${eventBundle?.settings.design.font.body_name}";
								src: url(${eventBundle?.settings.design.font.body_url});
							}
						`
						}
					
					.live-event-container, #modal-global-container.live-event-modal .Limelight {
						/* Custom input styling --customInputBorderRadius, --customButtonBorderRadius, --customContainerBorderRadius --customLabelBorderRadius usage: */
						/* border-radius: var(--customInputBorderRadius, 12px); */
						/* Where 12px (or the appropriate value for the specific component) would be the fallback theme default value */
							${eventBundle?.settings.design.custom?.input?.use_custom && eventBundle?.settings.design.custom?.input?.css?.borderRadius ?
							`
								--customInputBorderRadius: ${eventBundle.settings.design.custom.input.css.borderRadius};
								${eventBundle?.settings.design.custom?.input?.css?.borderRadius === '100px' ? `
									--textAreaLeftPadding: 50px;
								` : ''}
							`
							: ''}
							${eventBundle?.settings.design.custom?.button?.use_custom && eventBundle?.settings.design.custom?.button?.css?.borderRadius ?
							`--customButtonBorderRadius: ${eventBundle.settings.design.custom.button.css.borderRadius};`
							: ''}
							${eventBundle?.settings.design.custom?.container?.use_custom && eventBundle?.settings.design.custom?.container?.css?.borderRadius ?
							`--customContainerBorderRadius: ${eventBundle.settings.design.custom.container.css.borderRadius};`
							: ''}
							${eventBundle?.settings.design.custom?.img?.use_custom && eventBundle?.settings.design.custom?.img?.css?.borderRadius ?
							`--customImageBorderRadius: ${eventBundle.settings.design.custom.img.css.borderRadius};`
							: ''}
							${eventBundle?.settings.design.custom?.label?.use_custom && eventBundle?.settings.design.custom?.label?.css?.borderRadius ?
							`--customLabelBorderRadius: ${eventBundle.settings.design.custom.label.css.borderRadius};`
							: ''}
							
						--headingFont: "${eventBundle?.settings.design.font.heading_name}", sans-serif;
						--bodyFont: "${eventBundle?.settings.design.font.body_name}", sans-serif;
						${colorVariables}
					}
					${classOverrides}
				`}
				</style>
			) : (
				<style>
					{`@import url('https://assets.brandlive.com/assets/fonts/replacement/familyOpenSans300300i400400i600600i700700i800800i.css');
						@import url('https://assets.brandlive.com/assets/fonts/replacement/familyRobotoitalwght010003000400050007000900110013001400150017001900displayswap.css');
					
						.editor {
							--headingFont: 'Roboto', sans-serif;
							--bodyFont: 'Open Sans', sans-serif; 
						}
					`}
				</style>
			)}
			{eventBundle?.template?.css ? (
				<style>
					{eventBundle.template.css}
				</style>
			) : null}

			{leaderboardStyles?.custom_css && leaderboardEnabled && (
				<style>
					{
						leaderboardStyles.compiled_css
							? leaderboardStyles.compiled_css
							: leaderboardStyles.custom_css
					}
				</style>
			)}
		</Helmet>
	);
}
