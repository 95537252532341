import './basic-card.scss';

interface IBasicCardProps {
	style?: React.CSSProperties;
	p?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20;
	className?: string;
}

const BasicCard: React.FC<React.PropsWithChildren<IBasicCardProps>> = ({
	style,
	children,
	p = 5,
	className = '',
	...rest
}) => {

	return (
		<div
			className={`basic-card padding-${p} ${className}`}
			style={style}
			{...rest}
		>
			{children}
		</div>
	);
};

export default BasicCard;
