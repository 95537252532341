import React, { useState } from "react";

import { SessionPanelLayoutsTypes } from "../../../../../../../types/working-model";
import Icon, { COLORS, ICONS } from "../../../../../../general-ui/icon";
import Select from "../../../../../../general-ui/select/select";
import TextInput, { Validation } from "../../../../../../general-ui/text-input/text";
import { TypographyItem } from "../../../../../../general-ui/typography-item/typography-item";
import { useHandleTab } from "../../panel/hooks/panel.hooks";
import { FEEDBACK_SCALE, FEEDBACK_SHAPE_TYPES } from "../constants/engage";
import { ErrorsTypes } from "../types/engage";
import { clearErrorsHandler, setErrorHandler, validateFeedback } from "../utils/engage-validate.utils";

const FEEDBACK_SHAPE = [
	{
		label: "Star",
		icon: <Icon name={ICONS.STAR} size={12} color={COLORS.WHITE} />,
		value: FEEDBACK_SHAPE_TYPES.Star,
	},
	{
		label: "Thumb",
		icon: <Icon name={ICONS.LIKED} size={12} color={COLORS.WHITE} />,
		value: FEEDBACK_SHAPE_TYPES.Thumb,
	},
	{
		label: "Heart",
		icon: <Icon name={ICONS.HEART_FILLED} size={12} color={COLORS.WHITE} />,
		value: FEEDBACK_SHAPE_TYPES.Heart,
	},
	{
		label: "Smiley",
		icon: <Icon name={ICONS.SMILEY_FACE} size={12} color={COLORS.WHITE} />,
		value: FEEDBACK_SHAPE_TYPES.Smiley,
	},
];

export interface IFeedback {
	title: string;
	questionTitle: string;
	scale: string;
	shape: FEEDBACK_SHAPE_TYPES;
}

const initialFeedback: IFeedback = {
	title: '',
	questionTitle: '',
	scale: '3',
	shape: FEEDBACK_SHAPE_TYPES.Star,
};

export default function CreateFeedback(): JSX.Element {
	const [feedback, setFeedback] = useState<IFeedback>(initialFeedback);
	const [validationErrors, setValidationErrors] = useState<{ [key: string]: string } | null>(null);

	const handlePath = useHandleTab();

	const setError = setErrorHandler(setValidationErrors);
	const clearErrors = clearErrorsHandler(setValidationErrors);

	const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		clearErrors(ErrorsTypes.FeedbackTitle);
		setFeedback({ ...feedback, title: e.target.value });
	};

	const handleQuestionTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFeedback({ ...feedback, questionTitle: e.target.value });
	};

	const handleSetScale = (scale: string) => {
		setFeedback({ ...feedback, scale });
	};

	const handleSetShape = (shape: FEEDBACK_SHAPE_TYPES) => {
		setFeedback({ ...feedback, shape });
	};

	const handleCancel = () => {
		handlePath(SessionPanelLayoutsTypes.Engage);
	};

	const validateFeedbackHandler = () => {
		return validateFeedback({ feedback, setError });
	};

	const handleSave = () => {
		const feedbackErrors = validateFeedbackHandler();

		if (Object.keys(feedbackErrors).length) {
			return;
		}

		handlePath(SessionPanelLayoutsTypes.AddEngagement);
	};

	return (
		<>
			<TextInput
				id="feedback-title"
				label={'Title*'}
				placeholder={`Type feedback title`}
				valid={validationErrors?.[ErrorsTypes.FeedbackTitle] ? Validation.error : Validation.normal}
				onChange={handleTitleChange}
			/>
			{validationErrors?.[ErrorsTypes.FeedbackTitle] && (
				<span className="session-panel-field-error admin-typography-label-2">
					{validationErrors?.[ErrorsTypes.FeedbackTitle]}
				</span>
			)}

			<TypographyItem tagName="p" className="session-panel-block-heading admin-typography-subtitle-1">
				Content
			</TypographyItem>

			<div className="e-engage-card">
				<div className="e-engage-card-field-list">
					<TextInput
						id="feedback-question-title"
						label={'Question Title'}
						placeholder={`Type question title`}
						onChange={handleQuestionTitleChange}
					/>
					<div className="e-enage-card-feedback-settings">
						<Select
							label="Scale"
							placeholder="Select scale"
							ignorePositioning={true}
							highlightSelectedItem={true}
							showOptionIcon={true}
							options={FEEDBACK_SCALE}
							selected={feedback.scale}
							medium={true}
							onChange={(value) => {
								if (typeof value === 'string') {
									handleSetScale(value);
								}
							}}
						/>
						<Select
							label="Shape"
							placeholder="Select shape"
							ignorePositioning={true}
							highlightSelectedItem={true}
							showOptionIcon={true}
							options={FEEDBACK_SHAPE}
							selected={feedback.shape}
							medium={true}
							onChange={(value) => {
								if (typeof value === 'string') {
									handleSetShape(value as FEEDBACK_SHAPE_TYPES);
								}
							}}
						/>
					</div>
				</div>

			</div>

			<div className="session-panel-actions">
				<button onClick={handleCancel}>
					Cancel
				</button>
				<button className="lemonade" onClick={handleSave}>
					Save
				</button>
			</div>
		</>
	);
}
