import { MouseEvent, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';

import {
	useGetSession,
	useIsAboveTheFold,
	useIsBelowTheFold,
} from '../../../../hooks/session.hooks';
import { useTypedSelector } from '../../../../store/reducers/use-typed-selector';
import { EditorSizes } from '../../../../types/template-layouts';
import { IQuestion, IQuestionPromptTranslations, PageModule, Survey, SurveyType } from '../../../../types/working-model';
import { OptionalComponent } from '../../../../utils/optional-component';
import { useScreenMediaQuery } from '../../../../utils/use-screen-media-query';
import Icon, { ICONS } from '../../../general-ui/icon';
import EngageCardTitle from './engage-card-title';
import EngageCardInfo from './engage-card-info';
import { useGetAnsweredQuestionCount } from './hooks/engage.hooks';
import { getEngageItemByType } from './utils/engage.utils';
import { matchSurveyResponseKey } from '../../../../utils/regex';
import { useIsAdmin } from 'hooks/context.hooks';
import { isSafari } from 'utils/utils';
import { useTranslate } from 'i18n/useTranslationModules';

import './engage-card.scss';

const ANIMATION_TIME = 300;
const OPACITY_TRANSITION_TIME = ANIMATION_TIME + 100;

interface IEngageCardWrapperProps {
	engageCardId: number;
	cardName: string;
	survey?: Survey;
	isSurveyType?: boolean;
	isReviewMode?: boolean;
	editableTitle?: JSX.Element;
	engageType?: SurveyType;
	showResults: boolean;
	children: ReactNode;
	searchComponent?: JSX.Element;
	filterComponent?: JSX.Element;
	className?: string;
	preview?: boolean;
	activeEngageCard?: number;
	order?: number;
	setActiveEngageCard: (state?: number) => void;
	onViewResults?: () => void;
	forceRetake?: () => void;
	qaQuestions?: [number, IQuestion][]
	isEditor?: boolean;
	module?: PageModule;
	questionPromptTranslations?: IQuestionPromptTranslations;
}

export default function EngageCardWrapper({
	engageCardId,
	cardName,
	survey,
	isSurveyType,
	isReviewMode,
	engageType,
	showResults,
	children,
	filterComponent,
	className = '',
	preview,
	activeEngageCard,
	setActiveEngageCard,
	onViewResults,
	forceRetake,
	qaQuestions,
	isEditor,
	questionPromptTranslations,
	module
}: IEngageCardWrapperProps): JSX.Element {
	const { isLessThan1024 } = useScreenMediaQuery();
	const editorSize = useTypedSelector(state => state.CreateEventReducer.editorSize);
	const engagementResults = useTypedSelector(state => state.SurveysReducer.engagementResults);
	const history = useHistory<{
		engageCardId?: number
	}>();
	const session = useGetSession();
	const isAboveTheFold = useIsAboveTheFold(session);
	const isBelowTheFold = useIsBelowTheFold(session);
	const answeredQuestionsCount = useGetAnsweredQuestionCount(survey);
	const isAdmin = useIsAdmin();
	const [isSearchOpen, setIsSearchOpen] = useState(false);
	const [totalResponses, setTotalResponses] = useState(0);
	const [totalQuestions, setTotalQuestions] = useState(0);

	const { tagIcon, name } = getEngageItemByType(showResults, engageType);
	const { language } = useParams() as Record<string, string>;

	const { t } = useTranslate(['session', 'homepage']);
	const translationUpdates = useTypedSelector(event => event.LiveEventReducer.translationUpdates);

	const translationLabel = useMemo(() => {
		const translatedName = t(`session:${name}`, t(`homepage:${name}`, name));
		if (questionPromptTranslations?.label) {
			if (questionPromptTranslations.label?.[language]) {
				return questionPromptTranslations.label[language];
			}
			if (questionPromptTranslations.label?.base) {
				return questionPromptTranslations.label.base;
			}
		}

		if (!survey?.label) return translatedName;

		return survey.label?.[language] || survey?.label.base || translatedName;

		// requires translationUpdates in the dependency array to update the translation when the language changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [t, name, questionPromptTranslations, language, survey, translationUpdates]) as string;

	useEffect(() => {
		const questionIDs = survey?.questions.map((q) => q?.survey_question)?.filter(i => i) || [];
		if (qaQuestions) {
			setTotalQuestions(qaQuestions.length);
		}
		let tempTotalResponses = 0;
		for (const engagement in engagementResults) {
			const engagementID = matchSurveyResponseKey(engagement);

			if (questionIDs.includes(Number(engagementID?.survey_question))) {
				tempTotalResponses += engagementResults[engagement];
			}
		}
		setTotalResponses(tempTotalResponses);
	}, [engagementResults, qaQuestions, survey]);


	const { questions } = survey || {};

	const maxQuestions = questions?.length || 0;
	const isDesktop = preview ? editorSize === EditorSizes.desktop : !isLessThan1024;
	const isQuestionType = engageType === SurveyType.question_prompt;
	const surveyCompleted = answeredQuestionsCount === maxQuestions;
	const privateResponsesEnabled = isQuestionType && module
		? module.modules?.[0].settings.private_responses === true
		: false;

	const expanded = activeEngageCard === engageCardId;

	const timeout = useRef<NodeJS.Timeout | null>(null);
	const [isAnimating, setIsAnimating] = useState(false);

	const handleOpen = () => {

		if (!expanded) {
			setIsAnimating(true);
			timeout.current && clearTimeout(timeout.current);
			timeout.current = setTimeout(() => setIsAnimating(false), ANIMATION_TIME);
		}

		if (activeEngageCard) setActiveEngageCard();
		setActiveEngageCard(engageCardId);

		// push an entry onto the current history state so the back button can trigger the close
		// but only if desktop, because on tablet and mobile we drop down to btf and there's no need to navigate there
		// or the page will scroll up on every click
		if (isAboveTheFold && isDesktop) {
			history.push(history.location.pathname, { engageCardId });
		}
	};

	const handleClose = (event: MouseEvent) => {
		event.stopPropagation();

		setIsAnimating(true);
		timeout.current && clearTimeout(timeout.current);
		timeout.current = setTimeout(() => setIsAnimating(false), ANIMATION_TIME / 2); // halving the time prevents input flashing

		setActiveEngageCard();
		setIsSearchOpen?.(false);

		if (isAdmin && forceRetake) {
			forceRetake();
		}

		if (history.location.state?.engageCardId) {
			history.goBack();
		}
	};

	useEffect(() => {
		// if the user has hit the back button while the engage card is open, close it
		const unlisten = history.listen((_, action) => {
			if (action === 'POP' && isAboveTheFold) {
				setActiveEngageCard();
				setIsSearchOpen?.(false);

				if (isAdmin && forceRetake) {
					forceRetake();
				}
			}
		});

		return () => {
			unlisten();
		};
	}, [history, setActiveEngageCard, isAboveTheFold, isAdmin, forceRetake]);

	const isAboveTheFoldExpanded = isAboveTheFold && expanded;
	const showArrowBack = isAboveTheFoldExpanded && isDesktop;
	const showCloseButton = (isBelowTheFold && expanded) || (isAboveTheFoldExpanded && !isDesktop);
	const renderBody = isBelowTheFold || !isDesktop;

	const handleToggleSearch = () => setIsSearchOpen?.((prev) => !prev);

	const searchTrigger = <button
		className={classNames('engage-card-search-trigger no-padding no-style', { active: isSearchOpen })}
		onClick={handleToggleSearch}
	>
		<Icon name={ICONS.SEARCH} color="" size={16} />
	</button>;

	return (
		<div
			className={classNames('engage-card', className, {
				'engage-card-survey': isSurveyType,
				'engage-card-review-mode': isReviewMode,
				'engage-card-expanded': expanded,
				'engage-card-disabled': false,
				'engage-card-search-open': isSearchOpen,
				'is-editor': isEditor,
				'is-safari': isSafari,
			})}
			onClick={handleOpen}
		>
			<div
				className="engage-card-header"
				style={{
					visibility: isAnimating && !expanded ? 'hidden' : 'visible',
					opacity: isAnimating && !expanded ? '0' : '1',
					transition: `opacity ${OPACITY_TRANSITION_TIME}ms ease-in-out`
				}}
			>
				<div className="engage-card-header-top">
					<EngageCardTitle
						name={translationLabel}
						tagIcon={tagIcon}
						cardName={cardName}
						isQuestionType={isQuestionType}
						surveyCompleted={surveyCompleted}
						searchTrigger={searchTrigger}
						filterComponent={filterComponent}
						onViewResults={onViewResults}
						handleOpen={handleOpen}
						privateResponsesEnabled={privateResponsesEnabled && expanded}
					/>
					<OptionalComponent display={showCloseButton || showArrowBack}>
						<button className="engage-card-header-action no-style no-padding" onClick={handleClose}>
							{showCloseButton ?
								<Icon name={ICONS.CLOSE} color="" size={16} /> :
								<Icon name={ICONS.ARROW_LEFT} color="" size={16} />
							}
						</button>
					</OptionalComponent>
				</div>

				<OptionalComponent display={!expanded}>
					<EngageCardInfo
						engageType={engageType}
						surveyCompleted={surveyCompleted}
						answeredQuestionsCount={answeredQuestionsCount}
						maxQuestions={maxQuestions}
						totalResponses={totalResponses}
						totalQuestions={totalQuestions}
						miscTranslations={survey?.misc_translations}
						privateResponsesEnabled={privateResponsesEnabled}
					/>
				</OptionalComponent>
			</div>

			<OptionalComponent display={!isAnimating}>
				<>
					{renderBody ? children : expanded && children}
				</>
			</OptionalComponent>

			<OptionalComponent display={!isAnimating}>
				<>
					<div className="scroll-overlay-top" />
					<div className="scroll-overlay-bottom" />
				</>
			</OptionalComponent>
		</div>
	);
}
