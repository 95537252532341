import React from 'react';
import { useParams } from 'react-router';
import classNames from 'classnames';

import { BrandliveEvent, ContentEditors, LanguagesAbbr, ModuleMap, PageLinkType, PageModule, PageModuleType, Session, SessionPreview, SessionTypesEnum } from '../../../../types/working-model';
import { getEventNameURL, getStylingOverrides, useLanguageParam } from '../../utils';
import { ParamsProps } from '../../live-event';
import { MainEventBannerLayoutTypesClassic } from '../../../../types/template-layouts';
import { Countdown } from '../../../general-ui/countdown/countdown';
import { getStartTime } from '../../../../utils/get-event-start-time';
import { isiOSMobile } from '../../../../utils/utils';
import { TypographyItem } from '../../../general-ui/typography-item/typography-item';
import { getDefaultModuleAlignment, getTextValue, niceDateTimeTz } from '../../../../utils/utils';
import LiveCallToAction from '../../../general-ui/live-call-to-action/live-call-to-action';
import { getAsset } from '../assets/asset-map';
import RenderEditorDescription from '../../../general-ui/editable-text/render-editor-description';
import { useTranslate } from '../../../../i18n/useTranslationModules';
import useTranslationRoute from '../../hooks/use-translation-route';
import { OptionalComponent } from '../../../../utils/optional-component';

import '../../../../scss/live-event/base/modules/main-event-banner.scss';

interface MainEventBannerProps {
	module: PageModule;
	template: string;
	eventBundle?: BrandliveEvent | null;
	isLandingPage?: boolean;
}

const MainEventBanner = React.forwardRef<HTMLDivElement, MainEventBannerProps>(({ module, template, eventBundle, isLandingPage = false }, ref) => {
	const { eventName }: ParamsProps = useParams();
	const language = useLanguageParam() as LanguagesAbbr;
	const { content, styling_overrides } = module;
	const layout_type = content?.layout_type || MainEventBannerLayoutTypesClassic.standard;
	const startTime = eventBundle ? getStartTime(eventBundle, false) as number : null;
	const startDate = eventBundle && startTime ? new Date(startTime) : null;
	const isEnded = startDate && startDate.valueOf() < Date.now();
	const translationRoute = useTranslationRoute();
	const { t } = useTranslate(translationRoute);

	const now = new Date().getTime();
	const defaultStartTimeText = (startTime ? `${t("homepage:STARTS ON")}  ${niceDateTimeTz(startTime, language)}` : '');
	const startsOnText = content?.start_time ? (content?.start_time[language] || content?.start_time?.base || defaultStartTimeText) : defaultStartTimeText;

	const image = content.image;
	const registration_on = eventBundle?.registration_on;
	const pageLink: PageLinkType = content?.callToAction?.content?.page || null;
	const sectionLink: PageModuleType = content?.callToAction?.content?.section_module_id || null;
	const sessionLink = content?.callToAction?.content?.session || null;
	const urlLink: string = content?.callToAction?.content?.url || null;

	const enableDynamicMainEventBannerHeight = content?.enableDynamicMainEventBannerHeight;

	//if the user has not registered, the sessions array will be empty. Fall back to the sessions previews.
	const sessionsToCheck = eventBundle?.sessions ?? eventBundle?.sessionsPreviews;

	//check if the property exists yet
	const countdownIsOn = ("countdownIsOn" in content)
		? content.countdownIsOn
		// backward compatable: check if any session has a broadcast date, then turn on
		: sessionsToCheck?.some((session: Session | SessionPreview) => session.session_type === SessionTypesEnum.broadcast);

	const {
		backgroundColor,
		backgroundImg,
		titleOverrides,
		descriptionOverrides,
		primaryButtonOverrides,
		startTimeOverrides
	} = getStylingOverrides(styling_overrides);

	const { description, title } = getTextValue(content, language);

	return (eventBundle && module.is_on && content) && (
		<section
			id={ModuleMap[PageModuleType.main_event_banner]}
			className={classNames(
				'main-event-banner',
				`main-event-banner-${layout_type}`,
				template,
				backgroundColor,
				{
					iOS: isiOSMobile(),
					'enable-dynamic-height': enableDynamicMainEventBannerHeight,
				}
			)}
			ref={ref}
		>

			<div className="module-container full-width" style={backgroundImg}>
				<div className="banner-title-container">
					<OptionalComponent display={now < (startTime ? startTime : 0)}>
						<TypographyItem className={classNames("evt-caption-1 banner-starts-on", startTimeOverrides)} tagName="p" >
							{startsOnText}
						</TypographyItem>
					</OptionalComponent>

					<TypographyItem className={classNames('evt-heading-1 banner-title', titleOverrides)} tagName="h1">
						{t(`${translationRoute}:mainEventBanner.${module.id}.title`, t(`${translationRoute}:mainEventBanner.title`, title))}
					</TypographyItem>
					<TypographyItem className={classNames("evt-body-text-1 banner-description", {
						'is-quill': eventBundle?.settings?.content_editor === ContentEditors.quill
					})}>
						<RenderEditorDescription
							description={t(`${translationRoute}:mainEventBanner.${module.id}.description`, t(`${translationRoute}:mainEventBanner.description`, description))}
							descriptionOverrides={classNames(descriptionOverrides)}
							useNew={eventBundle?.settings?.content_editor === ContentEditors.quill}
							defaultTextAlignment={getDefaultModuleAlignment(module, template)}
						/>
					</TypographyItem>

					{(startDate && !isEnded && countdownIsOn) ? <Countdown date={startDate} template={template} /> : null}
					<div className="button-holder">
						{
							(!isLandingPage && pageLink === PageLinkType.registration) ? null : (
								<LiveCallToAction
									classname={classNames('primary', primaryButtonOverrides)}
									template={template}
									buttonText={t(`${translationRoute}:mainEventBanner.${module.id}.callToAction`, t(`${translationRoute}:mainEventBanner.callToAction`, content?.callToAction?.button_name?.[language] || content?.callToAction?.button_name?.base || ''))}
									pageLink={pageLink}
									sectionLink={sectionLink}
									urlLink={urlLink}
									sessionLink={sessionLink}
									eventName={eventName || getEventNameURL(eventBundle)}
									uuid={eventBundle?.uuid}
									selectedLanguage={language}
									registration_on={!!registration_on}
								/>
							)
						}
						{/*
						We can't select videos in main-event-banners yet, so there's no video to watch. Comment back in when we actually have videos in main event banners.

						<Button
							classButton={classNames('watch-video-button secondary', { "no-border": template === TemplateClassNames.O2 })}
							template={template}
						>Watch Video</Button>

						*/}
					</div>
				</div>
				<div
					className={classNames("banner-preview-container", { 'enable-dynamic-height': enableDynamicMainEventBannerHeight })}
				>
					{eventBundle && <img src={image ? image : getAsset(eventBundle.template.name, 'video_background')} alt="" width="375" height="300" aria-hidden="true" />}
					{/* 
					
						NOTE: we only have images in the main event banner right now, so the play button is redundant. 
						Re-add when we can add promo videos to main event banner.
					
					*/}
					{/* <Button
						classButton={classNames('play-button primary')}
						template={template}
					>
						<EIcon name="play" size="medium" />
					</Button> */}
				</div>
			</div>
		</section>
	);
});

MainEventBanner.displayName = 'MainEventBanner';

export default MainEventBanner;
