import { OptionalComponent } from "../../../../../../utils/optional-component";
import { useContext, useState, useEffect, useMemo } from 'react';
import { VideoStateContext } from '../../session-stream-provider';
import { VideoPlayerType } from "../types";
import * as Signals from '../../../../../../utils/event-emitter';

export enum CuesActions {
	addCue = 'add-cue',
	removeCue = 'remove-cue',
	replaceCues = 'replace-cues',
	updateCues = 'update-cues',
}

const Captions: React.FC<unknown> = () => {
	const {
		state: {
			showCaptions,
			selectedCaptions,
			playerType
		}
	} = useContext(VideoStateContext);
	const isEmbed = playerType === VideoPlayerType.embed;
	const isPlaceholder = playerType === VideoPlayerType.placeholder;

	const [cues, setCues] = useState<string[]>([]);

	useEffect(() => {
		Signals.on(CuesActions.updateCues, ({ addCues }: { addCues: string[], removeCues: string[] }) => {
			setCues(addCues);
		});

		Signals.on(CuesActions.addCue, (cue: string) => {
			setCues(cues => [...cues, cue]);
		});

		Signals.on(CuesActions.removeCue, (cue: string) => {
			setCues(cues => cues.filter(c => c !== cue));
		});

		Signals.on(CuesActions.replaceCues, (cues: string[]) => {
			setCues(cues);
		});

		return () => {
			Signals.off(CuesActions.addCue);
			Signals.off(CuesActions.removeCue);
			Signals.off(CuesActions.replaceCues);
			Signals.off(CuesActions.updateCues);
		};
	}, []);

	return useMemo(() => (
		<OptionalComponent display={!isEmbed && !isPlaceholder && showCaptions}>
			<div className="captions-container">
				<div className="captions-text">
					{/* using dangerously set inner html because webvtt cues can contain <v> voice tags and we do not want to render them as text. */}
					<span className="cue" dangerouslySetInnerHTML={{ __html: cues.join(' ') }}></span>
				</div>
			</div>
		</OptionalComponent>
	), [cues, isEmbed, isPlaceholder, showCaptions]);
};

export default Captions;