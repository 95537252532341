import { useSelector, TypedUseSelectorHook, useDispatch } from 'react-redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction, Dispatch } from 'redux';

import { RootState } from './main';
import { AsyncAction, Action as CustomAction } from '../../types/actions';

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAppDispatch: <AppDispatch extends Dispatch<AnyAction | CustomAction> = Dispatch<AnyAction | CustomAction>>() => AppDispatch = useDispatch;

export const useThunkDispatch: () => ThunkDispatch<RootState, unknown, Action | AsyncAction> = useDispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	AnyAction
>;
