
import classNames from 'classnames';
import { Dispatch, SetStateAction, useState } from 'react';
import { PreviewEventSignIn } from '../../connection/auth';
import { useTypedSelector } from '../../store/reducers/use-typed-selector';
import ModalComponent from '../general-ui/modal/modal';
import TextInput from '../general-ui/text-input/text';
import '../general-ui/modal/modal.scss';

type Props = {
	setIsModalPreview: Dispatch<SetStateAction<boolean | null | undefined>>
}

export default function PreviewPasscodeChecke(props: Props): JSX.Element {
	const {
		setIsModalPreview
	} = props;

	const eventBundle = useTypedSelector(event => event.LiveEventReducer.eventBundle);

	const [previewPasscode, setPreviewPasscode] = useState("");
	const [previewModalOpen, setPreviewModalOpen] = useState(true);
	const [errorPasscodePreview, setErrorPasscodePreview] = useState("");

	const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const val = e.target.value;
		setPreviewPasscode(val);
	};

	const submitPreviewPasscode = async () => {
		if (eventBundle?.uuid && previewPasscode) {
			const res = await PreviewEventSignIn(eventBundle?.uuid, previewPasscode);
			if (res.error)
				setErrorPasscodePreview(res.error);
			else {
				cancelModal();
				setIsModalPreview(false);
			}
		}
	};

	const cancelModal = () => {
		setPreviewModalOpen(false);
	};

	return (
		<ModalComponent
			cancellable={false}
			closeable={false}
			open={previewModalOpen}
			onRequestClose={cancelModal}
			title="Preview Event"
			footer={(
				<>
					<button className={classNames("lemonade", { "disabled": !previewPasscode })} onClick={submitPreviewPasscode}>
						Submit Passcode
					</button>
				</>
			)}
		>
			<div className={classNames("preview-password")}>
				<label>Passcode</label>
				<TextInput
					onChange={(e) => handleNameChange(e)}
					placeholder="Passcode of your event"
					value={previewPasscode}
				/>
				<p className="error-passcode-preview">{errorPasscodePreview}</p>
			</div>
		</ModalComponent>
	);
}
