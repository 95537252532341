import classNames from "classnames";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { isObject } from "underscore";
import { CreatePageModule } from "../../../../../../../../connection/page-modules";
import { SearchResults, useContentSearch } from "../../../../../../../../connection/sessions-panel/search";
import { ESort } from "../../../../../../../../connection/sessions-panel/types";
import { addSessionPageModule, loadWorkingSession, updateGroupModules, updatePageModule } from "../../../../../../../../store/actions/admin/create-event/session";
import { getSpeakers } from "../../../../../../../../store/actions/admin/speakers";
import { useAppDispatch, useTypedSelector } from "../../../../../../../../store/reducers/use-typed-selector";
import { PageModule, PageModuleType, SessionPanelLayoutsTypes, Speaker, Templates } from "../../../../../../../../types/working-model";
import { useGetAdminUrl } from "../../../../../../../../utils/admin-routing-utils";
import { mergeUniqueById } from "../../../../../../../../utils/utils";
import { showAlert } from "../../../../../../../general-ui/alert/alert-service";
import StaggerChildren from "../../../../../../../general-ui/animated/stagger-children";
import SmallSelect from "../../../../../../../general-ui/select/small-select";
import TextInput from "../../../../../../../general-ui/text-input/text";
import WaitingIndicator from "../../../../../../../general-ui/waiting-indicator/waiting-indicator";
import { useFinishNavigate, usePageModule, usePageModuleGroup } from "../../hooks/panel.hooks";
import { SessionPanelMap } from "../../session-panel-route-map";
import { appendNewPageModulesToGroups } from "../../utils/module-group.utils";
import { PageModuleGroup } from "../../utils/prototypes/page-module-group-modules";
import SpeakerCard from "./speaker-card";
import SessionPanelAddFooter from "../../components/session-panel-add-footer";
import { getSessionPanelRouteState } from "../../../../../../../../utils/path-utils";
import { customSpeakersItems, extrasSpeakersItems } from "../../empty-state-panel/constants/empty-panel";
import { useLanguageParam } from "components/live-event/utils";
import { UpdateSessionInfo } from "connection/create-event";
import { batch } from "react-redux";
import { isSavingEvent } from "store/actions/admin/create-event";
import { OptionalComponent } from "utils/optional-component";
import FullScreenOverlay from "@general-ui/full-screen-overlay/full-screen-overlay";

const sortOptions = [
	{ label: 'Newest', value: ESort.dateDesc },
	{ label: 'Oldest', value: ESort.date },
	{ label: 'Name A-Z', value: ESort.name },
	{ label: 'Name Z-A', value: ESort.nameDesc },
];

type Props = {
	withinTab?: boolean;
}

const SpeakersLibrary: React.FC<Props> = ({ withinTab }) => {
	const token = useTypedSelector(state => state.AuthReducer.token);
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const [awaitingInitialLoad, setAwaitingInitialLoad] = useState(true);
	const [speakers, setSpeakers] = useState<Speaker[]>([]);
	const [total, setTotal] = useState(0);
	const [sortOrder, setSortOrder] = useState<ESort | undefined>(ESort.dateDesc);
	const searchTermRef = useRef<string>();
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [selectedSpeakers, setSelectedSpeakers] = useState<number[]>([]);
	const [hasScrolledToBottom, setHasScrolledToBottom] = useState(true);
	const [saving, setSaving] = useState(false);
	const containerRef = useRef<HTMLDivElement | null>(null);
	const scrollRef = useRef<HTMLDivElement | null>(null);
	const loadMoreTimeout = useRef<NodeJS.Timeout | null>(null);
	const { customPath } = useParams<{ customPath?: string }>();
	const pageModuleGroup = usePageModuleGroup();
	const pageModule = usePageModule();
	const dispatch = useAppDispatch();
	const { isExtrasCustom } = getSessionPanelRouteState(location.pathname);
	const extrasItems = isExtrasCustom ? customSpeakersItems : extrasSpeakersItems;
	const language = useLanguageParam();

	const adminPath = useGetAdminUrl();
	const eventId = workingEvent?.event;
	const channelId = workingSession?.channel;
	const finish = useFinishNavigate();
	const history = useHistory<{ speakerToReplace?: Speaker }>();

	const handleError = useCallback((e: string) => {
		console.error(e);
		showAlert({
			message: e,
			type: "error",
		});
	}, []);

	const {
		load,
		search,
		loading,
		clear,
		setSort,
		next
	} = useContentSearch<Speaker>('speakers', handleError);

	const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		searchTermRef.current = e.target.value;
	}, []);

	// append new items to end of list
	const handleMoreResults = useCallback((res: SearchResults<Speaker> | undefined) => {
		if (isObject(res) && res.results) {
			setTotal(res.total ?? 0);
			setSpeakers(mergeUniqueById<Speaker>('speaker', res.results ?? []));
		}
	}, []);

	// replace list with new items
	const handleNewResults = useCallback((res: SearchResults<Speaker> | undefined) => {
		if (isObject(res)) {
			if (scrollRef.current) {
				scrollRef.current.scrollTop = 0;
			}

			setAwaitingInitialLoad(false);
			setTotal(res.total ?? 0);
			setSpeakers(res.results ?? []);
		}
	}, []);

	const handleSearch = useCallback(() => {
		// not doing typeahead search, user has to press enter or blur field
		// so we aren't updating the state every keystroke, just holding the value in a ref
		setSearchTerm(searchTermRef.current ?? '');
	}, []);

	useEffect(() => {
		if (searchTerm && searchTerm.length >= 3) {
			// apply new search term and replace existing results with new
			search(searchTerm).then(handleNewResults);
		} else {
			// clear search term and replace existing results with new
			clear(true).then(handleNewResults);
		}
	}, [clear, handleNewResults, search, searchTerm]);

	const handleSort = useCallback((value: string) => {
		if (value) {
			setSortOrder(value as ESort);
		} else {
			setSortOrder(undefined);
		}
	}, []);

	const handleSelectSpeaker = (speaker: number, on: boolean) => {
		if (history.location.state?.speakerToReplace) {
			if (on) {
				setSelectedSpeakers([speaker]);
			} else {
				setSelectedSpeakers([]);
			}
		} else {
			if (on) {
				setSelectedSpeakers(speakers => [...speakers, speaker]);
			} else {
				setSelectedSpeakers(speakers => speakers.filter(s => s !== speaker));
			}
		}
	};

	useEffect(() => {
		const moreAvailable = total > speakers.length;

		if (hasScrolledToBottom && moreAvailable && !loading) {
			// debounce send in case user scrolls like crazy or we get multiple triggers
			if (loadMoreTimeout.current) {
				clearTimeout(loadMoreTimeout.current);
			}

			loadMoreTimeout.current = setTimeout(() => {
				loadMoreTimeout.current = null;
				next().then(handleMoreResults);
			}, 200);
		}
	}, [hasScrolledToBottom, total, handleMoreResults, next, loading, speakers.length]);

	useEffect(() => {
		// apply new value and replace list with new results
		setSort(sortOrder).then(handleNewResults);
	}, [handleNewResults, setSort, sortOrder]);

	useEffect(() => {
		setAwaitingInitialLoad(true);
		// initialize list
		load().then(handleNewResults);
	}, [handleNewResults, load]);

	const handleDone = async () => {
		if (!token || !workingEvent || !workingSession || !workingSession.module_grouping || !pageModuleGroup) return;
		let page_module: PageModule;
		let appendToGroup = false;
		dispatch(isSavingEvent(true));

		if (!pageModule?.id) {
			// no page module exists to contain these speakers - create a new one and append it ot the module group
			page_module = await CreatePageModule(token, {
				type: PageModuleType.speakers,
				eventName: workingEvent.name,
				template: Templates.Limelight,
				languages: workingSession.languages,
				baseLanguage: workingSession.default_language
			});

			dispatch(addSessionPageModule(page_module));
			appendToGroup = true;
		} else {
			page_module = pageModule;

			// this is a custom tab, and the user has navigated to the speakers tab, so we need to convert this
			// empty custom tab to a speakers tab
			if (page_module.type === PageModuleType.feed) {
				page_module.type = PageModuleType.speakers;
				dispatch(updatePageModule(page_module));
			}
		}

		let newSpeakers: Speaker[] = [];

		if (history.location.state?.speakerToReplace?.speaker) {
			const speakerToReplace = history.location.state.speakerToReplace;
			newSpeakers = [
				...speakers.filter(speaker => selectedSpeakers.includes(speaker.speaker)),
				...(page_module.modules?.filter(p => p.speaker !== speakerToReplace.speaker) ?? [])
			];
		} else {
			newSpeakers = [
				...speakers.filter(speaker => selectedSpeakers.includes(speaker.speaker)),
				...(page_module.modules ?? []),
			];
		}

		try {
			if (!token || !channelId || !eventId) {
				throw new Error('Unable to select speakers when there is no event to edit.');
			}
			setSaving(true);

			const updatedModule = {
				...page_module,
				modules: newSpeakers,
				content_modules: newSpeakers.map(s => s.speaker),
				is_edited: true,
			};

			const _workingSession = {
				...workingSession,
				modules: workingSession.modules.map((module: PageModule) => {
					if (module.id === updatedModule.id) {
						return updatedModule;
					}
					return module;
				}),
			};

			const updatedSession = await UpdateSessionInfo(_workingSession, token);

			batch(() => {
				dispatch(loadWorkingSession(updatedSession));
				dispatch(getSpeakers(channelId, token));
			});
		} catch (e) {
			console.error(e);
			showAlert({
				message: 'Unable to Save Speakers',
				description: "We ran into an issue adding these speakers. Please try again.",
				type: "error"
			});
		} finally {
			setSaving(false);
			dispatch(isSavingEvent(false));
		}

		if (appendToGroup) {
			const newModules = (workingSession.module_grouping as PageModuleGroup[])
				.map(appendNewPageModulesToGroups(pageModuleGroup.uuid, [page_module.id as number]));

			dispatch(updateGroupModules(newModules));
		}

		const path = (() => {
			if (customPath) {
				return adminPath({ path: SessionPanelMap[SessionPanelLayoutsTypes.CustomSpeakers], customPath, page_module: page_module.id });
			}

			if (page_module.content.custom_tab) {
				return adminPath({ path: SessionPanelMap[SessionPanelLayoutsTypes.ExtraCustom], page_module: page_module.id });
			} else {
				return adminPath({ path: SessionPanelMap[SessionPanelLayoutsTypes.ExtraSpeaker], page_module: page_module.id });
			}
		})();

		finish(path);
	};

	return (
		<div className={classNames("session-panel speakers-list", { 'has-selections': selectedSpeakers.length > 0 })} ref={containerRef}>
			<div className="session-panel-header-options">
				<TextInput
					defaultValue={''}
					onChange={handleSearchChange}
					onBlur={handleSearch}
					onEnterKey={handleSearch}
					placeholder="Search..."
					className="small"
				/>
				<SmallSelect
					options={sortOptions}
					selected={sortOrder || ''}
					onChange={handleSort}
				/>
			</div>

			{!withinTab && <label className="session-panel-section-label">Library</label>}

			{speakers.length ? (
				<StaggerChildren
					onScrolledToBottom={() => setHasScrolledToBottom(true)}
					onScrolledUpFromBottom={() => setHasScrolledToBottom(false)}
					ref={scrollRef}
					footer={loading ? <WaitingIndicator fillSpace={true} minHeight={36} transparentFill={true} /> : <Fragment />}
					className={classNames("session-panel-content")}
				>
					{speakers.map((speaker: Speaker) => (
						<SpeakerCard
							key={speaker.speaker}
							speaker={speaker}
							selected={selectedSpeakers.includes(speaker.speaker as number)}
							handleSelectSpeaker={handleSelectSpeaker}
						/>
					))}
				</StaggerChildren>
			) : (
				<>
					{(awaitingInitialLoad || loading) ? (
						<div className="session-panel-no-results padding-24">
							<section><WaitingIndicator /></section>
						</div>
					) : (
						<div className="session-panel-no-results padding-24">
							<section>{awaitingInitialLoad ? <WaitingIndicator /> : 'No results'}</section>
							<SessionPanelAddFooter
								scrollRef={scrollRef}
								items={extrasItems}
								small
							/>
						</div>
					)}
				</>

			)}

			<div className="session-panel-footer">
				<button onClick={() => setSelectedSpeakers([])}>Clear Selections</button>
				<button
					className="lemonade"
					onClick={handleDone}
					disabled={saving}
				>{saving ? <WaitingIndicator /> : 'Add to page'}</button>
			</div>

			<OptionalComponent display={saving}>
				<FullScreenOverlay />
			</OptionalComponent>
		</div>
	);
};

export default SpeakersLibrary;
