import { Put } from "../helpers";

const isGCP = process.env.REACT_APP_CLOUD_PROVIDER === 'gcp';

export const SetItemContentType = async (
	token = '',
	filename: string,
	contentType: string,
	isLiveSession = false,
	headers = {}
) => {
	let url = '/v3/admin/channel/upload-endpoint/content-type';
	if (isLiveSession) {
		url = '/v3/admin/live-session/upload-endpoint/content-type';
	}

	if (isGCP && filename.startsWith('http')) {
		// TODO - check once we have a GCP CDN that this is still necessesary
		const uri = new URL(filename);
		const parts = uri.pathname.split('/');

		// remove the leading / and the bucket name component of the path
		const noBucketname = parts.slice(2);
		filename = noBucketname.join('/');
	} else if (!isGCP && filename.startsWith('http')) {
		const uri = new URL(filename);
		filename = uri.pathname.substring(1); // Get path without leading /
	}

	return Put(url, token, { filename, contentType }, false, true, undefined, headers)
		.then(async res => {
			if (res.ok) {
				return await res.json();
			}
			const body = await res?.json?.();
			throw new Error(body?.message || res?.statusText || url);
		});
};

export async function UploadFileToProvider(
	endpoint: {
		url: string,
		fields: { [key: string]: string },
		cdnHost?: string
	},
	file: File | Blob,
	progress?: (uploadedBytes: number) => void,
	filename?: string,
	returnFileHttpRequest?: (request: XMLHttpRequest) => unknown
): Promise<string> {
	const { url, fields: data, cdnHost } = endpoint;
	const formData = new FormData();

	// AWS requires extra signing params in the form
	if (!isGCP && data) {
		for (const key in data) {
			if (key !== 'key') {
				formData.append(key, data[key]);
			}
		}
	}

	formData.append('file', file, filename);

	return new Promise((resolve, reject) => {
		const request = new XMLHttpRequest();

		returnFileHttpRequest?.(request);
		request.open(isGCP ? 'PUT' : 'POST', url);

		if (isGCP) {
			request.setRequestHeader('Content-Type', 'application/octet-stream');
		}

		request.upload.onprogress = (e: ProgressEvent<EventTarget>) => {
			const uploaded = e.loaded;
			progress?.(uploaded);
		};

		request.onerror = (e) => {
			reject(e);
		};

		request.onload = () => {
			if (isGCP) {
				const finalPath = new URL(url);
				resolve(`${finalPath.origin}${finalPath.pathname}`);
			} else {
				if (cdnHost) {
					resolve(`${cdnHost}/${data?.Key}`);
				}
				resolve(`${url}/${data?.Key}`);
			}
		};

		if (isGCP) {
			request.send(file);
		} else {
			request.send(formData);
		}
	});
}