import './utils/custom-history-listener';
import './wdyr';
import 'ts-replace-all';
import './utils/datadog';
// polyfill for requestIdleCallback for safari which is 7 years behind spec (as of 2024)
import 'requestidlecallback';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store/main';
import { Provider } from 'react-redux';
import { ModalProvider } from 'react-modal-hook';

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store as any}>
			<ModalProvider>
				<App />
			</ModalProvider>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();