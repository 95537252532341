type Props = {
	display?: boolean;
	children: JSX.Element | JSX.Element[];
};


export function OptionalComponent({ display, children }: Props): JSX.Element | null {

	if (display) {
		return <>{children}</>;
	}

	return null;
}