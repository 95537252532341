import { useMemo, useContext, useState, useRef, useEffect } from "react";

import { convertSecondsToHMS } from "../../../../../../utils/utils";
import { VideoStateContext } from "../../session-stream-provider";
import { VideoPlayerType } from "../types";
import { useGetSession } from "hooks/session.hooks";
import useLive from "utils/use-live";

const LiveIndicator: React.FC = () => {
	const session = useGetSession();
	const { state: { playbackUrl } } = useContext(VideoStateContext);

	const { state: { streamStartTime, playerType } } = useContext(VideoStateContext);
	const [sec, setSec] = useState(0);
	const timeInterval = useRef<NodeJS.Timeout | null>(null);

	// Only relevant for eCDN, which uses the HLS player
	const [broadcastIsLive] = useLive(playbackUrl, session || undefined);

	useEffect(() => {
		// start time not guaranteed to update every second, so we need to update the current time every second to keep it rendering
		timeInterval.current = setInterval(() => {
			setSec(Date.now());
		}, 1000);

		return () => {
			if (timeInterval.current) {
				clearInterval(timeInterval.current);
			}
		};
	}, []);

	return useMemo(() => {
		if (playerType === VideoPlayerType.ivs || broadcastIsLive) {
			return (
				<div className="live-indicator">
					<span className="live-dot" />
					<span>LIVE {(!!streamStartTime && sec > streamStartTime) && <span id="current-second">{convertSecondsToHMS((sec - streamStartTime) / 1000, true)}</span>}</span>
				</div>
			);
		}

		return null;
	}, [streamStartTime, playerType, sec, broadcastIsLive]);
};

export default LiveIndicator;