import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { Session } from '../../../types/working-model';

interface Props {
	session: Session;
}

export default function SessionDocumentHead({ session }: Props): JSX.Element {
	useEffect(() => {
		const custom = document.createElement('style');
		document.body.appendChild(custom);
		//if there is custom css
		if (session.custom_css || session.custom_css_compiled) {
			//manually fill the custom css tag from the bottom of the <body> tag
			custom.innerHTML =
				(session.custom_css_compiled)
					? session.custom_css_compiled
					: session.custom_css as string;
		}

		return () => {
			document.body.removeChild(custom);
		};
	}, [
		session.custom_css,
		session.custom_css_compiled
	]);

	return (
		<Helmet>
		</Helmet>
	);
}