import React, { Fragment, useRef } from "react";
import classNames from "classnames";
import { useParams } from "react-router";

import { QuestionType, SurveyQuestion, SurveyQuestionOption, SurveyQuestionType, SurveyType } from "../../../../../../../types/working-model";
import Icon, { COLORS, ICONS } from "../../../../../../general-ui/icon";
import { RadioInput } from "../../../../../../general-ui/radio-group/radio";
import TextInput, { Validation } from "../../../../../../general-ui/text-input/text";
import { ParamsProps } from "../../../../../../live-event/live-event";
import { useAddOption, useSetOptionLabel } from "../hooks/engage.hooks";
import { ErrorsTypes } from "../types/engage";
import { onCheckCorrectHandler, removeOptionHandler } from "../utils/engage-create.utils";
import StaggerChildren from "../../../../../../general-ui/animated/stagger-children";

interface CreateEngageOptionsProps {
	workingQuestion: SurveyQuestion | null;
	isQuiz: boolean;
	validationErrors: { [p: string]: string } | null;
	clearErrors: (key: (ErrorsTypes | null)) => void;
	setWorkingQuestion: React.Dispatch<React.SetStateAction<SurveyQuestion | null>>;
	canFocus: boolean;
	survey_type: SurveyType;
}

const optionLabelEmpty = (option: SurveyQuestionOption, language: string) => {
	return !((option.label?.[language] as string)?.trim() ?? option.label.base?.trim());
};
export default function CreateEngageOptions({
	workingQuestion,
	isQuiz,
	validationErrors,
	clearErrors,
	setWorkingQuestion,
	canFocus,
	survey_type
}: CreateEngageOptionsProps): JSX.Element | null {
	const { language } = useParams<ParamsProps>();

	const inputRefs = useRef<Record<number, HTMLInputElement | null>>({});

	const addOption = useAddOption({ setWorkingQuestion, clearErrors });
	const removeOption = removeOptionHandler(setWorkingQuestion);
	const setOptionLabel = useSetOptionLabel({ clearErrors, setWorkingQuestion });
	const onCheckCorrect = onCheckCorrectHandler({
		workingQuestion,
		setWorkingQuestion,
		clearErrors,
	});

	const handleEnterKey = async (value: string) => {
		const options = workingQuestion?.options || [];

		await addOption();

		if (inputRefs.current[options.length]) {
			inputRefs.current[options.length]?.focus();
		}
	};

	const handleAddOptionButton = async () => {
		const options = workingQuestion?.options || [];

		await addOption();

		if (inputRefs.current[options.length]) {
			inputRefs.current[options.length]?.focus();
		}
	};

	const handleRemoveOption = (surveyQnOption: number) => {
		removeOption(surveyQnOption);

		// re-validate
		clearErrors(ErrorsTypes.QuestionOptionText);
	};

	if (!workingQuestion || workingQuestion.question_type === SurveyQuestionType.text) {
		return null;
	}

	return (
		<>
			<StaggerChildren className="e-engage-card-field-list options">
				{workingQuestion.options.map((option, index) => {
					const isEmptyOption = !option.label.base?.trim() && validationErrors?.[ErrorsTypes.QuestionOptionText];
					const notSelected = isQuiz && validationErrors?.[ErrorsTypes.QuizCorrectOption];

					return (
						<Fragment key={option.survey_question_option}>
							<div
								className={classNames('e-engage-card-option', { 'quiz-option': isQuiz })}
								key={option.survey_question_option}
							>
								{isQuiz && <RadioInput
									ariaLabel={option.label.base}
									onClick={() => onCheckCorrect(option)}
									selected={!!option.is_correct}
								/>}
								<TextInput
									ref={ref => inputRefs.current[index] = ref}
									id={option.label.base}
									label={`Option ${index + 1}`}
									placeholder="Type answer"
									defaultValue={option.label?.[language] as string ?? option.label.base}
									valid={(isEmptyOption || notSelected) ? Validation.error : Validation.normal}
									onChange={setOptionLabel(index)}
									onEnterKey={(value) => handleEnterKey(value)}
									// question should autofocus if the question has a name, this is the last option and it's empty
									// Should only autofocus if the survey is a quiz because quizzes will not have the dropdown.
									autoFocus={
										canFocus &&
										optionLabelEmpty(option, language) &&
										survey_type === SurveyType.quiz &&
										index === workingQuestion.options.length - 1
									}
								/>
								<button className="no-padding no-style" onClick={() => handleRemoveOption(option.survey_question_option)}>
									<Icon name={ICONS.CLOSE} size={16} color={COLORS.MED_LIGHT_GRAY} />
								</button>
							</div>
							{(isEmptyOption || notSelected) && (
								<span className="session-panel-field-error admin-typography-label-2">
									{validationErrors?.[ErrorsTypes.QuestionOptionText]}
									{validationErrors?.[ErrorsTypes.QuizCorrectOption]}
								</span>
							)}
						</Fragment>
					);
				})}
			</StaggerChildren>
			<button
				className="e-engage-card-add-option-btn text-only-primary no-padding no-style lemonade"
				onClick={handleAddOptionButton}
			>
				+ Add Option
			</button>
		</>
	);
}