import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Icon, { COLORS, ICONS } from '@general-ui/icon';
import { Tooltip } from '@general-ui/tooltip/tooltip';
import { setRegistrationFeature } from '../../../../../../../../store/actions/admin/create-event';
import { useTypedSelector } from '../../../../../../../../store/reducers/use-typed-selector';
import { RegistrationStepType } from '../../../../../../../../types/working-model';
import { showAlert } from '../../../../../../../general-ui/alert/alert-service';
import Switch from '../../../../../../../general-ui/switch/switch';
import PanelBody from '../../panel-body';
import LimitRegistrationEmails from '../../registration/limit-registration-emails';

const EmailConfirmation: React.FC = () => {
	const workingEvent = useTypedSelector(state => state.CreateEventReducer?.workingEvent);

	const dispatch = useDispatch();

	const registration_settings = workingEvent?.registration_settings;
	const ticketingOn = workingEvent?.registration_steps?.find(step => step.type === RegistrationStepType.ticketing)?.isOn;

	const [emailConfirmation, setEmailConfirmation] = useState(registration_settings?.registrationConfirmation);

	function handleFeatureChange(value: string, on: boolean) {
		// Send Email Confirmation is turned on whenever registrationConfirmation is toggled on.
		if (value === 'registrationConfirmation') {
			if (on) {
				setEmailConfirmation(true);
				dispatch(setRegistrationFeature('sendEmailConfirmation', on));
			} else {
				setEmailConfirmation(false);
			}
		}

		// Don't allow toggling send email confirmation off when email Confirmation is on or if ticketing is on
		if (value === 'sendEmailConfirmation' && !on && (emailConfirmation || ticketingOn)) {
			showAlert({
				message: 'Emails are required for other registration functions currently in use.',
				duration: 3000,
				type: 'error',
			});

			return;
		}

		dispatch(setRegistrationFeature(value, on));
	}

	return (
		<div className="settings-panel-container">
			<PanelBody>
				<div className="settings-card">
					<label>
						Send confirmation
					</label>
					<Switch
						value="sendEmailConfirmation"
						on={registration_settings?.sendEmailConfirmation ?? false}
						onClick={handleFeatureChange}
						disabled={registration_settings?.registrationConfirmation}
					/>
				</div>

				<div className="settings-card">
					<label>
						Require confirmation
					</label>
					<Switch
						value="registrationConfirmation"
						on={registration_settings?.registrationConfirmation ?? false}
						onClick={handleFeatureChange}
					/>
				</div>

				<div className="settings-card">
					<label>
						Suppress duplicates
						<Tooltip tooltip="When disabled, registrants will receive a new confirmation email each time they submit the registration form.">
							<Icon name={ICONS.INFO_OUTLINE} size={14} color={COLORS.CYAN} />
						</Tooltip>
					</label>
					<Switch
						value="skipEmailOnDuplicateRegistration"
						on={registration_settings?.skipEmailOnDuplicateRegistration ?? true}
						onClick={handleFeatureChange}
						disabled={!registration_settings?.sendEmailConfirmation}
					/>
				</div>

				<LimitRegistrationEmails />
			</PanelBody>
		</div>
	);
};

export default EmailConfirmation;
