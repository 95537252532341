import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ESort } from "../../../../../../../../connection/sessions-panel/types";
import { updatePageModuleAndSave } from "../../../../../../../../store/actions/admin/create-event/session";
import { useAppDispatch, useTypedSelector } from "../../../../../../../../store/reducers/use-typed-selector";
import { Document, PageModule, PageModuleGroupModules, PageModuleType, SessionPanelLayoutsTypes } from "../../../../../../../../types/working-model";
import { useGetAdminUrl } from "../../../../../../../../utils/admin-routing-utils";
import { getSessionPanelRouteState } from "../../../../../../../../utils/path-utils";
import StaggerChildren from "../../../../../../../general-ui/animated/stagger-children";
import SmallSelect from "../../../../../../../general-ui/select/small-select";
import TextInput from "../../../../../../../general-ui/text-input/text";
import DocumentCard from "../../components/document-card";
import SessionPanelAddFooter from "../../components/session-panel-add-footer";
import { customResourcesItems, extrasResourcesItems } from "../../empty-state-panel/constants/empty-panel";
import { usePageModule } from "../../hooks/panel.hooks";
import { SessionPanelMap } from "../../session-panel-route-map";
import classNames from "classnames";

const sortOptions = [
	{ label: 'Newest', value: ESort.dateDesc },
	{ label: 'Oldest', value: ESort.date },
	{ label: 'Name A-Z', value: ESort.name },
	{ label: 'Name Z-A', value: ESort.nameDesc },
];

const documentSearch = (term: string, documents: Document[]): Document[] => {
	return [...documents].filter(document => {
		// search across the entire stringified JSON
		// as a naive way of doing multi-language search
		const terms = JSON.stringify(document?.display_name).toLowerCase() + JSON.stringify(document?.display_name).toLowerCase();
		return terms.includes(term);
	});
};

const sortDocuments = (sort: ESort | undefined, documents: Document[]): Document[] => {
	return [...documents].sort((a: Document, b: Document) => {
		// many products have the same creation date
		// use the product id as a tiebreaker
		const aId = (a.document as number);
		const bId = (b.document as number);
		switch (sort) {
			case ESort.date: {
				return (a.created_at.toString() + aId) > (b.created_at.toString() + bId) ? -1 : 1;
			}
			case ESort.dateDesc: {
				return (a.created_at.toString() + aId) < (b.created_at.toString() + bId) ? -1 : 1;
			}
			case ESort.name: {
				return a.display_name.base.toLowerCase() < b.display_name.base.toLowerCase() ? -1 : 1;
			}
			case ESort.nameDesc: {
				return a.display_name.base.toLowerCase() > b.display_name.base.toLowerCase() ? -1 : 1;
			}
			default: {
				return (a.document as number) < (b.document as number) ? -1 : 1;
			}
		}
	});
};

const ActiveResourcesList: React.FC<unknown> = () => {
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);
	const [documents, setDocuments] = useState<Document[]>([]);
	const [sortOrder, setSortOrder] = useState<ESort | undefined>(ESort.dateDesc);
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [, setHasScrolledToBottom] = useState(true);
	const [addButtonIsOpen, setAddButtonIsOpen] = useState(false);

	const searchTermRef = useRef<string>();
	const containerRef = useRef<HTMLDivElement | null>(null);
	const scrollRef = useRef<HTMLDivElement | null>(null);
	const moduleGroupingRef = useRef<PageModuleGroupModules[] | undefined>(workingSession?.module_grouping);
	const pageModulesRef = useRef<PageModule[] | undefined>(workingSession?.modules);

	const { customPath } = useParams<{ customPath?: string }>();
	const pageModule = usePageModule();
	const history = useHistory();
	const getAdmin = useGetAdminUrl();
	const dispatch = useAppDispatch();

	const { isExtrasCustom } = getSessionPanelRouteState(location.pathname);
	const extrasItems = isExtrasCustom ? customResourcesItems : extrasResourcesItems(!!customPath);
	const moduleGrouping = workingSession?.module_grouping;
	const pageModules = workingSession?.modules;

	useEffect(() => {
		if (moduleGrouping) {
			moduleGroupingRef.current = moduleGrouping;
		}

		if (pageModules) {
			pageModulesRef.current = pageModules;
		}
	}, [moduleGrouping, pageModules]);

	const getExistingDocuments = useCallback(() => {
		if (pageModule?.modules) {
			return pageModule.modules as Document[];
		}

		return [];
	}, [pageModule]);

	const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		searchTermRef.current = e.target.value;
	}, []);

	const handleSearch = useCallback(() => {
		// not doing typeahead search, user has to press enter or blur field
		// so we aren't updating the state every keystroke, just holding the value in a ref
		setSearchTerm(searchTermRef.current ?? '');
	}, []);

	useEffect(() => {
		const documents = getExistingDocuments();
		const filteredDocuments = documentSearch(searchTerm, documents);
		const sortedDocuments = sortDocuments(sortOrder, filteredDocuments);
		setDocuments(sortedDocuments);
	}, [searchTerm, getExistingDocuments, sortOrder]);

	useEffect(() => {
		setDocuments(getExistingDocuments());
	}, [getExistingDocuments]);

	const handleSort = useCallback((value: string) => {
		if (value) {
			setSortOrder(value as ESort);
		} else {
			setSortOrder(undefined);
		}
	}, []);

	const handleEdit = useCallback((document: Document) => {
		if (!pageModule?.id) return;

		history.push(getAdmin({
			path: SessionPanelMap[SessionPanelLayoutsTypes.CreateExtraResource],
			customPath,
			page_module: pageModule?.id,
		}), { documentToEdit: document, panelTitle: 'Edit Resource' });
	}, [customPath, getAdmin, history, pageModule?.id]);

	const handleDelete = useCallback((document: Document) => {
		if (!pageModule) return;

		dispatch(updatePageModuleAndSave({
			...pageModule,
			content_modules: pageModule.content_modules?.filter(module => module !== document.document),
			modules: pageModule.modules?.filter((module: Document) => module.document !== document.document),
			type: PageModuleType.documents
		}));
	}, [dispatch, pageModule]);

	return (
		<div className={"session-panel resources-list"} ref={containerRef}>
			<div className="session-panel-header-options">
				<TextInput
					defaultValue={''}
					onChange={handleSearchChange}
					onBlur={handleSearch}
					onEnterKey={handleSearch}
					placeholder="Search..."
					className="small"
				/>
				<SmallSelect
					options={sortOptions}
					selected={sortOrder || ''}
					onChange={handleSort}
				/>
			</div>

			{documents.length ? (
				<StaggerChildren
					onScrolledToBottom={() => setHasScrolledToBottom(true)}
					onScrolledUpFromBottom={() => setHasScrolledToBottom(false)}
					ref={scrollRef}
					className={classNames(
						"session-panel-content added-items",
						{
							'overflow-y-visible': documents.length === 1 && addButtonIsOpen,
						}
					)}
					enableFades={true}
					footer={
						(
							<SessionPanelAddFooter
								items={extrasItems}
								scrollRef={scrollRef}
								onMenuToggle={setAddButtonIsOpen}
								dropdownPosition="top"
							/>
						)
					}
				>
					{documents.map((document: Document) => (
						<DocumentCard
							key={document.document}
							document={document}
							handleEdit={handleEdit}
							handleDelete={handleDelete}
						/>
					))}
				</StaggerChildren>
			) : (
				<div className="session-panel-no-results"><section>No results</section></div>
			)}
		</div>
	);
};

export default ActiveResourcesList;