import classNames from "classnames";
import { useRef, useState } from "react";
import { ESort } from "../../../../../../../connection/sessions-panel/types";
import { OptionalComponent } from "../../../../../../general-ui/animated/optional-component";
import Icon, { ICONS } from "../../../../../../general-ui/icon";
import SmallSelect from "../../../../../../general-ui/select/small-select";
import TextInput from "../../../../../../general-ui/text-input/text";

type PanelSearchProps = {
	sortOptions: { label: string; value: ESort }[];
	sortOrder: ESort;
	handleSort: (value: ESort) => void;
	filterOptions: { label: string; value: string }[];
	userFilter: string;
	handleFilter: (value: string) => void;
	handleSearch: (term: string) => void;
}
const PanelSearch: React.FC<PanelSearchProps> = (props) => {
	const [optionsOpen, setOptionsOpen] = useState(false);
	const searchTermRef = useRef('');

	const handleSearchChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
		searchTermRef.current = value;
	};

	return (
		<div className={classNames("session-panel-header-options openable", { open: optionsOpen })}>
			<div className="grid search-row">
				<TextInput
					defaultValue={''}
					onChange={handleSearchChange}
					onBlur={() => props.handleSearch(searchTermRef.current)}
					onEnterKey={() => props.handleSearch(searchTermRef.current)}
					placeholder="Search..."
					className="small search-area"
					onClear={() => {
						searchTermRef.current = '';
						props.handleSearch(searchTermRef.current);
					}}
				/>
				<button onClick={() => setOptionsOpen(prev => !prev)} className="round no-style no-padding no-margin button-area">
					<Icon name={ICONS.FILTER} size={12} color="" />
				</button>
			</div>

			<OptionalComponent display={optionsOpen} usingHeight={32 + 8}>
				<div className="grid col-2">
					<SmallSelect
						options={props.sortOptions}
						selected={props.sortOrder || ''}
						onChange={props.handleSort}
						className="sort-area"
					/>
					<SmallSelect
						options={props.filterOptions}
						selected={props.userFilter}
						onChange={props.handleFilter}
						className="filter-area"
					/>
				</div>
			</OptionalComponent>
		</div>
	);
};

export default PanelSearch;