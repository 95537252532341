import {
	Document,
	ThumbnailStatus,
	BLAdmin,
} from '../types/working-model';
import { Post, Put } from './helpers';
import { extractFileType, getUploadFilename } from '../utils/utils';
import { postDocument } from './content-connection';
import {
	FILE_NUMBER_MAP,
	FILE_TYPES,
	THUMBNAIL_TYPES,
} from '../store/utils/content-utils';
import { UploadFileToProvider as UploadMulticloud } from './multicloud/upload';
const isGCP = process.env.REACT_APP_CLOUD_PROVIDER === 'gcp';

export const GetUploadEndpoint = async (
	token: string,
	filename: string,
	isThumb = false,
	isLiveSession = false,
	contentType = '',
	headers = {},
): Promise<any> => {
	let url = '/v3/admin/channel/upload-endpoint';
	if (isLiveSession) {
		url = '/v3/admin/live-session/upload-endpoint';
	}

	return Put(url, token, { filename, isThumb, contentType }, false, true, undefined, headers)
		.then(async res => {
			if (res.ok) {
				return await res.json();
			}
			const body = await res?.json?.();
			throw new Error(body?.message || res?.statusText || url);
		});
};

export const SetItemContentType = async (
	token: string,
	filename: string,
	contentType: string,
	isLiveSession = false,
	headers = {}
) => {
	let url = '/v3/admin/channel/upload-endpoint/content-type';
	if (isLiveSession) {
		url = '/v3/admin/live-session/upload-endpoint/content-type';
	}

	return Put(url, token, { filename, contentType }, false, true, undefined, headers)
		.then(async res => {
			if (res.ok) {
				return await res.json();
			}
			const body = await res?.json?.();
			throw new Error(body?.message || res?.statusText || url);
		});
};

export const UploadFileToProvider = UploadMulticloud;

/**
 * @deprecated
 * All uploaded items are public by default
 */
export const MakeItemPublic = async (token: string, filename: string, isLiveSession = false): Promise<any> => {
	let url = '/v3/admin/channel/upload-endpoint/make-public';
	if (isLiveSession) {
		url = '/v3/admin/live-session/upload-endpoint/make-public';
	}

	return Put(url, token, { filename }, false, true)
		.then(res => {
			if (res.ok) {
				return res.ok;
			}

			throw new Error(`${url} didn't work`);
		});
};

export const UploadFileNonAdmin = async (token: string, file: File, location: string, onProgress?: (uploadedBytes: number) => void): Promise<string> => {
	const endpoint = await GetUploadEndpoint(token, file.name, false, true);
	const handleUploadProgress = onProgress || (() => null);

	//upload to S3 - handle upload progress in separate function
	return await UploadFileToProvider(endpoint, file, handleUploadProgress, file.name);
};

const getEndpoint = (url: string, key: string) => {
	if (isGCP) {
		const _url = new URL(url);
		// GET endpoint is the same as PUT endpoint just with no query params
		return `${_url.origin}${_url.pathname}`;
	} else {
		return url.replace("brandlive-upload.s3-us-west-2.amazonaws.com", "assets.brandlive.com") + '/' + key;
	}
};

export function UploadFile(
	user: BLAdmin,
	token: string,
	file: File,
	onProgress?: (uploadedBytes: number) => void,
	headers = {},
): Promise<string> {
	// eslint-disable-next-line no-async-promise-executor
	return new Promise(async (resolve, reject) => {
		try {
			if (file instanceof File) {
				const filenameParts = file.name.split(".");
				const suffix = filenameParts[filenameParts.length - 1];

				let contentType = file.type;
				if (!file.type) {
					contentType = await getFileContentType(file);
				}

				//if this is an image, we want to pull the dimensions out of the image 
				//and put them into the filename
				if (['image/jpeg', 'image/gif', 'image/png'].includes(file.type)) {
					//read the file out as binary
					const reader = new FileReader();
					reader.onload = () => {
						//when image is finished being read
						if (typeof reader.result === 'string') {

							//create an image element and load in the image in base64
							const image = document.createElement('img');
							image.onload = async () => {
								//image's height and width will be available to us
								//write that into the filename
								const filename = `${Date.now()}_w${image.width}_h${image.height}.${suffix}`;

								try {
									const endpoint = await GetUploadEndpoint(
										token,
										filename,
										undefined,
										undefined,
										contentType,
										headers,
									);
									const finalUrl = await UploadFileToProvider(endpoint, file, onProgress);
									await SetItemContentType(token, endpoint.fields.Key, file.type, undefined, undefined);
									resolve(finalUrl);
								} catch (e) {
									reject(e);
								}
							};
							image.src = reader.result;
						}
					};

					//reads the file
					reader.readAsDataURL(file);
				} else {
					try {
						const filename = file instanceof File ? getUploadFilename(file.name) : `${Date.now()}.jpg`;
						const endpoint = await GetUploadEndpoint(
							token,
							filename,
							undefined,
							undefined,
							contentType,
							headers,
						);
						const finalUrl = await UploadFileToProvider(endpoint, file, onProgress);
						await SetItemContentType(token, endpoint.fields.Key, file.type, undefined, undefined);
						resolve(finalUrl);
					} catch (e) {
						reject(e);
					}
				}
			} else {
				// not a blob, just go ahead and resolve empty string
				resolve('');
			}
		} catch (e) {
			console.error(e);
			reject();
		}
	});
}

export function getFileContentType(file: File): Promise<string> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			if (typeof reader.result === 'string') {
				// https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL
				// readAsDataURL returns a string formatted as such "data:*/*;base64," where */* represents the content type
				// for example, "data:application/pdf;base64,"
				// so we want to extract everything between ":" and ";" to get that value
				const contentType = reader.result.substring(
					reader.result.indexOf(":") + 1,
					reader.result.lastIndexOf(";")
				);
				if (contentType) {
					resolve(contentType);
				}
			}
			resolve('');
		};
		reader.onerror = error => reject(error);
	});
}

export function UploadAndConvertDocument(
	user: BLAdmin,
	token: string,
	file: File,
	onProgress?: (uploadedBytes: number) => void,
	headers?: Record<string, string>,
	filename?: string,
	thumbnail?: string
): Promise<Document> {
	// eslint-disable-next-line no-async-promise-executor
	return new Promise(async (resolve, reject) => {
		try {
			const fileToUpload = new File(
				[file],
				file.name
					.replace(/[`~!@#$%^&*|+=?;:'",<>{}[\]\\/]/gi, '')
					.replace(/\s/g, '_')
			);
			const fileName = fileToUpload.name;

			let contentType = fileToUpload.type;
			if (!fileToUpload.type) {
				contentType = await getFileContentType(file);
			}

			const endpoint = await GetUploadEndpoint(token, fileName, undefined, undefined, contentType, headers);
			const splitKey = endpoint.fields.Key.split('/');
			const prefix = splitKey.slice(0, splitKey.length - 1).join('/');
			const finalUrl = await UploadFileToProvider(endpoint, file, onProgress);
			await SetItemContentType(token, endpoint.fields.Key, file.type, undefined, undefined);

			const fileExt = extractFileType(file.name);
			const location = finalUrl;

			const isImage = FILE_NUMBER_MAP[fileExt] === FILE_TYPES.IMAGES;
			const isThumbnailType = THUMBNAIL_TYPES.has(fileExt);

			const document = await postDocument(
				{
					original_name: file.name,
					location: location,
					thumbnail: thumbnail ?? isImage ? location : null,
					thumbnail_status: thumbnail ? ThumbnailStatus.done : isThumbnailType
						? ThumbnailStatus.generating
						: ThumbnailStatus.never,
					filesize: file.size,
					display_name: { base: filename ?? file.name, changed: 'true' },
					generated_thumbnail: null
				},
				token
			);

			if (isThumbnailType) {
				const body = {
					document: document.document,
					ext: fileExt,
					prefix: prefix,
					filename: fileName,
				};
				await Post(
					'/v3/admin/channel/documents/convert',
					token,
					body,
					true
				);
			}
			resolve(document);
		} catch (e) {
			console.error(e);
			reject();
		}
	});
}
