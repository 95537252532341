import { useState } from "react";
import { batch } from "react-redux";

import { CreateNewColorPack, SaveColor } from "../../../../../../connection/colors";
import { addNewColorPack, saveColorPack, setSelectedColor } from "../../../../../../store/actions/admin/create-event";
import { useAppDispatch, useTypedSelector } from "../../../../../../store/reducers/use-typed-selector";
import { Color, EColorPackchannels } from "../../../../../../types/working-model";
import { showAlert } from "../../../../../general-ui/alert/alert-service";
import ColorEditor from "../../../settings/design/colors/color-editor";

import './panel-theme-color-selector.scss';

const PanelThemeColorSelector = () => {
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const token = useTypedSelector(state => state.AuthReducer.token);

	const colorPalette = workingEvent?.settings?.design?.colors;
	const colors = colorPalette?.colors;

	const [savingOnLoad, setSavingOnLoad] = useState(true);

	const dispatch = useAppDispatch();

	const handleChange = async (
		field: 'accentColor' | 'secondaryAccentColor',
		updatedColor: [string, number]
	) => {
		if (!token) return;
		if (!colorPalette) return;
		if (savingOnLoad) {
			setSavingOnLoad(false);
			return; // this will prevent the initial load from triggering a save
		}

		try {
			let updatedColorPalette: Color = {
				...colorPalette,
				colors: {
					...colorPalette.colors,
					[field]: updatedColor,
				}
			};

			// if editing a bse color, create a new color pack
			if (colorPalette.channel === EColorPackchannels.base_colors) {
				// create new palette and apply to event
				updatedColorPalette = {
					...updatedColorPalette,
					title: `${colorPalette.title} Copy - ${workingEvent?.name}`,
				};
				const newColor = await CreateNewColorPack(token, updatedColorPalette);
				batch(() => {
					dispatch(setSelectedColor(newColor)); // sets and saves the new color pack onto the event
					dispatch(addNewColorPack(newColor)); // adds the color pack to redux color pack list
				});
				return;
			}

			// save the updated color pack
			await SaveColor(token, updatedColorPalette);
			dispatch(saveColorPack(updatedColorPalette));
		} catch (e) {
			console.error(e);
			let message = 'Something went wrong when saving color pack. Please try again.';
			if (typeof e === 'string' && e.trim().length && e.toLowerCase() !== 'bad request') {
				message = e;
			}
			showAlert({
				type: 'error',
				message: message
			});
		}
	};

	const renderTitle = (title: string) => () => {
		return (
			<div className="panel-color-picker-label-wrapper">
				{title}
			</div>
		);
	};

	if (!workingEvent || !colors) return null;
	return (
		<div className="panel-color-picker">
			<ColorEditor
				title="Accent Color"
				renderTitle={renderTitle("Accent Color")}
				color={colors.accentColor}
				onChange={(color: string, opacity: number) => handleChange('accentColor', [color, opacity])}
			/>
			<ColorEditor
				title="Secondary Accent Color"
				renderTitle={renderTitle("Secondary Accent Color")}
				color={colors.secondaryAccentColor}
				onChange={(color: string, opacity: number) => handleChange('secondaryAccentColor', [color, opacity])}
			/>
		</div>
	);
};

export default PanelThemeColorSelector;
