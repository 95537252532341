import { Suspense, lazy } from "react";

const _Icons = lazy(() => import('./icon-wrapped'));

export const ICONS = {
	ADD: "add",
	ADD_VARIANT: "addVariant",
	ADD_CLOSE_CIRCLE: "addCloseCircle",
	ANONYMOUS: "anonymous",
	ANONYMOUS_DISABLED: "anonymousDisabled",
	API_KEY: "apiKey",
	ARROW_UP_FULL: "arrowUpFull",
	ARROW_UP_THIN: "arrowUpThin",
	ARROW_UP: "arrowUp",
	ARROW_DOWN: "arrowDown",
	ARROW_LEFT: "arrowLeft",
	ARROW_LEFT_SHADOW: "arrowLeftShadow",
	ARROW_RIGHT: "arrowRight",
	ATTACHMENT: "attachment",
	KEYBOARD_ARROW_UP: "keyboardArrowUp",
	KEYBOARD_ARROW_DOWN: "keyboardArrowDown",
	CAMERA_ON: "camera",
	CAMERA_OFF: "cameraOff",
	CHAT_STYLE_CONVERSATIONAL: "chatStyleConversational",
	CHAT_STYLE_DEFAULT: "chatStyleDefault",
	CHECKMARK: "checkmark",
	CHECK: "check",
	CHECKBOX: "checkbox",
	CHECKBOX_CHECKED: "checkboxChecked",
	CHECKBOX_CHECKED_PARTIAL: "checkboxCheckedPartial",
	COPY: "copy",
	CLOSE: "close",
	DISSOLVE: "dissolve",
	DOWNLOAD: 'download',
	DOUBLE_DONE: 'doubleDone',
	DOWNLOAD_SPEED: "downloadSpeed",
	DROPBOX: "dropbox",
	EDIT: "edit",
	EMOJI: "emoji",
	FOLDER: "folder",
	FULLSCREEN: "fullscreen",
	MINIMIZE_SCREEN: "minimizeScreen",
	INFO_CIRCLE: "infoCircle",
	INFO_OUTLINE: "infoOutline", //if this string changes, change it in the API in the "GetBasePageModuleDbGrouping" func
	INFO_OUTLINE_STRAIGHT: "infoOutlineStraight",
	INTERNET_SPEED: "internetSpeed",
	KEYBOARD_ARROW_LEFT: "keyboardArrowLeft",
	KEYBOARD_ARROW_RIGHT: "keyboardArrowRight",
	MAGIC_LINK: "magicLink",
	MESSAGE: "message",
	MESSAGES: "messages",
	MICROPHONE_ON: "microphoneOn",
	MICROPHONE_OFF: "microphoneOff",
	MODERATOR: "moderator",
	LINK: "link",
	LIVE: "live",
	LOG_OUT: "logOut",
	PEOPLE: "people",
	PHOTO: "photo",
	PHOTO_ALT: "photoAlt",
	POWER: "power",
	RECYCLE: "recycle",
	REPLACE: "replace",
	REACTION_ADD: "reactionAdd",
	REFRESH: "refresh",
	REFRESH_CIRCLE: "refreshCircle",
	REORDER: "reorder",
	SEARCH: "search",
	SEND: "send",
	SEND_FILL_RIGHT: "sendFillRight",
	SETTINGS: "settings",
	SETTINGS_TRANSPARENT: "settingsTransparent",
	SHARE_SCREEN: "shareScreen",
	SOUND: "sound",
	THREE_DOTS_VERTICAL: "threeDotsVertical",
	THREE_DOTS_HORIZONTAL: "threeDotsHorizontal",
	THREE_LINES_TRIANGLE: "threeLinesTriangle",
	UNLINK: "unlink",
	VIEWERS_EYE: "viewersEye",
	VIEWERS_EYE_OFF: "viewersEyeOff",
	VIRTUAL_BACKGROUND: "virtualBackground",
	BOLD: 'bold',
	ITALIC: 'italic',
	UNDERLINE: 'underline',
	UNDO: 'undo',
	REDO: 'redo',
	OL: 'ol',
	UL: 'ul',
	LINE_SPACING: 'lineSpacing',
	ALIGN_RIGHT: 'align-right',
	ALIGN_CENTER: 'align-center',
	ALIGN_LEFT: 'align-left',
	TRASH: 'trash',
	FONT: 'font',
	SHARE: 'share',
	QUESTION: 'question',
	QUESTION_OUTLINED: 'question-outlined',
	QUESTIONS_AND_POSTS: 'questions-and-posts',
	TITLES_ON: 'titlesOn',
	TITLES_OFF: 'titlesOff',
	INVITE: 'invite',
	ADD_INVITE: 'addInvite',
	TAB_CHAT: 'tabChat',
	TAB_FEEDS: 'tabFeeds',
	TAB_MEDIA: 'tabMedia',
	TAB_NOTES: 'tabNotes',
	CHECK_CIRCLE: 'checkCircle',
	CHECK_CIRCLE_FILLED: 'checkCircleFilled',
	CHECK_CIRCLE_OUTLINE: 'checkCircleOutline',
	REFRESH_TRANSPARENT: 'refreshTransparent',
	SETTINGS_SLIDERS: 'settingsSliders',
	COMPUTER: 'computer',
	PHONE: 'phone',
	EMAIL: 'email',
	RADIO_ON: 'radioOn',
	PLAY: 'play',
	PLAY_ALT: 'play-alt',
	PLAY_SHADOW: 'play-shadow',
	PAUSE: 'pause',
	PAUSE_SHADOW: 'pause-shadow',
	CLOCK: 'clock',
	CLOCK_ALT: 'clock-alt',
	STOP_LIVE: 'stop-live',
	WINDOW: 'window',
	WALLPAPER: 'wallpaper',
	DOCUMENT: 'document',
	DOCUMENTS: 'documents',
	MOVE: 'move',
	UPLOAD: 'upload',
	ZOOM_IN: 'zoomIn',
	ZOOM_OUT: 'zoomOut',
	CAMERA_OUTLINE: 'cameraOutline',
	LITTLE_ARROW_UP: 'littleArrowUp',
	LITTLE_ARROW_DOWN: 'littleArrowDown',
	LAYOUT: 'layout',
	LAYOUT_GRID: 'layoutGrid',
	LAYOUT_GRID_EMPTY: 'layoutGridEmpty',
	LAYOUT_LIST: 'layoutList',
	IMAGE: 'image',
	IMAGE_INVERSE: 'imageInverse',
	FONTS: 'fonts',
	PALETTES: 'palettes',
	SQUARE_CHECKBOX_ALL: 'squareCheckboxAll',
	SQUARE_CHECKBOX_SELECTED: 'squareCheckboxSelected',
	SQUARE_CHECKBOX_UNSELECTED: 'squareCheckboxUnselected',
	ADD_PRODUCT: 'addProduct',
	NEW_WINDOW: 'newWindow',
	ADD_CATEGORY: 'addCategory',
	PHONE_SLIM: "phoneSlim",
	TABLET_SLIM: "tabletSlim",
	DESKTOP_SLIM: "desktopSlim",
	DRAG_HANDLE: 'dragHandle',
	REPLY: 'Reply',
	REPLY_FILLED: 'ReplyFilled',
	UNDELETE_FILLED: 'UndeleteFilled',
	DONE_FILLED: 'DoneFilled',
	ARROW_RIGHT_TOP_FILLED: 'ArrowRightTopFilled',
	ARROW_RIGHT_BOTTOM_FILLED: 'ArrowRightBottomFilled',
	MODERATION_FILLED: 'ModerationFilled',
	MODERATION_CROSSED_FILLED: 'ModerationCrossedFilled',
	SHIELD: 'Shield',
	CHAT_COMMENT: 'ChatComment',
	CHAT_HIDE: 'ChatHide',
	DIRECT_CHAT: 'DirectChat',
	QUESTION_FILLED: 'QuestionFilled',
	PRIMARY_TOOLTIP: "PrimaryTooltip",
	CALENDAR: 'Calendar',
	CALENDAR_PLUS: 'CalendarPlus',
	CALENDAR_STARRED: 'calendarStarred',
	SMILEY_FACE: 'smiley-face',
	APPLAUSE: 'applause',
	TWITTER: 'Twitter',
	LINKEDIN: 'LinkedIn',
	WEBSITE: 'Website',
	YOUTUBE: 'YouTube',
	FACEBOOK: 'FaceBook',
	INSTAGRAM: 'Instagram',
	PRODUCT: 'product',
	QUIZ: 'quiz',
	POLL: 'poll',
	POLL_FILLED: 'pollFilled',
	SURVEY: 'survey',
	EVENTS_NAV: 'EventsNav',
	ANALYTICS_NAV: 'AnalyticsNav',
	PEOPLE_NAV: 'PeopleNav',
	CONTENT_NAV: 'ContentNav',
	PRODUCTS_NAV: 'ProductsNav',
	WHATS_NEW: 'WhatsNew',
	SUPPORT: 'Support',
	BELL: 'Bell',
	BELL_EMPTY: 'BellEmpty',
	THEME_PALETTE: 'ThemePalette',
	VERSIONS: 'Versions',
	TRASH_OUTLINE: 'trashOutline',
	PAINTBRUSH: 'Paintbrush',
	ARROW_CIRCLE: 'ArrowCircle',
	DELETE: 'Delete',
	ON: 'On',
	OFF: 'Off',
	CLUB_HOUSE: 'clubHouse',
	OPEN_BOOK: 'openBook',
	PERSON: 'person',
	FILTER_ACTIVE: 'filterActive',
	FILTER_INACTIVE: 'filterInactive',
	DRAG_ARROWS: 'DragArrows',
	PASSWORD: "password",
	ALIGN_CENTER_ALT: "align-center-alt",
	ALIGN_LEFT_ALT: "align-left-alt",
	ALIGN_RIGHT_ALT: "align-right-alt",
	UNDO_ALT: "undo-alt",
	REDO_ALT: "redo-alt",
	ON_DEMAND: "on-demand",
	PAPERCLIP: "paperclip",
	CODE: "code",
	BAR_GRAPH: "bar-graph", //if this string changes, change it in the API in the "GetBasePageModuleDbGrouping" function
	LAYOUT_NO_TABS: 'layoutNoTabs',
	LAYOUT_HORIZONTAL: 'layoutHorizontal',
	LAYOUT_VERTICAL: 'layoutVertical',
	CHECKBOX_BORDERLESS: 'checkboxBorderless',
	SIGNAL: "signal", //if this string changes, change it in the API in the "GetBasePageModuleDbGrouping" function
	IMAGES: "images", //if this string changes, change it in the API in the "GetBasePageModuleDbGrouping" function
	DOCUMENTS_REVERSE: "documentsReverse",
	URL: "Url",
	GIF: 'gif',
	GOOGLE_DRIVE: 'google-drive',
	EMOJI_SMILE: 'emoji-smile',
	LIKE: 'like',
	LIKED: 'liked',
	COMMENT_EMPTY: 'comment-empty',
	CLOUD_DOWNLOAD: 'icon-cloud-download',
	MUTE: 'mute',
	MUTE_SHADOW: 'mute-shadow',
	MUTED: 'muted',
	MUTED_SHADOW: 'muted-shadow',
	SPEAKER_OFF: 'speakerOff',
	YOUTUBE_OUTLINE: 'youtube-outline',
	GLOBE_OUTLINE: 'globe-outline',
	TWITTER_OUTLINE: 'twitter-outline',
	LINKEDIN_OUTLINE: 'linkedin-outline',
	HOME: 'HOME',
	EXPAND: 'EXPAND',
	CONTRACT: 'CONTRACT',
	CALENDAR_ADD: "calendarAdd",
	REGISTRATION: "registration",
	BREAKOUT_ROOMS: "breakoutRooms",
	BUILDING: "building",
	MAP_ARROW: "map-arrow",
	ROUND_HOLLOW_STAR: "round-hollow-star",
	BRIEFCASE: "briefcase",
	STAR: "star",
	STAR_OUTLINE: "starOutline",
	EMAIL_FILLED: "email-filled",
	REJOIN_LIVE: "rejoin-live",
	SHOUTOUTS: "shoutouts",
	HOURGLASS: "hourglass",
	HYPERLINK: "hyperlink",
	CAPTIONS: "captions",
	CAPTIONS_OUTLINE: "captions-outline",
	BOOKMARK: "bookmark",
	BOOKMARK_FILLED: "bookmark-filled",
	IMAGE_CROP: 'image-crop',
	COMMENT_FULL: "comment-full",
	EMPTY_DM: "empty-dm",
	EMPTY_DM_SEARCH: "empty-dm-search",
	NEW_MESSAGE: "new-message",
	FAST_FORWARD: "fast-forward",
	FIRESIDE: "fireside",
	HAND: 'hand',
	HAND_CROSSED: 'hand-crossed',
	HAND_FRONT: 'hand-front',
	HAND_FRONT_OFF: 'hand-front-off',
	HEART_FILLED: 'heart-filled',
	COPY_FILLED: 'copy-filled',
	CAUTION: 'caution',
	THEMES: 'themes',
	TEMPLATES: 'templates',
	TEMPLATES2: 'templates2',
	MAGIC_LINK_SPARKLE: 'magic-link-sparkle',
	EXCLAMATION_MARK: 'exclamation-mark',
	ANGLE_LEFT: 'angle-left',
	ANGLE_RIGHT: 'angle-right',
	LEADERBOARD_NAV: 'leaderboard-nav',
	TROPHY_FILLED: 'trophy-filled',
	GLOBE: 'globe',
	TABLE_FILTER: 'table-filter',
	LINE_GRAPH: 'line-graph',
	PIE_CHART: 'pie-chart',
	DONE: 'done',
	FILTER: 'filter',
	MENU: 'menu',
	MENU_NO_SHADOW: 'menu-no-shadow',
	MENU_VER_CLOSE: 'menu-ver-close',
	MENU_HOR_CLOSE: 'menu-hor-close',
	BACK_TEN: 'back-ten',
	FWD_TEN: 'fwd-ten',
	CHAT_DIALOG: 'chat-dialog',
	CHAT_DIALOG_EXIT: 'chat-dialog-exit',
	SCISSORS: 'scissors',
	SEEK_START: 'seek-start',
	SETTINGS_HD: 'settings-hd',
	SETTINGS_SD: 'settings-sd',
	SETTINGS_4K: 'settings-4k',
	SETTINGS_SHADOW: 'settings-shadow',
	SETTINGS_NO_SHADOW: 'settings-no-shadow',
	TRANSCRIPT: 'transcript',
	CHROMECAST: 'chrome-cast',
	PIP: 'pip',
	PIP_EXIT: 'pip-exit',
	FULLSCREEN_SQUARE: 'fullscreen-square',
	FULLSCREEN_SQUARE_EXIT: 'fullscreen-square-exit',
	DEF_HD: 'hd',
	DEF_SD: 'sd',
	DEF_4K: '4k',
	TIKTOK: 'tiktok',
	LIGHT_MODE: 'light-mode',
	DARK_MODE: 'dark-mode',
	ABOVE_THE_FOLD: 'above-the-fold',
	BELOW_THE_FOLD: 'below-the-fold',
	NO_CHAT: 'no-chat',
	OVERLAY_CHAT: 'overlay-chat',
	SIDE_PANEL_CHAT: 'side-panel-chat',
	MESSAGE_FILLED: 'message-filled',
	TV_WATCH: 'tv-watch',
	RSVP_LOGO: 'rsvp-logo',
	PADLOCK: 'padlock',
	SUGGESTED: 'suggested', // this looks exactly like the youtube logo
	UPVOTE: 'upvote',
	INFO_I: 'info-i',
	EXTERNAL_LINK: 'external-link',
	BAN: 'ban',
	ADD_RAINBOW: 'add-rainbow',
	DOUBLE_ARROW_LEFT: 'double-arrow-left',
	DOUBLE_ARROW_RIGHT: 'double-arrow-right',
	LOCK: 'lock',
	REG_ABOVE_THE_FOLD_L: 'reg-above-the-fold-L',
	REG_ABOVE_THE_FOLD_R: 'reg-above-the-fold-R',
	REG_BELOW_THE_FOLD_L: 'reg-below-the-fold-L',
	REG_BELOW_THE_FOLD_R: 'reg-below-the-fold-R',
	REG_FULL_PAGE_L: 'reg-full-page-L',
	REG_FULL_PAGE_R: 'reg-full-page-R',
	REG_POPUP: 'reg-popup',
	TRANSLATIONS: 'translations',
};

export type IconType = keyof typeof ICONS;
// The actual coloring of the icon svg happens in _colors.scss. By default all of the icons they gave us are defaultGray.
// If we use other colors of the icons I apply those in _colors.scss.
export const COLORS = {
	ACCENT_BLUE: '#3456FF',
	ACCENT_PURPLE: 'accent-purple',
	ALT_GRAY: 'alt-gray',
	AUTOCOMPLETE: 'autocomplete',
	BLACK: "black",
	BLUE: 'blue',
	BORDER_DARK_GRAY: 'border-dark-gray',
	BORDER_GRAY: 'border-gray',
	BORDER_LIGHT_GRAY: 'border-light-gray',
	BORDER_MED_GRAY: 'border-med-gray',
	BRIGHT_GREEN: 'bright-green',
	CHAT_BLACK: 'chat-black',
	CLASSIC_GRAY_TEXT: 'classic-gray-text',
	CYAN_ACTIVE: 'cyan-active',
	CYAN_FOCUS: 'cyan-focus',
	CYAN_HOVER_RGBA: 'cyan-hover-rgba',
	CYAN_HOVER: 'cyan-hover',
	CYAN: 'cyan',
	DANGER: '#F46D66',
	DARK_BLUE: 'dark-blue',
	DARK_DANGER: '#6B211D',
	DARK_GRAY: 'dark-gray',
	DARK_GREY: 'dark-gray',
	DARKER_GRAY: 'darker-gray',
	DEFAULT_GRAY: "default-gray",
	DEFAULT: 'default',
	ERROR: 'error',
	EXCEL_GREEN: 'excel-green',
	FOCUS_BORDER: 'focus-border',
	GRADIENT: 'gradient',
	GRAY_ACTIVE: 'gray-active',
	GRAY_HOVER: 'gray-hover',
	GRAY_PLACEHOLDER: 'gray-placeholder',
	GRAY_PRESENTER: "gray-presenter", // light gray labels + icons for inputs on presenter app entry flow (teal background)
	GRAY: "gray",
	GRAYISH_BLUE: 'grayish-blue',
	GRAYISH_PURPLE: 'grayish-purple',
	GREEN_CHECK: 'green-check',
	GREEN: "green",
	GREENCHECK: 'green-check',
	GREENROOM: 'greenroom',
	ICON_GRAY: 'icon-gray',
	IMAGE_GRAY: 'image-gray',
	LEMONADE: 'lemonade',
	LIGHT_BLACK: "light-black",
	LIGHT_BLUE: 'light-blue',
	LIGHT_GRAY: 'light-gray',
	LIGHTER_GRAY: 'lighter-gray',
	LIME: 'lime',
	MAGENTA: 'magenta',
	MED_GRAY_INACTIVE: 'med-gray-inactive',
	MED_GRAY: 'med-gray',
	MED_LIGHT_GRAY: "med-light-gray",
	MODAL_BACKGROUND: 'modal-background',
	OFF_BLACK: 'off-black',
	OFF_WHITE: 'off-white',
	OPTION_BORDER_GRAY: 'option-border-gray',
	OPTION_GRAY: 'option-gray',
	OPTION_GREEN: 'option-green',
	OPTION_RED: 'option-red',
	PDF_RED: 'pdf-red',
	PINK_LEMONADE: 'pink-lemonade',
	PINK: 'pink',
	POWERPOINT_ORANGE: 'powerpoint-orange',
	PURPLE_STAR: 'purple-star',
	PURPLE: 'purple',
	PURPLESTAR: 'purple-star',
	RED: 'red',
	ROSE: 'rose',
	SHADOW_ALERT: 'shadow-alert',
	WHITE: "white",
	WORD_BLUE: 'word-blue',
	YELLOW_STAR: 'yellow-star',
	YELLOW: 'yellow',
	YELLOWSTAR: 'yellow-star',
	DOUBLE_ARROW_LEFT: 'double-arrow-left',
	DOUBLE_ARROW_RIGHT: 'double-arrow-right',
};

interface Props {
	name: string;
	size: number;	// replaces width + height in the svg. If you use actual width/height from figma the icon is too small. Take the larger
	// of width + height and use that as the size param
	color: string;
	viewBox?: string;
	backgroundColor?: string;
}

export default function Icon(props: Props): JSX.Element {
	return (
		<Suspense fallback="">
			<_Icons {...props} />
		</Suspense>
	);
}