import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import Icon, { COLORS, ICONS } from '../icon';

interface TimepickerProps {
	label?: string;
	placeholder?: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	style?: { [key: string]: any };
	initialValue?: number;
}

export default function Timepicker(props: TimepickerProps): JSX.Element {
	const { label, placeholder, onChange, style, initialValue } = props;
	const [value, setValue] = useState(initialValue);
	const [entered, setEntered] = useState(false);
	const input = useRef<HTMLInputElement | null>(null);

	function keyUp() {
		setEntered(!!input.current?.value);
	}

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		onChange(e);
		setValue(parseInt(e.target.value));
	};
	// if initial value, then set it
	useEffect(() => {
		if (initialValue && input?.current) {
			input.current.valueAsNumber = initialValue;
		}
	}, [initialValue]);

	function focusText() {
		input.current?.focus();
	}

	return (
		<div>
			{props.label && <label>{label}</label>}
			<div
				className={classNames('timepicker', { entered })}
				style={style}
			>
				<div className="timepicker-input">
					<input
						value={value}
						ref={input}
						type="number"
						placeholder={placeholder}
						onChange={handleChange}
						onKeyUp={keyUp}
					/>
				</div>
				<div onClick={focusText} style={{ cursor: "pointer", display: "flex" }}>
					<Icon
						name={ICONS.CLOCK}
						size={16}
						color={COLORS.WHITE}
					/>
				</div>
			</div>
		</div>
	);
}
