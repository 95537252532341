import { useEffect, useRef, useState } from 'react';

import { OptionalComponent } from '../../../../../../utils/optional-component';
import { numClamp } from '../../../../../../utils/utils';
import ColorPicker from '../../../../../general-ui/color-picker/color';
import useClickAwayListener from '../../../../../../utils/use-click-away-listener';

import '../../../session/editor/moduleControls/controls.scss';

interface Props {
	color: [string, number];
	title: string;
	onChange: Function;
	disableOpacity?: boolean;
	displayLeft?: boolean;
	disabled?: boolean;
	renderTitle?: () => JSX.Element;
}
export default function ColorEditor(props: Props): JSX.Element {
	const {
		color,
		title,
		onChange,
		disableOpacity = false,
		displayLeft,
		disabled = false,
		renderTitle,
	} = props;
	const [hex, opacity] = color;

	const [pickedColor, setPickedColor] = useState(hex);
	const [pickedOpacity, setPickedOpacity] = useState(Math.ceil(opacity * 100));
	const [pickerOpen, setPickerOpen] = useState(false);

	const self = useRef<HTMLDivElement>(null);

	useClickAwayListener({
		ref: self,
		onClickAway: () => null,
		onMousedownClickAway: () => setPickerOpen(false),
		onEscape: () => setPickerOpen(false),
		open: pickerOpen,
	});

	useEffect(() => {
		onChange(pickedColor, pickedOpacity / 100);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pickedColor, pickedOpacity]);

	return (
		<div className="color-editor">
			<label>{renderTitle?.() || title}</label>
			<div className="color-input">
				<div className="color-input-left" ref={self}>
					<div
						className="color-bubble"
						onClick={() => !disabled && setPickerOpen(prev => !prev)}
						style={{ backgroundColor: `${hex}` }}
					/>
					<span className="hex-readout"># <input disabled={disabled} type="text" value={pickedColor.replace('#', '').toUpperCase()} onChange={e => setPickedColor('#' + e.target.value)} /></span>
					<ColorPicker
						open={pickerOpen}
						value={pickedColor}
						color={pickedColor}
						label={title}
						close={() => setPickerOpen(false)}
						onChangeComplete={(e: any) => setPickedColor(e.hex)}
						displayLeft={displayLeft}
					/>
				</div>
				<OptionalComponent display={!disableOpacity}>
					<div className="color-input-right">
						<input
							type="number"
							min={0}
							max={100}
							step={1}
							value={pickedOpacity}
							onChange={(e) => setPickedOpacity(numClamp(e.target.valueAsNumber, 0, 100))}
							disabled={disabled}
						/>%
					</div>
				</OptionalComponent>
			</div>
		</div>
	);
}