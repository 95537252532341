import { ConnectedAdmin, IBreakoutRoomProfile } from '../types/working-model';
import { useEffect, useState } from 'react';
import manager from './socket-main-thread/socket-manager';
import { SocketConnection } from './socket-main-thread/socket-connection';
import { SocketMessage } from './socket-main-thread/types';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export default interface Socket extends SocketConnection {
	// Must use SocketManager to get a new socket connection. 
	// using new Socket() is deprecated in components
}

const baseConnection = new SocketConnection('');

export function useSocket(channel: string, admin?: ConnectedAdmin, profile?: IBreakoutRoomProfile): Socket {
	const [socket, setSocket] = useState<Socket>(baseConnection);

	useEffect(() => {
		setSocket(manager.getFromHook(channel, admin, profile));

		return () => {
			manager.leave(channel);
		};
	}, [admin, channel, profile]);

	return socket;
}

export type { SocketMessage };