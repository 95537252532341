import { Action } from '../../types/actions';
import { Channel, ChannelAuthIntegrationOnly, BLAdmin, PresenterAuthType, AuthConsumer, ReducedUser } from '../../types/working-model';
import {
	ADD_CHECKLIST_ITEM,
	AuthenticationAction,
	CLEAR_CHECKLIST,
	COMPLETE_WELCOME_ONBOARDING,
	CONFIRM_CODE,
	FORGOT_PASSWORD,
	GET_ACCOUNT_INFO_BY_STORED_TOKEN,
	GET_ADMIN_CHANNELS,
	GET_AUTH_TYPE_FOR_PRESENTER,
	GET_CHANNELS_INFO_FOR_ADMIN,
	GREENROOM_LOGIN_ERROR,
	REFORMAT_OAUTH_TOKEN,
	REFRESH_OAUTH_TOKEN,
	REFRESH_TOKEN,
	SET_CHANGING_CHANNELS,
	SET_CHANNEL_SSO_INFO,
	SET_TEMP_NUMBER,
	SET_USER,
	SIGN_IN,
	SIGN_OUT,
	UPDATE_ADMIN_PROFILE,
	UPDATE_CHANNEL,
	UPDATE_PASSWORD_ERROR,
	UPDATE_PASSWORD_LOADING,
	UPDATE_ECDN_CONFIGURATION,
	ACKNOWLEDGE_DMA
} from '../actions/authentication';
import { handle } from 'redux-pack';
import { jwtDecode, handleLoginErrorMessage } from '../../utils/utils';
import { getStorageItem, removeStorageItem, setStorageItem } from '../../utils/local-storage';

export enum StoredTokens {
	currentUser = "currentUser",
	storedTwoFactor = "storedTwoFactor",
	currentChannel = "currentChannel"
}

const initialToken = getStorageItem(StoredTokens.currentUser) ?? null;
const initialTwoFactor = getStorageItem(StoredTokens.storedTwoFactor) ?? null;

export interface AuthState {
	token: string | null;
	user: BLAdmin | null;
	channels: Channel[];
	signingIn: boolean;
	signInFailure: string;
	forgotPasswordLoading: boolean;
	forgotPasswordError: string;
	forgotPasswordSuccess: boolean;
	updatePasswordLoading: boolean;
	updatePasswordError: string;
	updatePasswordSuccess: boolean;
	temp_profile: string | null;
	two_factor_stored: string | null;
	phone_hash: string | null;
	request_phone: boolean;
	onboardedLoading: boolean;
	awaitCode: boolean;
	changingChannels: boolean;
	updateChannelError: boolean;
	channelsInfo: Channel[];
	channelSSO: Channel | Record<string, never> | ChannelAuthIntegrationOnly;
	reduced_user: ReducedUser | null;
	greenroomLoginError: string;
	presenterAuthType?: PresenterAuthType | null;
	oauthFlowToken?: string | null;
	oauthFlowTokenForRedirect?: string | null;
	oauthFlowTokenError?: string | null;
	gettingChannelsInfo: boolean;
	gettingChannelsInfoError: string;
}

const initialState: AuthState = {
	awaitCode: false,
	changingChannels: false,
	channels: [],
	channelsInfo: [],
	channelSSO: {},
	forgotPasswordError: '',
	forgotPasswordLoading: false,
	forgotPasswordSuccess: false,
	greenroomLoginError: '',
	onboardedLoading: false,
	phone_hash: null,
	reduced_user: { email: '', channels_using_sso: [], channelId: 0, channels: undefined },
	request_phone: false,
	signInFailure: '',
	signingIn: false,
	temp_profile: null,
	token: initialToken,
	two_factor_stored: initialTwoFactor,
	updateChannelError: false,
	updatePasswordError: '',
	updatePasswordLoading: false,
	updatePasswordSuccess: false,
	user: initialToken ? jwtDecode(initialToken) as BLAdmin : null,
	presenterAuthType: null,
	oauthFlowToken: null,
	oauthFlowTokenForRedirect: null,
	oauthFlowTokenError: null,
	gettingChannelsInfo: false,
	gettingChannelsInfoError: '',
};

const getUserFromToken = (token: string): BLAdmin => jwtDecode(token) as BLAdmin;

const setStorageAndGetUser = (token: string): BLAdmin => {
	//set token in localStorage
	setStorageItem(StoredTokens.currentUser, token, 1);

	//decode JWT
	const user = getUserFromToken(token);

	//use plain localStorage for this key so it doesn't have to be parsed by the useChannelMonitor hook
	localStorage.setItem(StoredTokens.currentChannel, String(user.active_channel));

	return user;
};

export default function AuthReducer(
	state: AuthState = initialState,
	action: AuthenticationAction
): AuthState {
	switch (action.type) {
		case REFRESH_TOKEN: {
			return handle(state, action as Action, {
				success: (state) => {
					if (action.payload?.token) {
						const user = setStorageAndGetUser(action.payload.token) as BLAdmin;

						return {
							...state,
							signInFailure: '',
							token: action.payload.token,
							user
						};
					} else {
						return {
							...state,
							token: null,
							user: null,
						};
					}
				},
			});
		}

		case SIGN_IN: {
			return handle(state, action as Action, {
				start: (state) => ({
					...state,
					signingIn: true,
				}),
				finish: (state) => ({
					...state,
					signingIn: false,
				}),
				failure: (state) => {
					let message = "Failed to sign in";

					if (action.payload instanceof Error) {
						message = action.payload.message;
					}

					return {
						...state,
						signInFailure: handleLoginErrorMessage(message)
					};
				},
				success: (state) => {
					if (action.payload?.token
						&& action.payload?.auth_consumer
						&& action.payload?.auth_consumer !== AuthConsumer.Platform) {
						// User authed successfully, however they authed for some
						// other platform.  Don't update the token for Platform,
						// store in another place.  This prevents other effects
						// from trying to do work on a 'bad' token.
						const user = getUserFromToken(action.payload.token);

						return {
							...state,
							signInFailure: '',
							oauthFlowToken: action.payload.token,
							user,
						};
					}
					else if (action.payload?.token) {
						const user = setStorageAndGetUser(action.payload.token) as BLAdmin;

						return {
							...state,
							signInFailure: '',
							token: action.payload.token,
							user
						};
					} else {
						if (
							action.payload?.two_factor_required &&
							action.payload.temp_profile
						) {
							return {
								...state,
								temp_profile: action.payload.temp_profile,
								phone_hash: action.payload.phone_hash ?? null,
								request_phone: action.payload.request_phone ?? false,
								awaitCode: !action.payload.request_phone,
								signInFailure: ''
							};
						}

						return {
							...state,
							signInFailure: action.payload?.message ?? "Failed to sign in",
						};
					}
				},
			});
		}

		case SET_TEMP_NUMBER: {
			return handle(state, action, {
				start: (state) => ({
					...state,

				}),
				finish: (state) => ({
					...state,

				}),
				failure: (state) => ({
					...state,

				}),
				success: (state) => ({
					...state,
					phone_hash: action.payload?.phone_hash ?? null,
					awaitCode: true
				}),
			});
		}

		case CONFIRM_CODE: {
			return handle(state, action, {
				start: (state) => ({
					...state,
					signingIn: true,
				}),
				finish: (state) => ({
					...state,
					signingIn: false,
				}),
				failure: (state) => {
					let message = "Failed to sign in.";

					if (action.payload instanceof Error) {
						message = action.payload.message;
					}
					return {
						...state,
						signInFailure: message,
					};
				},
				success: (state) => {
					if (action.payload?.token && action.payload?.storage_token) {
						//remember device for 30 days
						setStorageItem(StoredTokens.storedTwoFactor, action.payload.storage_token, 30 * 24);
						const user = setStorageAndGetUser(action.payload.token) as BLAdmin;
						return {
							...state,
							signInFailure: '',
							token: action.payload.token,
							user: user,
						};
					} else {
						return {
							...state,
							signInFailure: 'failed to confirm code'
						};
					}
				},
			});
		}

		case FORGOT_PASSWORD: {
			return handle(state, action, {
				start: (state) => ({
					...state,
					forgotPasswordLoading: true,
				}),
				finish: (state) => ({
					...state,
					forgotPasswordLoading: false,
				}),
				failure: (state) => ({
					...state,
					forgotPasswordError:
						'Unable to send password reset. Please check your inputs and try again.',
				}),
				success: (state) => ({
					...state,
					forgotPasswordSuccess: true,
					forgotPasswordError: '',
				}),
			});
		}

		case UPDATE_PASSWORD_LOADING: {
			return {
				...state,
				updatePasswordLoading: action.payload,
			};
		}

		case UPDATE_PASSWORD_ERROR: {
			return {
				...state,
				updatePasswordError: action.payload || '',
			};
		}

		case GET_ADMIN_CHANNELS: {
			return handle(state, action, {
				success: state => {
					const channels = action.payload ?? [];

					return {
						...state,
						channels
					};
				}
			});
		}

		case SET_CHANGING_CHANNELS: {
			return {
				...state,
				changingChannels: action.payload,
			};
		}

		case SET_USER: {
			if (!action.payload?.token) return state;

			const user = setStorageAndGetUser(action.payload.token) as BLAdmin;
			return {
				...state,
				token: action.payload.token,
				user,
			};
		}

		case UPDATE_CHANNEL: {
			return handle(state, action, {
				success: state => {
					return {
						...state,
						channels: state.channels.map((channel) => {
							return channel.channel === action.payload?.channel ? action.payload : channel;
						}),
						updateChannelError: false,
					};
				},
				failure: state => {
					return {
						...state,
						updateChannelError: true,
					};
				}
			});
		}

		case SIGN_OUT: {
			removeStorageItem(StoredTokens.currentUser);
			return {
				...state,
				user: null,
				token: null,
				presenterAuthType: null,
			};
		}

		case ADD_CHECKLIST_ITEM: {
			return handle(state, action, {
				start: (state) => ({
					...state,
					onboardedLoading: true
				}),
				finish: (state) => ({
					...state,
					onboardedLoading: false
				}),
				success: (state) => {
					if (!state.user)
						return state;
					else {
						return ({
							...state,
							user: {
								...state.user,
								onboarded: action.payload || []
							}
						});
					}
				}
			});
		}

		case CLEAR_CHECKLIST: {
			if (action.payload && state.user)
				return ({ ...state, user: { ...state.user, onboarded: [] } });
			else
				return state;
		}

		case COMPLETE_WELCOME_ONBOARDING: {
			return handle(state, action, {
				start: (state) => ({
					...state,
					onboardedLoading: true
				}),
				finish: (state) => ({
					...state,
					onboardedLoading: false
				}),
				success: (state) => {
					if (!state.user)
						return state;
					else {
						return ({
							...state,
							user: {
								...state.user,
								onboarded: action.payload ?? []
							}
						});
					}
				}
			});
		}

		case UPDATE_ADMIN_PROFILE: {
			if (!state.user || !action.payload) return state;
			return {
				...state,
				user: {
					...state.user,
					profile: action.payload
				}
			};
		}

		case GET_CHANNELS_INFO_FOR_ADMIN: {
			return handle(state, action, {
				start: (state) => ({
					...state,
					gettingChannelsInfo: true,
					gettingChannelsInfoError: ""
				}),
				finish: (state) => ({
					...state,
					gettingChannelsInfo: false,
				}),
				failure: (state) => ({
					...state,
					gettingChannelsInfoError: `Failed to load channels. Please check your internet connection and try again.`,
					channelsInfo: []
				}),
				success: (state) => {
					if (!state || !action.payload) return state;
					return {
						...state,
						channelsInfo: action.payload
					};
				},
			});
		}

		case GET_AUTH_TYPE_FOR_PRESENTER: {
			if (!state || !action.payload) return state;
			return {
				...state,
				presenterAuthType: action.payload
			};
		}

		case REFORMAT_OAUTH_TOKEN: {
			return handle(state, action, {
				start: (state) => ({
					...state,
				}),
				finish: (state) => ({
					...state,
				}),
				failure: (state) => {
					let message = `Unable to create token for redirect`;

					if (action.payload instanceof Error) {
						message = `Unable to create token for redirect: ${action.payload.message}`;
					}

					return {
						...state,
						oauthFlowTokenError: message
					};
				},
				success: (state) => {
					if (!state || !action.payload) return state;
					return {
						...state,
						oauthFlowTokenError: '',
						oauthFlowTokenForRedirect: action.payload.token,
					};
				},
			});
		}

		case REFRESH_OAUTH_TOKEN: {
			return handle(state, action, {
				start: (state) => ({
					...state,
				}),
				finish: (state) => ({
					...state,
				}),
				failure: (state) => {
					let message = `Unable to refresh token for redirect`;

					if (action.payload instanceof Error) {
						message = `Unable to refresh token for redirect: ${action.payload.message}`;
					}

					return {
						...state,
						oauthFlowTokenError: message
					};
				},
				success: (state) => {
					if (!state || !action.payload) return state;
					return {
						...state,
						oauthFlowTokenError: '',
						oauthFlowTokenForRedirect: action.payload.token,
					};
				},
			});
		}

		case GET_ACCOUNT_INFO_BY_STORED_TOKEN: {
			return handle(state, action, {
				success: (state) => {
					if (!state || !action.payload) return state;
					return {
						...state,
						reduced_user: action.payload.account
					};
				},
			});
		}

		case SET_CHANNEL_SSO_INFO: {
			if (!state || !action.payload) return state;
			return {
				...state,
				channelSSO: action.payload
			};
		}

		case GREENROOM_LOGIN_ERROR: {
			return { ...state, greenroomLoginError: action.payload ? 'Failed to log into Greenroom. Try refreshing the page.' : '' };
		}

		case UPDATE_ECDN_CONFIGURATION: {
			return {
				...state,
				channels: state.channels.map((channel) => {
					return channel.channel === action.payload?.channel
						? { ...channel, ecdn_configuration: action.payload.ecdn_configuration }
						: channel;
				})
			};
		}

		case ACKNOWLEDGE_DMA: {
			if (!state.user) return state;

			return {
				...state,
				user: {
					...state.user,
					dma_acknowledgement_date: action.payload
				}
			};
		}

		default:
			return state;
	}
}
