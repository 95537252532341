// overrides history.pushState an history.replaceState to fire a custom event whenever
// the functions are called (which react router calls) - lets us listen for location
// updates outside of the router (ie: in Alerts, Modals, custom scripts, etc)
export default (function() {
	const pushState = history.pushState;
	const replaceState = history.replaceState;

	history.pushState = function(...args: unknown[]) {
		pushState.apply(history, args as any);
		window.dispatchEvent(new Event('pushstate'));
		window.dispatchEvent(new Event('locationchange'));
	};

	history.replaceState = function(...args: unknown[]) {
		replaceState.apply(history, args as any);
		window.dispatchEvent(new Event('replacestate'));
		window.dispatchEvent(new Event('locationchange'));
	};

	window.addEventListener('popstate', function() {
		window.dispatchEvent(new Event('locationchange'));
	});
})();