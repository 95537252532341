import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { useTypedSelector } from '../../../../store/reducers/use-typed-selector';
import { OptionalComponent } from '../../../../utils/optional-component';
import { EIcon } from '../../../general-ui/icon/icon';
import { toggleNavigation } from '../../../../store/actions/admin/content-actions';

import './navigation-toggle.scss';
import { setEventNavOpen } from 'store/actions/event/event-actions';

interface INavigationToggleProps {
	isDesktop?: boolean;
	hideToggle: boolean;
	bannerAnnouncementShowing?: boolean;
	forceOn?: boolean;
}

export default function NavigationToggle({ isDesktop, forceOn, hideToggle, bannerAnnouncementShowing }: INavigationToggleProps): JSX.Element {
	const hideControls = useTypedSelector(state => state.VideosReducer.showVideoControls);
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	// There are currently TWO navigation toggles.  One is accross all the pre-video_v2
	// pages, while the other is ON the video_v2 page.  Previously, a hook was used
	// to check for videoV2 and supress _this_ navigation toggle.  however there are pages
	// that do not have the video_v2 component.  The hook was removed in favor of allowing
	// the caller to decided if the toggle should be hidden/shown.
	// Example is breakout rooms and fireside chat.
	const dispatch = useDispatch();

	return (
		<OptionalComponent display={forceOn || (isDesktop && !hideControls)}>
			<button
				onClick={() => dispatch(setEventNavOpen(true))}
				className={classNames('no-style no-padding navigation-toggle', { 'navigation-toggle-hide': hideToggle, 'has-banner-announcement': (eventBundle?.announcements && eventBundle.announcements.length > 0) || bannerAnnouncementShowing })}
			>
				<EIcon name="menu" size="large" />
			</button>
		</OptionalComponent>
	);
}