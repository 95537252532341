import { signOut } from "../store/actions/authentication";
import store from "../store/main";
import { getStorageItem, removeStorageItem, setStorageItem } from "./local-storage";

const ALLOWED_TIME = 1000 * 60 * 60;

function handleActivity() {
	setStorageItem('activityTime', (Date.now() + ALLOWED_TIME).toString());
}

export default function MonitorUserTime(): void {
	window.addEventListener("mousedown", handleActivity);
	window.addEventListener("scroll", handleActivity);
	window.addEventListener("keydown", handleActivity);

	// we use local storage so we can have multiple tabs open sharing the same time
	setStorageItem('activityTime', (Date.now() + ALLOWED_TIME).toString());

	setInterval(() => {
		const activityTime = getStorageItem('activityTime');
		if (parseInt(activityTime) < Date.now()) {
			const token = store.getState().AuthReducer.token;

			//if they're signed in and they've not interacted with the page for 1 hour
			if (token) {
				// NOTE: activityTime is also removed from local storage inside of src/store/reducers/main.ts
				removeStorageItem('activityTime');
				//log them out
				store.dispatch(signOut());
				console.info("Logging out for 1 hour of inactivity");
			}
		}
	}, 10000);
}
