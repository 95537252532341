import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import classNames from 'classnames';

import {
	SessionTabsChatTypes,
	SessionTabsLayoutTypes,
	SessionTabsThemeTypes,
	SessionTypesEnum,
	SessionTabsChatStyles
} from "../../../../../../types/working-model";
import Icon, { COLORS, ICONS } from "../../../../../general-ui/icon";
import {
	setSessionChatDarkMode,
	setSessionLayout,
	toggleDisplaySessionChat,
	setSessionChatStyle
} from "../../../../../../store/actions/admin/create-event/session";
import { useTypedSelector } from "../../../../../../store/reducers/use-typed-selector";
import PanelBlock from "./panel-block";
import ReactionSegment from "./reaction-segment";
import { ConditionalWrapper } from "../../../../../../utils/conditional-wrapper";
import { Tooltip } from "../../../../../general-ui/tooltip/tooltip";
import PanelThemeColorSelector from "./panel-theme-color-selector";
import "./session-settings-v2.scss";

export const tooltipComponent = (children: JSX.Element) =>
	<Tooltip position="bottom-center" tooltip="Disabled due to other layout settings" maxWidth={150} >
		{children}
	</Tooltip>;

const SessionLayoutV2: React.FC = () => {
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);

	const [copiedText, setCopiedText] = useState("");

	const darkModeLightMode = workingSession?.session_chat_dark_mode_enabled ?? false;
	const pageStyleLayout = workingSession?.layout?.tabs?.layout ?? SessionTabsLayoutTypes.BelowTheFold;
	const noChat = !workingSession?.session_chat_enabled ?? false;
	const isOverlay = workingSession?.layout?.overlayChat ?? false;
	const isSidePanel = !workingSession?.layout?.overlayChat ?? true;
	const isBreakout = workingSession?.session_type === SessionTypesEnum.breakoutRooms;

	// leaving this here for now because we might want to allow above the fold with breakouts at some point
	// const isGoogleBreakout = useMemo(() => workingEvent?.sessions
	// 	.find(session => session.session === workingSession?.session)
	// 	?.breakout_session?.use_google_meet ?? false, [workingEvent, workingSession]);

	const isNoChat = noChat || isBreakout;  // Show as disabled when Google Breakout
	const isOverlayChat = isOverlay && !isNoChat;
	const isSidePanelChat = isSidePanel && !isNoChat;
	const isDefaultChatStyle = !workingSession?.session_chat_style || workingSession?.session_chat_style === SessionTabsChatStyles.Default || workingSession?.session_chat_style === SessionTabsChatStyles.HighVolume;
	const isConversationalChatStyle = workingSession?.session_chat_style === SessionTabsChatStyles.Conversational || workingSession?.session_chat_style === SessionTabsChatStyles.Standard;

	const disableAboveTheFold = isOverlayChat || isBreakout;

	useEffect(() => {
		if (copiedText) setTimeout(() => setCopiedText(""), 2000);
	}, [copiedText]);

	const dispatch = useDispatch();

	const setDarkMode = useCallback((on: boolean) => {
		dispatch(setSessionChatDarkMode(on));
	}, [dispatch]);

	const toggleSessionChat = useCallback((on: boolean) => {
		if (!workingSession) return;
		dispatch(toggleDisplaySessionChat(on));
	}, [dispatch, workingSession]);

	const setSessionChatStyleType = useCallback((style: SessionTabsChatStyles) => {
		if (!workingSession) return;
		dispatch(setSessionChatStyle(style));
	}, [dispatch, workingSession]);

	function setSidePanelChatClick() {
		if (!workingSession) return;
		if (noChat) dispatch(toggleDisplaySessionChat(true));
		dispatch(setSessionLayout({ ...workingSession.layout, overlayChat: false }));
	}

	function setUseOverlayClick() {
		if (!workingSession) return;
		if (noChat) dispatch(toggleDisplaySessionChat(true));
		dispatch(setSessionLayout({ ...workingSession.layout, overlayChat: true }));
	}

	function setSelectedLayoutClick(layout: SessionTabsLayoutTypes) {
		if (!workingSession) return;
		dispatch(setSessionLayout({ ...workingSession.layout, tabs: { layout: layout, show_icons: false } }));
	}

	const tooltipComponent = (children: JSX.Element, tooltip?: string) =>
		<Tooltip position="bottom-center" tooltip={tooltip || ''} maxWidth={150} >
			{children}
		</Tooltip>;

	const renderButton = (
		icon: string,
		name: string,
		isActive: boolean,
		onClickHandler: () => void | string,
		disabled?: boolean,
		defaultColorOverride?: string,
		disabledTooltip?: string
	) => {
		const defaultColor = defaultColorOverride ?? COLORS.DEFAULT;

		return (
			<ConditionalWrapper condition={!!disabled} wrapper={children => tooltipComponent(children, disabledTooltip)}>
				<div className={classNames("lemonade-wrapper", { active: isActive, disabled })}>
					<button
						className={classNames(
							'clear no-style inherit-dimensions layout-tabs-button',
							{
								active: isActive,
								disabled
							}
						)}
						onClick={onClickHandler}
					>
						<Icon name={icon} color={isActive ? COLORS.MED_LIGHT_GRAY : defaultColor} size={100} />
						<div className="layout-tabs-button-text">
							{name}
						</div>
					</button>
				</div>
			</ConditionalWrapper>

		);
	};

	return workingSession && (
		<>
			<PanelBlock title="Theme" id="layout-theme">
				<div className="layout-button-group">
					{renderButton(ICONS.LIGHT_MODE, SessionTabsThemeTypes.LightMode, !darkModeLightMode, () => setDarkMode(false), false, COLORS.GRAY)}
					{renderButton(ICONS.DARK_MODE, SessionTabsThemeTypes.DarkMode, darkModeLightMode, () => setDarkMode(true))}
				</div>
				<PanelThemeColorSelector />
			</PanelBlock>

			<PanelBlock title="Page Style" id="layout-page-style">
				<div className="layout-button-group">
					{renderButton(
						ICONS.ABOVE_THE_FOLD,
						SessionTabsLayoutTypes.AboveTheFold,
						pageStyleLayout === SessionTabsLayoutTypes.AboveTheFold,
						() => setSelectedLayoutClick(SessionTabsLayoutTypes.AboveTheFold),
						disableAboveTheFold,
						undefined,
						isBreakout ? 'Disabled due to session type' : 'Disabled due to other layout settings'
					)}
					{renderButton(ICONS.BELOW_THE_FOLD, SessionTabsLayoutTypes.BelowTheFold, pageStyleLayout === SessionTabsLayoutTypes.BelowTheFold, () => setSelectedLayoutClick(SessionTabsLayoutTypes.BelowTheFold))}
				</div>
			</PanelBlock>

			<PanelBlock title="Chat Type" id="layout-chat-type">
				<div className="layout-button-group three-columns">
					{renderButton(ICONS.NO_CHAT, SessionTabsChatTypes.NoChat, isNoChat, () => toggleSessionChat(false))}
					{renderButton(
						ICONS.OVERLAY_CHAT,
						SessionTabsChatTypes.Overlay,
						isOverlayChat, () => setUseOverlayClick(),
						pageStyleLayout === SessionTabsLayoutTypes.AboveTheFold || isBreakout,
						undefined,
						'Disabled due to other layout settings'
					)}
					{renderButton(ICONS.SIDE_PANEL_CHAT,
						SessionTabsChatTypes.SidePanel,
						isSidePanelChat,
						() => setSidePanelChatClick(),
						isBreakout,
						undefined,
						'Disabled due to other layout settings'
					)}
				</div>
			</PanelBlock>

			<PanelBlock title="Chat Style" id="layout-chat-style">
				<div className="layout-button-group">
					{renderButton(
						ICONS.CHAT_STYLE_DEFAULT,
						'Default',
						isDefaultChatStyle,
						() => setSessionChatStyleType(SessionTabsChatStyles.Default),
						!workingSession?.session_chat_enabled,
						undefined,
						'Disabled due to chat being disabled'
					)}
					{renderButton(
						ICONS.CHAT_STYLE_CONVERSATIONAL,
						'Conversational',
						isConversationalChatStyle,
						() => setSessionChatStyleType(SessionTabsChatStyles.Conversational),
						!workingSession?.session_chat_enabled,
						undefined,
						'Disabled due to chat being disabled'
					)}
				</div>
			</PanelBlock>

			<PanelBlock title="Reactions" id="layout-reactions">
				<ReactionSegment />
			</PanelBlock>

			{/* <PanelBlock title="Other Options" id="layout-other-options"> */}
			{/* REMOVING UNTIL FURTHER NOTICE */}
			{/* <ToggleOption
					id='option-countdown-clock'
					title='Countdown Clock'
					tooltip='Turns on and off the countdown on the session page'
					switchName=''
					onSwitchToggle={}
					toggleValue={}
				/> */}

			{/* REMOVING UNTIL FURTHER NOTICE */}
			{/* Removed from engagement list as part of https://brandlive.atlassian.net/browse/BP-4023 
				But product has directed we may be bringing it back */}
			{/* <ToggleOption
					id='option-feedback-survery'
					title='Post-show Feedback Survey'
					tooltip='Survey toggle will be available after the session starts. This copy will be applied across all sessions.'
					switchName='feedbackSurvey'
					onSwitchToggle={toggleFeedbackSurvey}
					toggleValue={!!workingSession?.enable_feedback_survey}
				>
					<OptionalComponent display={!!workingSession?.enable_feedback_survey && Number(workingEvent?.settings.translation_version) !== 2}>
						<EditableTextSlim tagType="p" value={workingEvent?.settings?.feedback_survey_text?.title?.[language] as string ?? t('userSurvey.ratingLabel')} onChange={handleUserFeedbackSurveyTextChange} />
					</OptionalComponent>
				</ToggleOption> */}
			{/* </PanelBlock> */}
		</>
	);
};

export default SessionLayoutV2;
