import { isObject } from "underscore";
import { latinChars } from "../../../../../../../i18n/transliteration";
import { PageModuleGroupModules, SessionPanelLayoutsTypes, TranslateString } from "../../../../../../../types/working-model";

export const getHeaderTitle = (activeTab: string) => {
	if (activeTab.includes('Create')) {
		return 'Create';
	}

	if (activeTab.includes('Extra')) {
		return activeTab.replace('Extra ', '');
	}

	return activeTab;
};

export const canClosePanel = (activeTab: string) => {
	return ![
		String(SessionPanelLayoutsTypes.CreateSurvey),
		String(SessionPanelLayoutsTypes.CreatePoll),
		String(SessionPanelLayoutsTypes.CreateQuiz),
		String(SessionPanelLayoutsTypes.CreateFeedback),
		String(SessionPanelLayoutsTypes.CreateQAndA),
	].includes(activeTab);
};

export const isTranslateString = (translateString?: unknown): translateString is TranslateString => {
	if (isObject(translateString) && 'base' in translateString) {
		return true;
	}

	return false;
};

export const getCustomModulePath = async (module: PageModuleGroupModules): Promise<string> => {
	if (isTranslateString(module.name)) {
		return await latinChars.slugify(module.name.base);
	} else {
		return '';
	}
};