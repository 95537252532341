import { AsyncAction, Action } from "../../../types/actions";
import * as AnalyticsFetcher from "../../../connection/analytics";

export const GET_CHANNEL_EVENTS = "GET_CHANNEL_EVENTS";
export function getChannelEvents(
	channel: number,
	token: string,
	start?: number | undefined,
	end?: number | undefined,
	audienceLists?: string[],
	search?: string,
	sortBy?: string
): AsyncAction {
	return {
		type: GET_CHANNEL_EVENTS,
		promise: AnalyticsFetcher.GetChannelEvents(channel, token, start, end, audienceLists, 0, search, sortBy)
	};
}

export const GET_MORE_CHANNEL_EVENTS = "GET_MORE_CHANNEL_EVENTS";
export function getMoreChannelEvents(
	channel: number,
	token: string,
	start?: number | undefined,
	end?: number | undefined,
	audienceLists?: string[],
	offset?: number,
	search?: string,
	sortBy?: string
): AsyncAction {
	return {
		type: GET_MORE_CHANNEL_EVENTS,
		promise: AnalyticsFetcher.GetChannelEvents(channel, token, start, end, audienceLists, offset, search, sortBy)
	};
}

export const GET_CHANNEL_SESSIONS_TOTAL = "GET_CHANNEL_SESSIONS_TOTAL";
export function getChannelSessionsTotal(channel: number, token: string): AsyncAction {
	return {
		type: GET_CHANNEL_SESSIONS_TOTAL,
		promise: AnalyticsFetcher.GetChannelSessionsTotal(channel, token)
	};
}

export const GET_UNFILTERED_CHANNEL_EVENTS = "GET_UNFILTERED_CHANNEL_EVENTS";
export function getUnfilteredChannelEvents(channel: number, token: string): AsyncAction {
	return {
		type: GET_UNFILTERED_CHANNEL_EVENTS,
		promise: AnalyticsFetcher.GetChannelEvents(channel, token)
	};
}

export const GET_CHANNEL_DEVICE_TYPES = "GET_CHANNEL_DEVICE_TYPES";
export function getChannelDeviceTypes(channel: number, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_CHANNEL_DEVICE_TYPES,
		promise: AnalyticsFetcher.GetChannelDeviceTypes(channel, token, start, end, audienceLists)
	};
}

export const GET_CHANNEL_BROWSERS = "GET_CHANNEL_BROWSERS";
export function getChannelBrowsers(channel: number, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_CHANNEL_BROWSERS,
		promise: AnalyticsFetcher.GetChannelBrowsers(channel, token, start, end, audienceLists)
	};
}

export const GET_CHANNEL_LANGUAGES = "GET_CHANNEL_LANGUAGES";
export function getChannelLanguages(channel: number, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_CHANNEL_LANGUAGES,
		promise: AnalyticsFetcher.GetChannelLanguages(channel, token, start, end, audienceLists)
	};
}

export const GET_CHANNEL_AUDIENCE = "GET_CHANNEL_AUDIENCE";
export function getChannelAudience(channel: number, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_CHANNEL_AUDIENCE,
		promise: AnalyticsFetcher.GetChannelAudience(channel, token, start, end, audienceLists)
	};
}

export const GET_CHANNEL_REGISTRATIONS = "GET_CHANNEL_REGISTRATIONS";
export function getUniqueChannelRegistrations(channel: number, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_CHANNEL_REGISTRATIONS,
		promise: AnalyticsFetcher.GetUniqueChannelRegistrations(channel, token, start, end, audienceLists)
	};
}

export const GET_CHANNEL_DURATION = "GET_CHANNEL_DURATION";
export function getChannelDurations(channel: number, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_CHANNEL_DURATION,
		promise: AnalyticsFetcher.GetChannelDurations(channel, token, start, end, audienceLists)
	};
}

export const GET_CHANNEL_VIEWS = "GET_CHANNEL_VIEWS";
export function getChannelViews(channel: number, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_CHANNEL_VIEWS,
		promise: AnalyticsFetcher.GetChannelViews(channel, token, start, end, audienceLists)
	};
}

export const GET_CHANNEL_VIEWS_PER_EVENT = "GET_CHANNEL_VIEWS_PER_EVENT";
export function getChannelViewsPerEvent(channel: number, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_CHANNEL_VIEWS_PER_EVENT,
		promise: AnalyticsFetcher.GetChannelViewsPerEvent(channel, token, start, end, audienceLists)
	};
}

export const GET_CHANNEL_WATCHTIME = "GET_CHANNEL_WATCHTIME";
export function getChannelWatchTime(channel: number, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_CHANNEL_WATCHTIME,
		promise: AnalyticsFetcher.GetChannelWatchtime(channel, token, start, end, audienceLists)
	};
}

export const GET_CHANNEL_RETENTION_AVERAGE = "GET_CHANNEL_RETENTION_AVERAGE";
export function getChannelRetentionAverage(channel: number, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_CHANNEL_RETENTION_AVERAGE,
		promise: AnalyticsFetcher.GetChannelRetentionAverage(channel, token, start, end, audienceLists)
	};
}

export const GET_CHANNEL_RETENTION_BUCKETS = "GET_CHANNEL_RETENTION_BUCKETS";
export function getChannelRetentionBuckets(channel: number, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_CHANNEL_RETENTION_BUCKETS,
		promise: AnalyticsFetcher.GetChannelRetentionBuckets(channel, token, start, end, audienceLists)
	};
}

export const GET_CHANNEL_REGIONS = "GET_CHANNEL_REGIONS";
export function getChannelRegions(channel: number, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_CHANNEL_REGIONS,
		promise: AnalyticsFetcher.GetChannelRegions(channel, token, start, end, audienceLists)
	};
}

export const GET_CHANNEL_UNIQUE_ATTENDANCE = "GET_CHANNEL_UNIQUE_ATTENDANCE";
export function getChannelUniqueAttendees(channel: number, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_CHANNEL_UNIQUE_ATTENDANCE,
		promise: AnalyticsFetcher.GetChannelUniqueAttendance(channel, token, start, end, audienceLists)
	};
}

export const GET_CHANNEL_AVERAGE_WATCHTIME = "GET_CHANNEL_AVERAGE_WATCHTIME";
export function getChannelAverageUserWatchtime(channelID: number, token: string, start?: number, end?: number, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_CHANNEL_AVERAGE_WATCHTIME,
		promise: AnalyticsFetcher.GetChannelAverageUserWatchtime(channelID, token, start, end, audienceLists)
	};
}

export const GET_EVENT_DEVICE_TYPES = "GET_EVENT_DEVICE_TYPES";
export function getEventDeviceTypes(uuid: string, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_EVENT_DEVICE_TYPES,
		promise: AnalyticsFetcher.GetEventDeviceTypes(uuid, token, start, end, audienceLists)
	};
}
export const GET_EVENT_REGIONS = "GET_EVENT_REGIONS";
export function getEventRegions(uuid: string, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_EVENT_REGIONS,
		promise: AnalyticsFetcher.GetEventRegions(uuid, token, start, end, audienceLists)
	};
}
export const GET_EVENT_LANGUAGES = "GET_EVENT_LANGUAGES";
export function getEventLanguages(uuid: string, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_EVENT_LANGUAGES,
		promise: AnalyticsFetcher.GetEventLanguages(uuid, token, start, end, audienceLists)
	};
}
export const GET_EVENT_VIEWS = "GET_EVENT_VIEWS";
export function getEventViews(uuid: string, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_EVENT_VIEWS,
		promise: AnalyticsFetcher.GetEventViews(uuid, token, start, end, audienceLists)
	};
}
export const GET_LIVE_AND_ON_DEMAND_EVENT_VIEWS = "GET_LIVE_AND_ON_DEMAND_EVENT_VIEWS";
export function getLiveAndOnDemandEventViews(uuid: string, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_LIVE_AND_ON_DEMAND_EVENT_VIEWS,
		promise: AnalyticsFetcher.GetLiveAndOnDemandEventViews(uuid, token, start, end, audienceLists)
	};
}
export const GET_EVENT_DURATION = "GET_EVENT_DURATION";
export function getEventDuration(uuid: string, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_EVENT_DURATION,
		promise: AnalyticsFetcher.GetEventDuration(uuid, token, start, end, audienceLists)
	};
}

export const GET_EVENT_RETENTION_AVERAGE = "GET_EVENT_RETENTION_AVERAGE";
export function getEventRetentionAverage(uuid: string, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_EVENT_RETENTION_AVERAGE,
		promise: AnalyticsFetcher.GetEventRetentionAverage(uuid, token, start, end, audienceLists)
	};
}

export const GET_EVENT_RETENTION_BUCKETS = "GET_EVENT_RETENTION_BUCKETS";
export function getEventRetentionBuckets(uuid: string, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_EVENT_RETENTION_BUCKETS,
		promise: AnalyticsFetcher.GetEventRetentionBuckets(uuid, token, start, end, audienceLists)
	};
}

export const GET_EVENT_REGISTRATION_ANALYTICS = "GET_EVENT_REGISTRATIONS_ANALYTICS";
export function getEventRegistrations(uuid: string, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_EVENT_REGISTRATION_ANALYTICS,
		promise: AnalyticsFetcher.GetEventRegistrations(uuid, token, start, end, audienceLists)
	};
}

export const GET_EVENT_EMAILS = "GET_EVENT_EMAILS";
export function getEventEmails(uuid: string, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_EVENT_EMAILS,
		promise: AnalyticsFetcher.GetEventEmails(uuid, token, start, end, audienceLists)
	};
}

export const GET_EVENT_WATCHTIME = "GET_EVENT_WATCHTIME";
export function getEventWatchtime(uuid: string, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_EVENT_WATCHTIME,
		promise: AnalyticsFetcher.GetEventWatchtime(uuid, token, start, end, audienceLists)
	};
}

export const GET_EVENT_BROWSERS = "GET_EVENT_BROWSERS";
export function getEventBrowsers(uuid: string, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_EVENT_BROWSERS,
		promise: AnalyticsFetcher.GetEventBrowsers(uuid, token, start, end, audienceLists)
	};
}
export const GET_EVENT_SESSIONS = "GET_EVENT_SESSIONS";
export function getEventSessions(uuid: string, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_EVENT_SESSIONS,
		promise: AnalyticsFetcher.GetEventSessions(uuid, token, start, end, audienceLists)
	};
}

export const GET_FIRST_SESSION_START_TIME = "GET_FIRST_SESSION_START_TIME";
export function getFirstSessionStartTime(eventUuid: string, token: string): AsyncAction {
	return {
		type: GET_FIRST_SESSION_START_TIME,
		promise: AnalyticsFetcher.GetFirstSessionStartTime(eventUuid, token)
	};
}

export const GET_EVENT_SESSIONS_PAGE_VIEWS = "GET_EVENT_SESSIONS_PAGE_VIEWS";
export function getEventSessionsPageViews(uuid: string, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_EVENT_SESSIONS_PAGE_VIEWS,
		promise: AnalyticsFetcher.GetEventSessionsPageViews(uuid, token, start, end, audienceLists)
	};
}

export const GET_CHANNEL_EVENT_COUNTRY_MAP = "GET_CHANNEL_EVENT_COUNTRY_MAP";
export function getChannelEventCountryMap(channel: number, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_CHANNEL_EVENT_COUNTRY_MAP,
		promise: AnalyticsFetcher.GetChannelEventMapData(channel, token, start, end, audienceLists)
	};
}
export const GET_EVENT_COUNTRY_MAP = "GET_EVENT_COUNTRY_MAP";
export function getEventCountryMap(uuid: string, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_EVENT_COUNTRY_MAP,
		promise: AnalyticsFetcher.GetEventMapData(uuid, token, start, end, audienceLists)
	};
}

export const GET_EVENT_UNIQUE_ATTENDANCE = "GET_EVENT_UNIQUE_ATTENDANCE";
export function getEventUniqueAttendance(uuid: string, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_EVENT_UNIQUE_ATTENDANCE,
		promise: AnalyticsFetcher.GetEventUniqueAttendance(uuid, token, start, end, audienceLists)
	};
}

export const GET_EVENT_ATTENDANCE_RATE = "GET_EVENT_ATTENDANCE_RATE";
export function getEventAttendanceRate(uuid: string, token: string): AsyncAction {
	return {
		type: GET_EVENT_ATTENDANCE_RATE,
		promise: AnalyticsFetcher.GetEventAttendanceRate(uuid, token)
	};
}

export const GET_EVENT_AVERAGE_WATCHTIME = "GET_EVENT_AVERAGE_WATCHTIME";
export function getEventAverageUserWatchtime(uuid: string, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_EVENT_AVERAGE_WATCHTIME,
		promise: AnalyticsFetcher.GetEventAverageUserWatchtime(uuid, token, start, end, audienceLists)
	};
}

export const GET_SESSION_DEVICE_TYPES = "GET_SESSION_DEVICE_TYPES";
export function getSessionDeviceTypes(uuid: string, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[], language?: string): AsyncAction {
	return {
		type: GET_SESSION_DEVICE_TYPES,
		promise: AnalyticsFetcher.GetSessionDeviceTypes(uuid, token, start, end, audienceLists, language)
	};
}

export const GET_USER_SURVEY = "GET_USER_SURVEY";
export function getSessionUserSurvey(uuid: string, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[], language?: string | undefined): AsyncAction {
	return {
		type: GET_USER_SURVEY,
		promise: AnalyticsFetcher.GetSessionUserSurvey(uuid, token, start, end, audienceLists, language)
	};
}

export const GET_SESSION_REGIONS = "GET_SESSION_REGIONS";
export function getSessionRegions(uuid: string, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_SESSION_REGIONS,
		promise: AnalyticsFetcher.GetSessionRegions(uuid, token, start, end, audienceLists)
	};
}
export const GET_SESSION_LANGUAGES = "GET_SESSION_LANGUAGES";
export function getSessionLanguages(uuid: string, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[], language?: string): AsyncAction {
	return {
		type: GET_SESSION_LANGUAGES,
		promise: AnalyticsFetcher.GetSessionLanguages(uuid, token, start, end, audienceLists, language)
	};
}
export const GET_SESSION_VIEWS = "GET_SESSION_VIEWS";
export function getSessionViews(uuid: string, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[], language?: string): AsyncAction {
	return {
		type: GET_SESSION_VIEWS,
		promise: AnalyticsFetcher.GetSessionViews(uuid, token, start, end, audienceLists, language)
	};
}
export const GET_SESSION_DURATION = "GET_SESSION_DURATION";
export function getSessionDuration(uuid: string, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_SESSION_DURATION,
		promise: AnalyticsFetcher.GetSessionDuration(uuid, token, start, end, audienceLists)
	};
}
export const GET_SESSION_RETENTION_AVERAGE = "GET_SESSION_RETENTION_AVERAGE";
export function getSessionRetentionAverage(uuid: string, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_SESSION_RETENTION_AVERAGE,
		promise: AnalyticsFetcher.GetSessionRetentionAverage(uuid, token, start, end, audienceLists)
	};
}
export const GET_SESSION_REGISTRATIONS = "GET_SESSION_REGISTRATIONS";
export function getSessionRegistrations(uuid: string, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_SESSION_REGISTRATIONS,
		promise: AnalyticsFetcher.GetSessionRegistrations(uuid, token, start, end, audienceLists)
	};
}
export const GET_SESSION_EMAILS = "GET_SESSION_EMAILS";
export function getSessionEmails(uuid: string, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_SESSION_EMAILS,
		promise: AnalyticsFetcher.GetSessionEmails(uuid, token, start, end, audienceLists)
	};
}
export const GET_SESSION_WATCHTIME = "GET_SESSION_WATCHTIME";
export function getSessionWatchtime(uuid: string, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[], language?: string): AsyncAction {
	return {
		type: GET_SESSION_WATCHTIME,
		promise: AnalyticsFetcher.GetSessionWatchtime(uuid, token, start, end, audienceLists, language)
	};
}
export const GET_AVERAGE_SESSION_WATCHTIMES = "GET_AVERAGE_SESSION_WATCHTIMES";
export function getAverageSessionWatchtimes(uuid: string, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[], language?: string): AsyncAction {
	return {
		type: GET_AVERAGE_SESSION_WATCHTIMES,
		promise: AnalyticsFetcher.GetAverageSessionWatchtimes(uuid, token, start, end, audienceLists, language)
	};
}
export const GET_SESSION_BROWSERS = "GET_SESSION_BROWSERS";
export function getSessionBrowsers(uuid: string, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[], language?: string): AsyncAction {
	return {
		type: GET_SESSION_BROWSERS,
		promise: AnalyticsFetcher.GetSessionBrowsers(uuid, token, start, end, audienceLists, language)
	};
}
export const GET_SESSION_TIMELINE = "GET_SESSION_TIMELINE";
export function getSessionTimeline(uuid: string, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_SESSION_TIMELINE,
		promise: AnalyticsFetcher.GetSessionTimeline(uuid, token, start, end, audienceLists)
	};
}

export const GET_SESSION_COUNTRY_MAP = "GET_SESSION_COUNTRY_MAP";
export function getSessionCountryMap(uuid: string, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[], language?: string): AsyncAction {
	return {
		type: GET_SESSION_COUNTRY_MAP,
		promise: AnalyticsFetcher.GetSessionMapData(uuid, token, start, end, audienceLists, language)
	};
}

export const GET_SESSION_90_SEC_VIDEO_VIEWERS = "GET_SESSION_90_SEC_VIDEO_VIEWERS";
export function getSession90SecondVideoViewers(uuid: string, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[], language?: string): AsyncAction {
	return {
		type: GET_SESSION_90_SEC_VIDEO_VIEWERS,
		promise: AnalyticsFetcher.GetSession90SecondVideoViewers(uuid, token, start, end, audienceLists, language)
	};
}

export const GET_HIGHEST_CONCURRENT = "GET_HIGHEST_CONCURRENT";
export function getSessionHighestConcurrent(uuid: string, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_HIGHEST_CONCURRENT,
		promise: AnalyticsFetcher.GetHighestConcurrent(uuid, token, start, end, audienceLists)
	};
}

export const GET_SESSION_PAGE_VIEWS = "GET_SESSION_PAGE_VIEWS";
export function getSessionPageViews(uuid: string, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[], language?: string): AsyncAction {
	return {
		type: GET_SESSION_PAGE_VIEWS,
		promise: AnalyticsFetcher.GetSessionPageViews(uuid, token, start, end, audienceLists, language)
	};
}

export const GET_SESSION_UNIQUE_ATTENDANCE = "GET_SESSION_UNIQUE_ATTENDANCE";
export function getSessionUniqueAttendance(uuid: string, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[], language?: string): AsyncAction {
	return {
		type: GET_SESSION_UNIQUE_ATTENDANCE,
		promise: AnalyticsFetcher.GetSessionUniqueAttendance(uuid, token, start, end, audienceLists, language)
	};
}

export const GET_AVERAGE_SESSION_ATTENDANCE = "GET_AVERAGE_SESSION_ATTENDANCE";
export function getEventAverageSessionAttendance(uuid: string, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_AVERAGE_SESSION_ATTENDANCE,
		promise: AnalyticsFetcher.GetEventAverageSessionAttendance(uuid, token, start, end, audienceLists)
	};
}

export const GET_SESSION_REACTIONS = "GET_SESSION_REACTIONS";
export function getSessionReactions(sessionUUID: string, token: string, start?: number, end?: number, audienceList?: string[], language?: string): AsyncAction {
	return {
		type: GET_SESSION_REACTIONS,
		promise: AnalyticsFetcher.GetSessionReactions(sessionUUID, token, start, end, audienceList, language)
	};
}

/* FILTERS */
export const SET_TIME_RANGE = "SET_TIME_RANGE";
export function setTimeRange(dateRange: { start?: Date | undefined, end?: Date | undefined }): Action {
	return {
		type: SET_TIME_RANGE,
		payload: { "start": dateRange.start, "end": dateRange.end }
	};
}

export const SET_AUDIENCE_LISTS_FILTER = "SET_AUDIENCE_LISTS_FILTER";
export function setAudienceListsFilter(list: string[]): Action {
	return {
		type: SET_AUDIENCE_LISTS_FILTER,
		payload: list
	};
}

export const GET_SESSION_RETENTION_TIMELINE = "GET_SESSION_RETENTION_TIMELINE";
export function getSessionRetentionTimeline(uuid: string, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[], language?: string): AsyncAction {
	return {
		type: GET_SESSION_RETENTION_TIMELINE,
		promise: AnalyticsFetcher.GetSessionRetentionTimeline(uuid, token, start, end, audienceLists, language)
	};
}

export const SET_LANGUAGE_FILTER = "SET_LANGUAGE_FILTER";
export function setLanguageFilter(language: string): Action {
	return {
		type: SET_LANGUAGE_FILTER,
		payload: language
	};
}

export const GET_EVENT_SESSIONS_UNIQUE_ATTENDEES = "GET_EVENT_SESSIONS_UNIQUE_ATTENDEES";
export function getEventSessionsListUniqueAttendees(uuid: string, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_EVENT_SESSIONS_UNIQUE_ATTENDEES,
		promise: AnalyticsFetcher.GetEventSessionsListUniqueAttendees(uuid, token, start, end, audienceLists)
	};
}

export const GET_EVENT_SESSIONS_AVG_RETENTION = "GET_EVENT_SESSIONS_AVG_RETENTION";
export function getEventSessionsListAvgRetention(uuid: string, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[]): AsyncAction {
	return {
		type: GET_EVENT_SESSIONS_AVG_RETENTION,
		promise: AnalyticsFetcher.GetEventSessionsListAvgRetention(uuid, token, start, end, audienceLists)
	};
}

export const GET_SESSION_ENGAGEMENT = "GET_SESSION_ENGAGEMENT";
export function getSessionEngagement(uuid: string, token: string, start?: number | undefined, end?: number | undefined, audienceLists?: string[], language?: string): AsyncAction {
	return {
		type: GET_SESSION_ENGAGEMENT,
		promise: AnalyticsFetcher.GetSessionEngagement(uuid, token, start, end, audienceLists, language)
	};
}

export const GET_ACTUAL_SESSION_START_TIME = "GET_ACTUAL_SESSION_START_TIME";
export function getActualSessionStartTime(sessionUuid: string, token: string): AsyncAction {
	return {
		type: GET_ACTUAL_SESSION_START_TIME,
		promise: AnalyticsFetcher.GetActualSessionStartTime(sessionUuid, token)
	};
}

export const GET_PAGINATED_SESSION_ATTENDEES = "GET_PAGINATED_SESSION_ATTENDEES";
export function getPaginatedSessionAttendees(
	sessionUuid: string,
	token: string,
	channel: number,
	start?: number | undefined,
	end?: number | undefined,
	audienceLists?: string[],
	language?: string,
	pageNumber?: number,
	textSearch?: string,
	sortBy?: string,
	sortOrder?: number,
): AsyncAction {
	return {
		type: GET_PAGINATED_SESSION_ATTENDEES,
		promise: AnalyticsFetcher.GetPaginatedSessionAttendees(
			sessionUuid,
			token,
			channel,
			start,
			end,
			audienceLists,
			language,
			pageNumber,
			textSearch,
			sortBy,
			sortOrder
		)
	};
}

export const CLEAR_PAGINATED_SESSION_ATTENDEES = 'CLEAR_PAGINATED_SESSION_ATTENDEES';
export function clearPaginatedSessionAttendees(): Action {
	return {
		type: CLEAR_PAGINATED_SESSION_ATTENDEES,
		payload: null,
	};
}
