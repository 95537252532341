import React from 'react';
import ReactDOM from 'react-dom';

interface IModalPortal {
	children: React.ReactNode;
	className?: string;
}

export default class ModalPortal extends React.Component<IModalPortal> {
	node = document.getElementById('modal-global-container');

	componentDidMount() {
		if (this.props.className) {
			if (this.node) {
				this.node.classList.add(this.props.className);
			}
		}
	}

	componentWillUnmount(): void {
		if (this.node && this.props.className) {
			if (this.node.classList.contains(this.props.className)) {
				this.node.classList.remove(this.props.className);
			}
		}
	}

	render(): any {
		return ReactDOM.createPortal(
			this.props.children,
			this.node as HTMLElement
		);
	}
}