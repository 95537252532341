import { useState } from 'react';
import { useModal } from 'react-modal-hook';
import classNames from 'classnames';
import { useHistory, useParams } from 'react-router';
import { useTranslate } from 'i18n/useTranslationModules';

import { ContentEditors, LanguagesAbbr, Registration, Speaker } from '../../../types/working-model';
import { TypographyItem } from '../../general-ui/typography-item/typography-item';
import { getTextValue } from '../../../utils/utils';
import { ParamsProps } from '../live-event';
import { getStylingOverrides, useLanguageParam } from '../utils';
import { getStartSession } from '../../../utils/get-event-start-time';
import { useTypedSelector } from 'store/reducers/use-typed-selector';
import { StreamedDate } from '../session/session-modules/session-details-modal/session-details-modal';
import SessionTags from '../modules/agenda/session-tags';
import useGetSessionTags from 'utils/use-get-session-tags';
import SpeakersLineup from '../session/session-modules/speakers/speakers-lineup';
import RenderEditorDescription from '@general-ui/editable-text/render-editor-description';
import { SpeakerDetailsModal } from '../containers/speaker-details-modal/speaker-details.modal';
import { OptionalComponent } from 'utils/optional-component';

import "./project-overview-content.scss";

interface Props {
	registration: Registration;
	startDate?: Date | null;
}

export const ProjectOverviewContent = ({
	startDate,
	registration,
}: Props): JSX.Element => {
	const language = useLanguageParam() as LanguagesAbbr;

	const params: ParamsProps = useParams();
	const styling_overrides = registration.style?.styling_overrides;
	const { titleOverrides, descriptionOverrides } = getStylingOverrides(styling_overrides);
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const { t } = useTranslate("session");

	const { title, description } = getTextValue(registration, language);

	const event = eventBundle ?? workingEvent;

	const enableOverviewContent = event?.registration_settings?.enableOverviewContent ?? true;
	const enableSpeakers = event?.registration_settings?.enableSpeakers ?? true;

	const session = event ? getStartSession(event) : undefined;
	const sessionTags = useGetSessionTags(event, session);

	const history = useHistory();
	const [speaker, setSpeaker] = useState<Speaker | null>(null);

	const [showSpeakerDetailsModal, hideSpeakerDetailsModal] = useModal(() => {
		return (
			<>
				{!!speaker && (
					<SpeakerDetailsModal
						template={''}
						onClose={hideSpeakerDetailsModal}
						language={language}
						speaker={speaker as Speaker}
						setSpeaker={setSpeaker}
						params={params}
						onRelatedSessionClick={history.push}
					/>
				)}
			</>
		);
	}, [speaker]);

	return (
		<div className="project-overview-content">
			<OptionalComponent display={enableOverviewContent}>
				<>
					<div className='registration-header'>
						{/* Title */}
						<TypographyItem className={classNames('evt-heading-1 registration-title', titleOverrides)} tagName="h1">
							{title}
						</TypographyItem>

						{/* Streaming Date */}
						<div className={classNames("registration-start-time", { hideStartTime: !startDate })}>
							<div className="live-in-card-text">
								{!!session &&
									<StreamedDate session={session} language={language} t={t} />
								}
							</div>
						</div>
					</div>

					{/* Description */}
					{description && (
						<TypographyItem className={classNames("evt-body-text-1 banner-description", {
							'is-quill': eventBundle?.settings?.content_editor === ContentEditors.quill
						})}>
							<RenderEditorDescription
								description={description}
								descriptionOverrides={classNames("session-live-card-description", descriptionOverrides)}
								useNew={eventBundle?.settings?.content_editor === ContentEditors.quill}
							/>
						</TypographyItem>
					)}

					{/* Speakers */}
					<OptionalComponent display={enableSpeakers}>
						<SpeakersLineup showAllSpeakersFromAllSessions={true} />
					</OptionalComponent>

					{/* Tags */}
					<SessionTags tags={sessionTags} shouldRecalculate={false} />
				</>
			</OptionalComponent>
		</div>

	);
};
