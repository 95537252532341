
import React, { useEffect, useState } from "react";
import classNames from "classnames";

import { updateHomepageModule, updateRegistrationSettings } from "../../../../../../../store/actions/admin/create-event";
import { ERegistrationLayoutTypes, ERegistrationLayoutFullNameTypes, RegistrationLayoutTypes, EHeroSizes, CallToAction, LanguagesAbbr, PageModuleType } from "../../../../../../../types/working-model";
import Icon, { COLORS, ICONS } from "../../../../../../general-ui/icon";
import { useAppDispatch, useTypedSelector } from "../../../../../../../store/reducers/use-typed-selector";
import PanelBlock from "../panel-block";
import { ConditionalWrapper } from "utils/conditional-wrapper";
import { tooltipComponent } from "../session-layout-v2";
import "./registration-panel.scss";
import PillToggleRound from "@general-ui/pill-toggle/pill-toggle-round";
import { OptionalComponent } from "utils/optional-component";
import Structure from "../../structure/structure";

import CallToActionForm from "@general-ui/call-to-action/call-to-action";
import { getDefaultGrabYourSpot } from "store/utils/create-event";
import { getHomepageDefaultLanguage } from "utils/get-language";
import { useLanguageParam } from "components/live-event/utils";

import "./registration-panel.scss";
import CallToActionFormRegistration from "@general-ui/call-to-action/call-to-action-registration";

interface RenderButtonProps {
	icon: string;
	name: string;
	isActive: boolean;
	onClickHandler: () => void | string;
	disabled?: boolean;
	defaultColorOverride?: string;
}

const RenderButton = (
	{
		icon,
		name,
		isActive,
		onClickHandler,
		disabled,
		defaultColorOverride,
	}: RenderButtonProps
) => {
	const defaultColor = defaultColorOverride ?? COLORS.DEFAULT;

	return (
		<ConditionalWrapper condition={!!disabled} wrapper={children => tooltipComponent(children)}>
			<div className={classNames("lemonade-wrapper", { active: isActive, disabled })}>
				<button
					className={classNames(
						'clear no-style inherit-dimensions layout-tabs-button',
						{
							active: isActive,
							disabled
						}
					)}
					onClick={onClickHandler}
				>
					<Icon name={icon} color={defaultColor} size={100} />
					<div className="layout-tabs-button-text">
						{name}
					</div>
				</button>
			</div>
		</ConditionalWrapper>
	);
};

const PanelLayout: React.FC = () => {
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const token = useTypedSelector(state => state.AuthReducer.token);
	const dispatch = useAppDispatch();
	const language = useLanguageParam() as LanguagesAbbr;
	const baseLanguage = getHomepageDefaultLanguage();

	const registrationStyle = workingEvent?.registration_settings?.style;
	const layout = registrationStyle?.layout || ERegistrationLayoutTypes.aboveTheFoldR;
	const calloutModule = workingEvent?.homepage?.modules?.find(module => module.type === PageModuleType.callout);

	const [layoutState, setLayout] = useState<RegistrationLayoutTypes>(layout);
	const [changingLayout, setChangingLayout] = useState(false);
	const [heroSize, setHeroSize] = useState(registrationStyle?.hero_size);
	const [workingCallToAction, setWorkingCallToAction] = useState<CallToAction | null>(calloutModule?.content?.callToAction);
	const [showCallToActionSave, setShowCallToActionSave] = useState(false);
	useEffect(() => {
		function handleSaveRegistrationLayout() {
			if (!workingEvent?.registration_settings || !token) return;
			const updatedSettings = {
				...workingEvent.registration_settings,
				style: {
					...registrationStyle,
					layout: layoutState,
					hero_size: heroSize,
				}
			};
			dispatch(updateRegistrationSettings(token, workingEvent.uuid, updatedSettings));
		}

		if (changingLayout) {
			handleSaveRegistrationLayout();
			setChangingLayout(false);
		}
	}, [changingLayout, dispatch, heroSize, layoutState, registrationStyle, token, workingEvent?.registration_settings, workingEvent?.uuid]);


	function toggleHeroSize() {
		setHeroSize(prev => prev === EHeroSizes.small ? EHeroSizes.large : EHeroSizes.small);
		setChangingLayout(true);
	}

	function onClickHandler(layout: RegistrationLayoutTypes) {
		setLayout(layout);
		setChangingLayout(true);
	}

	function handleWorkingCTAChange(callToAction: CallToAction) {
		setWorkingCallToAction(callToAction);
		setShowCallToActionSave(true);
	}

	return (
		<div className="registration-options-container-v2">
			<PanelBlock title="Page Style" id="layout-page-style">
				<div className="layout-button-group">
					<RenderButton
						icon={ICONS.REG_ABOVE_THE_FOLD_L}
						name={ERegistrationLayoutFullNameTypes.aboveTheFoldL}
						isActive={layout === ERegistrationLayoutTypes.aboveTheFoldL}
						onClickHandler={() => onClickHandler(ERegistrationLayoutTypes.aboveTheFoldL)}
					/>

					<RenderButton
						icon={ICONS.REG_ABOVE_THE_FOLD_R}
						name={ERegistrationLayoutFullNameTypes.aboveTheFoldR}
						isActive={layout === ERegistrationLayoutTypes.aboveTheFoldR}
						onClickHandler={() => onClickHandler(ERegistrationLayoutTypes.aboveTheFoldR)}
					/>

					<RenderButton
						icon={ICONS.REG_BELOW_THE_FOLD_L}
						name={ERegistrationLayoutFullNameTypes.heroL}
						isActive={layout === ERegistrationLayoutTypes.heroL}
						onClickHandler={() => onClickHandler(ERegistrationLayoutTypes.heroL)}
					/>
					<RenderButton
						icon={ICONS.REG_BELOW_THE_FOLD_R}
						name={ERegistrationLayoutFullNameTypes.heroR}
						isActive={layout === ERegistrationLayoutTypes.heroR}
						onClickHandler={() => onClickHandler(ERegistrationLayoutTypes.heroR)}
					/>

					<RenderButton
						icon={ICONS.REG_FULL_PAGE_L}
						name={ERegistrationLayoutFullNameTypes.landingL}
						isActive={layout === ERegistrationLayoutTypes.landingL}
						onClickHandler={() => onClickHandler(ERegistrationLayoutTypes.landingL)}
					/>
					< RenderButton
						icon={ICONS.REG_FULL_PAGE_R}
						name={ERegistrationLayoutFullNameTypes.landingR}
						isActive={layout === ERegistrationLayoutTypes.landingR}
						onClickHandler={() => onClickHandler(ERegistrationLayoutTypes.landingR)}
					/>

					< RenderButton
						icon={ICONS.REG_POPUP}
						name={ERegistrationLayoutFullNameTypes.modal}
						isActive={layout === ERegistrationLayoutTypes.modal}
						onClickHandler={() => onClickHandler(ERegistrationLayoutTypes.modal)}
					/>


				</div>
			</PanelBlock>

			<OptionalComponent display={layout !== ERegistrationLayoutTypes.modal}>
				<PanelBlock title="Tablet/Mobile Registration Callout" id="layout-call-to-action-editor">
					<CallToActionFormRegistration
						workingCTA={workingCallToAction || calloutModule?.content?.callToAction}
						onWorkingCTAChange={handleWorkingCTAChange}
						language={language}
						baseLanguage={baseLanguage}
					/>
					<OptionalComponent display={showCallToActionSave}>
						<button
							onClick={() => {
								if (workingCallToAction && calloutModule) {
									dispatch(
										updateHomepageModule({
											...calloutModule,
											content: {
												...calloutModule.content,
												callToAction: workingCallToAction
											}
										})
									);
								}
								setShowCallToActionSave(false);
							}}
						>
							Save
						</button>
					</OptionalComponent>
				</PanelBlock>
			</OptionalComponent>


			{/* SECTIONS PANEL IS PART OF THE LANDING PAGE BUILDOUT */}
			<OptionalComponent display={
				layout === ERegistrationLayoutTypes.landingL ||
				layout === ERegistrationLayoutTypes.landingR
			}>
				<PanelBlock title="Sections" id="layout-full-page">
					<Structure isRegistrationPage={true} />
				</PanelBlock>
			</OptionalComponent>

			<OptionalComponent display={
				layout === ERegistrationLayoutTypes.heroL ||
				layout === ERegistrationLayoutTypes.heroR
			}>
				<PanelBlock title="Hero Image Size" id="layout-hero-image">
					<PillToggleRound
						selected={heroSize === EHeroSizes.large ? 0 : 1}
						value={heroSize}
						disabled={false}
						onClick={toggleHeroSize}
						leftText="Small"
						rightText="Large"
					/>
				</PanelBlock>
			</OptionalComponent>
		</div>
	);
};

export default PanelLayout;
