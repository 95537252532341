import { useMemo } from 'react';
import { ColorOptions, BrandliveEvent } from '../types/working-model';
import { useTypedSelector } from 'store/reducers/use-typed-selector';

export function parseColor(color: [string, number]): string {
	const [h, opacity] = color;
	const r = '0x' + h[1] + h[2];
	const g = '0x' + h[3] + h[4];
	const b = '0x' + h[5] + h[6];

	return `rgba(${parseInt(r, 16)},${parseInt(g, 16)},${parseInt(
		b,
		16
	)},${opacity})`;
}
function parseColorRGB(color: [string, number]): string {
	const [h] = color;
	const r = '0x' + h[1] + h[2];
	const g = '0x' + h[3] + h[4];
	const b = '0x' + h[5] + h[6];

	return `${parseInt(r, 16)}, ${parseInt(g, 16)}, ${parseInt(b, 16)}`;

}

const defaultColors: ColorOptions = {
	customColor0: ['#000000', 0.5],
	accentColor: ['#000000', 0.5],
	headingTextColor: ['#000000', 0.5],
	backgroundColor: ['#000000', 0.5],
	bodyTextColor: ['#000000', 0.5],
	containerColor: ['#000000', 0.5],
	secondaryAccentColor: ['#000000', 0.5],
	secondaryBackgroundColor: ['#000000', 0.5]
};

//Export this helper functions so that live-event-document-head.tsx has access and the classOverrides and colorVariables only have to be edited in one place
export function getVariablesAndOverrides(event: BrandliveEvent | null): {
	colorVariables: string;
	classOverrides: string;
} {
	// We are overridding the background color for lightmode sessions
	// Per this ticket:  https://brandlive.atlassian.net/browse/BP-3783

	return useMemo(() => {

		const colors = event?.settings?.design?.colors?.colors ?? defaultColors;

		const _colorVariables: string[] = []; // [hex, opacity]
		const _classOverrides: string[] = [];

		Object.entries(colors).forEach(([name, color]) => {
			// Short curcuit if color is falsy  or if it's not an array of [hex, opacity]
			if (!color || !Array.isArray(color) || color.length !== 2) {
				return;
			}
			const parsedColor = parseColor(color);
			_colorVariables.push(`--${name}: ${parsedColor};`);
			_colorVariables.push(`--${name}-rgb: ${parseColorRGB(color)};`);
			//The !important tag needs to stay here.
			//This is the finest detail of customization we can go to so they need to overide everything else. 
			//!important tags should be avoided everywhere else (if at all possible) so that these classes maintain their override power.
			_classOverrides.push(`
				.${name}-background { background-color: ${parsedColor} !important;}
				.${name}-border { border: 1px solid ${parsedColor} !important;}
				.${name}-color, .${name}-color>path, .${name}-color * { color: ${parsedColor} !important; fill: ${parsedColor} !important}
				.${name}-hover:hover { color: rgba(${parseColorRGB(color)}, .5) !important }
			`);
		});


		return {
			colorVariables: _colorVariables.join(' '),
			classOverrides: _classOverrides.join(' ')
		};
	}, [event]);
}
