import classNames from "classnames";
import { SetStateAction, useCallback } from "react";
import { isObject } from "underscore";
import { UploadFile } from "../../../../../../../../connection/uploads";
import { useTypedSelector } from "../../../../../../../../store/reducers/use-typed-selector";
import { CreateSpeaker, LanguagesAbbr, PageModule, SocialLink, SocialPlatforms, Speaker, SpeakerAttribute, TranslateString } from "../../../../../../../../types/working-model";
import { OptionalComponent } from "../../../../../../../../utils/optional-component";
import { socialOptions } from "../../../../../../../../utils/speaker-utils";
import { updateTranslateKey } from "../../../../../../../../utils/utils";
import LargeButton from "../../../../../../../general-ui/button/large-button";
import Icon, { COLORS, ICONS } from "../../../../../../../general-ui/icon";
import SelectInput from "../../../../../../../general-ui/select/select";
import SessionSelectInput from "../../../../../../../general-ui/select/session-select";
import SmallSelect from "../../../../../../../general-ui/select/small-select";
import Switch from "../../../../../../../general-ui/switch/switch";
import TextInput, { Validation } from "../../../../../../../general-ui/text-input/text";
import Textarea from "../../../../../../../general-ui/textarea/textarea";
import { speakerAttributeIconOptions } from "../../../../../../content-creation/speakers/speaker";

import './create-new-speaker.scss';

type TSpeakerTextFields = "first_name" | "last_name";
type TSpeakerTranslateStringFields = "job_title" | "description";

interface CreateNewSpeakerInfoProps {
	workingSpeaker: CreateSpeaker | Speaker;
	setWorkingSpeaker: (speaker: SetStateAction<CreateSpeaker | Speaker>) => void;
	setUploadingImage: (isUploading: SetStateAction<boolean>) => void;
	baseLanguage: LanguagesAbbr;
	language: LanguagesAbbr;
	pageModule?: PageModule;
	workingRelatedSessions: number[];
	setWorkingRelatedSessions: (sessions: SetStateAction<number[]>) => void;
	requiredFields: { [key: string]: Validation };
	setRequiredFields: (requiredFields: SetStateAction<{ [key: string]: Validation }>) => void;
	isOverviewModal?: boolean;
}

const CreateNewSpeakerInfo: React.FC<CreateNewSpeakerInfoProps> = ({
	workingSpeaker,
	setWorkingSpeaker,
	setUploadingImage,
	baseLanguage,
	language,
	workingRelatedSessions,
	setWorkingRelatedSessions,
	requiredFields,
	setRequiredFields,
	isOverviewModal = false,
}) => {
	const user = useTypedSelector(state => state.AuthReducer.user);
	const token = useTypedSelector(state => state.AuthReducer.token);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);

	const uploadImage = useCallback(async (file: File | FileList) => {
		const _file = Array.isArray(file) ? file?.[0] : file;
		if (_file && user && token) {
			setUploadingImage(true);
			const uploaded = await UploadFile(user, token, _file);
			setWorkingSpeaker(prevWorkingSpeaker => ({
				...prevWorkingSpeaker,
				image: uploaded,
				original_image: uploaded,
			}));

			setRequiredFields({
				...requiredFields,
				speakerImage: uploaded ? Validation.normal : Validation.error,
			});
			setUploadingImage(false);
		}
	}, [requiredFields, setRequiredFields, setUploadingImage, setWorkingSpeaker, token, user]);

	const addSocialMediaLink = () => {
		if (workingSpeaker?.social_links && workingSpeaker.social_links.length > 5) {
			return;
		}

		setWorkingSpeaker(prevWorkingSpeaker => {
			if (prevWorkingSpeaker.social_links) {
				return {
					...prevWorkingSpeaker,
					social_links: [...prevWorkingSpeaker.social_links, { platform: '', url: '' }]
				};
			} else {
				return {
					...prevWorkingSpeaker,
					social_links: [{ platform: '', url: '' }]
				};
			}
		});
	};

	const handleTextChange = (field: TSpeakerTextFields) => (e: React.ChangeEvent<HTMLInputElement>) => {
		setWorkingSpeaker(prevWorkingSpeaker => ({
			...prevWorkingSpeaker,
			[field]: e.target.value
		}));
	};

	const handleTranslateStringChange = (field: TSpeakerTranslateStringFields) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setWorkingSpeaker(prevWorkingSpeaker => ({
			...prevWorkingSpeaker,
			[field]: {
				...prevWorkingSpeaker[field],
				...updateTranslateKey({
					translateString: workingSpeaker[field],
					input: e.target.value,
					baseLanguage,
					language,
				})
			}
		}));
	};

	const setSpeakerAttribute = (index: string | number, key: 'icon' | 'attribute' | 'value') => {
		return (option: string | unknown) => {
			const isIcon = key === 'icon';
			const value = isIcon
				? option
				: isObject(option) && option?.target?.value ? option.target.value : '';
			setWorkingSpeaker(preWworkingSpeaker => {
				return {
					...preWworkingSpeaker,
					attributes: preWworkingSpeaker?.attributes?.map((attribute: SpeakerAttribute, i: number) => {
						if (i === Number(index)) {
							attribute[key] = isIcon ? value : updateTranslateKey({
								translateString: (attribute[key] as TranslateString) ?? { base: "", changed: "" },
								input: value,
								baseLanguage,
								language
							});
						}
						return attribute;
					})
				};
			});
		};
	};

	const removeAttribute = (index: number) => {
		return () => {
			setWorkingSpeaker(prevWorkingSpeaker => {

				if (prevWorkingSpeaker?.attributes) {
					return {
						...prevWorkingSpeaker,
						attributes: prevWorkingSpeaker?.attributes.filter((_, i) => i !== index)
					};
				} else {
					return prevWorkingSpeaker;
				}
			});
		};
	};

	const addSpeakerAttribute = () => {
		const defaultTranslateString = { base: '', changed: '' };
		setWorkingSpeaker(prevWorkingSpeaker => {
			if (prevWorkingSpeaker?.attributes) {
				return {
					...prevWorkingSpeaker,
					attributes: [...(prevWorkingSpeaker?.attributes ?? []), { icon: 'info-circle', attribute: defaultTranslateString, value: defaultTranslateString }]
				};
			} else {
				return {
					...prevWorkingSpeaker,
					attributes: [{ icon: 'info-circle', attribute: defaultTranslateString, value: defaultTranslateString }]
				};
			}
		});
	};

	const toggleLinkSocial = () => {
		setWorkingSpeaker(prevWorkingSpeaker => {
			return {
				...prevWorkingSpeaker,
				link_social: !prevWorkingSpeaker.link_social
			};
		});
	};

	const toggleEnableAttributes = () => {
		setWorkingSpeaker(prevWorkingSpeaker => {
			return {
				...prevWorkingSpeaker,
				attributes_enabled: !prevWorkingSpeaker.attributes_enabled
			};
		});
	};

	const setSocialLinkPlatform = (index: number) => {
		return (platform: SocialPlatforms) => {
			setWorkingSpeaker(prevWorkingSpeaker => {
				return {
					...prevWorkingSpeaker,
					social_links: prevWorkingSpeaker.social_links?.map((link: SocialLink, i: number) => {
						if (i === index) {
							link.platform = platform;
						}
						return link;
					})
				};
			});
		};
	};

	const setSocialLinkUrl = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setWorkingSpeaker(prevWorkingSpeaker => {
			return {
				...prevWorkingSpeaker,
				social_links: prevWorkingSpeaker.social_links?.map((link: SocialLink, i: number) => {
					if (i === index) {
						link.url = value;
					}
					return link;
				})
			};
		});
	};

	const removeSocialLink = (index: number) => () => {
		setWorkingSpeaker(prevWorkingSpeaker => {
			if (prevWorkingSpeaker.social_links) {
				return {
					...prevWorkingSpeaker,
					social_links: prevWorkingSpeaker.social_links.filter((_, i: number) => i !== index)
				};
			} else {
				return prevWorkingSpeaker;
			}
		});
	};

	return (
		<div className={classNames("working-speaker", { overview: isOverviewModal })}>
			<div className="speaker-image-and-name speaker-panel-image-and-name">
				<LargeButton
					title="Upload"
					customTitle={isOverviewModal ? (
						<><Icon name={ICONS.ADD_RAINBOW} size={12} color={COLORS.DEFAULT} /><span>Upload photo</span></>
					) : (
						<><Icon name={ICONS.ADD} size={13} color={COLORS.CYAN} /><span>Upload</span></>
					)}
					description={isOverviewModal ? "Use PNG or JPG up to 10 mb" : ""}
					allowedFileTypes={["image/png", "image/jpeg", "image/gif"]}
					onFile={uploadImage}
					style={{
						backgroundImage: `url(${workingSpeaker.image})`,
					}}
					errorOutline={requiredFields.speakerImage === Validation.error}
					hideText={!!workingSpeaker.image}
				/>
				<div className="speaker-name">
					<div className="speaker-name-input-wrapper">
						<TextInput
							placeholder={"Enter first name"}
							label={"First Name*"}
							value={workingSpeaker.first_name}
							onChange={handleTextChange("first_name")}
							onBlur={() => {
								setRequiredFields(prev => ({
									...prev,
									firstName: workingSpeaker.first_name?.trim() ? Validation.normal : Validation.error
								}));
							}}
							id="first_name"

							shouldUpdateValue={true}
							updateValue={workingSpeaker.first_name}
							valid={requiredFields.firstName}
						/>
						<TextInput
							placeholder={"Enter last name"}
							label={"Last Name*"}
							onChange={handleTextChange("last_name")}
							value={workingSpeaker.last_name}
							onBlur={() => {
								setRequiredFields(prev => ({
									...prev,
									lastName: workingSpeaker?.last_name ? Validation.normal : Validation.error
								}));
							}}
							id="last_name"
							shouldUpdateValue={true}
							updateValue={workingSpeaker.last_name}
							valid={requiredFields.lastName}
						/>
					</div>
				</div>

			</div>
			<TextInput
				placeholder={"Enter job title"}
				label={"Job Title*"}
				onBlur={() => {
					setRequiredFields(prev => ({
						...prev,
						jobTitle: (workingSpeaker?.job_title?.[language] || workingSpeaker?.job_title?.base)
							? Validation.normal
							: Validation.error
					}));
				}}
				onChange={handleTranslateStringChange("job_title")}
				value={workingSpeaker.job_title?.[language] as string || workingSpeaker.job_title?.base}
				id="job_title"
				shouldUpdateValue={true}
				valid={requiredFields.jobTitle}
			/>
			<SessionSelectInput
				sessions={workingEvent?.sessions || []}
				label="Related session"
				placeholder="Selected session"
				onChange={setWorkingRelatedSessions}
				preSelectedSessions={workingRelatedSessions}
				ignoreInlineStyling
			/>
			<Textarea
				label={"Description"}
				placeholder={"About speaker"}
				onChange={e => handleTranslateStringChange("description")(e)}
				value={workingSpeaker.description[language] as string || workingSpeaker.description.base}
				shouldUpdateValue={true}
			/>
			<div className="switch-and-label">
				<label className="social-media-label">Speaker Attributes</label>
				<Switch
					value="attributes_enabled"
					on={!!workingSpeaker.attributes_enabled}
					onClick={toggleEnableAttributes}
				/>
			</div>

			<OptionalComponent display={!!workingSpeaker.attributes_enabled}>
				<>
					{workingSpeaker?.attributes?.map((attribute: SpeakerAttribute, index: number) => (
						<div className="speaker-attributes-list" key={index}>
							<SelectInput
								noMaxHeight
								options={speakerAttributeIconOptions}
								selected={attribute.icon || "info-circle"}
								onChange={setSpeakerAttribute(index, "icon")}
								ignoreInlineStyling
								ignorePositioning
							/>
							<TextInput
								placeholder="Name"
								value={attribute.attribute.base}
								onChange={setSpeakerAttribute(index, "attribute")}
								id="attribute-name"
								shouldUpdateValue={true}
								updateValue={attribute.attribute.base}
							/>
							<TextInput
								placeholder="Value"
								value={attribute.value.base}
								onChange={setSpeakerAttribute(index, 'value')}
								id="value"
								shouldUpdateValue={true}
								updateValue={attribute.value.base}
							/>
							<button className="clear" onClick={removeAttribute(index)}>
								<Icon name={ICONS.CLOSE} size={20} color={COLORS.WHITE} />
							</button>
						</div>
					))}
					<button className="small add-attribute" onClick={addSpeakerAttribute}>
						<Icon name={ICONS.ADD} size={12} color={COLORS.WHITE} /> <span>Add</span>
					</button>
				</>
			</OptionalComponent>

			<div className="link-social switch-and-label">
				<label>Link social media</label>

				<Switch
					value="link-social"
					on={workingSpeaker.link_social}
					onClick={toggleLinkSocial}
				/>
			</div>
			{workingSpeaker?.link_social && (
				<>
					{workingSpeaker?.social_links?.map((link: SocialLink, index: number) => (
						<div
							className={"social-media-list"}
							key={index}
						>
							<SmallSelect
								options={socialOptions}
								selected={link.platform}
								placeholder={<>Select social <Icon name={ICONS.KEYBOARD_ARROW_DOWN} color={COLORS.WHITE} size={13} /></>}
								onChange={setSocialLinkPlatform(index)}
								isArrow={false}
							/>
							<TextInput
								placeholder={isOverviewModal ? "Enter URL" : "URL"}
								value={link.url}
								onChange={setSocialLinkUrl(index)}
								id="url"
								shouldUpdateValue={true}
								updateValue={link.url}
							/>
							<button
								className="clear"
								onClick={removeSocialLink(index)}
							>
								<Icon name={ICONS.CLOSE} size={20} color={COLORS.WHITE} />
							</button>
						</div>
					))}
					<button className="small add-attribute" onClick={addSocialMediaLink}>
						<Icon name={ICONS.ADD} size={12} color={COLORS.WHITE} /> <span>Add</span>
					</button>
				</>
			)}
		</div>
	);
};

export default CreateNewSpeakerInfo;