import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { batch, useDispatch } from "react-redux";
import { DndContext, closestCenter, useSensor, useSensors, PointerSensor, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToParentElement } from '@dnd-kit/modifiers';

import { updateRegistrationSteps } from 'store/actions/admin/create-event';
import { RegistrationQuestion, EPermissions, RegistrationStepType } from 'types/working-model';
import { useFieldRequired } from 'utils/registration-required-fields';
import { useTypedSelector } from 'store/reducers/use-typed-selector';
import { } from 'store/actions/admin/create-event';
import { showAlert } from '@general-ui/alert/alert-service';
import { AuthWrapper } from '@admin/authentication/AuthWrapper';
import SortableRegistrationQuestionV2 from './sortable-registration-question-v2';
import NavigationDropdown from '@general-ui/dropdown/navigation-dropdown';
import { Link } from 'react-router-dom';
import { useGetAdminUrl } from 'utils/admin-routing-utils';
import { RegistrationPanelMap } from '@admin/navigation/registration-panel-route-map';
import SortableStepItem from './sortable-step-item';
import { UpdateRegistrationSteps } from 'connection/create-event';

const GeneralInformationPanel: React.FC = () => {
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const token = useTypedSelector(state => state.AuthReducer.token);

	const registrationSteps = useTypedSelector(state => state.CreateEventReducer.workingEvent?.registration_steps);
	const registrationStep = useTypedSelector(state => state.CreateEventReducer.registrationStep);
	const registrationQuestions = useTypedSelector(state => state.RegistrationQuestionsReducer.registrationQuestions);
	const channels = useTypedSelector(state => state.AuthReducer.channels);
	const activeChannel = useTypedSelector(state => state.AuthReducer.user?.active_channel);

	const getAdmin = useGetAdminUrl();
	const isFieldRequired = useFieldRequired();
	const dispatch = useDispatch();

	const [selectedQuestions, setSelectedQuestions] = useState<RegistrationQuestion[]>([]);
	const [requiredQuestions, setRequiredQuestions] = useState<number[]>([]);
	const [expandedRegCard, setExpandedRegCard] = useState(0);

	const isOn = registrationSteps?.find(step => step.type === registrationStep)?.isOn;

	useEffect(() => {
		if (!workingEvent?.registration_steps) return;
		setRequiredQuestions(workingEvent.required_registration_questions ?? []);
		setSelectedQuestions(workingEvent.registration_steps.find(step => step.type === registrationStep)?.questions || []);
	}, [registrationStep, workingEvent?.required_registration_questions, workingEvent?.registration_steps]);


	const limitRegistrationQuestionCreation: boolean = useMemo(() => {
		if (!channels) return false;
		const currentChannel = channels.find((channel) => channel.channel === activeChannel);
		return currentChannel?.limit_registration_question_creation ?? false;
	}, [channels, activeChannel]);


	const selectedIds = selectedQuestions.map((question: RegistrationQuestion) => question.registration_question);

	// change question properties
	const setQuestionRequired = useCallback((question: number, value: number) => {
		const required = Boolean(value);
		if (required) {
			if (isFieldRequired.onAndOptional.includes(question)) {
				showAlert({
					message: "This field must be on and not required.",
					duration: 3000,
					type: "error",
				});
				return;
			}
			setRequiredQuestions([...requiredQuestions, question]);
		} else {
			if (isFieldRequired.on.includes(question)) {
				showAlert({
					message: "This field is required for event features currently in use.",
					duration: 3000,
					type: "error",
				});
				return;
			}
			setRequiredQuestions(requiredQuestions.filter((_question: number) => _question !== question));
		}
	}, [requiredQuestions, isFieldRequired]);

	// drag & sort
	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 1
			}
		})
	);

	const handleQuestionDragEnd = async (event: DragEndEvent): Promise<void> => {
		if (!workingEvent?.registration_steps || !token) return;

		const _selectedQuestions = selectedQuestions.slice();
		const startIndex = _selectedQuestions.findIndex(_q => _q.registration_question.toString() === event?.active?.id);
		const newIndex = _selectedQuestions.findIndex(_q => _q.registration_question.toString() === event?.over?.id);
		const [question] = _selectedQuestions.splice(startIndex, 1);
		_selectedQuestions.splice(newIndex, 0, question);

		const updatedSteps = workingEvent.registration_steps.map(step => {
			if (step.type !== registrationStep) return step;
			return { ...step, questions: _selectedQuestions };
		});

		setSelectedQuestions(_selectedQuestions);

		dispatch(updateRegistrationSteps(updatedSteps));
	};

	const AddQuestionButton = useCallback(() => {
		const questionOptions = [
			{
				path: RegistrationPanelMap['Create Registration Question'],
				label: "Create",
			},
			{
				path: RegistrationPanelMap['Add Registration Question'],
				label: "From Library",
			}
		];

		return (
			<NavigationDropdown
				title="Add Field"
				className="registration-panel-dropdown add-reqistration-field"
				buttonClassName="large"
				yPosition={"bottom"}
				isAsync={true}
			>

				{questionOptions.map((item, index) => (
					<Link
						key={`${index}.${item.label}`}
						className={"creating-registration-quetion"}
						to={{
							pathname: getAdmin({ path: item.path })
						}}>
						{item.label}
					</Link>
				))}
			</NavigationDropdown>
		);

	}, [getAdmin]);

	return (
		<div className="registration-options-container-v2">

			{/* This is the toggle card for turning on or off the step */}
			{registrationStep === RegistrationStepType.profile && <div className="registration-option-v2">
				<SortableStepItem step={{
					type: RegistrationStepType.profile,
					isOn: !!isOn,
				}} />
			</div>}

			<DndContext
				sensors={sensors}
				collisionDetection={closestCenter}
				onDragStart={() => { setExpandedRegCard(0); }}
				onDragEnd={handleQuestionDragEnd}
				modifiers={[restrictToParentElement]}
				autoScroll={false}

			>
				<SortableContext
					items={selectedQuestions.map(q => q.registration_question.toString()) ?? []}
					strategy={verticalListSortingStrategy}
				>
					{selectedQuestions.map((question: RegistrationQuestion, idx: number) => {
						return (
							<SortableRegistrationQuestionV2 key={question.registration_question}
								{...{
									question,
									requiredQuestions,
									selectedIds,
									selectedQuestions,
									setQuestionRequired,
									setSelectedQuestions,
									expandedRegCard,
									setExpandedRegCard,
									setRequiredQuestions
								}}
							/>
						);
					})}
				</SortableContext>
			</DndContext>

			{limitRegistrationQuestionCreation
				? <AuthWrapper allowedRoles={[EPermissions.Owner, EPermissions.Admin]}>
					<AddQuestionButton />
				</AuthWrapper>
				: <AddQuestionButton />
			}
		</div>
	);
};



export default GeneralInformationPanel;
