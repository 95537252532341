import { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router";
import { matchPath, useLocation, useParams, useRouteMatch } from "react-router-dom";
import { latinChars } from "../../../../../../../i18n/transliteration";
import { useTypedSelector } from "../../../../../../../store/reducers/use-typed-selector";
import { Channel, PageModuleGroupModules, SessionPanelLayoutsTypes, TranslateString } from "../../../../../../../types/working-model";
import { useGetAdminUrl } from "../../../../../../../utils/admin-routing-utils";
import { getSessionPanelRouteState } from "../../../../../../../utils/path-utils";
import { usePathname } from "../../../../../../../utils/use-pathname";
import { EEngageItemsType } from "../empty-state-panel/constants/empty-panel";
import { SessionPanelMap } from "../session-panel-route-map";
import { isTranslateString } from "../utils/session-panel.utils";
import type { LocationDescriptor } from "history";

export const useHandleTab = () => {
	const history = useHistory();
	const adminPath = useGetAdminUrl();

	return (path: SessionPanelLayoutsTypes) => {
		history.replace(adminPath({ path: SessionPanelMap[path] }));
	};
};

export const useSessionPanelParams = () => {
	const location = useLocation();

	return useMemo(() => {
		const match = matchPath(location.pathname, {
			path: Object.values(SessionPanelMap)
		});

		return match?.params as { customPath: string, } ?? {};
	}, [location.pathname]);
};

export const useDropdownHandler = (): ((type: EEngageItemsType) => void) => {
	const handlePath = useHandleTab();

	return (type: EEngageItemsType) => {
		switch (type) {
			case EEngageItemsType.FromLibrary:
				handlePath(SessionPanelLayoutsTypes.AddEngagement);
				break;
			case EEngageItemsType.Survey:
				handlePath(SessionPanelLayoutsTypes.CreateSurvey);
				break;
			case EEngageItemsType.Poll:
				handlePath(SessionPanelLayoutsTypes.CreatePoll);
				break;
			case EEngageItemsType.Quiz:
				handlePath(SessionPanelLayoutsTypes.CreateQuiz);
				break;
			case EEngageItemsType.QAndA:
				handlePath(SessionPanelLayoutsTypes.CreateQAndA);
				break;
			case EEngageItemsType.Feedback:
				handlePath(SessionPanelLayoutsTypes.CreateFeedback);
				break;
			default:
				break;
		}
	};
};

export const usePageModuleGroup = () => {
	const modules = useTypedSelector(state => state.CreateSessionReducer.workingSession?.module_grouping);
	const [selectedModule, setSelectedModule] = useState<PageModuleGroupModules | undefined | null>(undefined);
	const match = useRouteMatch<{ customPath?: string }>();
	const [moduleMap, setModuleMap] = useState<Record<string, PageModuleGroupModules>>({});
	const location = useLocation();

	const {
		isEngage,
		isDetails,
		isEducation,
		isExtras
	} = useMemo(() => {
		return getSessionPanelRouteState(location.pathname);
	}, [location.pathname]);

	useEffect(() => {
		if (!modules) {
			return;
		}

		for (const module of modules) {
			if (module.type === 'custom' && isTranslateString(module.name)) {
				latinChars.slugify(module.name.base).then(key => {
					setModuleMap(prev => ({ ...prev, [key]: module }));
				});
			}
		}
	}, [modules]);

	useEffect(() => {
		if (match.url) {
			if (match.params.customPath) {
				setSelectedModule(moduleMap[match.params.customPath] ?? null);
			} else {
				if (isEngage || isDetails || isEducation || isExtras) {
					if (isEngage) {
						setSelectedModule(modules?.find(group => group.type === 'engage'));
					} else if (isDetails) {
						setSelectedModule(modules?.find(group => group.type === 'details'));
					} else if (isEducation) {
						setSelectedModule(modules?.find(group => group.type === 'education'));
					} else if (isExtras) {
						setSelectedModule(modules?.find(group => group.type === 'extras'));
					}
				}
			}
		}
	}, [isDetails, isEducation, isEngage, isExtras, match, moduleMap, modules]);

	return selectedModule;
};

export const usePageModule = () => {
	const { page_module } = useParams<{ customPath?: string, page_module?: string }>();
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);
	const history = useHistory<{ activeModule?: number }>();
	const group = usePageModuleGroup();

	const pageModule = useMemo(() => {
		if (page_module) {
			return workingSession?.modules.find(module => module.id === Number(page_module));
		}

		if (group) {
			return workingSession?.modules.find(module => group.modules?.includes(module.id as number));
		}

		return undefined;
	}, [group, page_module, workingSession?.modules]);

	useEffect(() => {
		if (pageModule && !history.location.state?.activeModule) {
			history.replace(history.location.pathname, { ...history.location.state, activeModule: pageModule.id });
		}
	}, [pageModule, history]);

	return pageModule;
};

export const useComponentIsMounted = () => {
	const mounted = useRef<boolean>(true);

	useEffect(() => {
		return () => {
			mounted.current = false;
		};
	}, []);

	return mounted;
};

export const useSelectedChannel = (): Channel | undefined => {
	const user = useTypedSelector(state => state.AuthReducer.user);
	const channels = useTypedSelector(state => state.AuthReducer.channels);

	return useMemo(() => {
		return channels.find(channel => channel.channel === user?.active_channel);
	}, [user, channels]);
};

type ReplaceFunc = (
	location: LocationDescriptor<{
		currentTab?: string;
		panelTitle?: string | TranslateString;
	}>,
	state?: {
		currentTab?: string;
		panelTitle?: string | TranslateString;
	}
) => void;

type SessionPanelHistoryState = {
	currentTab?: string;
	panelTitle?: TranslateString | string;
}

// on direct navigation to the session panel there's a good possibility that the panel title
// state will be undefined. This will create a default title set as the session's name in the 
// current language.
export const usePanelTitle = () => {
	// history has a tendency to over-fire, so going to store the replace function in a ref
	const history = useHistory<SessionPanelHistoryState>();
	const [historyState, setHistoryState] = useState<SessionPanelHistoryState | undefined>();
	const sessionTitle = useTypedSelector(state => state.CreateSessionReducer.workingSession?.title);
	const pathname = usePathname();
	const isSetRef = useRef<boolean>(false);
	const replaceRef = useRef<ReplaceFunc>();

	useEffect(() => {
		replaceRef.current = history.replace;

		// check for deep equality on the state change to ensure we're not triggering
		// a re-render on every navigation - this is more efficient than a full react render
		setHistoryState(prev => {
			if (JSON.stringify(history.location.state) !== JSON.stringify(prev)) {
				return history.location.state;
			}

			return prev;
		});
	}, [history]);

	useEffect(() => {
		if (!historyState?.panelTitle && !isSetRef.current) {
			replaceRef.current?.(pathname, { ...historyState, panelTitle: sessionTitle, currentTab: 'Content' });
			isSetRef.current = true;
		}
	}, [pathname, historyState, sessionTitle]);
};

export const useFinishNavigate = () => {
	const history = useHistory();

	return (to: string) => {
		if (history.length) {
			history.goBack();
		} else {
			history.replace(to);
		}
	};
};