import { IAnnouncement, IUserSurvey, IUserSurveyDB, IUserSurveyResponse, SqsSocketTypes } from "../types/working-model";
import { Delete, Get, Post, GetHv, PostHv, Put, HvHostMap } from "./helpers";
import { IRegistrationGDPR } from "./registration";


// Event Announcements
export const SaveAnnouncement_V2 = async (content: { [key: string]: any; }, defaultLanguage: string, token: string, channel: number): Promise<any> => {
	return Post(`/v3/admin/channel/${channel}/notifications/announcement_v2`, token, { content, defaultLanguage });
};

export const UpdateAnnouncement_V2 = async (announcement: IAnnouncement, defaultLanguage: string, token: string, channel: number, deleteAnnouncement = false, cancelAnnouncement = false): Promise<any> => {
	return Put(`/v3/admin/channel/${channel}/notifications/announcement_v2`, token, { announcement, deleteAnnouncement, cancelAnnouncement, defaultLanguage }, true);
};

export const SendAnnouncement = async (content: { [key: string]: any; }, token: string): Promise<any> => {
	return Post(`/v3/admin/notifications/announcement`, token, { message_type: SqsSocketTypes.ANNOUNCEMENT, content });
};

export const saveScheduledAnnouncement = (token: string, announcement: Partial<IAnnouncement>): Promise<IAnnouncement> => {
	return Post(`/v3/admin/notifications/scheduled-announcement`, token, announcement);
};

export const getScheduledAnnouncement = (token: string, event_uuid: string): Promise<IAnnouncement[]> => {
	return Get(`/v3/admin/notifications/scheduled-announcement/${event_uuid}`, token);
};

export const updateAnnouncement = (token: string, announcement: IAnnouncement): Promise<IAnnouncement> => {
	return Put(`/v3/admin/notifications/scheduled-announcement`, token, { announcement, uuid: announcement.uuid, deleteAnnouncement: false }, true);
};

export const deleteAnnouncement = (token: string, announcement: IAnnouncement): Promise<IAnnouncement> => {
	return Put(`/v3/admin/notifications/scheduled-announcement`, token, { announcement, uuid: announcement.uuid, deleteAnnouncement: true }, true);
};


// User Surveys
export const SendUserSurvey = async (token: string, survey: IUserSurvey): Promise<IUserSurveyDB> => {
	return Post('/v3/admin/notifications/user-survey', token, survey);
};

export const GetUserSurveySessionRating = async (sessionUuid: string, userProfileUuid: string, token?: string): Promise<any> => {
	return GetHv(HvHostMap.surveys, `/e3-user-survey-session-rating/${sessionUuid}/${userProfileUuid}`, token);
};

export const SaveUserSurveyResponse = async (surveyResponse: IUserSurveyResponse, token?: string): Promise<any> => {
	return PostHv(HvHostMap.surveys, '/e3-user-survey-response', surveyResponse, token);
};

export const GetUserSurveys = async (token: string, sessionID: number): Promise<IUserSurveyDB[]> => {
	return Get(`/v3/admin/notifications/user-survey/${sessionID}`, token);
};

export const UpdateUserSurvey = async (token: string, survey: IUserSurveyDB): Promise<IUserSurveyDB> => {
	return Put('/v3/admin/notifications/user-survey', token, survey, true);
};

export const DeleteUserSurvey = async (token: string, surveyUUID: string): Promise<string | undefined> => {
	const response = await Delete(`/v3/admin/notifications/user-survey/${surveyUUID}`, token);
	if (response) {
		return surveyUUID;
	}
};

export const ConfirmGDPRCookies = async (token: string, registrationUUID: string, data: IRegistrationGDPR) => {
	return PostHv(HvHostMap.surveys, `/e3-confirm-gdpr-cookies/${registrationUUID}`, data, token);
};
