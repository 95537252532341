
import React from "react";
import { Redirect } from 'react-router-dom';
import { RegistrationPanelLayoutsTypes, RegistrationStepType } from "../../../../../../../types/working-model";
import "./registration-panel.scss";
import { useGetAdminUrl } from "utils/admin-routing-utils";
import { useHistoryState } from "utils/use-history-state";
import { PageTabTypeMap, RegistrationPanelMap } from "@admin/navigation/registration-panel-route-map";
import { chooseRegistrationStep } from "store/actions/admin/create-event";
import { useDispatch } from "react-redux";

const ContentPanel: React.FC = () => {
	const adminPath = useGetAdminUrl();
	const historyState = useHistoryState();

	// when the user arrives at the custom tab for the first time, redirect to the first subtab (which may be invisible)
	const getFirstTab = () => {
		const typeMap = PageTabTypeMap();
		const panel = typeMap["Content"];
		const dispatch = useDispatch();
		dispatch(chooseRegistrationStep(RegistrationStepType.general));	

		return adminPath({ path: RegistrationPanelMap[panel as RegistrationPanelLayoutsTypes] });
	};

	return (
		<Redirect to={{ pathname: getFirstTab(), state: historyState }} />
	);
};

export default ContentPanel;
