import PanelWrapper from "./panel-wrapper";
import TopPanelWrapper from "./top-panel-wrapper";
import { useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { RegistrationPanelLayoutsTypes, ERegistrationTabNames } from "types/working-model";
import { PATHNAMES } from "utils/admin-routing-utils";
import PanelLayout from "@admin/create/homepage/navigation/panel/registration/panel-layout";
import PanelSettings from "@admin/create/homepage/navigation/panel/registration/panel-settings";
import EmailConfirmation from "@admin/create/homepage/navigation/panel/settings/registration-settings/email-confirmation";
import PaidTicketingSettings from "@admin/create/homepage/navigation/panel/settings/registration-settings/paid-ticketing";
import SSORegistrationSettings from "@admin/create/homepage/navigation/panel/settings/registration-settings/sso-registration-settings";
import InPersonAttendanceToggle from "@admin/create/homepage/navigation/panel/settings/registration-settings/in-person-attendance-toggle";
import SubTabNavigation from "./subtab-navigation";
import ContentPanel from "@admin/create/homepage/navigation/panel/registration/panel-content";
import GeneralInformationPanel from "@admin/create/homepage/navigation/panel/registration/panel-general-information";
import PanelQuestionLibrary from "@admin/create/homepage/navigation/panel/registration/panel-question-library";
import PanelCreateQuestion from "@admin/create/homepage/navigation/panel/registration/panel-create-question";
import PanelAvatar from "@admin/create/homepage/navigation/panel/registration/panel-avatar";
import PanelAdditionalSettings from "@admin/create/homepage/navigation/panel/registration/panel-additional-settings";
import PanelEmbedCode from "@admin/create/homepage/navigation/panel/registration/card-embedded-code";
import PanelGating from "@admin/create/homepage/navigation/panel/registration/panel-gating";

const registrationBaseRoute = "/admin/events/create/:id/registration/:language";

const path = (p: string) => `${registrationBaseRoute}${p}`;

export type RegistrationPanel = {
	key: string;
	label: string;
	/** ensures panel title always uses "label" instead of module content */
	enforceLabelTitle?: boolean;
	path: string;
	type: "root" | "group",
	deepPathPattern?: string;
	navKey?: ERegistrationTabNames | RegistrationPanelLayoutsTypes;
	rightHeader?: boolean;
	// allowing any purposefully - we don't want to dictate what types of components
	// are allowed here, but passing props into them will need to be done carefully as
	// this component doesn't accept props itself. Ideally, child routes will get their state
	// from either redux, the context, or the path itself 
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	component?: (props: any) => JSX.Element | React.FC<any> | null;
};

// only the top level may contain tabs
export type RegistrationPanelWithTabs = RegistrationPanel & {
	tabs?: RegistrationPanel[];
}

export type RegistrationPanelWithPages = RegistrationPanel & {
	pages?: RegistrationPanelWithPages[];
}

export const _RegistrationPanels: (RegistrationPanel | RegistrationPanelWithTabs | RegistrationPanelWithPages)[] = [
	{
		label: "Content",
		path: path('/content'),
		key: "content",
		type: "group",
		// use for lookups using the react router matcher - all paths must obey this pattern
		component: () => <ContentPanel />,
		navKey: ERegistrationTabNames.Content,
		pages: [
			{
				key: "general-info",
				label: "General Info",
				path: path('/content/general-information'),
				type: "group",
				component: () => <GeneralInformationPanel />,//
				// < PanelSteps />,
				navKey: RegistrationPanelLayoutsTypes.ContentGeneralInformation,
				pages: [
					{
						key: "create-registration-question",
						label: "Create Registration Question",
						path: path('/content/general-information/create-registration-question'),
						type: "group",
						component: () => <PanelCreateQuestion />,
						navKey: RegistrationPanelLayoutsTypes.ContentGeneralInformationCreateQuestion,
					},
					{
						key: "adding-registration-question",
						label: "Add Registration Question",
						path: path('/content/general-information/add-registration-question'),
						type: "group",
						component: () => <PanelQuestionLibrary />,
						navKey: RegistrationPanelLayoutsTypes.ContentGeneralInformationAddQuestion,
					},
				]
			},
			{
				key: "avatar",
				label: "Avatar",
				path: path('/content/avatar'),
				type: "group",
				component: () => <PanelAvatar />,
				navKey: RegistrationPanelLayoutsTypes.ContentAvatar,
			},

			{
				key: "profile",
				label: "Profile",
				path: path('/content/profile'),
				type: "group",
				component: () => <GeneralInformationPanel />,
				navKey: RegistrationPanelLayoutsTypes.ContentProfile,
			},
			{
				key: "ticketing",
				label: "Ticekting",
				path: path('/content/ticketing'),
				type: "group",
				component: () => <PaidTicketingSettings inV2={true} />,
				navKey: RegistrationPanelLayoutsTypes.ContentTicketing,
			},
		],
	},
	{
		label: "Layout",
		path: path('/layout'),
		key: "layout",
		type: "root",
		component: () => {
			return (
				<PanelLayout />
			);
		},
		navKey: ERegistrationTabNames.Layout,
	},
	{
		label: "Settings",
		path: path('/settings'),
		key: "settings",
		type: "root",
		component: () => <PanelSettings />,
		navKey: ERegistrationTabNames.Settings,
		pages: [
			{
				key: "gating",
				label: "Gating",
				path: path('/settings/gating'),
				type: "root",
				component: () => <PanelGating />,
				// <RegistrationGating />, // Just the 
				// <RegistrationSettings />, //
				navKey: RegistrationPanelLayoutsTypes.SettingsGating,
			},

			{
				key: "sso-registration",
				label: "SSO Registration",
				path: PATHNAMES.Event.SSORegistrationSettings,
				type: "root",
				component: () => <SSORegistrationSettings />,
				navKey: RegistrationPanelLayoutsTypes.SettingsSSO,
			},

			{
				key: "registration-emails",
				label: "Registration Emails",
				path: path('/settings/email-confirmation'),
				type: "root",
				component: () => <EmailConfirmation />,
				navKey: RegistrationPanelLayoutsTypes.SettingsEmails,
			},

			{
				key: "additional-settings",
				label: "Additional Settings",
				path: path("/settings/additional-settings"),
				type: "root",
				component: () => <PanelAdditionalSettings />,
				navKey: RegistrationPanelLayoutsTypes.SettingsAdditionalSettings,
				pages: [
					{
						key: "embedded-code",
						label: "Embeded Code",
						path: path('/settings/additional-settings/embedded-code'),
						type: "root",
						component: () => <PanelEmbedCode />,
						navKey: RegistrationPanelLayoutsTypes.SettingsEmbedCode,

					},
				]
			},

			{
				key: "paid-ticketing",
				label: "Paid ticketing",
				path: PATHNAMES.Event.Ticketing,
				type: "root",
				component: () => <PaidTicketingSettings inV2={true} />,
				navKey: RegistrationPanelLayoutsTypes.SettingsPaidTicketing,

			},

			{
				key: "in-person-attendance",
				label: "In Person Attendance",
				path: PATHNAMES.Event.InPersonAttendanceSettings,
				type: "root",
				component: () => <InPersonAttendanceToggle />,
				navKey: RegistrationPanelLayoutsTypes.SettingsInPersonAttendance,

			},
		]
	}
];

export const RegistrationPanelMap = ((): Record<RegistrationPanelLayoutsTypes | ERegistrationTabNames, string> => {
	const map: Record<string, string> = {};

	const addToMap = (panels: RegistrationPanelWithPages[]) => {
		for (const panel of panels) {
			if (panel.navKey) {
				map[panel.navKey] = panel.path;
			}

			if (panel.pages) {
				addToMap(panel.pages);
			}
		}
	};

	addToMap(_RegistrationPanels);
	return map;
})();



export const PageTabTypeMap = (): { [key in string]?: RegistrationPanelLayoutsTypes } => {

	return ({
		["Content"]: RegistrationPanelLayoutsTypes.ContentGeneralInformation,
		["Layout"]: RegistrationPanelLayoutsTypes.Layout,
		["Settings"]: RegistrationPanelLayoutsTypes.SettingsGating,

	});
};

const WrapperTypes = {
	root: TopPanelWrapper,
	group: PanelWrapper
};

export default function RegistrationPanelsRouter() {
	// leaving the door open to modify the router at runtime if needs be
	// but this is not being done right now
	const [panels, /*setPanels*/] = useState(_RegistrationPanels);
	const [routes, setRoutes] = useState<JSX.Element[]>([]);
	const location = useLocation();

	useEffect(() => {
		const _panels: JSX.Element[] = [];

		const addPanels = (level: RegistrationPanelWithPages[]) => {
			for (const panel of level) {
				const Component = panel.component as () => JSX.Element;
				const Wrapper = WrapperTypes[panel.type];
				const canShowTabs = panel.type === "group";

				// we want the longest routes to be first in order (as much as possible)
				// and the longest routs live at the tips of these branches. Using unshift as we traverse
				// deeper in the panel paths will put the longer routes ahead of the shorter ones,
				// which makes route determination easier in react router

				_panels.unshift(
					<Route path={panel.path} exact={true} key={panel.key}>
						<Wrapper
							title={panel.label}
							id={panel.key}
						>
							<>
								{canShowTabs && <SubTabNavigation />}
								{typeof Component === 'function' ? <Component /> : <h1>unmapped</h1>}
							</>
						</Wrapper>
					</Route>
				);

				if (panel.pages) {
					addPanels(panel.pages);
				}
			}
		};

		addPanels(panels);
		setRoutes(_panels);
	}, [panels]);

	return (
		<TransitionGroup className="transition-wrapper">
			<CSSTransition
				key={location.pathname}
				classNames="fade"
				timeout={200}
			>
				<Switch location={location}>
					{routes}
				</Switch>
			</CSSTransition>
		</TransitionGroup>
	);
}