// "standard" is the default when changing templates.
// One layout for each template enum must be called "standard" so that switch will happen correctly.

// All the other layout names are arbitrary
// Since there can be different numbers of layouts for different templates,
// the enums need to be individually created instead of dynamically mapped.

//When you make a new layout enum, please add it to AllLayoutTypes and LayoutTypesMap 
//at the bottom of the file so they can be dynamically generated in the makeLayoutSelectors function

import { PageModule, PageModuleType } from "./working-model";

export enum EditorSizes {
	desktop = 'desktop'
	, tablet = 'tablet'
	, mobile = 'mobile'
}

//Classic, Bold, and Fun and decrecated template names, they are here for backwards comppatability purposes, but should not be used moving forward
export const TemplateNames: Record<string, string> = {
	Classic: 'Apollo',
	Bold: 'Studio 54',
	Fun: 'O2',
	Apollo: "Apollo",
	Studio54: "Studio 54",
	O2: "O2",
	Limelight: "Limelight"
};

//This enum is to allow backward compatability with new Template names as well as format Temp names to be classNames
export const TemplateClassNames: Record<string, string> = {
	"Apollo": "Apollo",
	"apollo": "Apollo",
	"Classic": "Apollo",
	"classic": "Apollo",
	"Studio 54": "Studio54",
	"Studio54": "Studio54",
	"studio 54": "Studio54",
	"studio54": "Studio54",
	"Bold": "Studio54",
	"bold": "Studio54",
	"O2": "O2",
	"o2": "O2",
	"Fun": "O2",
	"fun": "O2",
	"Limelight": "Limelight",
};

export enum HeaderAllTemplates {
	left = 'left',
	center = 'center',
	right = 'right'
}

export enum agendaCalendarViewEnum {
	LIST = 'LIST',
	CALENDAR = 'CALENDAR'
}

export enum MainEventBannerLayoutTypesClassic {
	standard = 'standard',
	right_full = 'right_full',
	center = 'center',
	center_left_text = 'center_left_text',
	center_no_bg = 'center_no_bg',
	left_small = 'left_small',
	right_small = 'right_small'
}
export enum MainEventBannerLayoutTypesBold {
	standard = 'standard',
	right_full = 'right_full',
	center = 'center',
	center_left_text = 'center_left_text',
	center_no_bg = 'center_no_bg',
	left_small = 'left_small',
	right_small = 'right_small'
}
export enum MainEventBannerLayoutTypesFun {
	standard = 'standard',
	right_full = 'right_full',
	center = 'center',
	center_left_text = 'center_left_text',
	center_no_bg = 'center_no_bg',
	left_small = 'left_small',
	right_small = 'right_small'
}

export enum SpeakerLayoutTypesClassic {
	standard = 'standard',
	small_horizontal = 'small_horizontal',
	staggered = 'staggered',
	solid = 'solid',
	small_vertical = 'small_vertical'
}
export enum SpeakerLayoutTypesBold {
	standard = 'standard',
	small_horizontal = 'small_horizontal',
	staggered = 'staggered',
	solid = 'solid',
	small_vertical = 'small_vertical'
}
export enum SpeakerLayoutTypesFun {
	standard = 'standard',
	small_horizontal = 'small_horizontal',
	staggered = 'staggered',
	solid = 'solid',
	small_vertical = 'small_vertical'
}

export enum AgendaLayoutTypesClassic {
	calendar = 'calendar',
	standard = 'standard',
	ltr = 'ltr',
	ltr_even_split = 'ltr_even_split',
	contained_card = 'contained_card',
	vertical_card = 'vertical_card',
	vertical_card_with_featured = 'vertical_card_with_featured',
	standard_no_image = 'standard_no_image',
	contained_card_no_image = 'contained_card_no_image',
	// these were not templates
	// contained_card_hover = 'contained_card_hover',
	// featured_live_session = 'featured_live_session',
}
export enum AgendaLayoutTypesBold {
	calendar = 'calendar',
	standard = 'standard',
	ltr = 'ltr',
	ltr_even_split = 'ltr_even_split',
	contained_card = 'contained_card',
	vertical_card = 'vertical_card',
	vertical_card_with_featured = 'vertical_card_with_featured',
	standard_no_image = 'standard_no_image',
	contained_card_no_image = 'contained_card_no_image',
}
export enum AgendaLayoutTypesFun {
	calendar = 'calendar',
	standard = 'standard',
	ltr = 'ltr',
	ltr_even_split = 'ltr_even_split',
	contained_card = 'contained_card',
	vertical_card = 'vertical_card',
	vertical_card_with_featured = 'vertical_card_with_featured',
	standard_no_image = 'standard_no_image',
	contained_card_no_image = 'contained_card_no_image',
}

export enum AboutLayoutTypesClassic {
	standard = 'standard',
	standard_flip = 'standard_flip',
	standard_full = 'standard_full',
	standard_full_flip = 'standard_full_flip',
	center = 'center',
	center_full = 'center_full',
	center_split = 'center_split'
}
export enum AboutLayoutTypesBold {
	standard = 'standard',
	standard_flip = 'standard_flip',
	standard_full = 'standard_full',
	standard_full_flip = 'standard_full_flip',
	center = 'center',
	center_full = 'center_full',
	center_split = 'center_split'
}
export enum AboutLayoutTypesFun {
	standard = 'standard',
	standard_flip = 'standard_flip',
	standard_full = 'standard_full',
	standard_full_flip = 'standard_full_flip',
	center = 'center',
	center_full = 'center_full',
	center_split = 'center_split'
}

export enum ProductsLayoutTypesClassic {
	standard = 'standard',
	grid = 'grid',
	vertical_list = 'vertical_list'
}
export enum ProductsLayoutTypesBold {
	standard = 'standard',
	grid = 'grid',
	vertical_list = 'vertical_list'
}
export enum ProductsLayoutTypesFun {
	standard = 'standard',
	grid = 'grid',
	vertical_list = 'vertical_list'
}

export enum VideosLayoutTypesClassic {
	standard = 'standard',
	wide = 'wide',
	full = 'full',
	ltr = 'ltr'
}
export enum VideosLayoutTypesBold {
	standard = 'standard',
	wide = 'wide',
	full = 'full',
	ltr = 'ltr'
}
export enum VideosLayoutTypesFun {
	standard = 'standard',
	wide = 'wide',
	full = 'full',
	ltr = 'ltr'
}

export enum SponsorsLayoutTypesClassic {
	standard = 'standard',
	card_full = 'card_full',
	card_short = 'card_short',
	split = 'split',
}
export enum SponsorsLayoutTypesBold {
	standard = 'standard',
	card_full = 'card_full',
	card_short = 'card_short',
	split = 'split',
}
export enum SponsorsLayoutTypesFun {
	standard = 'standard',
	card_full = 'card_full',
	card_short = 'card_short',
	split = 'split',
}

export enum CalloutLayoutTypesClassic {
	standard = 'standard',
	no_border = 'no_border',
	custom_background = 'custom_background',
	ltr = 'ltr'
}
export enum CalloutLayoutTypesBold {
	standard = 'standard',
	no_border = 'no_border',
	custom_background = 'custom_background',
	ltr = 'ltr'
}
export enum CalloutLayoutTypesFun {
	standard = 'standard',
	no_border = 'no_border',
	custom_background = 'custom_background',
	ltr = 'ltr'
}

export enum AccordionLayoutTypes {
	standard = 'standard',
}

export type AllLayoutTypes =
	| SpeakerLayoutTypesClassic
	| AgendaLayoutTypesClassic
	| AboutLayoutTypesClassic
	| ProductsLayoutTypesClassic
	| SponsorsLayoutTypesClassic
	| CalloutLayoutTypesClassic
	| MainEventBannerLayoutTypesClassic
	| VideosLayoutTypesClassic
	| SpeakerLayoutTypesBold
	| AgendaLayoutTypesBold
	| AboutLayoutTypesBold
	| ProductsLayoutTypesBold
	| SponsorsLayoutTypesBold
	| CalloutLayoutTypesBold
	| MainEventBannerLayoutTypesBold
	| VideosLayoutTypesBold
	| SpeakerLayoutTypesFun
	| AgendaLayoutTypesFun
	| AboutLayoutTypesFun
	| ProductsLayoutTypesFun
	| SponsorsLayoutTypesFun
	| CalloutLayoutTypesFun
	| MainEventBannerLayoutTypesFun
	| VideosLayoutTypesFun
	| AccordionLayoutTypes;

export const LayoutTypesMap = {
	[TemplateNames.Classic]: {
		[PageModuleType.speakers]: SpeakerLayoutTypesClassic,
		[PageModuleType.agenda]: AgendaLayoutTypesClassic,
		[PageModuleType.about]: AboutLayoutTypesClassic,
		[PageModuleType.products]: ProductsLayoutTypesClassic,
		[PageModuleType.sponsors]: SponsorsLayoutTypesClassic,
		[PageModuleType.callout]: CalloutLayoutTypesClassic,
		[PageModuleType.main_event_banner]: MainEventBannerLayoutTypesClassic,
		[PageModuleType.videos]: VideosLayoutTypesClassic,
		[PageModuleType.broadcast]: {},
		[PageModuleType.questions]: {},
		[PageModuleType.documents]: {},
		[PageModuleType.feed]: {},
		[PageModuleType.embed_widget]: {},
		[PageModuleType.slideshows]: {},
		[PageModuleType.quizzes]: {},
		[PageModuleType.survey]: {},
		[PageModuleType.description]: {},
		[PageModuleType.similar_sessions]: {},
		[PageModuleType.blank]: {},
		[PageModuleType.people]: {},
		[PageModuleType.breakout_room]: {}, //needed to show breakout room tab in breakout session
		[PageModuleType.accordion]: AccordionLayoutTypes,
	},
	[TemplateNames.Limelight]: {
		[PageModuleType.speakers]: SpeakerLayoutTypesClassic,
		[PageModuleType.agenda]: AgendaLayoutTypesClassic,
		[PageModuleType.about]: AboutLayoutTypesClassic,
		[PageModuleType.products]: ProductsLayoutTypesClassic,
		[PageModuleType.sponsors]: SponsorsLayoutTypesClassic,
		[PageModuleType.callout]: CalloutLayoutTypesClassic,
		[PageModuleType.main_event_banner]: MainEventBannerLayoutTypesClassic,
		[PageModuleType.videos]: VideosLayoutTypesClassic,
		[PageModuleType.broadcast]: {},
		[PageModuleType.questions]: {},
		[PageModuleType.documents]: {},
		[PageModuleType.feed]: {},
		[PageModuleType.embed_widget]: {},
		[PageModuleType.slideshows]: {},
		[PageModuleType.quizzes]: {},
		[PageModuleType.survey]: {},
		[PageModuleType.description]: {},
		[PageModuleType.similar_sessions]: {},
		[PageModuleType.blank]: {},
		[PageModuleType.people]: {},
		[PageModuleType.breakout_room]: {}, //needed to show breakout room tab in breakout session
		[PageModuleType.accordion]: AccordionLayoutTypes,
	},
	[TemplateNames.Bold]: {
		[PageModuleType.speakers]: SpeakerLayoutTypesBold,
		[PageModuleType.agenda]: AgendaLayoutTypesBold,
		[PageModuleType.about]: AboutLayoutTypesBold,
		[PageModuleType.products]: ProductsLayoutTypesBold,
		[PageModuleType.sponsors]: SponsorsLayoutTypesBold,
		[PageModuleType.callout]: CalloutLayoutTypesBold,
		[PageModuleType.main_event_banner]: MainEventBannerLayoutTypesBold,
		[PageModuleType.videos]: VideosLayoutTypesBold,
		[PageModuleType.broadcast]: {},
		[PageModuleType.questions]: {},
		[PageModuleType.documents]: {},
		[PageModuleType.feed]: {},
		[PageModuleType.embed_widget]: {},
		[PageModuleType.slideshows]: {},
		[PageModuleType.quizzes]: {},
		[PageModuleType.survey]: {},
		[PageModuleType.description]: {},
		[PageModuleType.similar_sessions]: {},
		[PageModuleType.blank]: {},
		[PageModuleType.people]: {},
		[PageModuleType.breakout_room]: {},
		[PageModuleType.accordion]: AccordionLayoutTypes,
	},
	[TemplateNames.Fun]: {
		[PageModuleType.speakers]: SpeakerLayoutTypesFun,
		[PageModuleType.agenda]: AgendaLayoutTypesFun,
		[PageModuleType.about]: AboutLayoutTypesFun,
		[PageModuleType.products]: ProductsLayoutTypesFun,
		[PageModuleType.sponsors]: SponsorsLayoutTypesFun,
		[PageModuleType.callout]: CalloutLayoutTypesFun,
		[PageModuleType.main_event_banner]: MainEventBannerLayoutTypesFun,
		[PageModuleType.videos]: VideosLayoutTypesFun,
		[PageModuleType.broadcast]: {},
		[PageModuleType.questions]: {},
		[PageModuleType.documents]: {},
		[PageModuleType.feed]: {},
		[PageModuleType.embed_widget]: {},
		[PageModuleType.slideshows]: {},
		[PageModuleType.quizzes]: {},
		[PageModuleType.survey]: {},
		[PageModuleType.description]: {},
		[PageModuleType.similar_sessions]: {},
		[PageModuleType.blank]: {},
		[PageModuleType.people]: {},
		[PageModuleType.breakout_room]: {},
		[PageModuleType.accordion]: AccordionLayoutTypes,
	}
};

export enum TextAlignmentsEnum {
	center = 'center',
	left = 'left',
	right = 'right',
	justify = 'justify',
}

interface IDefaultModuleContentTextAlign {
	[key: string]: {
		[key: string]: {
			[key: string]: {
				description: TextAlignmentsEnum;
			}
		}
	}
}
// this is used to determine the text-align defaults for module content
export const defaultModuleContentTextAlign: IDefaultModuleContentTextAlign = {
	[TemplateNames.Classic]: {
		[PageModuleType.main_event_banner]: {
			[MainEventBannerLayoutTypesClassic.center]: {
				description: TextAlignmentsEnum.center,
			},
			[MainEventBannerLayoutTypesClassic.center_no_bg]: {
				description: TextAlignmentsEnum.center,
			},
			[MainEventBannerLayoutTypesClassic.center_left_text]: {
				description: TextAlignmentsEnum.left,
			},
			[MainEventBannerLayoutTypesClassic.standard]: {
				description: TextAlignmentsEnum.left,
			},
			[MainEventBannerLayoutTypesClassic.right_full]: {
				description: TextAlignmentsEnum.left,
			},
			[MainEventBannerLayoutTypesClassic.left_small]: {
				description: TextAlignmentsEnum.left,
			},
			[MainEventBannerLayoutTypesClassic.right_small]: {
				description: TextAlignmentsEnum.left,
			},
		},
		[PageModuleType.about]: {
			[AboutLayoutTypesClassic.center]: {
				description: TextAlignmentsEnum.center,
			},
			[AboutLayoutTypesClassic.center_full]: {
				description: TextAlignmentsEnum.center,
			},
			[AboutLayoutTypesClassic.center_split]: {
				description: TextAlignmentsEnum.left,
			},
			[AboutLayoutTypesClassic.standard]: {
				description: TextAlignmentsEnum.left,
			},
			[AboutLayoutTypesClassic.standard_flip]: {
				description: TextAlignmentsEnum.left,
			},
			[AboutLayoutTypesClassic.standard_full]: {
				description: TextAlignmentsEnum.left,
			},
			[AboutLayoutTypesClassic.standard_full_flip]: {
				description: TextAlignmentsEnum.left,
			},
		},
		[PageModuleType.agenda]: {
			[AgendaLayoutTypesClassic.contained_card]: {
				description: TextAlignmentsEnum.center,
			},
			[AgendaLayoutTypesClassic.contained_card_no_image]: {
				description: TextAlignmentsEnum.center,
			},
			[AgendaLayoutTypesClassic.ltr]: {
				description: TextAlignmentsEnum.center,
			},
			[AgendaLayoutTypesClassic.ltr_even_split]: {
				description: TextAlignmentsEnum.center,
			},
			[AgendaLayoutTypesClassic.standard]: {
				description: TextAlignmentsEnum.center,
			},
			[AgendaLayoutTypesClassic.standard_no_image]: {
				description: TextAlignmentsEnum.center,
			},
			[AgendaLayoutTypesClassic.vertical_card]: {
				description: TextAlignmentsEnum.center,
			},
			[AgendaLayoutTypesClassic.vertical_card_with_featured]: {
				description: TextAlignmentsEnum.center,
			},
			[AgendaLayoutTypesClassic.calendar]: {
				description: TextAlignmentsEnum.center
			}
		},
		[PageModuleType.speakers]: {
			[SpeakerLayoutTypesClassic.small_horizontal]: {
				description: TextAlignmentsEnum.center
			},
			[SpeakerLayoutTypesClassic.small_vertical]: {
				description: TextAlignmentsEnum.center
			},
			[SpeakerLayoutTypesClassic.solid]: {
				description: TextAlignmentsEnum.center
			},
			[SpeakerLayoutTypesClassic.staggered]: {
				description: TextAlignmentsEnum.center
			},
			[SpeakerLayoutTypesClassic.standard]: {
				description: TextAlignmentsEnum.center
			},
		},
		[PageModuleType.products]: {
			[ProductsLayoutTypesClassic.grid]: {
				description: TextAlignmentsEnum.left,
			},
			[ProductsLayoutTypesClassic.standard]: {
				description: TextAlignmentsEnum.left,
			},
			[ProductsLayoutTypesClassic.vertical_list]: {
				description: TextAlignmentsEnum.left,
			},
		},
		[PageModuleType.videos]: {
			[VideosLayoutTypesClassic.full]: {
				description: TextAlignmentsEnum.left,
			},
			[VideosLayoutTypesClassic.ltr]: {
				description: TextAlignmentsEnum.left,
			},
			[VideosLayoutTypesClassic.standard]: {
				description: TextAlignmentsEnum.center,
			},
			[VideosLayoutTypesClassic.wide]: {
				description: TextAlignmentsEnum.center,
			},
		},
		[PageModuleType.sponsors]: {
			[SponsorsLayoutTypesClassic.card_full]: {
				description: TextAlignmentsEnum.center,
			},
			[SponsorsLayoutTypesClassic.card_short]: {
				description: TextAlignmentsEnum.center,
			},
			[SponsorsLayoutTypesClassic.split]: {
				description: TextAlignmentsEnum.left,
			},
			[SponsorsLayoutTypesClassic.standard]: {
				description: TextAlignmentsEnum.left,
			},
		},
		[PageModuleType.accordion]: {
			[AccordionLayoutTypes.standard]: {
				description: TextAlignmentsEnum.center,
			},
		},
	},
	[TemplateNames[TemplateNames.Limelight]]: {
		[PageModuleType.main_event_banner]: {
			[MainEventBannerLayoutTypesClassic.center]: {
				description: TextAlignmentsEnum.center,
			},
			[MainEventBannerLayoutTypesClassic.center_no_bg]: {
				description: TextAlignmentsEnum.center,
			},
			[MainEventBannerLayoutTypesClassic.center_left_text]: {
				description: TextAlignmentsEnum.left,
			},
			[MainEventBannerLayoutTypesClassic.standard]: {
				description: TextAlignmentsEnum.left,
			},
			[MainEventBannerLayoutTypesClassic.right_full]: {
				description: TextAlignmentsEnum.left,
			},
			[MainEventBannerLayoutTypesClassic.left_small]: {
				description: TextAlignmentsEnum.left,
			},
			[MainEventBannerLayoutTypesClassic.right_small]: {
				description: TextAlignmentsEnum.left,
			},
		},
		[PageModuleType.about]: {
			[AboutLayoutTypesClassic.center]: {
				description: TextAlignmentsEnum.center,
			},
			[AboutLayoutTypesClassic.center_full]: {
				description: TextAlignmentsEnum.center,
			},
			[AboutLayoutTypesClassic.center_split]: {
				description: TextAlignmentsEnum.left,
			},
			[AboutLayoutTypesClassic.standard]: {
				description: TextAlignmentsEnum.left,
			},
			[AboutLayoutTypesClassic.standard_flip]: {
				description: TextAlignmentsEnum.left,
			},
			[AboutLayoutTypesClassic.standard_full]: {
				description: TextAlignmentsEnum.left,
			},
			[AboutLayoutTypesClassic.standard_full_flip]: {
				description: TextAlignmentsEnum.left,
			},
		},
		[PageModuleType.agenda]: {
			[AgendaLayoutTypesClassic.contained_card]: {
				description: TextAlignmentsEnum.center,
			},
			[AgendaLayoutTypesClassic.contained_card_no_image]: {
				description: TextAlignmentsEnum.center,
			},
			[AgendaLayoutTypesClassic.ltr]: {
				description: TextAlignmentsEnum.center,
			},
			[AgendaLayoutTypesClassic.ltr_even_split]: {
				description: TextAlignmentsEnum.center,
			},
			[AgendaLayoutTypesClassic.standard]: {
				description: TextAlignmentsEnum.center,
			},
			[AgendaLayoutTypesClassic.standard_no_image]: {
				description: TextAlignmentsEnum.center,
			},
			[AgendaLayoutTypesClassic.vertical_card]: {
				description: TextAlignmentsEnum.center,
			},
			[AgendaLayoutTypesClassic.vertical_card_with_featured]: {
				description: TextAlignmentsEnum.center,
			},
			[AgendaLayoutTypesClassic.calendar]: {
				description: TextAlignmentsEnum.center
			}
		},
		[PageModuleType.speakers]: {
			[SpeakerLayoutTypesClassic.small_horizontal]: {
				description: TextAlignmentsEnum.center
			},
			[SpeakerLayoutTypesClassic.small_vertical]: {
				description: TextAlignmentsEnum.center
			},
			[SpeakerLayoutTypesClassic.solid]: {
				description: TextAlignmentsEnum.center
			},
			[SpeakerLayoutTypesClassic.staggered]: {
				description: TextAlignmentsEnum.center
			},
			[SpeakerLayoutTypesClassic.standard]: {
				description: TextAlignmentsEnum.center
			},
		},
		[PageModuleType.products]: {
			[ProductsLayoutTypesClassic.grid]: {
				description: TextAlignmentsEnum.left,
			},
			[ProductsLayoutTypesClassic.standard]: {
				description: TextAlignmentsEnum.left,
			},
			[ProductsLayoutTypesClassic.vertical_list]: {
				description: TextAlignmentsEnum.left,
			},
		},
		[PageModuleType.videos]: {
			[VideosLayoutTypesClassic.full]: {
				description: TextAlignmentsEnum.left,
			},
			[VideosLayoutTypesClassic.ltr]: {
				description: TextAlignmentsEnum.left,
			},
			[VideosLayoutTypesClassic.standard]: {
				description: TextAlignmentsEnum.center,
			},
			[VideosLayoutTypesClassic.wide]: {
				description: TextAlignmentsEnum.center,
			},
		},
		[PageModuleType.sponsors]: {
			[SponsorsLayoutTypesClassic.card_full]: {
				description: TextAlignmentsEnum.center,
			},
			[SponsorsLayoutTypesClassic.card_short]: {
				description: TextAlignmentsEnum.center,
			},
			[SponsorsLayoutTypesClassic.split]: {
				description: TextAlignmentsEnum.left,
			},
			[SponsorsLayoutTypesClassic.standard]: {
				description: TextAlignmentsEnum.left,
			},
		},
		[PageModuleType.accordion]: {
			[AccordionLayoutTypes.standard]: {
				description: TextAlignmentsEnum.center,
			},
		},
	},
	[TemplateNames.O2]: {
		[PageModuleType.main_event_banner]: {
			[MainEventBannerLayoutTypesFun.center]: {
				description: TextAlignmentsEnum.center,
			},
			[MainEventBannerLayoutTypesFun.center_left_text]: {
				description: TextAlignmentsEnum.left,
			},
			[MainEventBannerLayoutTypesFun.center_no_bg]: {
				description: TextAlignmentsEnum.center,
			},
			[MainEventBannerLayoutTypesFun.left_small]: {
				description: TextAlignmentsEnum.left,
			},
			[MainEventBannerLayoutTypesFun.right_full]: {
				description: TextAlignmentsEnum.left,
			},
			[MainEventBannerLayoutTypesFun.right_small]: {
				description: TextAlignmentsEnum.left,
			},
			[MainEventBannerLayoutTypesFun.standard]: {
				description: TextAlignmentsEnum.left,
			},
		},
		[PageModuleType.speakers]: {
			[SpeakerLayoutTypesFun.small_horizontal]: {
				description: TextAlignmentsEnum.center,
			},
			[SpeakerLayoutTypesFun.small_vertical]: {
				description: TextAlignmentsEnum.center,
			},
			[SpeakerLayoutTypesFun.solid]: {
				description: TextAlignmentsEnum.center,
			},
			[SpeakerLayoutTypesFun.staggered]: {
				description: TextAlignmentsEnum.center,
			},
			[SpeakerLayoutTypesFun.standard]: {
				description: TextAlignmentsEnum.center,
			},
		},
		[PageModuleType.agenda]: {
			[AgendaLayoutTypesFun.contained_card]: {
				description: TextAlignmentsEnum.center,
			},
			[AgendaLayoutTypesFun.contained_card_no_image]: {
				description: TextAlignmentsEnum.center,
			},
			[AgendaLayoutTypesFun.ltr]: {
				description: TextAlignmentsEnum.center,
			},
			[AgendaLayoutTypesFun.ltr_even_split]: {
				description: TextAlignmentsEnum.center,
			},
			[AgendaLayoutTypesFun.standard]: {
				description: TextAlignmentsEnum.center,
			},
			[AgendaLayoutTypesFun.standard_no_image]: {
				description: TextAlignmentsEnum.center,
			},
			[AgendaLayoutTypesFun.vertical_card]: {
				description: TextAlignmentsEnum.center,
			},
			[AgendaLayoutTypesFun.vertical_card_with_featured]: {
				description: TextAlignmentsEnum.center,
			},
			[AgendaLayoutTypesFun.calendar]: {
				description: TextAlignmentsEnum.center
			}
		},
		[PageModuleType.products]: {
			[ProductsLayoutTypesFun.grid]: {
				description: TextAlignmentsEnum.left,
			},
			[ProductsLayoutTypesFun.standard]: {
				description: TextAlignmentsEnum.left,
			},
			[ProductsLayoutTypesFun.vertical_list]: {
				description: TextAlignmentsEnum.left,
			},
		},
		[PageModuleType.videos]: {
			[VideosLayoutTypesFun.full]: {
				description: TextAlignmentsEnum.left,
			},
			[VideosLayoutTypesFun.ltr]: {
				description: TextAlignmentsEnum.left,
			},
			[VideosLayoutTypesFun.standard]: {
				description: TextAlignmentsEnum.center,
			},
			[VideosLayoutTypesFun.wide]: {
				description: TextAlignmentsEnum.center,
			},
		},
		[PageModuleType.sponsors]: {
			[SponsorsLayoutTypesFun.card_full]: {
				description: TextAlignmentsEnum.center,
			},
			[SponsorsLayoutTypesFun.card_short]: {
				description: TextAlignmentsEnum.center,
			},
			[SponsorsLayoutTypesFun.split]: {
				description: TextAlignmentsEnum.left,
			},
			[SponsorsLayoutTypesFun.standard]: {
				description: TextAlignmentsEnum.left,
			},
		},
		[PageModuleType.callout]: {
			[CalloutLayoutTypesFun.custom_background]: {
				description: TextAlignmentsEnum.left,
			},
			[CalloutLayoutTypesFun.ltr]: {
				description: TextAlignmentsEnum.left,
			},
			[CalloutLayoutTypesFun.no_border]: {
				description: TextAlignmentsEnum.left,
			},
			[CalloutLayoutTypesFun.standard]: {
				description: TextAlignmentsEnum.left,
			},
		},
		[PageModuleType.about]: {
			[AboutLayoutTypesFun.center]: {
				description: TextAlignmentsEnum.center,
			},
			[AboutLayoutTypesFun.center_full]: {
				description: TextAlignmentsEnum.center,
			},
			[AboutLayoutTypesFun.center_split]: {
				description: TextAlignmentsEnum.left,
			},
			[AboutLayoutTypesFun.standard]: {
				description: TextAlignmentsEnum.left,
			},
			[AboutLayoutTypesFun.standard_flip]: {
				description: TextAlignmentsEnum.left,
			},
			[AboutLayoutTypesFun.standard_full]: {
				description: TextAlignmentsEnum.left,
			},
			[AboutLayoutTypesFun.standard_full_flip]: {
				description: TextAlignmentsEnum.left,
			},
		},
		[PageModuleType.accordion]: {
			[AccordionLayoutTypes.standard]: {
				description: TextAlignmentsEnum.center,
			},
		},
	},
	[TemplateClassNames[TemplateNames.Studio54]]: {
		[PageModuleType.main_event_banner]: {
			[MainEventBannerLayoutTypesBold.center]: {
				description: TextAlignmentsEnum.center,
			},
			[MainEventBannerLayoutTypesBold.center_left_text]: {
				description: TextAlignmentsEnum.left,
			},
			[MainEventBannerLayoutTypesBold.center_no_bg]: {
				description: TextAlignmentsEnum.center,
			},
			[MainEventBannerLayoutTypesBold.left_small]: {
				description: TextAlignmentsEnum.left,
			},
			[MainEventBannerLayoutTypesBold.right_full]: {
				description: TextAlignmentsEnum.left,
			},
			[MainEventBannerLayoutTypesBold.right_small]: {
				description: TextAlignmentsEnum.left,
			},
			[MainEventBannerLayoutTypesBold.standard]: {
				description: TextAlignmentsEnum.left,
			},
		},
		[PageModuleType.speakers]: {
			[SpeakerLayoutTypesBold.small_horizontal]: {
				description: TextAlignmentsEnum.center,
			},
			[SpeakerLayoutTypesBold.small_vertical]: {
				description: TextAlignmentsEnum.center,
			},
			[SpeakerLayoutTypesBold.solid]: {
				description: TextAlignmentsEnum.center,
			},
			[SpeakerLayoutTypesBold.staggered]: {
				description: TextAlignmentsEnum.center,
			},
			[SpeakerLayoutTypesBold.standard]: {
				description: TextAlignmentsEnum.center,
			},
		},
		[PageModuleType.agenda]: {
			[AgendaLayoutTypesBold.contained_card]: {
				description: TextAlignmentsEnum.center,
			},
			[AgendaLayoutTypesBold.contained_card_no_image]: {
				description: TextAlignmentsEnum.center,
			},
			[AgendaLayoutTypesBold.ltr]: {
				description: TextAlignmentsEnum.center,
			},
			[AgendaLayoutTypesBold.ltr_even_split]: {
				description: TextAlignmentsEnum.center,
			},
			[AgendaLayoutTypesBold.standard]: {
				description: TextAlignmentsEnum.center,
			},
			[AgendaLayoutTypesBold.standard_no_image]: {
				description: TextAlignmentsEnum.center,
			},
			[AgendaLayoutTypesBold.vertical_card]: {
				description: TextAlignmentsEnum.center,
			},
			[AgendaLayoutTypesBold.vertical_card_with_featured]: {
				description: TextAlignmentsEnum.center,
			},
			[AgendaLayoutTypesBold.calendar]: {
				description: TextAlignmentsEnum.center
			}
		},
		[PageModuleType.about]: {
			[AboutLayoutTypesBold.center]: {
				description: TextAlignmentsEnum.center,
			},
			[AboutLayoutTypesBold.center_full]: {
				description: TextAlignmentsEnum.center,
			},
			[AboutLayoutTypesBold.center_split]: {
				description: TextAlignmentsEnum.left,
			},
			[AboutLayoutTypesBold.standard]: {
				description: TextAlignmentsEnum.left,
			},
			[AboutLayoutTypesBold.standard_flip]: {
				description: TextAlignmentsEnum.left,
			},
			[AboutLayoutTypesBold.standard_full]: {
				description: TextAlignmentsEnum.left,
			},
			[AboutLayoutTypesBold.standard_full_flip]: {
				description: TextAlignmentsEnum.left,
			},
		},
		[PageModuleType.products]: {
			[ProductsLayoutTypesBold.grid]: {
				description: TextAlignmentsEnum.left,
			},
			[ProductsLayoutTypesBold.standard]: {
				description: TextAlignmentsEnum.left,
			},
			[ProductsLayoutTypesBold.vertical_list]: {
				description: TextAlignmentsEnum.left,
			},
		},
		[PageModuleType.videos]: {
			[VideosLayoutTypesBold.full]: {
				description: TextAlignmentsEnum.left,
			},
			[VideosLayoutTypesBold.ltr]: {
				description: TextAlignmentsEnum.left,
			},
			[VideosLayoutTypesBold.standard]: {
				description: TextAlignmentsEnum.center,
			},
			[VideosLayoutTypesBold.wide]: {
				description: TextAlignmentsEnum.center,
			},
		},
		[PageModuleType.sponsors]: {
			[SponsorsLayoutTypesBold.card_full]: {
				description: TextAlignmentsEnum.center,
			},
			[SponsorsLayoutTypesBold.card_short]: {
				description: TextAlignmentsEnum.center,
			},
			[SponsorsLayoutTypesBold.split]: {
				description: TextAlignmentsEnum.left,
			},
			[SponsorsLayoutTypesBold.standard]: {
				description: TextAlignmentsEnum.left,
			},
		},
		[PageModuleType.callout]: {
			[CalloutLayoutTypesBold.custom_background]: {
				description: TextAlignmentsEnum.left,
			},
			[CalloutLayoutTypesBold.ltr]: {
				description: TextAlignmentsEnum.left,
			},
			[CalloutLayoutTypesBold.no_border]: {
				description: TextAlignmentsEnum.left,
			},
			[CalloutLayoutTypesBold.standard]: {
				description: TextAlignmentsEnum.left,
			},
		},
		[PageModuleType.accordion]: {
			[AccordionLayoutTypes.standard]: {
				description: TextAlignmentsEnum.center,
			},
		},
	},
};


export const maxSpeakers = (layout_type: AllLayoutTypes, displayCount?: number | null) => {
	if (displayCount) return displayCount;
	switch (layout_type) {
		case SpeakerLayoutTypesClassic.standard:
		case SpeakerLayoutTypesBold.standard:
		case SpeakerLayoutTypesFun.standard:
			return 6;

		case SpeakerLayoutTypesClassic.small_horizontal:
		case SpeakerLayoutTypesBold.small_horizontal:
		case SpeakerLayoutTypesFun.small_horizontal:
			return 4;

		case SpeakerLayoutTypesClassic.staggered:
		case SpeakerLayoutTypesBold.staggered:
		case SpeakerLayoutTypesFun.staggered:
			return 2;

		case SpeakerLayoutTypesClassic.solid:
		case SpeakerLayoutTypesBold.solid:
		case SpeakerLayoutTypesFun.solid:
			return 6;

		case SpeakerLayoutTypesClassic.small_vertical:
		case SpeakerLayoutTypesBold.small_vertical:
		case SpeakerLayoutTypesFun.small_vertical:
			return 4;
		default:
			return 2;
	}
};

export const maxProducts = (layout_type: AllLayoutTypes, displayCount?: number | null) => {
	if (displayCount) return displayCount;
	switch (layout_type) {
		case ProductsLayoutTypesClassic.standard:
		case ProductsLayoutTypesBold.standard:
		case ProductsLayoutTypesFun.standard:
			return 3;

		case ProductsLayoutTypesClassic.grid:
		case ProductsLayoutTypesBold.grid:
		case ProductsLayoutTypesFun.grid:
			return 4;

		case ProductsLayoutTypesClassic.vertical_list:
		case ProductsLayoutTypesBold.vertical_list:
		case ProductsLayoutTypesFun.vertical_list:
			return 4;

		default:
			return 2;
	}
};

export const maxCount = (moduleType: PageModuleType, layout: AllLayoutTypes): number => {
	switch (moduleType) {
		case PageModuleType.products:
			return maxProducts(layout);
		case PageModuleType.speakers:
			return maxSpeakers(layout);
		default:
			return 0;
	}
};

export const getModuleDisplayCount = (module: PageModule) => {
	const displayOptions = module?.content?.display_options;
	const modulesLength = module?.modules?.length;
	const displayCount: number | null = displayOptions
		? displayOptions
		: displayOptions === 0 // 0 means ALL
			? modulesLength
			: null;
	return displayCount;
};
