import { useMemo } from "react";
import { GetDefaultColorPack } from "store/utils/create-event";
import { ColorOptions } from "types/working-model";

const defaultColors = GetDefaultColorPack().colors;

const handleReduce = (colors: ColorOptions): ColorOptions => {
	return Object.entries(colors).reduce((acc, color) => {
		const [key, value] = color;
		if (Array.isArray(value) && value.length === 2) {
			acc[key] = value;
		}

		return acc;
	}, {} as Record<string, [string, number]>) as ColorOptions;
};

const useGetTopLevelColorVariables = (colors?: ColorOptions): ColorOptions => {
	if (!colors) return defaultColors;
	return useMemo(() => {
		return handleReduce(colors);
	}, [colors]);
};

export default useGetTopLevelColorVariables;


// non hook version
export const getTopLevelColorVariables = (colors?: ColorOptions): ColorOptions => {
	if (!colors) return defaultColors;
	return handleReduce(colors);
};
