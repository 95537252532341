import { Action } from "../../../types/actions";
import { FontPack } from "../../../types/working-model";

export const SET_PAGE_FONTS = "SET_PAGE_FONTS";
export function setPageFonts(fonts: FontPack): Action {
  return {
    type: SET_PAGE_FONTS,
    payload: fonts
  };
}
