import { handle } from 'redux-pack';
import { Action } from '../../../types/actions';
import { PasscodeList } from '../../../types/working-model';
import {
	ADD_PASSCODE_LIST,
	DELETE_PASSCODE_LIST,
	GET_PASSCODE_LISTS,
	RESET_DELETE_ERROR,
	GET_ASSOCIATED_EVENTS,
	GET_ASSOCIATED_EVENTS_DETAILS,
	GET_LINKED_EMAIL_TO_EVENTS
} from '../../actions/admin/passcode-lists';


export interface PasscodeListState {
	passcodeLists: PasscodeList[];
	associatedEvents: string[];
	associatedEventsDetails: { name: string, uuid: string }[];
	associatedEventsForChannel: { [key: string]: number[] };
	linkedEmailsToEvents: number[];
	passcodeListsError: { message: string, data: string };
	loadingPasscodeLists: boolean;
	loadingAssociatedEvents: boolean;
	loadingAssociatedEventsDetails: boolean;
	loadingLinkedEmailsToEvents: boolean;
	loadingAssociatedEventsForChannel: boolean;
}


const initialState: PasscodeListState = {
	associatedEvents: [],
	associatedEventsDetails: [],
	associatedEventsForChannel: {},
	linkedEmailsToEvents: [],
	loadingAssociatedEvents: true,
	loadingAssociatedEventsDetails: true,
	loadingAssociatedEventsForChannel: true,
	loadingLinkedEmailsToEvents: true,
	loadingPasscodeLists: true, //they won't be loaded on init, start this in true
	passcodeLists: [],
	passcodeListsError: { message: "", data: "" },
};

export default function PasscodeListReducer(
	state: PasscodeListState = initialState,
	action: Action
): PasscodeListState {
	switch (action.type) {
		case GET_ASSOCIATED_EVENTS: {
			return handle(state, action, {
				start: (state) => ({
					...state,
					loadingAssociatedEvents: true
				}),
				success: (state) => ({
					...state,
					associatedEvents: action.payload,
				}),
				finish: (state) => ({
					...state,
					loadingAssociatedEvents: false
				})
			});
		}

		case GET_ASSOCIATED_EVENTS_DETAILS: {
			return handle(state, action, {
				start: (state) => ({
					...state,
					loadingAssociatedEventsDetails: true
				}),
				success: (state) => ({
					...state,
					associatedEventsDetails: action.payload,
				}),
				finish: (state) => ({
					...state,
					loadingAssociatedEventsDetails: false
				})
			});
		}

		case GET_LINKED_EMAIL_TO_EVENTS: {
			return handle(state, action, {
				start: (state) => ({
					...state,
					loadingLinkedEmailsToEvents: true
				}),
				success: (state) => ({
					...state,
					linkedEmailsToEvents: action.payload,
				}),
				finish: (state) => ({
					...state,
					loadingLinkedEmailsToEvents: false
				})
			});
		}


		case GET_PASSCODE_LISTS: {
			return handle(state, action, {
				start: (state) => ({
					...state,
					loadingPasscodeLists: true
				}),
				success: (state) => ({
					...state,
					passcodeLists: action.payload,
				}),
				finish: (state) => ({
					...state,
					loadingPasscodeLists: false
				})
			});
		}
		case ADD_PASSCODE_LIST: {
			return {
				...state,
				passcodeLists: [...state.passcodeLists, action.payload],
			};
		}
		case DELETE_PASSCODE_LIST: {
			return handle(state, action, {
				success: state => {
					// if return contains error message, update redux state to display to user
					if (action?.payload?.listErrorMessage) {
						return ({
							...state, passcodeListsError: { message: action?.payload?.listErrorMessage, data: action?.payload?.listErrorData }
						});
					} else {
						// remove deleted passcode list from current redux state
						const newList = state.passcodeLists.filter((list: PasscodeList) => {
							return list.passcode_list !== action?.payload?.listRemoved?.passcode_list;
						});
						return ({ ...state, passcodeLists: newList });
					}
				}
			});
		}
		case RESET_DELETE_ERROR: {
			return ({ ...state, passcodeListsError: { message: "", data: "" } });
		}
		default:
			return state;
	}
}
