import { useRouteMatch } from 'react-router-dom';
import { shouldDisplayHomepage } from 'utils/utils';

import { DebugDefaultRouteMap, DefaultRouteMap } from '../components/live-event/hooks/use-route-map';
import { PATHNAMES } from '../utils/admin-routing-utils';
import { useGetEvent, useIsSingleSession } from './session.hooks';

export const useIsSessionsPage = (): boolean => {
	const { path } = useRouteMatch();
	const isDebugSessionsPath = path.includes(DebugDefaultRouteMap.Sessions);
	const isPublishSessionsPath = path.includes(DefaultRouteMap.Sessions);

	return isDebugSessionsPath || isPublishSessionsPath;
};

export const useIsSessionPage = (): boolean => {
	const { path } = useRouteMatch();
	const isEditSessionPage = useIsAdminCreateSessionPage();
	const isDebugSessionPage = path.includes(DebugDefaultRouteMap.Session);
	const isLiveSessionPage = path.includes(DefaultRouteMap.Session);
	const isSingleSession = useIsSingleSessionPage();

	return isEditSessionPage || isDebugSessionPage || isLiveSessionPage || isSingleSession;
};

export const useIsAdminCreateSessionPage = (): boolean => {
	const { path } = useRouteMatch();

	return path.includes(PATHNAMES.Session.Session);
};

export const useIsAdminCreateSessionsPage = (): boolean => {
	const { path } = useRouteMatch();

	return path.includes(PATHNAMES.Event.SessionsPath);
};

export const useIsSingleSessionPage = (): boolean => {
	const { path } = useRouteMatch();
	const event = useGetEvent();
	const isSingleSession = useIsSingleSession();

	if (!isSingleSession) return false;

	const isDebugSingleSessionPath = path === DebugDefaultRouteMap.SingleSession;
	const isAdminSingleSessionPath = path === DebugDefaultRouteMap.AdminSingleSession;
	const isAdminSessionPath = path === PATHNAMES.Session.Session;
	const isAdminEventPath = path === PATHNAMES.Event.Event;
	const isSingleSessionWithoutHome = !shouldDisplayHomepage(event) && path === DefaultRouteMap.Landing;

	return isAdminEventPath
		|| isSingleSessionWithoutHome
		|| isAdminSessionPath
		|| isDebugSingleSessionPath
		|| isAdminSingleSessionPath;
};
