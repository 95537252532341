import { PageModuleType, SessionPanelLayoutsTypes } from "../../../../../../../../types/working-model";
import { ICONS } from "../../../../../../../general-ui/icon";

export type TItemsType = EEngageItemsType | EResourcesItemsType | EProductItemsType | ESuggestedItemsType | ESpeakerItemsType;

export interface IEmptyPanelItem {
	[key: string]: {
		icon: string;
		name: string;
		pageModuleType: PageModuleType;
		type: TItemsType;
		mustCreateModule?: boolean;
		navTo?: SessionPanelLayoutsTypes;
	}[];
}

export enum EEngageGroups {
	Library = 'Library',
	CreateNew = 'Create new',
}

export enum EEngageItemsType {
	FromLibrary = 'from-library',
	QAndA = 'q-and-a',
	Survey = 'survey',
	Poll = 'poll',
	Quiz = 'quiz',
	Feedback = 'feedback',
}

type CustomTabContentType = {
	label: string,
	icon: keyof typeof ICONS,
	type: PageModuleType,
	directTo?: string
}
export const CustomTabContentTypes: CustomTabContentType[] = [
	{
		label: 'Resources',
		icon: ICONS.OPEN_BOOK as keyof typeof ICONS,
		type: PageModuleType.documents,
		directTo: SessionPanelLayoutsTypes.CustomResources
	},
	{
		label: 'Products',
		icon: ICONS.PRODUCT as keyof typeof ICONS,
		type: PageModuleType.products,
		directTo: SessionPanelLayoutsTypes.CustomProducts
	},
	{
		label: 'Suggested',
		icon: ICONS.SUGGESTED as keyof typeof ICONS,
		type: PageModuleType.similar_sessions,
		directTo: SessionPanelLayoutsTypes.CustomSuggested
	},
	{
		label: 'Speakers',
		icon: ICONS.PERSON as keyof typeof ICONS,
		type: PageModuleType.speakers,
		directTo: SessionPanelLayoutsTypes.CustomSpeakers
	},
	{
		label: 'Embed section',
		icon: ICONS.CODE as keyof typeof ICONS,
		type: PageModuleType.blank,
		directTo: SessionPanelLayoutsTypes.AddCustomEmbed
	}
];

export const ExtrasTabContentTypes: CustomTabContentType[] = [
	{
		label: 'Resources',
		icon: ICONS.OPEN_BOOK as keyof typeof ICONS,
		type: PageModuleType.documents
	},
	{
		label: 'Products',
		icon: ICONS.PRODUCT as keyof typeof ICONS,
		type: PageModuleType.products
	},
	{
		label: 'Suggested',
		icon: ICONS.SUGGESTED as keyof typeof ICONS,
		type: PageModuleType.similar_sessions,
		directTo: SessionPanelLayoutsTypes.ExtraSuggested
	},
	{
		label: 'Speakers',
		icon: ICONS.PERSON as keyof typeof ICONS,
		type: PageModuleType.speakers
	},
	{
		label: 'Embed section',
		icon: ICONS.CODE as keyof typeof ICONS,
		type: PageModuleType.blank,
		directTo: SessionPanelLayoutsTypes.ExtraEmbed
	}
];

export const pageModuleCustomItemsMap: Record<number, { library: SessionPanelLayoutsTypes, upload: SessionPanelLayoutsTypes }> = {
	[PageModuleType.documents]: {
		library: SessionPanelLayoutsTypes.AddCustomResourceFromLibrary,
		upload: SessionPanelLayoutsTypes.AddCustomResourceFromUpload,
	},
	[PageModuleType.products]: {
		library: SessionPanelLayoutsTypes.AddCustomProductFromLibrary,
		upload: SessionPanelLayoutsTypes.AddCustomProductFromUpload,
	},
	[PageModuleType.speakers]: {
		library: SessionPanelLayoutsTypes.AddCustomSpeakersFromLibrary,
		upload: SessionPanelLayoutsTypes.AddCustomSpeakersFromUpload,
	}
};

export const pageModuleExtraItemsMap: Record<number, { library: SessionPanelLayoutsTypes, upload: SessionPanelLayoutsTypes }> = {
	[PageModuleType.documents]: {
		library: SessionPanelLayoutsTypes.AddExtraResource,
		upload: SessionPanelLayoutsTypes.CreateExtraResource,
	},
	[PageModuleType.products]: {
		library: SessionPanelLayoutsTypes.AddExtraProduct,
		upload: SessionPanelLayoutsTypes.CreateExtraProduct,
	},
	[PageModuleType.speakers]: {
		library: SessionPanelLayoutsTypes.AddExtraSpeaker,
		upload: SessionPanelLayoutsTypes.CreateExtraSpeaker,
	}
};

export const customItems = (type: PageModuleType): IEmptyPanelItem => ({
	[EEngageGroups.Library]: [
		{
			icon: ICONS.FOLDER,
			name: 'From library',
			type: EEngageItemsType.FromLibrary,
			navTo: pageModuleCustomItemsMap[type]?.library,
			pageModuleType: type
		}
	],
	[EEngageGroups.CreateNew]: [
		{
			icon: ICONS.UPLOAD,
			name: 'Create',
			type: EEngageItemsType.FromLibrary,
			navTo: pageModuleCustomItemsMap[type]?.upload,
			pageModuleType: type
		},
	]
});

export const customExtraItems = (type: PageModuleType): IEmptyPanelItem => ({
	[EEngageGroups.Library]: [
		{
			icon: ICONS.FOLDER,
			name: 'From library',
			type: EEngageItemsType.FromLibrary,
			navTo: pageModuleExtraItemsMap[type]?.library,
			pageModuleType: type
		}
	],
	[EEngageGroups.CreateNew]: [
		{
			icon: ICONS.UPLOAD,
			name: 'Create',
			type: EEngageItemsType.FromLibrary,
			navTo: pageModuleExtraItemsMap[type]?.upload,
			pageModuleType: type
		},
	]
});

export const engageItems: IEmptyPanelItem = {
	[EEngageGroups.Library]: [
		{
			icon: ICONS.FOLDER,
			name: 'From library',
			type: EEngageItemsType.FromLibrary,
			pageModuleType: PageModuleType.questions,
			navTo: SessionPanelLayoutsTypes.AddEngagement
		}
	],
	[EEngageGroups.CreateNew]: [
		{
			icon: ICONS.QUESTION_FILLED,
			name: 'Question',
			type: EEngageItemsType.QAndA,
			navTo: SessionPanelLayoutsTypes.AddEngagementQAndA,
			pageModuleType: PageModuleType.questions,
			mustCreateModule: true
		},
		{
			icon: ICONS.SURVEY,
			name: 'Survey',
			type: EEngageItemsType.Survey,
			navTo: SessionPanelLayoutsTypes.CreateSurvey,
			pageModuleType: PageModuleType.survey,
			mustCreateModule: true
		},
		{
			icon: ICONS.POLL_FILLED,
			name: 'Poll',
			type: EEngageItemsType.Poll,
			navTo: SessionPanelLayoutsTypes.CreatePoll,
			pageModuleType: PageModuleType.survey,
			mustCreateModule: true
		},
		{
			icon: ICONS.QUIZ,
			name: 'Quiz',
			type: EEngageItemsType.Quiz,
			navTo: SessionPanelLayoutsTypes.CreateQuiz,
			pageModuleType: PageModuleType.survey,
			mustCreateModule: true
		},
		// Removed from engagement list as part of https://brandlive.atlassian.net/browse/BP-4023 
		// But product has directed we may be bringing it back
		// {
		// 	icon: ICONS.MESSAGE_FILLED,
		// 	name: 'Feedback',
		// 	type: EEngageItemsType.Feedback,
		// 	pageModuleType: PageModuleType.feed, // TODO: change to feedback
		// 	navTo: SessionPanelLayoutsTypes.CreateFeedback,
		// 	mustCreateModule: true
		// },
	],
};

export enum EExtrasGroups {
	LocalStorage = 'Local storage',
	UploadFromCloud = 'Upload from cloud',
}

export enum EResourcesItemsType {
	ResourceFromLibrary = 'resource-from-library',
	ResourceUpload = 'resource-upload',
	GoogleDrive = 'google-drive',
	Dropbox = 'dropbox',
}

export const extrasResourcesItems = (isCustomPath = false): IEmptyPanelItem => ({
	[EExtrasGroups.LocalStorage]: [
		{
			icon: ICONS.FOLDER,
			name: "From library",
			pageModuleType: PageModuleType.documents,
			type: EResourcesItemsType.ResourceFromLibrary,
			navTo: isCustomPath
				? SessionPanelLayoutsTypes.AddCustomResourceFromLibrary
				: SessionPanelLayoutsTypes.AddExtraResource,
		},
		{
			icon: ICONS.UPLOAD,
			name: "Upload",
			type: EResourcesItemsType.ResourceUpload,
			navTo: isCustomPath
				? SessionPanelLayoutsTypes.AddCustomResourceFromUpload
				: SessionPanelLayoutsTypes.CreateExtraResource,
			pageModuleType: PageModuleType.documents
		},
	],
	// [EExtrasGroups.UploadFromCloud]: [
	// 	{
	// 		icon: ICONS.GOOGLE_DRIVE,
	// 		name: "Google Drive",
	// 		type: EResourcesItemsType.GoogleDrive,
	// 		pageModuleType: PageModuleType.documents
	// 	},
	// 	{
	// 		icon: ICONS.DROPBOX,
	// 		name: "Dropbox",
	// 		type: EResourcesItemsType.Dropbox,
	// 		pageModuleType: PageModuleType.documents
	// 	},
	// ],
});

export enum EProductItemsType {
	ProductFromLibrary = 'product-from-library',
	ProductCreate = 'product-create',
}

export const extrasProductsItems: IEmptyPanelItem = {
	[EExtrasGroups.LocalStorage]: [
		{
			icon: ICONS.FOLDER,
			name: "From library",
			type: EProductItemsType.ProductFromLibrary,
			navTo: SessionPanelLayoutsTypes.AddExtraProduct,
			pageModuleType: PageModuleType.products
		},
		{
			icon: ICONS.UPLOAD,
			name: "Create",
			type: EProductItemsType.ProductCreate,
			pageModuleType: PageModuleType.products,
			navTo: SessionPanelLayoutsTypes.CreateExtraProduct
		},
	],
};

export enum ESpeakerItemsType {
	SpeakerFromLibrary = 'speaker-from-library',
	SpeakerCreate = 'speaker-create'
}

export const extrasSpeakersItems: IEmptyPanelItem = {
	[EExtrasGroups.LocalStorage]: [
		{
			icon: ICONS.FOLDER,
			name: "From library",
			type: ESpeakerItemsType.SpeakerFromLibrary,
			navTo: SessionPanelLayoutsTypes.AddExtraSpeaker,
			pageModuleType: PageModuleType.speakers
		},
		{
			icon: ICONS.UPLOAD,
			name: "Create",
			type: ESpeakerItemsType.SpeakerCreate,
			pageModuleType: PageModuleType.speakers,
			navTo: SessionPanelLayoutsTypes.CreateExtraSpeaker
		},
	],
};

export enum ESuggestedItemsType {
	SuggestedFromLibrary = 'suggested-from-library',
	GoogleDrive = 'google-drive',
	Dropbox = 'dropbox',
}

export const extrasSuggestedItems: IEmptyPanelItem = {
	[EExtrasGroups.LocalStorage]: [
		{
			icon: ICONS.FOLDER,
			name: "From library",
			type: ESuggestedItemsType.SuggestedFromLibrary,
			navTo: SessionPanelLayoutsTypes.AddExtraSuggested,
			pageModuleType: PageModuleType.similar_sessions
		},
	],
	// [EExtrasGroups.UploadFromCloud]: [
	// 	{
	// 		icon: ICONS.GOOGLE_DRIVE,
	// 		name: "Google Drive",
	// 		type: ESuggestedItemsType.GoogleDrive,
	// 		pageModuleType: PageModuleType.similar_sessions
	// 	},
	// 	{
	// 		icon: ICONS.DROPBOX,
	// 		name: "Dropbox",
	// 		type: ESuggestedItemsType.Dropbox,
	// 		pageModuleType: PageModuleType.similar_sessions
	// 	},
	// ],
};

export const customResourcesItems: IEmptyPanelItem = {
	[EExtrasGroups.LocalStorage]: [
		{
			icon: ICONS.FOLDER,
			name: "From library",
			type: EProductItemsType.ProductFromLibrary,
			navTo: SessionPanelLayoutsTypes.AddCustomResourceFromLibrary,
			pageModuleType: PageModuleType.documents
		},
		{
			icon: ICONS.UPLOAD,
			name: "Upload",
			type: EProductItemsType.ProductCreate,
			navTo: SessionPanelLayoutsTypes.AddCustomResourceFromUpload,
			pageModuleType: PageModuleType.documents

		},
	],
};

export const customProductsItems: IEmptyPanelItem = {
	[EExtrasGroups.LocalStorage]: [
		{
			icon: ICONS.FOLDER,
			name: "From library",
			type: EEngageItemsType.FromLibrary,
			navTo: SessionPanelLayoutsTypes.AddCustomProductFromLibrary,
			pageModuleType: PageModuleType.products

		},
		{
			icon: ICONS.UPLOAD,
			name: "Create",
			type: EEngageItemsType.FromLibrary,
			pageModuleType: PageModuleType.products,
			navTo: SessionPanelLayoutsTypes.AddCustomProductFromUpload,
		},
	],
};

export const customSpeakersItems: IEmptyPanelItem = {
	[EExtrasGroups.LocalStorage]: [
		{
			icon: ICONS.FOLDER,
			name: "From library",
			type: EEngageItemsType.FromLibrary,
			navTo: SessionPanelLayoutsTypes.AddCustomSpeakersFromLibrary,
			pageModuleType: PageModuleType.speakers
		},
		{
			icon: ICONS.UPLOAD,
			name: "Create",
			type: EEngageItemsType.FromLibrary,
			navTo: SessionPanelLayoutsTypes.AddCustomSpeakersFromUpload,
			pageModuleType: PageModuleType.speakers
		},
	]
};

export const customSuggestedItems: IEmptyPanelItem = {
	[EExtrasGroups.LocalStorage]: [
		{
			icon: ICONS.FOLDER,
			name: "From library",
			type: EEngageItemsType.FromLibrary,
			pageModuleType: PageModuleType.similar_sessions,
			navTo: SessionPanelLayoutsTypes.AddCustomSuggested
		},
	],
	// [EExtrasGroups.UploadFromCloud]: [
	// 	{
	// 		icon: ICONS.GOOGLE_DRIVE,
	// 		name: "Google Drive",
	// 		type: EEngageItemsType.GoogleDrive,
	// 		pageModuleType: PageModuleType.similar_sessions

	// 	},
	// 	{
	// 		icon: ICONS.DROPBOX,
	// 		name: "Dropbox",
	// 		type: EEngageItemsType.Dropbox,
	// 		pageModuleType: PageModuleType.similar_sessions

	// 	},
	// ],
};