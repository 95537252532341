import { useState, useEffect, useCallback, useRef, useMemo, forwardRef, useContext } from 'react';
import classNames from 'classnames';
import Icon, { COLORS, ICONS } from '../icon';
import './popover-with-nav.scss';
import usePrevious from '../../../utils/use-previous';
import { useTranslate } from '../../../i18n/useTranslationModules';
import { useScreenMediaQuery } from '../../../utils/use-screen-media-query';
import React from 'react';
import ReactDOM from 'react-dom';
import { VideoStateContext } from '../../live-event/session/session-stream/session-stream-provider';

export type PopoverWithNavPageOption = {
	id: string;
	label: string;
	icon?: keyof typeof ICONS;
}

export type PopoverWithNavPage = {
	icon: string;
	selected: string;
	selectedIcon?: keyof typeof ICONS,
	subSelected?: string | JSX.Element;
	options: PopoverWithNavPageOption[];
	label: string;
}

export type PageKey = string;

export type PopoverWithNavOptions = Record<PageKey, PopoverWithNavPage>;

export type PopoverWithNavProps = {
	pages: PopoverWithNavOptions;
	onSelected: (key: PageKey, option: string) => void;
	onRequestClose: () => void;
	open: boolean;
	isFullscreen?: boolean;
}

type InnerProps = {
	open: boolean;
	isLessThan1024: boolean;
}

const POPOVER_OPTION_HEIGHT = 40;
const POPOVER_OPTION_HEIGHT_MOBILE = 56;

// nested inner component that we can de-render when the popover is closed and the animation has completed
// we want to wait until the animation is done to allow for smoother closing animations
const PopoverWithNavInner = forwardRef<HTMLDivElement, InnerProps & PopoverWithNavProps>(({ pages, onSelected, open, isLessThan1024 }, ref) => {
	const { state } = useContext(VideoStateContext);
	const { activeQuality} = state;
	
	const [selectedPage, setSelectedPage] = useState<PageKey | undefined>();
	const [showSelected, setShowSelected] = useState(false);

	const itemHeight = isLessThan1024 ? POPOVER_OPTION_HEIGHT_MOBILE : POPOVER_OPTION_HEIGHT;
	const { t } = useTranslate(["session"]);
	const innerAnimationEnded = useCallback(() => {
		if (!showSelected) {
			setSelectedPage(undefined);
		}
	}, [showSelected]);

	useEffect(() => {
		if (selectedPage) {
			setShowSelected(true);
		}
	}, [selectedPage]);

	const height = (() => {
		if (showSelected && selectedPage) {
			// each option height + header
			return (pages[selectedPage].options.length * itemHeight) + itemHeight;
		} else {
			return Object.keys(pages).length * itemHeight;
		}
	})();

	return (
		<div ref={ref} className={classNames("popover-with-nav", { open, 'page-selected': showSelected })} style={{ height }}>
			<div className="popover-with-nav-inner">
				<ul className="popover-with-nav-main">
					{Object.entries(pages).map(([pageKey, page]) => (
						<li key={pageKey} className={classNames("popover-with-nav-link", { selected: selectedPage === pageKey })} onClick={() => setSelectedPage(pageKey)}>
							<div className="left">
								<Icon name={page.icon} color={COLORS.WHITE} size={14} />
								<span className="popover-with-nav-link-label">{page.label}</span>
							</div>
							<div className="right">
								<span className="popover-with-nav-link-selected">
									<span>{page.selected}</span>
									{page.label === "Quality" && page.selected === "Auto" && <span>&nbsp;{activeQuality.label}</span>}
									{page.selectedIcon && <Icon name={page.selectedIcon} color={COLORS.WHITE} size={12} />} </span>
								<span className="popover-with-nav-link-arrow"><Icon name={ICONS.ANGLE_RIGHT} color={COLORS.WHITE} size={12} /></span>
							</div>
						</li>
					))}
				</ul>
				<div className={classNames("popover-with-nav-sub", { in: showSelected })} onTransitionEnd={innerAnimationEnded}>
					<div className="popover-with-nav-back" onClick={() => setShowSelected(false)}>
						<Icon name={ICONS.ARROW_LEFT} color={COLORS.WHITE} size={16} />
						<span className="popover-with-nav-back-label">{selectedPage && pages[selectedPage].label}</span>
					</div>
					<ul>
						{!!selectedPage && pages[selectedPage].options.map((option) => {
							const selected = pages[selectedPage].selected === option.label;

							return (
								<li
									key={option.id}
									className={classNames("popover-with-nav-sub-link", { selected })}
									onClick={() => onSelected(selectedPage, option.id)}
								>
									{selected && <span className="selected"><Icon name={ICONS.CHECKMARK} size={16} color={COLORS.WHITE} /></span>}
									{t(option.label, option.label)}
									{option.icon && <span className="label-icon">&nbsp;&nbsp;<Icon name={option.icon} size={12} color={COLORS.WHITE} /></span>}
									{selected && pages[selectedPage].subSelected && <span className="subselected">&nbsp;&nbsp;{pages[selectedPage].subSelected}</span>}
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		</div>
	);
});

PopoverWithNavInner.displayName = "PopoverWithNavInner";

const PopoverWithNav: React.FC<PopoverWithNavProps> = (props) => {
	const { open, onRequestClose, isFullscreen: isLandscape } = props;
	const [isOpen, setIsOpen] = useState(false);
	const selector = useRef<HTMLDivElement | null>(null);
	const inner = useRef<HTMLDivElement | null>(null);
	const prevOpen = usePrevious(open);
	const popover = selector.current;
	const inModal = useMemo(() => popover?.closest('.modal') !== null, [popover]);
	const { isLessThan1024 } = useScreenMediaQuery();
	// use a ref to prevent re-renders if the parent component is re-rendering a lot
	const onRequestCloseRef = useRef(onRequestClose);

	useEffect(() => {
		onRequestCloseRef.current = onRequestClose;
	}, [onRequestClose]);

	useEffect(() => {
		if (open && !isOpen) {
			setIsOpen(true);
		}
	}, [open, isOpen]);

	// on escape, close dropdown
	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				onRequestCloseRef.current();
			}
		};
		if (!inModal) {
			document.addEventListener('keydown', handleKeyDown);
		}
		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [inModal]);

	// on click out anywhere in the window other than within the popover, close dropdown
	useEffect(() => {
		const ignoreRef = isLessThan1024 ? inner.current : selector.current;
		// disabled any because of the path property - this is not standard across browsers but we attempt to backfill it
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		function checkClick(e: any) {
			// looks this css key not need more if (e.target?.className?.includes?.('popover-opener')) return;
			const path = e.path || e.composedPath();
			for (const item of path) {
				if (ignoreRef === item) return;
			}

			onRequestCloseRef.current();
		}

		// we need to check if it's already open, otherwise it immediately closes
		if (prevOpen && open && !inModal) {
			window.addEventListener('click', checkClick);
		}

		return () => window.removeEventListener('click', checkClick);
	}, [open, prevOpen, inModal, isLessThan1024]);

	// animation has finished closing the popover, safe to remove the inner component from the DOM
	const animationEnded = useCallback(() => {
		if (!open && isOpen) {
			setIsOpen(false);
		}
	}, [isOpen, open]);

	const handleCancelClick = (e: React.MouseEvent) => {
		const popupBackground = e.target as HTMLElement;
		if (popupBackground.classList.contains('popover-with-nav-container')) {
			setIsOpen(false);
		}
	};

	const contain = (children: JSX.Element) => {
		if (isLessThan1024 && !isLandscape) {
			return <PopoverWithNavPortal>{children}</PopoverWithNavPortal>;
		} else {
			return <>{children}</>;
		}
	};

	return contain(
		<div ref={selector} className={classNames("popover-with-nav-container", { open, fullscreen: isLandscape })}
			onClick={handleCancelClick}
			onTransitionEnd={animationEnded}>
			{isOpen && <PopoverWithNavInner ref={inner} {...props} open={isOpen} isLessThan1024={isLessThan1024 && !isLandscape} />}
		</div>
	);
};

export default PopoverWithNav;

interface IModalPortal {
	children: React.ReactNode;
	className?: string;
}

class PopoverWithNavPortal extends React.Component<IModalPortal> {
	node = document.getElementById('popover-global-container');

	componentDidMount() {
		if (this.props.className) {
			if (this.node) {
				this.node.classList.add(this.props.className);
			}
		}
	}

	componentWillUnmount(): void {
		if (this.node && this.props.className) {
			if (this.node.classList.contains(this.props.className)) {
				this.node.classList.remove(this.props.className);
			}
		}
	}

	render(): any {
		return ReactDOM.createPortal(
			this.props.children,
			this.node as HTMLElement
		);
	}
}