import { closestCenter, DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';
import classNames from "classnames";
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useSocket } from "../../../../../../connection/socket";
import { saveWorkingSession, updateGroupModules, updatePageModule, updateWorkingSession } from "../../../../../../store/actions/admin/create-event/session";
import { addTextAnswer } from "../../../../../../store/actions/admin/surveys";
import { useAppDispatch, useTypedSelector } from "../../../../../../store/reducers/use-typed-selector";
import { PageModule, PageModuleGroupModules, Survey, QuestionPrompt, SurveyType, PageModuleType, SessionPanelLayoutsTypes, TextAnswer } from "../../../../../../types/working-model";
import { useGetAdminUrl } from "../../../../../../utils/admin-routing-utils";
import { mouseAlert } from "../../../../../general-ui/alert/alert-service";
import StaggerChildren from "../../../../../general-ui/animated/stagger-children";
import NavigationDropdown from "../../../../../general-ui/dropdown/navigation-dropdown";
import Icon, { COLORS, ICONS } from "../../../../../general-ui/icon";
import TextInput from "../../../../../general-ui/text-input/text";
import WaitingIndicator from "../../../../../general-ui/waiting-indicator/waiting-indicator";
import { engageItemsConfig } from "../../../../../live-event/session/engage-section/utils/engage.utils";
import { EExtrasGroups, engageItems, TItemsType } from "../panel/empty-state-panel/constants/empty-panel";
import { useCreateModuleAndGo } from "../panel/empty-state-panel/empty-state-panel";
import { usePageModuleGroup } from "../panel/hooks/panel.hooks";
import { SessionPanelMap } from "../panel/session-panel-route-map";
import EngageCard from "./add-engagement/engage-card";
import { useEngagementTotals } from "./hooks/engage.hooks";


const getType = (contentItem: Survey | QuestionPrompt) => {
	if ('question_prompt' in contentItem) {
		return 'question_prompt';
	}

	if (contentItem.survey_type === 'quiz') {
		return 'quiz';
	}

	return contentItem.show_results ? 'poll' : 'survey';
};

const SortableItem: React.FC<{
	item: EngageListItem;
	id: string;
}> = ({ item, id }) => {
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);
	const publishUrl = useTypedSelector(state => state.CreateEventReducer.publishedUrl);
	const userEngagements = useEngagementTotals();
	const pageModuleGroup = usePageModuleGroup();

	const getAdmin = useGetAdminUrl();
	const history = useHistory();

	const { language } = useParams<{ language: string }>();
	const dispatch = useAppDispatch();

	const type = getType(item);
	const { tagIcon, name: tagName } = engageItemsConfig[type as SurveyType];
	const name = 'question_prompt' in item ? item.title : item.name;

	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
	} = useSortable({ id: id });

	const style = {
		transform: CSS.Transform.toString(transform),
		display: 'flex',
		cursor: "grabbing",
	};

	const handleCardAction = useCallback((actionType: 'edit' | 'delete', item: EngageListItem) => {

		if (!workingSession || !pageModuleGroup) return;

		const navigate = (path: string) => {
			history.push(getAdmin({
				path,
				page_module: item.page_module
			}));
		};

		if (actionType === 'edit') {
			// item is a question prompt
			if ('question_prompt' in item) {
				navigate(SessionPanelMap[SessionPanelLayoutsTypes.AddEngagementQAndA]);
			} else if ('survey_type' in item) {
				switch (item.survey_type) {
					case SurveyType.quiz: {
						navigate(SessionPanelMap[SessionPanelLayoutsTypes.CreateQuiz]);
						break;
					}
					case SurveyType.survey: {
						navigate(SessionPanelMap[SessionPanelLayoutsTypes.CreateSurvey]);
						break;
					}
					case SurveyType.poll: {
						navigate(SessionPanelMap[SessionPanelLayoutsTypes.CreatePoll]);
						break;
					}
				}
			}
		} else {
			dispatch(updateWorkingSession({
				...workingSession,
				modules: workingSession.modules?.filter((module: PageModule) => {
					return module.id !== item.page_module;
				}),
				module_grouping: workingSession.module_grouping?.map((group: PageModuleGroupModules) => {
					const modules = group.modules.filter((mod: number) => mod !== item.page_module);
					return {
						...group,
						modules
					};
				})
			}));
			dispatch(saveWorkingSession());
		}
	}, [dispatch, getAdmin, history, pageModuleGroup, workingSession]);

	const handleOnClick = (item: EngageListItem) => {
		if (!publishUrl) {
			mouseAlert({
				message: "Publish required",
			});

			return;
		}

		const navigate = (path: string) => {
			history.push(getAdmin({
				path,
				page_module: item.page_module
			}));
		};

		if ('question_prompt' in item) {
			navigate(SessionPanelMap[SessionPanelLayoutsTypes.ViewSingleQAndAResponses]);
		} else if ('survey_type' in item) {
			switch (item.survey_type) {
				case SurveyType.quiz: {
					navigate(SessionPanelMap[SessionPanelLayoutsTypes.QuizResults]);
					break;
				}
				case SurveyType.survey: {
					if (item.show_results) {
						navigate(SessionPanelMap[SessionPanelLayoutsTypes.PollResults]);
					} else {
						navigate(SessionPanelMap[SessionPanelLayoutsTypes.SurveyResults]);
					}
					break;
				}
				default: {
					return;
				}
			}
		}
	};

	return (
		<div
			key={id}
			ref={setNodeRef}
			style={style}
			{...attributes}
			{...listeners}
			className="registration-option-v2-container" // used for animating the hover open and close effect
		>

			<div className="drag-handle">
				<Icon
					name={ICONS.DRAG_HANDLE}
					size={16}
					color={COLORS.GRAY} />
			</div>

			<EngageCard
				id={item.module_id as number}
				type={tagName}
				icon={tagIcon}
				title={name[language] as string ?? name.base ?? ''}
				item={item}
				responsesCount={userEngagements.get(item.page_module as number)}
				onClick={() => handleOnClick(item)}
				clickHandler={handleCardAction}
			/>
		</div>
	);
};

const engageItemSearch = (term: string, items: EngageListItem[]): EngageListItem[] => {
	return [...items].filter(item => {
		// search across the entire stringified JSON
		// as a naive way of doing multi-language search
		const name = 'name' in item ? item.name : item.title;
		const terms = JSON.stringify(name).toLowerCase() + JSON.stringify(name).toLowerCase();
		return terms.includes(term);
	});
};

export type EngageListItem = (QuestionPrompt | Survey) & { page_module: number };


const ActiveEngageItemsList: React.FC<unknown> = () => {
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);
	const [items, setItems] = useState<EngageListItem[]>([]);
	const searchTermRef = useRef<string>();
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [selectedItems, setSelectedItems] = useState<number[]>([]);
	const [saving, /*setSaving*/] = useState(false);
	const containerRef = useRef<HTMLDivElement | null>(null);
	const scrollRef = useRef<HTMLDivElement | null>(null);
	const moduleGroupingRef = useRef<PageModuleGroupModules[] | undefined>(workingSession?.module_grouping);
	const pageModulesRef = useRef<PageModule[] | undefined>(workingSession?.modules);
	const moduleGrouping = workingSession?.module_grouping;
	const pageModules = useMemo(() => workingSession?.modules, [workingSession?.modules]);
	const pageModuleGroup = usePageModuleGroup();
	const [creatingModule, setCreatingModule] = useState(false);
	const getAdmin = useGetAdminUrl();
	const dispatch = useAppDispatch();

	const sessionUuid = workingSession?.uuid;

	const socket = useSocket(`session-${sessionUuid}-engagement-text`);
	const createModuleAndGo = useCreateModuleAndGo(setCreatingModule, false);

	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 8
			}
		})
	);


	const moduleMap = useMemo(() => {
		const map: { [key: number]: PageModule } = {};
		pageModules?.forEach(module => {
			map[module.id as number] = module;
		});
		return map;
	}, [pageModules]);

	useEffect(() => {
		const handleText = ({ data }: { data: TextAnswer & { questionId: number } }) => {
			dispatch(addTextAnswer(data.questionId, data));
		};

		socket.addListener('session-engagement-text', handleText);

		return () => {
			socket.removeListener('session-engagement-text', handleText);
		};
	}, [dispatch, socket]);

	useEffect(() => {
		if (moduleGrouping) {
			moduleGroupingRef.current = moduleGrouping;
		}

		if (pageModules) {
			pageModulesRef.current = pageModules;
		}
	}, [moduleGrouping, pageModules]);

	const modules = useMemo(() => {
		// const pModules = pageModules?.filter((mod: PageModule) => {
		// 	return mod.is_on && mod.modules?.length && pageModuleGroup?.modules.includes(mod.id as number);
		// }).sort((a, b) => {
		// 	const aIndex = pageModuleGroup?.modules.indexOf(a.id as number) ?? 0;
		// 	const bIndex = pageModuleGroup?.modules.indexOf(b.id as number) ?? 0;
		// 	return aIndex - bIndex;
		// });

		// return pModules ?? [];
		return pageModuleGroup?.modules.map((modId: number) => {
			return moduleMap[modId];
		}).filter((mod: PageModule | undefined) => !!mod) ?? [];
	}, [pageModuleGroup, moduleMap]);


	const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		searchTermRef.current = e.target.value;
	}, []);

	const handleSearch = useCallback(() => {
		// not doing typeahead search, user has to press enter or blur field
		// so we aren't updating the state every keystroke, just holding the value in a ref
		setSearchTerm(searchTermRef.current ?? '');
	}, []);

	useEffect(() => {
		const items = modules.flatMap(module => module.modules?.map(mod => {
			mod.page_module = module.id;
			return mod;
		}) ?? []);
		const filteredItems = engageItemSearch(searchTerm, items);

		setItems(filteredItems);
	}, [searchTerm, modules]);


	// this is not in use yet, but will be when we add the ability to remove products from a page
	const handleDone = async () => {
		// TODO: handle whatever is supposed to happen when selecting one of these
		console.warn('HANDLE DONE FROM ACTIVE ENGAGE LIST IS UNIMPLEMENTED');
	};




	/**********************************************************************
	 * - Get the start and end index of the module that is being dragged
	 * - Get new array order of module ids
	 * - Update the page module group with the new module order
	 **********************************************************************/
	function handleDragEnd(event: DragEndEvent) {

		/**********************************************************************
		 * Getting the element index that is getting dragged and where it is being dropped
		 **********************************************************************/
		const startIndex = Number((event.active.id as string)?.split('.')[0]);
		const overIndex = Number((event?.over?.id as string)?.split('.')[0]);

		// Early escape if we never moved the module card
		if (!(startIndex >= 0 && overIndex >= 0 && startIndex !== overIndex)) return;

		// Use Items since it's what's being displayed and dragged
		const curModuleOrder = items.map(item => item.page_module);
		const newModuleOrder = arrayMove(curModuleOrder, startIndex, overIndex);

		if (pageModuleGroup && moduleGrouping) {

			const newEngageModuleGroup = {
				...pageModuleGroup,
				modules: newModuleOrder
			};

			const newModuleGrouping = moduleGrouping.map(group => {
				if (group.uuid === pageModuleGroup?.uuid) {
					return newEngageModuleGroup;
				}
				return group;
			});

			dispatch(updateGroupModules(newModuleGrouping));
		}

	}

	return (
		<div
			className={classNames("session-panel products-list", { 'has-selections': selectedItems.length > 0 })}
			ref={containerRef}
		>
			<div className="session-panel-header-options search-only">
				<TextInput
					defaultValue={''}
					onChange={handleSearchChange}
					onBlur={handleSearch}
					onEnterKey={handleSearch}
					placeholder="Search..."
					className="small"
				/>
			</div>

			<DndContext
				sensors={sensors}
				collisionDetection={closestCenter}
				onDragEnd={handleDragEnd}
				modifiers={[restrictToParentElement]}
				autoScroll={false}
			>

				{items.length ? (
					<StaggerChildren
						ref={scrollRef}
						footer={(
							<NavigationDropdown
								title="+ Add Engagement"
								className="session-panel-dropdown"
								buttonClassName="large no-style lemonade"
								yPosition={items.length <= 2 ? "bottom" : "top"}
								isAsync={true}
								root={scrollRef}
							>
								{Object.entries(engageItems).map(([group, groupItems]) => (
									<Fragment key={group}>
										<span>{group}</span>
										{groupItems.map((item: {
											icon: string;
											name: string;
											pageModuleType: PageModuleType;
											type: TItemsType;
											mustCreateModule?: boolean | undefined;
											navTo?: SessionPanelLayoutsTypes | undefined;
										}, index) => {
											const { icon, name, navTo, mustCreateModule } = item;

											return (
												<Link
													key={`${index}.${name}`}
													className={creatingModule ? "disabled" : ""}
													onClick={(e) => {
														// this is a new custom tab with no content, create a new page module and append it to the group
														// before navigating there
														if (mustCreateModule && item.navTo) {
															e.preventDefault();
															createModuleAndGo(item.pageModuleType, item.navTo);
														}
													}}
													to={(navTo && !mustCreateModule) ? {
														pathname: getAdmin({ path: SessionPanelMap[navTo as SessionPanelLayoutsTypes] })
													} : '#'}>
													<Icon
														name={icon}
														color={group === EExtrasGroups.UploadFromCloud ? "transparent" : COLORS.WHITE}
														size={12}
													/>
													{name}
												</Link>
											);
										})}
									</Fragment>
								))}
							</NavigationDropdown>
						)}
						flexGrow
						className={classNames("session-panel-content")}>
						<SortableContext
							items={items.map((item: EngageListItem, index) => `${index}.${item.module_id}`)}
							strategy={verticalListSortingStrategy}
							disabled={!!searchTerm} // Disabled if we're searching
						>

							{items.map((item: EngageListItem, index) => (
								<SortableItem
									key={`${index}.${item.module_id}`}
									id={`${index}.${item.module_id}`}
									item={item}
								/>
							)
							)}

						</SortableContext>
					</StaggerChildren>
				) : (
					<div className="session-panel-no-results">
						<section>{!workingSession ? <WaitingIndicator /> : 'No results'}</section>
					</div>
				)}
			</DndContext>


			<div className="session-panel-footer">
				<button onClick={() => setSelectedItems([])}>Clear Selections</button>
				<button
					className="lemonade"
					onClick={handleDone}
					disabled={saving}
				>{saving ? <WaitingIndicator /> : 'Add to page'}</button>
			</div>
		</div>
	);
};

export default ActiveEngageItemsList;
