import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { mergeRefs } from 'utils/merge-refs';

import { Field, FieldLabel, FieldProps } from '../field/field';

import { debounce } from 'underscore';
import { v4 as uuid } from 'uuid';

import { BlProfile } from '../../../types/working-model';
import Icon, { COLORS, ICONS } from '../icon';
import { Validation } from '../text-input/text';


export interface TextAreaProps extends FieldProps {
	autoComplete?: string;
	autoFocus?: boolean;
	channel?: number;
	className?: string;
	debounceTime?: number;
	defaultValue?: string;
	disabled?: boolean;
	highlightOnFocus?: boolean;
	icon?: string;
	id?: string;
	ignoreZIndex?: boolean;
	inputSize?: string | number;
	inputStyle?: React.CSSProperties;
	isRtl?: boolean;
	limit?: number;
	max?: number;
	maxLength?: number;
	min?: number;
	minLength?: number;
	noPlaceholder?: boolean;
	onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
	onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
	onClear?: () => void;
	onDebounce?: (value: string) => void;
	onEnterKey?: (value: string, event?: KeyboardEvent) => void;
	onEscape?: () => void;
	onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
	onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
	onKeyUp?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
	onTab?: () => void;
	persistent?: boolean;
	placeholder?: string;
	prefix?: string;
	profile?: BlProfile | null;
	rows?: number;
	shouldUpdateValue?: boolean; //deprecated and should not be used moving forward
	size?: 'large' | 'small' | 'normal';
	step?: number;
	style?: React.CSSProperties;
	textAreaStyle?: React.CSSProperties;
	tooltip?: string | [string | (() => JSX.Element), string];
	updateValue?: string; //deprecated and should not be used moving forward
	value?: string;
}

// eslint-disable-next-line react/display-name
const Textarea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref): JSX.Element => {
	const {
		autoFocus,
		className,
		debounceTime = 300,
		defaultValue,
		disabled = false,
		highlightOnFocus = false,
		icon,
		ignoreZIndex = false,
		label,
		limit,
		maxLength,
		name,
		onBlur = () => ({}),
		onChange,
		onClear,
		onDebounce,
		onEnterKey = () => ({}),
		onEscape = () => ({}),
		onKeyUp,
		onTab = () => ({}),
		placeholder,
		prefix,
		required,
		rows = 4,
		shouldUpdateValue = false,
		size = 'normal',
		style,
		textAreaStyle = {},
		tooltip,
		updateValue = '',
		valid = Validation.normal,
		value,
	} = props;

	let id = props.id;
	if (!id) {
		id = uuid();
	}

	const [entered, setEntered] = useState(false);
	const prefixRef = useRef<HTMLSpanElement | null>(null);
	const input = useRef<HTMLTextAreaElement | null>(null);
	const [internalText, setInternalText] = useState(defaultValue || '');
	const [changeFlash, setChangeFlash] = useState(false);
	const useInternalState = !value && value !== "";

	useEffect(() => {
		const _input = input.current;
		const handleShouldFlash = () => {
			setChangeFlash(true);
			setTimeout(() => {
				setChangeFlash(false);
			}, 300);
		};

		_input?.addEventListener('flash', handleShouldFlash);

		return () => {
			_input?.removeEventListener('flash', handleShouldFlash);
		};
	}, []);

	const debounced = useRef(debounce((value: string) => {
		onDebounce?.(value);
	}, debounceTime)).current;

	function handleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
		if (maxLength && e.target.value.length > maxLength) return;
		if (useInternalState) {
			onChange?.(e);
			setInternalText(e.target.value);
		} else {
			onChange?.(e);
		}
		if (onDebounce) {
			debounced(e.target.value);
		}
	}

	function keyUp(e: React.KeyboardEvent<HTMLTextAreaElement>) {
		setEntered(!!input.current?.value);
		if (onKeyUp) onKeyUp(e);
	}

	useEffect(() => {
		if (defaultValue) {
			setEntered(true);
		}
	}, [defaultValue]);

	useEffect(() => {
		if (autoFocus) {
			input.current?.focus();
			if (highlightOnFocus) {
				input.current?.select();
			}
		}
	}, [autoFocus, highlightOnFocus]);

	useEffect(() => {
		const handleKeyDown = (e: KeyboardEvent) => {
			if (input.current && document.activeElement === input.current) {
				if (e.key === 'Escape') {
					onEscape();
				}
				if (e.key === 'Tab') {
					onTab();
				}
				if (e.key === 'Enter') {
					onEnterKey(input.current.value, e);
				}
			}
		};
		document.addEventListener('keydown', handleKeyDown);
		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [input, onEnterKey, onEscape, onTab]);

	const providedValue = value !== undefined ? value : defaultValue;
	const inputValue = useInternalState ? internalText : providedValue;
	const len = inputValue?.length ?? 0;

	return (
		<Field {...props}>
			<div
				className={classNames('field-group textarea', { entered, flash: changeFlash }, valid, size, className)}
				style={style}
			>
				{label ? (
					<div style={{
						display: 'flex',
						alignItems: 'center',
						position: 'relative',
						...(ignoreZIndex ? {} : { zIndex: 1 })
					}}>
						<FieldLabel label={label} required={required} tooltip={tooltip} />
					</div>
				) : null}
				{prefix && (
					<span className={classNames("prefix", { 'has-tooltip': !!tooltip })} ref={prefixRef}>
						{prefix}
					</span>
				)}

				<textarea
					name={name}
					onBlur={onBlur}
					id={id}
					disabled={disabled}
					ref={mergeRefs(input, ref)}
					placeholder={placeholder || label}
					onChange={handleChange}
					onKeyUp={keyUp}
					value={inputValue}
					maxLength={limit ? limit : 64000}
					rows={rows}
					style={textAreaStyle}
				></textarea>
				{limit && (
					<div className="limit-indicator">
						<span>
							{len}/{limit}
						</span>
					</div>
				)}

				{icon && (
					<div className="input-icon">
						<Icon name={icon} size={14} color={COLORS.DEFAULT_GRAY} />
					</div>
				)}

				{!!onClear && (
					<button
						className={classNames("clear-button no-style no-padding no-marging", { visible: !!internalText })}
						onClick={() => {
							if (input.current) {
								setInternalText('');
								onClear();
							}
						}}
					>
						<Icon name={ICONS.CLOSE} size={12} color={COLORS.DEFAULT_GRAY} />
					</button>
				)}
			</div>
		</Field >
	);
});
export default Textarea;

