import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import Icon, { COLORS, ICONS } from '../icon';
import { ITooltipProps, Tooltip } from '../tooltip/tooltip';
import { setADropdownIsOpen } from '../../../store/actions/admin/create-event';
import usePrevious from '../../../utils/use-previous';
import { useAppDispatch } from '../../../store/reducers/use-typed-selector';

export interface SelectOption {
	icon?: any;
	label: any;
	value: string | number;
	code?: string;
}
interface Props {
	label?: string;
	info?: string;
	tooltipOptions?: Omit<ITooltipProps, 'children' | 'tooltip'>;
	options: SelectOption[];
	selected: string | number | undefined;
	onChange: (value: any, id?: any) => void;
	onOpen?: () => void;
	placeholder?: string;
	id?: any;
	disabled?: boolean;
	mainIcon?: any;
	small?: boolean;
	medium?: boolean;
	noMaxHeight?: boolean;
	ignoreSpacing?: boolean;
	ignorePositioning?: boolean;
	ignoreInlineStyling?: boolean;
	errorMessage?: string;
	highlightSelectedItem?: boolean;
	hideEmptyOption?: boolean;
	keyType?: keyof SelectOption;
	containerClass?: string;
	useRelativePosition?: boolean;
	clampSelectedOption?: boolean;
	closeOnEscape?: boolean;
	showOptionIcon?: boolean;
	ignoreEmptySpaceLabel?: boolean;
	iconIfOpen?: JSX.Element;
	dropdownClass?: string;
	makeControlledComponent?: boolean;
}

// FUTURE DEV: if you want more out of this component, like searchable functionality, or keyboard navigation, then consider using ReactSelect and reactSelectStyles
export default function SelectInput(props: Props): JSX.Element {
	const dispatch = useAppDispatch();

	const {
		label,
		options,
		selected,
		onChange,
		onOpen,
		placeholder,
		id,
		disabled = false,
		mainIcon,
		small,
		medium,
		noMaxHeight,
		ignoreSpacing = false,
		ignorePositioning = false,
		ignoreInlineStyling = false,
		info,
		errorMessage,
		highlightSelectedItem = false,
		hideEmptyOption = false,
		tooltipOptions = {},
		keyType = 'value',
		containerClass = '',
		useRelativePosition = false,
		clampSelectedOption = false,
		closeOnEscape = false,
		showOptionIcon = false,
		ignoreEmptySpaceLabel = false,
		iconIfOpen,
		dropdownClass = '',
		makeControlledComponent = false,
	} = props;
	const selector = useRef<HTMLDivElement | null>(null);
	const [selectedOption, setSelectedOption] = useState<SelectOption>(options.find((option: SelectOption) => option[keyType] === selected) || options[0]);
	const [open, setOpen] = useState(false);
	const [left, setLeft] = useState(0);
	const [top, setTop] = useState(0);
	const [width, setWidth] = useState(100);
	const [firstOpen, setFirstOpen] = useState(true);
	const dropdown = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (makeControlledComponent) {
			setSelectedOption(options.find((option: SelectOption) => option[keyType] === selected) || options[0]);
		}

	}, [keyType, makeControlledComponent, options, selected]);

	useEffect(() => {
		function checkClick(e: MouseEvent) {
			const path = e.composedPath();
			for (const item of path) {
				if (selector.current === item) { return; }
			}
			setOpen(false);
		}

		if (open) {
			window.addEventListener('click', checkClick);
		}

		return () => window.removeEventListener('click', checkClick);
	}, [open]);

	useEffect(() => {
		dispatch(setADropdownIsOpen(open));
	}, [dispatch, open]);

	const previousSelected = usePrevious(selected);

	useEffect(() => {
		let optionValue = options.find((option: SelectOption) => option[keyType] === selected);
		if (!optionValue) {
			optionValue = options.find((option: SelectOption) => option.label === selected);
		}
		optionValue && setSelectedOption(optionValue);
	}, [selected, options, keyType, open, previousSelected]);

	useEffect(() => {
		if (previousSelected !== selected) setOpen(false);
	}, [previousSelected, selected]);

	useEffect(() => {
		// If an initial selected value is passed, scroll the dropdown so that the selected value is visible and centered 
		// the first time the users opens it
		if (open && dropdown.current) {
			setFirstOpen(false);
			const indexOfSelected = options.findIndex((option: SelectOption) => option[keyType] === selected);
			if (indexOfSelected > 0) {
				// (indexOfSelected - 1) scrolls to the center instead of to the top
				dropdown.current.scrollTo(0, dropdown.current.scrollHeight * (indexOfSelected - 1) / options.length);
			}
		}
	}, [open, firstOpen, options, selected, keyType]);

	useEffect(() => {
		const rect = selector.current?.getBoundingClientRect();

		if (!ignorePositioning) {
			if (rect && !ignoreSpacing) {
				setLeft(rect.left ?? 0);
				setTop((rect.top ?? 0) + 30);
				setWidth(rect.width ?? 0);
			} else if (rect && ignoreSpacing) {
				setWidth(rect.width);
			}
		} else if (rect) {
			setWidth(rect.width);
		}

	}, [open, selector, ignoreSpacing, ignorePositioning]);

	useEffect(() => {
		// add event listener on selector ref for escape key to close dropdown
		const handleEscape = (e: KeyboardEvent) => {
			if (e.key === 'Escape') {
				setOpen(false);
			}
		};

		if (open && closeOnEscape) {
			document.addEventListener('keydown', handleEscape);
		}

		return () => {
			document.removeEventListener('keydown', handleEscape);
		};
	}, [closeOnEscape, open]);

	const renderPlacheolder = () => {
		if (
			ignoreEmptySpaceLabel
			&& selectedOption?.label?.trim() === ''
		) {
			return placeholder;
		}

		return selectedOption?.label ? selectedOption?.label : placeholder;
	};

	const placholderWithIgnoreEmptySpaceLabel = (placeholder && ignoreEmptySpaceLabel && selectedOption?.label?.trim() === '');

	return (
		<div
			ref={selector}
			id={id}
			className={classNames(
				"field-group select",
				containerClass,
				{
					disabled,
					small,
					medium,
					'select-open': open,
				}
			)}
			onClick={() => { setOpen(!open); onOpen && onOpen(); }}
		>
			{label && <label>
				{label}
				{info && (
					<Tooltip tooltip={info} {...tooltipOptions}>
						<Icon name={ICONS.PRIMARY_TOOLTIP} color={COLORS.CYAN} size={12} />
					</Tooltip>
				)}
			</label>}
			<div className={classNames("select-container", { error: errorMessage })}>
				<div
					className={
						classNames(
							"selected-option",
							{
								placeholder: !selectedOption?.label || placholderWithIgnoreEmptySpaceLabel,
								'clamp-selected-option': clampSelectedOption,
							}
						)
					}
				>
					{selectedOption?.icon ? selectedOption.icon : null}{renderPlacheolder()}
				</div>
				<div className={classNames('select-dropdown-container', { 'use-relative-position': useRelativePosition })}>
					<div
						ref={dropdown}
						className={classNames("select-dropdown", {
							open,
							noMaxHeight,
							'hide-empty-option': hideEmptyOption,
							[dropdownClass]: !!dropdownClass.trim().length,
						})}
						style={{
							...(ignoreInlineStyling ? {} : { left, top, width }),
							...(ignorePositioning ? { position: 'absolute' } : {})
						}}
					>
						{options.map((option: SelectOption) => (
							<div
								data-value={option[keyType]}
								key={option[keyType]}
								className={classNames("select-option", {
									active: highlightSelectedItem && option[keyType] === selectedOption[keyType],
								})}
								onClick={() => {
									// option can have value or code fields and both are treated the same, so we need to check which field to use for the onChange
									if ('value' in option) onChange(option.value, id);
									if ('code' in option) onChange(option.code, id);
								}}
							>
								<span>
									{showOptionIcon && option.icon}
									{option.label}
								</span>
							</div>
						))}
					</div>
					{iconIfOpen && open
						? <span className="main-icon">{iconIfOpen}</span>
						: mainIcon && <span className="main-icon">{mainIcon}</span>}
				</div>
			</div>
			{errorMessage && <label className="error-message">{errorMessage}</label>}
		</div>
	);
}