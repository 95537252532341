import { useCallback, useState } from "react";
import { batch, useDispatch } from "react-redux";
import classNames from "classnames";

import ModalComponent from "../../../../../general-ui/modal/modal";
// import CustomHTML from "../../../../../general-ui/edit-html/edit-html";

import {
	addSessionPageModule,
	updateGroupModules,
	updatePageModuleAndSave,
} from "../../../../../../store/actions/admin/create-event/session";
import {
	PageModule,
	PageModuleType,
	Session,
} from "../../../../../../types/working-model";
import { CreateSessionModule } from "../../../../../../connection/session-modules";
import { useTypedSelector } from "../../../../../../store/reducers/use-typed-selector";
import BasicCard from "../../../../../general-ui/basic-card/basic-card";
import WaitingIndicator from "../../../../../general-ui/waiting-indicator/waiting-indicator";

import './custom-options.scss';
import { GetDefaultPageModule } from "../../../../../../store/utils/create-event";
import CustomHTML from "../../../../../general-ui/edit-html/edit-html";

interface CustomOptionsProps {
	setAddModuleOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setBlankModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
	blankModalOpen: boolean;
	addModuleOpen: boolean;
	groupUuid?: string;
	workingSession: Session;
}

const CustomOptions: React.FC<CustomOptionsProps> = ({
	setAddModuleOpen,
	setBlankModalOpen,
	addModuleOpen,
	blankModalOpen,
	groupUuid,
	workingSession
}) => {
	const token = useTypedSelector(state => state.AuthReducer.token);

	const [activeType, setActiveType] = useState<PageModuleType | null>(null);
	const [uploading, setUploading] = useState(false);

	const languages = workingSession.languages;
	const sessionTitle = workingSession.title.base;
	const baseLanguage = workingSession.default_language;

	const [
		workingBlankModule,
		setWorkingBlankModule,
	] = useState<PageModule | null>(null);

	const dispatch = useDispatch();

	const closeModuleModal = useCallback(() => setAddModuleOpen(false), [setAddModuleOpen]);

	function selectModule(type: PageModuleType) {
		return () => {
			setActiveType(type);
		};
	}

	async function finishedEditingBlank() {
		if (workingBlankModule) {
			if (workingBlankModule.id) {
				dispatch(updatePageModuleAndSave(workingBlankModule));
			} else {
				createModule(workingBlankModule);
			}
		}
		closeBlankModule();
	}
	function closeBlankModule() {
		setBlankModalOpen(false);
	}

	function updateWorkingBlankModule(code: string) {
		setWorkingBlankModule((module: PageModule | null) => {
			if (!module) { return null; }
			return {
				...module,
				content: {
					html: code,
				},
			};
		});
	}

	const createModule = async (workingBlankModule?: PageModule) => {
		if (!activeType || !languages || !sessionTitle) return;
		setUploading(true);
		const {
			session: updatedSession,
			module: newModule,
		} = await CreateSessionModule(token!, workingSession!.session, {
			groupUuid,
			languages,
			baseLanguage,
			sessionTitle,
			moduleType: activeType,
			workingBlankModule
		});

		if (updatedSession && newModule) {
			batch(() => {
				if (updatedSession?.module_grouping) {
					dispatch(updateGroupModules(updatedSession.module_grouping));
				}
				dispatch(addSessionPageModule(newModule));
			});
		}
		setUploading(false);
		setAddModuleOpen(false);
	};

	const handleNewModule = () => createModule();

	const createBlankMemoized = useCallback(() => {
		if (activeType) {
			const module = GetDefaultPageModule(activeType);
			module.content.html = "";
			setAddModuleOpen(false);
			setWorkingBlankModule(module);
			setBlankModalOpen(true);
		}
	}, [activeType, setAddModuleOpen, setBlankModalOpen]);

	const section = () => {
		switch (activeType) {
			case PageModuleType.description: {
				return <button className="lemonade" onClick={handleNewModule}>Pick Description</button>;
			}
			case PageModuleType.speakers: {
				return <button className="lemonade" onClick={handleNewModule}>Pick Speakers</button>;
			}
			case PageModuleType.questions: {
				return <button className="lemonade" onClick={handleNewModule}>Pick Questions</button>;
			}
			case PageModuleType.quizzes: {
				return <button className="lemonade" onClick={handleNewModule}>Pick Quizes</button>;
			}
			case PageModuleType.survey: {
				return <button className="lemonade" onClick={handleNewModule}>Pick Surveys</button>;
			}
			case PageModuleType.products: {
				return <button className="lemonade" onClick={handleNewModule}>Pick Products</button>;
			}
			case PageModuleType.documents: {
				return <button className="lemonade" onClick={handleNewModule}>Pick Documents</button>;
			}
			case PageModuleType.similar_sessions: {
				return <button className="lemonade" onClick={handleNewModule}>Pick Similar Session</button>;
			}
			case PageModuleType.blank: {
				return <button className="lemonade" onClick={createBlankMemoized}>Pick Embed Widget</button>;
			}
			default: return <button className="lemonade" disabled>Choose a Layout</button>;
		}
	};

	if (!workingSession) return null;
	return (
		<div className="structure-container">
			<ModalComponent
				cancellable={true}
				closeable={true}
				open={addModuleOpen}
				onRequestClose={closeModuleModal}
				title={"Select Section"}
				footer={
					<div className="custom-section-layouts">{uploading
						? <button className="lemonade"><WaitingIndicator /> </button>
						: section()
					}</div>
				}
			>
				<div className="custom-section-modal">
					<ul>
						<li
							className={classNames({
								active: activeType === PageModuleType.description,
							})}
						>
							<BasicCard p={0}>
								<button className="no-style" onClick={selectModule(PageModuleType.description)}>Description</button>
							</BasicCard>
						</li>
						<li
							className={classNames({
								active: activeType === PageModuleType.speakers,
							})}
						>
							<BasicCard p={0}>
								<button className="no-style" onClick={selectModule(PageModuleType.speakers)}>Speakers</button>
							</BasicCard>
						</li>
						<li
							className={classNames({
								active: activeType === PageModuleType.questions,
							})}
						>
							<BasicCard p={0}>
								<button className="no-style" onClick={selectModule(PageModuleType.questions)}>Questions</button>
							</BasicCard>
						</li>
						<li
							className={classNames({
								active: activeType === PageModuleType.survey,
							})}
						>
							<BasicCard p={0}>
								<button className="no-style" onClick={selectModule(PageModuleType.survey)}>Surveys</button>
							</BasicCard>
						</li>
						<li
							className={classNames({
								active: activeType === PageModuleType.products,
							})}
						>
							<BasicCard p={0}>
								<button className="no-style" onClick={selectModule(PageModuleType.products)}>Products</button>
							</BasicCard>
						</li>
						{/* 
						quizzes are not in designs, dont show
						<li
							className={classNames({
								active: activeType === PageModuleType.quizzes,
							})}
						>
							<BasicCard p={0}>
								<button className="no-style" onClick={selectModule(PageModuleType.quizzes)}>Quizzes</button>
							</BasicCard>
						</li> */}
						<li
							className={classNames({
								active: activeType === PageModuleType.documents,
							})}
						>
							<BasicCard p={0}>
								<button className="no-style" onClick={selectModule(PageModuleType.documents)}>Documents</button>
							</BasicCard>
						</li>
						<li
							className={classNames({
								active: activeType === PageModuleType.similar_sessions,
							})}
						>
							<BasicCard p={0}>
								<button className="no-style" onClick={selectModule(PageModuleType.similar_sessions)}>Suggested</button>
							</BasicCard>
						</li>
						<li
							className={classNames({
								active: activeType === PageModuleType.blank,
							})}
						>
							<BasicCard p={0}>
								<button className="no-style" onClick={selectModule(PageModuleType.blank)}>Embed Widget</button>
							</BasicCard>
						</li>
					</ul>
				</div>
			</ModalComponent>
			<ModalComponent
				cancellable={true}
				closeable={false}
				open={blankModalOpen}
				onRequestClose={closeBlankModule}
				title={"Blank Module"}
				footer={
					<>
						<button onClick={closeBlankModule} style={{ marginRight: 16 }}>
							Cancel
						</button>
						<button onClick={finishedEditingBlank} className="lemonade">
							Done
						</button>
					</>
				}
			>
				<div>
					<label className="subtitle">Add custom HTML Code</label>

					<CustomHTML
						defaultValue={workingBlankModule?.content.html ?? ""}
						onChange={updateWorkingBlankModule}
					/>
				</div>
			</ModalComponent>
		</div>
	);
};

export default CustomOptions;
