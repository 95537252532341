import { Action } from '../../../types/actions';
import { FontPack } from '../../../types/working-model';
import { SET_PAGE_FONTS } from '../../actions/admin/page';

export interface PageState {
	fonts: FontPack | null;
}

const initialState: PageState = {
	fonts: null,
};

export default function PageReducer(
	state: PageState = initialState,
	action: Action
): PageState {
	switch (action.type) {
		case SET_PAGE_FONTS: {
			return { ...state, fonts: action.payload };
		}
		default:
			return state;
	}
}
