import { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useGetSession, useGetSessionPreview } from "../../../../../hooks/session.hooks";
import { PageModule, PageModuleType, Speaker, } from "../../../../../types/working-model";
import { OptionalComponent } from "../../../../../utils/optional-component";
import { dedupSpeakers } from "../../../../../utils/speakers";

import './speaker-lineup.scss';
import { resizedImage } from "utils/utils";
import { useTypedSelector } from "store/reducers/use-typed-selector";


interface SpeakersLineupProps {
	setSpeaker?: (speaker: Speaker) => void;
	showSpeakerDetailsModal?: VoidFunction;
	showAllSpeakersFromAllSessions?: boolean
}

export default function SpeakersLineup({
	setSpeaker,
	showSpeakerDetailsModal,
	showAllSpeakersFromAllSessions = false,
}: SpeakersLineupProps): JSX.Element {

	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const event = eventBundle ?? workingEvent;

	const session = useGetSession();
	const sessionPreview = useGetSessionPreview();
	const [speakers, setSpeakers] = useState<Speaker[]>([]);
	const _session = session ?? sessionPreview;

	const getExistingSpeakers = useCallback(() => {
		if (event?.sessions && showAllSpeakersFromAllSessions) {
			const _speakers = event.sessions.map((session) => {
				const speakers = session.modules
					.filter((module: PageModule) => (module.type === PageModuleType.speakers && module.is_on && module.content_modules?.length))
					.flatMap(module => module.modules ?? []);
				return speakers;
			}).reduce((acc, val) => acc.concat(val), []);
			const dedupedSpeakers = _speakers.reduce(dedupSpeakers, [[], new Set<number>()])[0];
			return dedupedSpeakers;
		}
		else if (_session?.modules) {
			const speakers = _session.modules
				.filter((module: PageModule) => (module.type === PageModuleType.speakers && module.is_on && module.content_modules?.length))
				.flatMap(module => module.modules ?? []);
			return speakers;
		}
		return [];
	}, [_session?.modules, event, showAllSpeakersFromAllSessions]);

	useEffect(() => {
		setSpeakers(getExistingSpeakers());
	}, [getExistingSpeakers]);

	const handleSpeakerClick = useCallback((speaker) => () => {
		if (!setSpeaker || !showSpeakerDetailsModal) return;
		setSpeaker(speaker);
		showSpeakerDetailsModal();
	}, [setSpeaker, showSpeakerDetailsModal]);

	const uniqueSpeakers = useMemo(() => {
		return speakers.reduce(dedupSpeakers, [[], new Set<number>()])[0];
	}, [speakers]);

	return (

		<OptionalComponent display={!!uniqueSpeakers?.length}>
			<ul className="session-details-modal-speakers">
				{uniqueSpeakers.map((speaker) => {
					const { speaker: id, first_name, last_name } = speaker;

					return (
						<li key={id}>
							<img src={resizedImage(speaker.image, { height: 64, width: 64, fit: 'cover' })} alt={speaker.first_name} className="speaker-image" />
							<button
								className={classNames("evt-typography-caption-l1 no-padding no-style")}
								onClick={handleSpeakerClick(speaker)}
							>
								{`${first_name} ${last_name}`}
								&nbsp;
							</button>
						</li>
					);
				})}
			</ul>
		</OptionalComponent>


	);
}