import { useMemo } from 'react';
import classNames from 'classnames';

import { Button } from '../../general-ui/button/button';
import { EIcon } from '../../general-ui/icon/icon';
import { useParams } from 'react-router';
import { ParamsProps } from '../live-event';
import { BrandliveEvent, RegistrationStepType } from '../../../types/working-model';
import { MarkdownItem } from '../../general-ui/typography-item/typography-item';
import { useTypedSelector } from '../../../store/reducers/use-typed-selector';
import { useTranslate } from '../../../i18n/useTranslationModules';
import { OptionalComponent } from '../../../utils/optional-component';
import registrationFirstStepButtonText from '../../../utils/registration-first-step-button-text';

export enum RegFailureReasons {
	passcode = 'invalid-passcode',
	domain = 'invalid-email-domain',
	missingRequired = 'missing-required-field',
	missingPasscodeLists = 'no-matching-passcode-lists',
	notFoundInPasscodeLists = 'not-found-passcode-lists',
	failedRecaptchaV3 = 'failed-recaptcha-v3',
	failedRecaptchaV2 = 'failed-recaptcha-v2',
	registrationClosed = 'registration-closed',
	badWords = 'bad-words-detected',
	deletedRegistration = 'deleted-registration'
}

export interface RegistrationFooterProps {
	isNextDisabled?: boolean;
	isFirstStep: boolean;
	isFinalStep: boolean;
	useCustomLogin?: boolean;
	template?: string;
	onPrevStepClick: (e: any) => void;
	waiting: boolean;
	error?: string;
	isSkippable?: boolean;
	eventBundle?: BrandliveEvent | null;
	renderRecaptcha?: () => JSX.Element;
}
export const RegistrationFooter = ({
	isNextDisabled,
	isFirstStep,
	isFinalStep,
	useCustomLogin,
	template,
	onPrevStepClick,
	waiting,
	error = '',
	isSkippable,
	eventBundle,
	renderRecaptcha
}: RegistrationFooterProps): JSX.Element => {
	const { t } = useTranslate(["homepage", "session"]);
	const { language } = useParams() as ParamsProps;

	const translationUpdates = useTypedSelector(event => event.LiveEventReducer.translationUpdates);
	const isLiveClosed = useTypedSelector(state => !!state.LiveEventReducer?.eventBundle?.registration_settings?.isClosed);
	const isWorkingClosed = useTypedSelector(state => !!state.CreateEventReducer?.workingEvent?.registration_settings?.isClosed);
	const registrationStep = useTypedSelector(state => state.LiveEventReducer.registrationStep);
	const blProfileUser = useTypedSelector(state => state.LiveEventReducer.blProfileUser);
	const eventbriteOrderDetailsRetry = useTypedSelector(state => state.LiveEventReducer?.eventbriteOrderDetailsRetry);

	const isRegistrationClosed = isLiveClosed || isWorkingClosed;
	const registrationSteps = eventBundle?.registration_steps;

	const isAvatarStep = useMemo(() => registrationSteps?.filter(step => step.isOn)?.[registrationStep].type === RegistrationStepType.avatar, [registrationStep, registrationSteps]);

	const channel = eventBundle?.channel ?? 0;
	const profile = blProfileUser?.profile?.[channel];
	const regErrorMessage = useMemo(() => {
		if (!error) return '';
		let errorMessage;

		switch (error) {
			case RegFailureReasons.domain:
			case RegFailureReasons.missingPasscodeLists:
			case RegFailureReasons.notFoundInPasscodeLists:
			case RegFailureReasons.passcode: {
				if (eventBundle?.registration_settings?.customRejectedPasscodeMessage?.base) {
					return (
						<MarkdownItem className="registration-error custom-failed-passcode" allowedElements={['a', 'p']}>
							{t(
								`email.${eventBundle.registration_settings.customRejectedPasscodeMessage.base}`,
								eventBundle?.registration_settings?.customRejectedPasscodeMessage?.[language] as string ?? eventBundle?.registration_settings?.customRejectedPasscodeMessage?.base
							)}
						</MarkdownItem>
					);
				}
				errorMessage = t("homepage:registration.gatingErrorMessage");
				break;
			}
			// this should never fire because submission is (should be) blocked until required fields are completed
			case RegFailureReasons.missingRequired: {
				errorMessage = t("homepage:registration.missingRequired");
				break;
			}
			case RegFailureReasons.deletedRegistration: {
				errorMessage = t("homepage:registration.deletedRegistration");
				break;
			}
			case RegFailureReasons.registrationClosed: {
				errorMessage = t("homepage:registration.Registration is closed");
				break;
			}
			case RegFailureReasons.badWords: {
				errorMessage = t("homepage:registration.badWords");
				break;
			}
			default: {
				errorMessage = t("homepage:registration.error");
			}
		}
		return <p className="registration-error">{errorMessage}</p>;

		// we need translationUpdates in deps array to make sure we have the latest translations
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error, eventBundle?.registration_settings?.customRejectedPasscodeMessage, language, t, translationUpdates]);

	if (useCustomLogin && isFirstStep) return <></>;
	if (isFirstStep)
		return (
			<div className="registration-actions primary">
				{error && <p className="registration-error" role="alert">{regErrorMessage}</p>}
				{renderRecaptcha?.()}
				<Button
					classButton="primary full-width"
					template={template}
					typeBtn="submit"
					isDisabled={isNextDisabled || waiting || isRegistrationClosed || !!eventbriteOrderDetailsRetry}
				>
					{registrationFirstStepButtonText({ isRegistrationClosed, isFinalStep, t })}
				</Button>
			</div>
		);
	else if (isFinalStep)
		return (
			<div className="registration-actions secondary">
				{error && <p className="registration-error" role="alert">{regErrorMessage}</p>}
				<OptionalComponent display={!useCustomLogin}>
					<Button
						isRound={true}
						classButton={classNames('registration-button secondary')}
						template={template}
						onClick={onPrevStepClick}
						typeBtn="button"
					>
						<EIcon name="keyboard-arrow-left" />
					</Button>
				</OptionalComponent>

				<div>
					{isSkippable ? (
						<Button
							classButton={classNames('registration-button secondary')}
							template={template}
							typeBtn="submit"
							isDisabled={waiting || isRegistrationClosed}
						>
							{isRegistrationClosed ? t("homepage:registration.Registration is closed") : t("Skip")}
						</Button>
					) : null}
					<Button
						classButton={classNames('registration-button', { "primary": !isAvatarStep, "secondary": isAvatarStep })}
						template={template}
						typeBtn="submit"
						isDisabled={isNextDisabled || waiting || isRegistrationClosed}
					>
						{isRegistrationClosed ? t("homepage:registration.Registration is closed") : t(profile?.avatar && isAvatarStep ? "session:Continue" : "Save")}
					</Button>
				</div>
			</div>
		);
	return (
		<div className="registration-actions secondary">
			{error && <p className="registration-error" role="alert">{regErrorMessage}</p>}
			<OptionalComponent display={!useCustomLogin}>
				<Button
					isRound={true}
					classButton={classNames('registration-button secondary')}
					template={template}
					onClick={onPrevStepClick}
				>
					<EIcon name="keyboard-arrow-left" />
				</Button>
			</OptionalComponent>
			<div>
				{isSkippable ? (
					<Button
						classButton={classNames('registration-button secondary')}
						template={template}
						isDisabled={waiting}
						typeBtn="submit"
					>
						{t("Skip")}
					</Button>
				) : null}
				<Button
					classButton={classNames('registration-button', { "primary": !isAvatarStep, "secondary": isAvatarStep })}
					template={template}
					isDisabled={isNextDisabled || waiting}
					typeBtn="submit"
				>
					{t(profile?.avatar && isAvatarStep ? "session:Continue" : "Save")}
				</Button>
			</div>
		</div>
	);
};
