import { chain, difference, intersection } from "underscore";
import { ESolutionNames } from "../types/integrations";
import { BrandliveEvent, RegistrationStep, RegistrationStepType, RegistrationTypes, Session, SessionPreview, StepsCompleted } from "../types/working-model";

/**
 *
 * @param eventBundle 
 * @param paymentRequired 
 */
export function hasEventAccess(
	eventBundle: BrandliveEvent | null,
	validSessions: Array<string | number>,
	finishedRegistering: boolean,
	userVerified: boolean,
	firesidesHostSessions: number[],
	registration_bypass: boolean,
	loadingValidSessions: boolean,
): boolean {

	if (!eventBundle) { return false; }
	if (!eventBundle.registration_on) { return true; } // No registration required
	if (registration_bypass && !loadingValidSessions) { return true; } // Registration bypass flag
	if (!validSessions || validSessions.length === 0) { return false; } // No session access

	const emailConfirmationRequired = !!eventBundle?.registration_settings?.registrationConfirmation || firesidesHostSessions.length > 0;

	return (finishedRegistering && !emailConfirmationRequired) || // Registration complete, no email confirmation required
		(emailConfirmationRequired && userVerified && finishedRegistering); // Registration complete, requires email and has confirmed email (usercode)
}

export function hasSessionAccess(
	eventBundle: BrandliveEvent | null | undefined,
	session: Session | SessionPreview | undefined,
	validSessions: Array<string>,
	registrationBypass: boolean,
	paidSessions?: Array<string>,
	isModerator?: boolean,
	ticketStep?: RegistrationStep,
): boolean {

	if (!eventBundle) { return false; }
	if (!session) { return false; }
	if (registrationBypass) { return true; }
	if (!eventBundle.registration_on || isModerator) { return true; } // No registration required

	if (paidSessions && paidSessions.includes(session.uuid)) {
		return true;
	}

	const ticketingStep = ticketStep || eventBundle?.registration_steps?.find(step => step.type == RegistrationStepType.ticketing && step.isOn);
	let sessionHasTicket = false;

	if (ticketingStep) {
		const sessionTickets = ticketingStep.ticketing?.find(ticket => ticket.session_uuids.indexOf(session.uuid) >= 0) || [];

		if (sessionTickets) {
			sessionHasTicket = true;
		}
	}

	if (sessionHasTicket) { // Already checked if paid session contains uuid
		return false;
	}

	const _validSessions = validSessions.map(Number);
	const isValidSession = _validSessions.includes(session.session);

	return isValidSession || eventBundle.registration_settings?.type !== RegistrationTypes.gated;
}

/**
 * Reconcile the required registration questions with the steps completed.
 * Returns a list of unanswered required questions
 */
export const findIncompleteRequiredQuestions = (requiredQuestions: number[] | null, stepsCompleted?: StepsCompleted): number[] => {
	// Nothing to do here
	if (!requiredQuestions || requiredQuestions.length === 0) {
		return [];
	}

	// get the question ids for all steps completed
	const answeredQuestions: number[] = chain(stepsCompleted ?? {}).values().flatten().uniq().value();

	return difference(requiredQuestions, answeredQuestions);
};

/**
 * Given a list of incomplete questions ids,
 * Get the first registration step that is turned on and has that question.
 */
export const findFirstIncompleteStep = (registrationSteps: RegistrationStep[], requiredQuestions: number[] | null, stepsCompleted: StepsCompleted): RegistrationStepType | null => {
	// For allowing null stepsCompleted for backwards compatibility.
	// The general registration step should be completed before this function is ran.
	if (!registrationSteps?.length || !requiredQuestions?.length || !stepsCompleted) { return null; }

	const requiredAndIncomplete = findIncompleteRequiredQuestions(requiredQuestions, stepsCompleted);
	if (!requiredAndIncomplete.length) { return null; }

	for (const step of registrationSteps) {
		// Skip inactive steps
		if (!step.isOn) {
			continue;
		}

		const questionIdsForStep = step.questions?.map(q => q.registration_question) ?? [];
		const hasOverlap = intersection(questionIdsForStep, requiredAndIncomplete).length > 0;

		if (hasOverlap) {
			return step.type;
		}
	}
	return null;
};


/**
 * Returns true if the event is using an external source for registrations.
 * @param event 
 * @returns 
 */
export const isExternalRegistration = (event: BrandliveEvent | null): boolean => {
	return !!event?.registration_settings?.externalRegistration?.isOn;
};

export function getIntegrationSchema(name: string | null, uuid?: string) {
	if (!name || !uuid) return;
	switch (name) {
		case ESolutionNames.Marketo: {
			return (
				`{"target": "${uuid}","email_address":{{lead.Email Address:default=edit me}},"first_name": {{lead.First Name:default=edit me}},"last_name": {{lead.Last Name:default=edit me}}}`
			);
		}
		case ESolutionNames.Salesforce:
		case ESolutionNames.Hubspot: {
			return JSON.stringify({
				target: uuid,
				email_address: "<user's email address>",
				first_name: "<user's first name>",
				last_name: "<user's last name>",
				company: "<user's company>",
				title: "<user's job title>"
			}, null, 2);
		}
		default: {
			return JSON.stringify({
				target: uuid,
				data: {
					email_address: "<user's email address>",
					first_name: "<user's first name>",
					last_name: "<user's last name>",
					company: "<user's company>",
					title: "<user's job title>"
				}
			}, null, 2);
		}
	}
}

export function getIntegrationResponse(name: string | null) {
	if (!name) return;
	switch (name) {
		case ESolutionNames.Custom:
		case ESolutionNames.Salesforce:
		case ESolutionNames.Hubspot:
		case ESolutionNames.Marketo: {
			return JSON.stringify({
				link: "<registered link for event>"
			}, null, 2);
		}
		default: return;
	}
}