import { BrandliveEvent, BreakoutRoom, IPostRegisterHomeModule, PageModule, Dictionary, EventListSortType, BrandliveEventListItem, IUpdateSessionsTracks, EventType, GetEventsResponse, EventsTotals } from '../types/working-model';
import { Get, HvHostMap, Post, PostHv, Put, PutJson } from './helpers';
import { createQueryString } from '../utils/utils';
import { TabTypes } from '../components/admin/events/tab-types';
import { RSVPLinkSync } from '../types/rsvp';

// Maps the UI tab to the API time filter
const tabTypeMap = {
	[TabTypes.Live]: 'live',
	[TabTypes.Upcoming]: 'upcoming',
	[TabTypes.OnDemand]: 'onDemand',
	[TabTypes.All]: 'all',
	[TabTypes.TestEvents]: 'testEvents'
};

export function GetEvents(
	channel: number,
	token: string,
	limit?: number,
	offset?: number,
	sort?: EventListSortType,
	search?: string,
	selectedEventGroupUuid?: string,
	time?: string,
	templateEvents?: boolean,
	eventType?: string
): Promise<{ events: GetEventsResponse[], totals: EventsTotals }> {
	const queryParams = createQueryString({
		limit: limit || 9,
		offset: Math.max(0, offset ?? 0),
		search: search || '',
		sort: sort || EventListSortType.dateDesc,
		selectedEventGroupUuid: selectedEventGroupUuid || '',
		time: time && tabTypeMap[time as TabTypes] || 'all',
		templateEvents: !!templateEvents,
		eventType: eventType || ''
	});

	return Get(`/v3/admin/events/${channel}?${queryParams}`, token);
}

export function GetEventsFullList(
	channel: number,
	token: string
): Promise<BrandliveEventListItem[]> {
	return Get(`/v3/admin/events/full/${channel}`, token);
}

export function DuplicateEvent(eventUuid: string, channel: number, token: string): Promise<Event> {
	return Post(`/v3/admin/events/duplicate-event/${eventUuid}`, token, { channel }, false);
}

export function UpdatePostRegisterPreviewModules(homepage: number, token: string, modules: IPostRegisterHomeModule[]): Promise<any> {
	return Put(`/v3/admin/events/homepage/post-register-modules/${homepage}`, token, modules);
}

export function DuplicateModulesForPostRegisterHomeView(
	homepage: number,
	token: string,
	modules: PageModule[]
): Promise<any> {
	return Post(`/v3/admin/events/homepage/modules/duplicate-for-home-view/${homepage}`, token, modules);
}

export function GetBreakoutsForEvent(uuid: string, token: string): Promise<{ [key: string]: BreakoutRoom[] }> {
	return Get(`/v3/admin/events/breakout-rooms/${uuid}`, token);
}

// yes, I know this function is a Get using Post, but it seemed nicer than putting an entire array of event uuids into a parameter string. sorry.
export function GetPlaybackURLsForEvents(events: BrandliveEvent[], token: string): Promise<{ event: string, playbackURLs: Dictionary; }[]> {
	return Post('/v3/admin/events/playback-urls', token, { events });
}

export async function UpdateUserSavedSessions(token: string, eventUuid: string, sessionUuids: string[]): Promise<any> {
	return PostHv(HvHostMap.eventData, `/e3-live-event/profiles/update-saved-sessions/${eventUuid}`, { sessionUuids }, token);
}

export async function UpdateSessionsTracks(token: string, eventUUID: string, data: { [sessionUUID: string]: IUpdateSessionsTracks }): Promise<{ [sessionUUID: string]: IUpdateSessionsTracks }> {
	return Post(`/v3/admin/events/update-session-title-and-tracks/${eventUUID}`, token, data);
}

export async function UpdateEventType(eventUuid: string, eventType: EventType, token: string): Promise<EventType> {
	return Put(`/v3/admin/event/${eventUuid}/event-type`, token, { eventType }, true);
}

export async function UpdateEventImage(token: string, eventUuid: string, url: string): Promise<string> {
	return Put(`/v3/admin/event/${eventUuid}/image`, token, { url }, true);
}

export async function UpdateSelectedEventDefaultLanguage({ token, eventUuid, language }: { token: string, eventUuid: string, language: string }): Promise<BrandliveEvent> {
	return PutJson({
		path: `/v3/admin/event/${eventUuid}/default-language`,
		token,
		data: {
			language
		}
	});
}

export async function UpdateEventPmManagedReq(token: string, eventUuid: string, isOn: boolean): Promise<string> {
	return PutJson({ path: `/v3/admin/event/${eventUuid}/pm-managed`, token, data: { isOn } });
}

export async function UpdateEventSimple(
	token: string,
	eventUuid: string,
	name: string,
	eventType: string,
	eventDescription?: string,
	eventImageUrl?: string,
	dmaID?: string | null,
	isTestEvent?: boolean
): Promise<string> {
	return Put(`/v3/admin/event/${eventUuid}/simple`, token, { name, eventType, eventDescription, eventImageUrl, dmaID, isTestEvent }, false, true);
}

export async function GetRSVPEventLink(token: string, eventUuid: string): Promise<{ rsvpLink: RSVPLinkSync }> {
	return Get(`/v3/admin/rsvp_link_sync/${eventUuid}`, token);
}

export async function ApproveRSVPEventLink(token: string, linkGroup: string, eventId: number): Promise<RSVPLinkSync> {
	return Put(`/v3/admin/rsvp_link_sync/approve`, token, { linkGroup, eventId }, true);
}

export async function UpdateEventPublishSettings(token: string, eventUuid: string, isLocked: boolean, dmaID: string | null): Promise<{ is_publish_locked: boolean, dma_id?: string }> {
	return Put(`/v3/admin/event/${eventUuid}/publish-settings`, token, { isLocked, dmaID }, true);
}

