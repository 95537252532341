import {
	Suspense,
	useCallback,
	useEffect,
	useMemo,
	useState,
	useRef,
} from 'react';
import classNames from 'classnames';

// HOOKS
import {
	filterPageModulesWithContent,
	useActiveModuleGroupsInSession,
	useDarkMode,
	useIsBelowTheFold,
	useIsSingleSession,
	joinGoogleMeetRoom,
} from '../../../../hooks/session.hooks';
import { useTranslate } from '../../../../i18n/useTranslationModules';
import { useTypedSelector } from '../../../../store/reducers/use-typed-selector';
import { toDictTyped, toMap } from '../../../../utils/utils';
import useTranslationRoute from '../../hooks/use-translation-route';
import { useTemplateFilename } from '../../../../hooks/template-name.hooks';

// TYPES
import { MeetApp, MeetAppBuilder, StartCallResult } from '../../../../types/meet';
import {
	LanguagesAbbr,
	ModuleGroupingTypes,
	ModuleGroups,
	PageModule,
	PageModuleType,
	Session,
	SessionTypesEnum,
	TranslateString,
} from "../../../../types/working-model";

// COMPONENTS
import { RenderSessionModules } from '../session';
import LiveChat from '../live-chat/live-chat';
import SlidingMenu, { SlidingMenuOption } from '../../../general-ui/sliding-menu/sliding-menu';
import { isTranslateString } from '../../../../utils/translate-string';
import useHasScrolledToTop from '../../../../utils/use-has-scrolled-to-top';
import useHasScrolledToBottom from '../../../../utils/use-has-scrolled-to-bottom';
import ScrollOverlay from './scroll-overlay';
import { useWatchAdminNav } from './utils';
import TabFilter from '../../../general-ui/tab-filter/tab-filter';

// STYLES
import './above-the-fold-content.scss';
import './session-main-below-the-fold.scss';
import '../../../../scss/live-event/base/session/session.scss';
import ScrollViewWithFade from '@general-ui/animated/fade-container';
import { ConditionalWrapper } from 'utils/conditional-wrapper';
import { OptionalComponent } from 'utils/optional-component';

const tababbleModules = [
	ModuleGroupingTypes.Extras,
	ModuleGroupingTypes.custom
];

type AboveTheFoldContentProps = {
	session: Session;
	language: LanguagesAbbr;
	googleMeetBreakoutsRef?: React.MutableRefObject<HTMLDivElement | null>;
	chatClosed: boolean;
	isEditor?: boolean;
	overlayChatEnabled?: boolean;
}

export type SlidingMenuOptionTab = {
	label: string,
	data: {
		uuid: string,
		isSoloTab?: boolean,
		tabCounter?: JSX.Element
	}
};

const AboveTheFoldContent = ({ session, language, googleMeetBreakoutsRef, chatClosed, isEditor, overlayChatEnabled }: AboveTheFoldContentProps) => {
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);
	const liveEventBlProfileUser = useTypedSelector(state => state.LiveEventReducer.blProfileUser);
	const moderatorBlProfile = useTypedSelector(state => state.ModeratorReducer?.moderatorRegistration?.blProfile);
	const translationUpdates = useTypedSelector(state => state.LiveEventReducer.translationUpdates);
	const event = eventBundle || workingEvent;
	const blProfileUser = moderatorBlProfile || liveEventBlProfileUser;
	const isBreakout = session.session_type === SessionTypesEnum.breakoutRooms;
	const isChatPanelEnabled = session.session_chat_enabled;
	const adminInitialSelectedRouteRef = useRef<string>();
	const adminInitialSelectedTabRef = useRef<number>();
	const [selectedTabUuid, setSelectedTabUuid] = useState<string>();
	const [selectedModuleTabId, setSelectedModuleTabId] = useState<number>();
	const [chatIsActive, setChatIsActive] = useState<boolean>(!!isChatPanelEnabled && !isBreakout);

	const [meetAppBuilt, setMeetAppBuilt] = useState<{ app: MeetApp, builder: MeetAppBuilder }>();

	const panelContainerRef = useRef<HTMLDivElement | null>(null);

	const { hasScrolledToTop } = useHasScrolledToTop({
		scrolledContainerRef: panelContainerRef.current,
		initialState: true,
	});
	const { hasScrolledToBottom } = useHasScrolledToBottom({
		scrolledContainerRef: panelContainerRef.current,
		initialState: false,
	});

	const activeModuleGroups = useActiveModuleGroupsInSession(session, isEditor);

	const translationNamespace = useTranslationRoute();
	const singleSession = useIsSingleSession();
	const isSessionDetailsBelowTheFoldV2 = useIsBelowTheFold(session);
	const isBelowTheFold = useIsBelowTheFold(session);
	const templateClassName = useTemplateFilename(event);
	const { t } = useTranslate(["session", translationNamespace]);
	const groups = session.module_grouping;

	// fires when session changes 
	useEffect(() => {
		if (isBelowTheFold) {
			setSelectedTabUuid(undefined);
		}
	}, [isBelowTheFold, session]);

	useEffect(() => {
		// if this is above the fold, there are module groups defined, chat is off and we have an initial route, 
		// then set the selected tab in the view to be the first item in that group that is enabled and not overview or details
		if (!isBelowTheFold && groups && !isChatPanelEnabled && !adminInitialSelectedRouteRef.current) {
			setSelectedTabUuid(
				groups.find(group =>
					group.type &&
					![ModuleGroupingTypes.Overview, ModuleGroupingTypes.Details].includes(group.type) &&
					group.is_on
				)?.uuid
			);
		}
	}, [isBelowTheFold, groups, isChatPanelEnabled]);

	const tabGroupsMappedByUuid = useMemo(() => {
		return session.module_grouping && toDictTyped('uuid', session.module_grouping);
	}, [session.module_grouping]);

	const tabGroups = tabGroupsMappedByUuid?.[selectedTabUuid || ''];
	const sessionModules = session?.modules;

	const [/*allModules*/, availableModules, availableModuleIds] = useMemo((): [PageModule[], PageModule[], number[]] => {
		if (!selectedTabUuid || !sessionModules || !tabGroups) return [[], [], []];
		const moduleIds = tabGroups.modules;
		const moduleMap = toMap('id', sessionModules);
		const allModulesInGroup = moduleIds.map(moduleId => moduleMap.get(moduleId)).filter(module => module) as PageModule[];
		const availableModules = allModulesInGroup?.filter(filterPageModulesWithContent) ?? [];
		const availableModulesFilteredSingleSession = singleSession
			? availableModules.filter(m => m.type !== PageModuleType.similar_sessions)
			: availableModules;
		const availableModuleIds = availableModulesFilteredSingleSession.flatMap(module => (module as PageModule).id as number);
		return [allModulesInGroup, availableModulesFilteredSingleSession, availableModuleIds];
	}, [selectedTabUuid, sessionModules, tabGroups, singleSession]);

	useEffect(() => {
		// the tab has changed, so we need to reset the selected module tab
		// if the panel has tabs at all (engage does not for example) then we still 
		// need to set what modules to render in the view
		if (availableModuleIds.length && selectedTabUuid && tabGroups) {
			// first check if the selected tab includes any enabled modules
			if (availableModuleIds.some(module => tabGroups.modules.includes(module))) {
				setSelectedModuleTabId(prev => {
					if (!prev && adminInitialSelectedTabRef.current) {
						return adminInitialSelectedTabRef.current;
					}

					if (!prev || !availableModuleIds.includes(prev)) {
						return availableModuleIds[0];
					}

					return prev;
				});
			} else {
				setSelectedModuleTabId(availableModuleIds[0]);
			}
		}
	}, [availableModuleIds, selectedTabUuid, tabGroups]);

	const previewSession = useMemo(() => {
		if (singleSession && workingEvent?.sessions[0]) {
			return workingEvent?.sessions[0];
		} else {
			return workingSession ?? undefined;
		}
	}, [singleSession, workingEvent, workingSession]);

	const isDarkMode = useMemo(() => useDarkMode(session), [session]);

	const submitGoogleToken = async (token: string | null, url: string): Promise<StartCallResult> => {
		if (googleMeetBreakoutsRef?.current) {
			const [joinResult] = await joinGoogleMeetRoom(googleMeetBreakoutsRef.current, url, token, meetAppBuilt, setMeetAppBuilt, setSelectedTabUuid, session, isDarkMode);
			return joinResult;
		}
		return Promise.resolve(StartCallResult.UNKNOWN_START_CALL_RESULT);
	};

	const isTabbableModule = useMemo(() => {
		const module = activeModuleGroups.find((group) => group.uuid === selectedTabUuid);

		return module?.type && tababbleModules.includes(module.type);
	}, [activeModuleGroups, selectedTabUuid]);

	const handleTab = useCallback((id: number) => () => {
		adminInitialSelectedTabRef.current = undefined;
		setSelectedModuleTabId(id);
	}, []);

	// Added to select the first subtab when extras is enabled
	useEffect(() => {
		const currentTab = activeModuleGroups.find((group) => group.uuid === selectedTabUuid);
		if (!currentTab?.modules.includes(selectedModuleTabId || 0) && availableModuleIds.length > 1) {
			setSelectedModuleTabId(prev => {
				if (!prev || !availableModuleIds.includes(prev)) {
					return availableModuleIds[0];
				}

				return prev;
			});
		}
	}, [activeModuleGroups, availableModuleIds, selectedModuleTabId, selectedTabUuid]);

	const renderModulesByGroup = () => {
		if (!selectedTabUuid || !session || !tabGroups) return <></>;

		if (isTabbableModule && availableModules.length) {
			const newModuleGroup = {
				...tabGroups,
				modules: [selectedModuleTabId || 0],
			};

			return (
				<div>
					<TabFilter
						availableModules={availableModules}
						selectedModuleTabId={selectedModuleTabId}
						isEditor={isEditor}
						handleTab={handleTab}
					/>

					<div className="page-container-body-inner">
						<RenderSessionModules
							key={newModuleGroup.uuid}
							moduleGroup={newModuleGroup}
							session={session}
							template={templateClassName}
							preview={!!previewSession}
							submitGoogleToken={submitGoogleToken}
							isEditor={isEditor}
						/>
					</div>
				</div>
			);
		}

		return (
			<div className="page-container-body-inner">
				<RenderSessionModules
					key={tabGroups.uuid}
					moduleGroup={tabGroups}
					session={session}
					template={templateClassName}
					preview={!!previewSession}
					submitGoogleToken={submitGoogleToken}
					isEditor={isEditor}
				/>
			</div>
		);
	};

	const displaySidePanel = useMemo(() => {
		if (!isChatPanelEnabled && isSessionDetailsBelowTheFoldV2) return false;

		// Hide panel if the chat is inactive, and details is not selected.
		if (isSessionDetailsBelowTheFoldV2) {
			return (!overlayChatEnabled && isChatPanelEnabled) || activeModuleGroups.some(group => group.type === ModuleGroupingTypes.Details);
		}

		// Hide panel if All tabs and chat are inactive
		if (!isSessionDetailsBelowTheFoldV2) {
			return (!overlayChatEnabled && isChatPanelEnabled) || activeModuleGroups.some(group => group.type !== ModuleGroupingTypes.Overview);
		}
	}, [activeModuleGroups, isSessionDetailsBelowTheFoldV2, isChatPanelEnabled, overlayChatEnabled]);

	const getTabLabel = useCallback((name: TranslateString | ModuleGroups): string => {
		if (isTranslateString(name)) {
			return (name[language] ?? name.base) as string;
		} else {
			return t(name, name);
		}
	}, [language, t]);

	const tabOptions = useMemo(() => {
		const baseTabs: SlidingMenuOptionTab[] = [];

		// start with the chat tab as the base tab
		if (!isBreakout && isChatPanelEnabled) {
			baseTabs.push({
				label: t("Chat"),
				data: {
					uuid: "chat"
				}
			});
		}

		const tabs = activeModuleGroups.reduce((tabs, group) => {
			const { name, type, modules } = group;

			// "Details" is always rendered above the fold even if below the fold is active
			const showDetailsForBelowTheFold = type === ModuleGroupingTypes.Details;

			// in above the fold, all tabs should appear regardless of whether they have content when in the editor
			const showForEditorAboveTheFold = !isSessionDetailsBelowTheFoldV2 && isEditor;

			// in above the fold, when NOT in the editor, only tabs with content modules should appear
			const showBecauseContainsContentAboveTheFold = (!isSessionDetailsBelowTheFoldV2 && !!modules.length);

			// the overview section should never be rendered as a tab
			const hideBecauseOverview = type === ModuleGroupingTypes.Overview;

			if (hideBecauseOverview) return tabs;

			if (
				showDetailsForBelowTheFold ||
				showForEditorAboveTheFold ||
				showBecauseContainsContentAboveTheFold
			) {
				return [
					...tabs,
					{
						label: getTabLabel(name),
						data: {
							uuid: group.uuid,
							/* REMOVING 10/10/2023 based on comment from will but may return */
							// tabCounter: session ? <EngageCount group={group} session={session} /> : undefined
						}
					}
				];
			}

			return tabs;
		}, baseTabs);

		if (tabs.length === 1) {
			// needs some clarification on what isSoloTab should do
			tabs[0].data.isSoloTab = true;
		}

		return tabs;

		// disabling exhaustive deps because we need translationUpdates and session
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		activeModuleGroups,
		getTabLabel,
		isBreakout,
		isChatPanelEnabled,
		isEditor,
		isSessionDetailsBelowTheFoldV2,
		t,
		translationUpdates,
		session
	]);

	// watches for admin navigation actions to update tab automatically
	useWatchAdminNav({
		session,
		setChatIsActive,
		setSelectedTabUuid,
		setSelectedModuleTabId,
		adminInitialSelectedRouteRef,
		adminInitialSelectedTabRef,
		tabOptions
	});


	const selectedTab = useMemo(() => {
		return tabOptions.find((tab: SlidingMenuOptionTab) => tab.data.uuid === selectedTabUuid) ?? null;
	}, [selectedTabUuid, tabOptions]);

	const selectTab = useCallback((tab: SlidingMenuOption<{ uuid: string }>) => {
		adminInitialSelectedRouteRef.current = undefined;
		setChatIsActive(tab.data?.uuid === "chat");
		setSelectedTabUuid(tab.data?.uuid);
	}, []);

	// Select first available tab if the selected tab is no longer available
	useEffect(() => {
		const selectedTab = tabOptions.find((tab: SlidingMenuOptionTab) => tab.data.uuid === selectedTabUuid);

		if (!selectedTab && tabOptions.length) {
			selectTab(tabOptions[0]);
		}
	}, [selectTab, selectedTabUuid, tabOptions]);

	if (!event) return <></>;

	return (
		displaySidePanel ?
			<div className={classNames('above-the-fold-layout', { 'with-chat': chatIsActive, 'chat-closed': chatClosed })}>
				<Suspense fallback="">
					{tabOptions.length >= 2 ? ( // Chat is always a tab option, but is hidden if it's the only tab
						<SlidingMenu
							options={tabOptions}
							selectedOption={selectedTab}
							callback={selectTab}
							className="above-the-fold-tabs"
							fullWidthDivider={true}
						/>
					) : null}
					<div
						className={classNames(
							"page-container-body",
							{
								'scrolled-to-top': hasScrolledToTop,
								'scrolled-to-bottom': hasScrolledToBottom,
								'no-tabs': tabOptions.length < 2
							}
						)}
						ref={panelContainerRef}
					>

						{chatIsActive ? (
							<LiveChat
								session={session}
								loading={false}
								language={language}
								blProfileUser={blProfileUser}
								template={templateClassName}
								forceOverlay={false}
								mobileOnly={false}
								isEditor={false}
								onChatClose={() => setChatIsActive(false)}
								chatClosed={false}
								eventBundle={event}
								isAdmin={false}
								canGoLandscape={false}
								registrationOn={!!event?.registration_on}
							/>
						) : (
							<OptionalComponent display={!isBelowTheFold}>
								<ScrollViewWithFade>
									{renderModulesByGroup()}
								</ScrollViewWithFade>
							</OptionalComponent>
						)}
					</div>
				</Suspense >
			</div > : null

	);
};

export default AboveTheFoldContent;
