import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { batch, useDispatch } from 'react-redux';

import {
	useGetSession,
	useIsAboveTheFold, useIsBelowTheFold,
	useIsNewModuleGrouping,
} from '../../../../../hooks/session.hooks';
import {
	setSessionPanelLayoutType,
	updatePageModule,
	updateSession,
	updateWorkingSession,
} from "../../../../../store/actions/admin/create-event/session";
import { useTypedSelector } from '../../../../../store/reducers/use-typed-selector';
import { EditorSizes } from '../../../../../types/template-layouts';
import {
	ModuleGroupingTypes,
	PageModule,
	PageModuleGroupModules,
	PageModuleType,
	Session,
	SessionTabNames
} from "../../../../../types/working-model";
import { hasPageModuleControls } from '../../../../../utils/controls-utils';
import Icon, { COLORS, ICONS } from '../../../../general-ui/icon';
import EditSectionColorsModal, { EContentColorOverrideNames } from '../../../../general-ui/edit-section-colors/edit-section-colors-modal';

import './controls.scss';
import { MODULE_TAB_TYPES } from '../../../../../types/module-tabs';
import { useParams } from 'react-router-dom';

interface InitialControlsDisplay {
	editColors?: boolean;
	addContent?: boolean;
	changeSectionPosition?: boolean;
}

const InitialControlsDisplay = {
	editColors: true,
	addContent: true,
	changeSectionPosition: true,
};

interface IControlsProps {
	page_module: PageModule;
	openEditModal: () => void;
	display?: InitialControlsDisplay;
}

const Controls: React.FC<React.PropsWithChildren<IControlsProps>> = ({
	page_module,
	openEditModal,
	children,
	display = InitialControlsDisplay
}) => {
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);
	const token = useTypedSelector(state => state.AuthReducer.token);
	const dispatch = useDispatch();
	const [openStyleModal, setOpenStyleModal] = useState(false);
	const [workingPageModule, setWorkingPageModule] = useState(page_module);

	const isModuleGroupingV2 = useIsNewModuleGrouping();
	const session = useGetSession();
	const isAboveTheFold = useIsAboveTheFold(session);
	const isBelowTheFold = useIsBelowTheFold(session);
	const editorSize = useTypedSelector(state => state.CreateEventReducer.editorSize);

	const { customUuid } = useParams<{ customUuid?: string }>();

	const resetToDefaultLayoutType = useCallback(() => {
		dispatch(setSessionPanelLayoutType(SessionTabNames.Content_v2));
	}, [dispatch]);

	const handleSetPanelLayoutType = useCallback((type: PageModuleType) => {
		resetToDefaultLayoutType();
		const key = customUuid ? "custom" : "base";
		const tab = MODULE_TAB_TYPES[key][type];

		if (tab !== undefined) {
			dispatch(setSessionPanelLayoutType(tab));
		} else {
			dispatch(setSessionPanelLayoutType(null));
		}
	}, [customUuid, dispatch, resetToDefaultLayoutType]);

	const isDesktop = editorSize === EditorSizes.desktop;

	useEffect(() => setWorkingPageModule(page_module), [page_module]);

	function changeModuleIndex(direction: number, moveModule: PageModule): void {
		if (workingSession) {
			const updatedSession: Session = {
				...workingSession,
				module_grouping: workingSession.module_grouping?.map((module: PageModuleGroupModules) => {
					// find correct module_group
					const matched = module.modules.find((moduleId: number) => moduleId === moveModule.id);
					if (!matched) return module;
					const _modules = module.modules.slice();
					const startIndex = _modules.findIndex((moduleId: number) => moduleId === moveModule.id);
					// i is either 1 or -1 indicating move up or down in the list
					const canMoveUp = !startIndex ? direction !== -1 : true;

					// if start index is 0 and direction is up, leave the module where it is!
					const newIndex = canMoveUp ? startIndex + direction : startIndex;

					const [removedModule] = _modules.splice(startIndex, 1);
					_modules.splice(newIndex, 0, removedModule);
					// placeholder for now
					return {
						...module,
						modules: _modules
					};
				}),
			};

			batch(() => {
				if (!token) return;
				// update db
				dispatch(updateSession(updatedSession, token));
				// update redux
				dispatch(updateWorkingSession(updatedSession));
			});

		}
	}

	const makeBackgroundStylingOverrides = useCallback((colorKey: string) => {
		const isColor = !colorKey.includes('.');
		const isRestoringDefault = colorKey === "";

		//If we are restoreing default we want it to just be a blank string, if changing color we want the classname with appended"-background"
		if (isColor && !isRestoringDefault) {
			colorKey += "-background";
		}
		return {
			color: page_module?.styling_overrides?.background?.color ?? '',
			image: { is_on: false, src: page_module?.styling_overrides?.background?.image.src ?? '' },
			[isColor ? 'color' : 'image']: isColor ? colorKey : { is_on: true, src: colorKey }
		};
	}, [page_module?.styling_overrides?.background?.color, page_module?.styling_overrides?.background?.image.src]);

	const handleFinishStyling = useCallback((colorKey: string) => {
		const background = makeBackgroundStylingOverrides(colorKey);

		if (hasPageModuleControls(page_module)) {
			// dispatch update session module here 
			dispatch(
				updatePageModule({
					...page_module,
					styling_overrides: {
						...page_module.styling_overrides,
						background
					}
				})
			);
		}
		setOpenStyleModal(false);
	}, [dispatch, makeBackgroundStylingOverrides, page_module]);

	const handleCancelStyling = useCallback(() => {
		setOpenStyleModal(false);
	}, []);

	const module_grouping = useMemo(() => {
		return workingSession?.module_grouping?.find((module: PageModuleGroupModules) => {
			// find correct module_group
			return module.modules.some((moduleId: number) => moduleId === page_module.id);
		});
	}, [page_module.id, workingSession?.module_grouping]);

	const modulesWithoutArrows = isModuleGroupingV2 // Only do the module check if we're on a V2 event
		&& ([ModuleGroupingTypes.Engage].includes(module_grouping?.type || "") // Always hid arrows for Engage
			|| ([ModuleGroupingTypes.custom].includes(module_grouping?.type || "") && module_grouping?.modules.length === 1)); // only hide arrows if there is only one module

	return (
		<div className={`session-module-controls ${children ? 'has-children' : ''}`}>
			<div className="button-container">
				{
					display.editColors && (
						<button
							className="round"
							onClick={() => setOpenStyleModal(true)}
						>
							<Icon name={ICONS.PAINTBRUSH} size={12} color={COLORS.WHITE} />
						</button>
					)
				}
				{(!isModuleGroupingV2 && display.addContent) &&
					<button className="button round" onClick={() => openEditModal()}>
						<Icon
							name={ICONS.EDIT}
							color={COLORS.WHITE}
							size={12}
							backgroundColor={COLORS.DARK_GREY}
						/>
					</button>
				}
				{(isModuleGroupingV2 && isAboveTheFold && isDesktop && display.addContent) &&
					<button onClick={() => {
						openEditModal();
						handleSetPanelLayoutType(page_module.type);
					}}>
						<Icon
							name={ICONS.EDIT}
							color={COLORS.WHITE}
							size={12}
						/>
						Add
					</button>
				}
				{(isModuleGroupingV2 && (isBelowTheFold || isAboveTheFold && !isDesktop) && display.addContent) &&
					<button onClick={() => {
						openEditModal();
						handleSetPanelLayoutType(page_module.type);
					}}>
						<Icon
							name={ICONS.EDIT}
							color={COLORS.WHITE}
							size={12}
							backgroundColor={COLORS.DARK_GREY}
						/>
						Edit
					</button>
				}
				{
					display.changeSectionPosition && !modulesWithoutArrows && (
						<>
							<button className="button round arrow-up" onClick={() => changeModuleIndex(-1, page_module)} >
								<Icon
									name={ICONS.ARROW_UP_THIN}
									color={COLORS.WHITE}
									size={12}
									backgroundColor={COLORS.DARK_GREY}
								/>
							</button>
							<button className="button round arrow-down" onClick={() => changeModuleIndex(1, page_module)}>
								<Icon
									name={ICONS.ARROW_UP_THIN}
									color={COLORS.WHITE}
									size={12}
									backgroundColor={COLORS.DARK_GREY}
								/>
							</button>
						</>
					)
				}
				{/* 
        el says no to this, keeping it just in case
        <button className="button round">
          <Icon
            name={ICONS.DELETE}
            color={COLORS.WHITE}
            size={12}
            backgroundColor={COLORS.DARK_GREY}
          />
        </button>
        */}
			</div>
			{children}
			<EditSectionColorsModal
				open={openStyleModal}
				title={EContentColorOverrideNames.background}
				backgroundImage={workingPageModule?.styling_overrides?.background?.image.src}
				onFinish={handleFinishStyling}
				onClose={handleCancelStyling}
			/>
		</div>
	);
};

export default Controls;
